import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkEmergencyLevel } from '../../../interface/WkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const WkEmergencyLevelListConfig: CmsColumnDef<WkEmergencyLevel>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Temps en heures', id: 'timeInHours', Filter: CmsTableFilter.Number },
];

/**
 * Liste des niveaux d'urgence
 */
export const WkEmergencyLevelList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_EMERGENCYLEVEL_CREATE])
    ? [<AddNavigationButton title="Ajouter un niveau d'urgence" to="/castres/work/emergencylevel/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des niveaux d'urgence"
      route={APIRoute.WkEmergencyLevel}
      columns={WkEmergencyLevelListConfig}
      navigateTo={(id: number) => `/castres/work/emergencylevel/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'un niveau d'urgence
 * @param id Id du niveau d'urgence à éditer
 * @param setHttpCodePage Fonction pour changer le code http de la page
 */
export const WkEmergencyLevelCreateEdit: FC = ({ id }: any) => (
  <CmsForm
    id={id}
    currentUrl="/castres/work/emergencylevel/"
    route={APIRoute.WkEmergencyLevel}
    title="un niveau d'urgence"
    thin
  >
    <CmsFormInput.Text id="name" label="Nom" required max={50} />
    <CmsFormInput.Text id="code" label="Code" required max={50} />
    <CmsFormInput.Number id="timeInHours" label="Temps en heures" required />
  </CmsForm>
);
