import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlPriceScheduleGroupType } from '../../../interface/BlType';
import ROLE from '../../../constant/role.constant';

const wkUnitConfigList: CmsColumnDef<BlPriceScheduleGroupType>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
];

export const BlPriceScheduleGroupTypeList: FC = () => {
  return (
    <CmsFrontendTable
      title="Type de bordereau"
      route={APIRoute.BlPriceScheduleGroupType}
      columns={wkUnitConfigList}
      navigateTo={(id: number) => `/castres/billing/priceschedulegrouptype/${id}/edit`}
      setFiltersInUrl
      actions={[
        <AddNavigationButton
          title="Ajouter d'un type de bordereau"
          to="/castres/billing/priceschedulegrouptype/create"
          access={[ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULEGROUPETYPE_CREATE]}
        />,
      ]}
    />
  );
};

export const BlPriceScheduleGroupTypeCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlPriceScheduleGroupType, title: 'type de bordereau' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/priceschedulegrouptype/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
    </CmsForm>
  );
};
