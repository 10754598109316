import { fetchWithAuth, getHeaderWithToken } from '../helper/handle-request';
import { API_URL } from '../constant/API_URL';
import {
  checkStatus,
  handleBlobResponse,
  handleCrudErrorNotif,
  handleJsonResponse,
  handleTextResponse,
} from '../helper/handle-response';
import { IdLabel } from '../interface/CommonType';
import NotificationService from './NotificationService';
import Utils from '../helper/Utils';

/**
 * Récupère une Liste de type T (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param params Paramètres de la requête
 * @param omitError Si true, ne renvoie pas d'erreur
 */
function getList<T>(target: string, params = '', omitError = false): Promise<Array<T>> {
  return fetchWithAuth({
    url: `${API_URL}${target}${params}`,
  })
    .then(handleJsonResponse)
    .catch((e) => handleCrudErrorNotif(e, omitError));
}

/**
 * Récupère une Liste de type IdLabel [{id: 0, label: ''}] (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param params Paramètres de la requête
 */
function getSimpleList(target: string, params?: string | number): Promise<Array<IdLabel>> {
  let url = `${API_URL}${target}/Simplified`;
  if (params && typeof params == 'number') url += '/' + params;
  else if (params) url += params;
  return fetchWithAuth({ url }).then(handleJsonResponse).catch(handleCrudErrorNotif);
}

/**
 * Récupère un objet de type T (connexion avec Token déjà géré)
 * @param target URL de la requête
 */
function getCustomObject<T>(target: string): Promise<T> {
  return fetchWithAuth({
    url: `${API_URL}${target}`,
  })
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif);
}

/**
 * Récupère une Liste de type IdLabel [{id: 0, label: ''}] (connexion avec Token déjà géré)
 * méthode utilisée pour l'auto-suggestion de la recherche
 * @param target URL de la requête
 * @param search Paramètres de la requête
 */
function getSuggestedList(target: string, search: object): Promise<Array<IdLabel>> {
  const url = `${API_URL}${target}/Suggested${Utils.queryObject(search)}`;
  return fetchWithAuth({ url }).then(handleJsonResponse).catch(handleCrudErrorNotif);
}

/**
 * Génère une référence depuis le serveur qui assure l'unicité de celle-ci (connexion avec Token déjà géré)
 * @param target le type de référence à générer
 */
function getGeneratedRef(target: string): Promise<any> {
  return fetchWithAuth({
    url: `${API_URL}RefGenerator/${target}`,
  })
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif);
}

/**
 * Récupère un objet de type T (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param id ID de l'objet à récupérer
 * @param omitError Si true, ne renvoie pas d'erreur
 */
function getById<T>(target: string, id: number | string, omitError = false): Promise<T> {
  return fetchWithAuth({
    url: `${API_URL}${target}/${id}`,
  })
    .then(handleJsonResponse)
    .catch((e) => handleCrudErrorNotif(e, omitError));
}

/**
 * Envoie un objet de type T par la méthode POST (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param item Objet à envoyer
 * @param isPut Si true, utilise la méthode PUT au lieu de POST
 */
function post<T>(target: string, item: any, isPut = false): Promise<T> {
  if (!!item && typeof item == 'object' && !!item?.id) item.id = +item.id;
  return fetchWithAuth({
    url: `${API_URL}${target}${item?.id && isPut ? '/' + item.id : ''}`,
    method: isPut ? 'PUT' : 'POST',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  })
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif);
}

interface WithCount<T> {
  count: number;
  summary?: any[];
  data: T[];
}

function postWithFilter<T>(target: string, filter: any): Promise<WithCount<T>> {
  return post(target + '/WithFilter', filter);
}

/**
 * Envoie un objet de type T par la méthode PUT (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param item Objet à envoyer
 */
function put<T>(target: string, item: any): Promise<T> {
  if (!!item && typeof item == 'object' && item?.id !== null) item.id = +item.id;
  return fetchWithAuth({
    url: `${API_URL}${target}${item?.id ? '/' + item.id : ''}`,
    method: 'PUT',
    body: JSON.stringify(item),
    headers: { 'Content-Type': 'application/json' },
  })
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif);
}

/**
 * Supprime un objet à partir de son ID (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param id ID de l'objet à supprimer
 * @param withJsonResponse Si true, signifie que le backend dévrait renvoyer un objet JSON au lieu d'un simple texte
 */
function deleteById(target: string, id: number | string, withJsonResponse = false): any {
  if (id) target += '/' + id;
  return fetchWithAuth({
    url: `${API_URL}${target}`,
    method: 'DELETE',
  })
    .then(withJsonResponse ? handleJsonResponse : handleTextResponse)
    .catch(handleCrudErrorNotif);
}

/**
 * Supprime une liste d'objets (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param body Liste des Ids à supprimer
 */
function deletes(target: string, body: number[]) {
  return fetchWithAuth({
    url: `${API_URL}${target}`,
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  })
    .then(handleTextResponse)
    .catch(handleCrudErrorNotif);
}

/**
 *  Récupère un fichier au format Blob (connexion avec Token déjà géré)
 * @param target URL de la requête
 * @param body Corps de la requête (optionnel, paramètre de la requête)
 * @param ifModifiedSince Date de la dernière modification du fichier (optionnel, paramètre de la requête)
 * @param omitError Si true, n'affiche pas les erreurs
 */
function getBlob(target: string, body?: any, ifModifiedSince?: Date | null, omitError = false): Promise<Blob> {
  let headers: { [key: string]: string } = { 'Content-Type': 'application/json', Accept: 'application/octet' };
  if (ifModifiedSince !== undefined) headers['If-Modified-Since'] = ifModifiedSince?.toUTCString() ?? '';
  let payload: any = { url: `${API_URL}${target}`, headers };
  if (body) payload = { ...payload, method: 'POST', body: JSON.stringify(body) };
  return fetchWithAuth(payload)
    .then(handleBlobResponse)
    .catch((response) => {
      handleCrudErrorNotif(response, omitError).then(() => {});
      return new Blob(); // pour que le composant appelant arrête l'animation de chargement et affiche que le fichier n'est pas disponible
    });
}

/**
 * Envoie un/des fichier(s) au format File ou File[] (connexion avec Token déjà géré)
 * @param url URL de la requête
 * @param file Fichier à envoyer
 */
function postFile(url: string, file: File | File[]): any {
  const formData: FormData = new FormData();
  if (Array.isArray(file)) for (let i = 0; i < file.length; i++) formData.append('file-' + i, file[i], file[i].name);
  else formData.append('file', file, file.name);
  const finalHeaders: Headers = new Headers({ ...getHeaderWithToken() });
  return fetch(`${API_URL}${url}`, {
    method: 'POST',
    headers: finalHeaders,
    body: formData,
  })
    .then(checkStatus)
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif);
}

/**
 * Méthode POST, mais avec un FormData au lieu d'un JSON (connexion avec Token déjà géré)
 * @param url URL de la requête
 * @param object Objet à envoyer (FormData)
 * @param isPut Si true, utilise la méthode PUT au lieu de POST
 */
function postFormData<T>(url: string, object: any, isPut = false): Promise<T> {
  const finalHeaders: Headers = new Headers({ ...getHeaderWithToken() });
  return fetch(`${API_URL}${url}${object?.id && isPut ? '/' + object.id : ''}`, {
    method: isPut ? 'PUT' : 'POST',
    headers: finalHeaders,
    body: mapFormData(object),
  })
    .then(checkStatus)
    .then(handleJsonResponse)
    .catch(handleCrudErrorNotif);
}

/**
 * Méthode POST, mais avec des FormData au lieu de JSON (connexion avec Token déjà géré)
 * @param url URL de la requête
 * @param objectList Liste d'objets à envoyer (FormData)
 */
async function postMultiple(url: string, objectList: object[]): Promise<boolean> {
  if (!objectList || objectList.length === 0) return false;
  try {
    for (let obj of objectList) await postFormData(url, obj);
  } catch (e: any) {
    NotificationService.error(e?.message ?? "Une erreur s'est produite");
    return false;
  }
  return true;
}

/**
 * Effectue plusieurs appèles à la fois et renvoie un objet contenant les résultats
 * @param fetchList Liste des URL à appeler
 */
async function getMultiple(fetchList?: string[]): Promise<any> {
  if (!fetchList || fetchList.length === 0) return {};
  const callList = [];
  for (let fetch of fetchList) callList.push(CRUD.getList<any>(fetch));
  const resultList: any = await Promise.all(callList);
  const result: any = {};
  for (let i = 0; i < fetchList.length; i++) result[fetchList[i]] = resultList[i];
  return result;
}

/**
 * Transforme un objet en FormData pour l'envoyer à l'API
 * @param obj Objet à transformer
 */
function mapFormData(obj: object): FormData {
  const formData: FormData = new FormData();
  if (!obj) return formData;
  for (let [key, value] of Object.entries(obj)) {
    if (value === undefined || value === null) continue;
    if (Object.prototype.toString.call(value) === '[object Date]') value = value.toISOString();
    if (Array.isArray(value)) for (let val of value) formData.append(key, val);
    else formData.append(key, value);
  }
  return formData;
}

const CRUD = {
  getList,
  getSimpleList,
  getCustomObject,
  getSuggestedList,
  getGeneratedRef,
  getById,
  post,
  postFile,
  put,
  deleteById,
  deletes,
  getBlob,
  postFormData,
  postMultiple,
  getMultiple,
  postWithFilter,
};

export default CRUD;
