import React from 'react';
import StorageIcon from '@mui/icons-material/Storage';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Stock: NavProps = {
  name: 'Stock',
  icon: <StorageIcon />,
  subList: [
    {
      name: 'Stock',
      linkV1: 'castres/stock/inventory/list',
      feature: ROLE.ADMIN_STOCK_INVENTORY_LIST,
    },
    {
      name: 'Mouvements',
      linkV1: 'castres/stock/movement/list',
      feature: ROLE.ADMIN_STOCK_MOVEMENT_LIST,
    },
    {
      name: 'Mouvements en attente',
      linkV1: 'castres/stock/movementgroup/list',
      feature: ROLE.ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_LIST,
    },
    {
      name: 'Réapprovisionnement',
      linkV1: 'castres/stock/stock/replenishment',
      feature: [
        ROLE.ADMIN_STOCK_INVENTORY_LIST,
        ROLE.ADMIN_STOCK_MOVEMENT_LIST,
        ROLE.ADMIN_CONFIGURATION_STOCK_MOVEMENTGROUP_LIST,
      ],
      //Si une des features au-dessus
    },
  ],
};
