import React, { CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import AccessFilter from '../../helper/AccessFilter';
import APIRoute from '../../constant/API.constant';
import { useNavigate } from 'react-router-dom';
import CRUD from '../../service/CRUD.service';
import LoadingScreen from '../../component/LoadingScreen';
import { CmsPaper } from '../../component/shared/Ui';
import { InputUI, UI, Buttons } from '../../component/shared';
import { UsGroups } from '../../interface/UsType';
import { IdLabel, IdLabelValue } from '../../interface/CommonType';
import NotificationService from '../../service/NotificationService';
import { ListItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import './user.scss';
import { GlobalContext } from '../../context/Global.context';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import ROLE from '../../constant/role.constant';

/**
 * Configuration des colonnes de la liste des groupes
 */
const usGroupsConfigList: CmsColumnDef<UsGroups>[] = [
  { header: 'Nom du groupe', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Commentaire', id: 'comment', Filter: CmsTableFilter.Text },
];

/**
 * Liste des groupes
 */
export const UsGroupList: FC = () => {
  const actions = AccessFilter([ROLE.SONATA_USER_ADMIN_GROUP_CREATE])
    ? [<Buttons.Nav.Add title="Ajouter un groupe" to="/sonata/user/group/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des groupes"
      route={APIRoute.UsGroups}
      columns={usGroupsConfigList}
      navigateTo={(id: number) => `/sonata/user/group/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

interface UsGroupsListProps {
  // Identifiant du groupe
  id: number;
  // Groupe
  group: UsGroups;
  // Fonction de mise à jour du groupe
  setGroup: React.Dispatch<UsGroups>;
  // Liste des permissions
  groupList: any[];
}

/**
 * Composant de la liste des permissions
 * @param id Identifiant du groupe
 * @param groupList Liste des permissions
 * @param group Groupe
 * @param setGroup Fonction de mise à jour du groupe
 */
const UsRolesList: FC<UsGroupsListProps> = ({ id, groupList, group, setGroup }: any) => {
  const [search, setSearch] = useState<string>();
  const navigate = useNavigate();
  const [currentOpenMenu, setCurrentOpenMenu] = useState<number>(-1);
  const memoizedList = useMemo(
    () => groupList?.filter((x: any) => !search || x.label.indexOf(search) > -1),
    [groupList, search],
  );

  const send = () => {
    // Exclure les champs 'userListSimplified' et 'roles' de l'objet 'group'
    const { userListSimplified, roles, ...groupWithoutFields } = group;
    CRUD.post<UsGroups>(APIRoute.UsGroups, groupWithoutFields, !!id).then(() => {
      NotificationService.success(!id ? 'Groupe Créé' : 'Groupe mis à jour');
      navigate('/sonata/user/group/list');
    });
  };

  const Delete = () => {
    CRUD.deleteById(APIRoute.UsGroups, id).then(() => {
      NotificationService.info('Groupe supprimé');
      navigate('/sonata/user/group/list');
    });
  };

  const handleItemUpdate = (id: number) => {
    if (!group.intPermissionList) return;
    if (!group.intPermissionList.find((x: number) => x === id)) group.intPermissionList.push(id);
    else group.intPermissionList = group.intPermissionList.filter((x: number) => x !== id);
    setGroup(Object.assign({}, group));
  };

  const countGroupChecked = (groupId: number): number => {
    const intList = groupList.find((x: IdLabel) => x.id === groupId);
    return intList.value.filter((x: IdLabel) => group.intPermissionList?.includes(x.id)).length;
  };

  const isGroupChecked = (groupId: number): true | false | 'half' => {
    const intList = groupList.find((x: IdLabel) => x.id === groupId);
    const count = intList.value.filter((x: IdLabel) => group.intPermissionList?.includes(x.id)).length;
    return count === 0 ? false : count === intList.value.length ? true : 'half';
  };

  const handleSubMenuToggle = (groupId: number, isCheckboxClick = false) => {
    setCurrentOpenMenu(currentOpenMenu !== groupId || isCheckboxClick ? groupId : -1);
    if (!isCheckboxClick) return;
    const isChecked = isGroupChecked(groupId);
    let permissionList: number[] = group.intPermissionList ?? [];
    const groupPermission: number[] = Array.from(groupList.find((x: any) => x.id === groupId).value, (x: any) => x.id);
    if (!isChecked) permissionList = permissionList.concat(groupPermission);
    else permissionList = permissionList.filter((x) => !groupPermission.includes(x));
    setGroup({ ...group, intPermissionList: permissionList });
  };

  const handleLabel = (label: string) => setGroup({ ...group, name: label });
  const handleComment = (comment: string) => setGroup({ ...group, comment });
  const menuCheckbox = (id: number) => handleSubMenuToggle(id, true);
  const isChecked = (id: number): boolean => !!group.intPermissionList?.find((x: number) => x === id);
  const title = id ? 'Édition du groupe: ' + group.name : "Création d'un nouveau groupe";

  return (
    <CmsPaper
      title={title}
      className="group-container"
      actions={[
        <Buttons.Valid color="primary" onClick={send}>
          {!id ? 'Créer' : 'Mettre à jour'}
        </Buttons.Valid>,
        <Buttons.Nav.ArrowBack to="/sonata/user/group/list" title="Retourner à la liste" />,
        !!id && (
          <Buttons.Delete color="primary" onClick={Delete} access={[ROLE.SONATA_USER_ADMIN_GROUP_DELETE]}>
            Supprimer
          </Buttons.Delete>
        ),
      ]}
    >
      <InputUI.DebouncedInput value={group.name} onChange={handleLabel} label="Nom du groupe" />
      <InputUI.DebouncedInput value={group.comment} onChange={handleComment} label="Commentaire" multiline />
      <InputUI.DebouncedInput onChange={(x: any) => setSearch(x?.toUpperCase())} label="Rechercher dans la liste" />
      <UI.MenuList title="" style={menuStyle} className="styled-scroll">
        {memoizedList.map((group: any, key: number) => (
          <UI.SubMenuItem
            isOpen={currentOpenMenu === group.id}
            key={group.id}
            id={group.id}
            title={
              <>
                <div className="role-clicker" onClick={() => handleSubMenuToggle(group.id)} />
                <UI.CheckboxCustom id={group.id} onClick={menuCheckbox} checkState={isGroupChecked(group.id)} />
                <h3 onClick={() => handleSubMenuToggle(group.id)} style={{ display: 'inline' }}>
                  ( {countGroupChecked(group.id)} ) {group.label}
                </h3>
              </>
            }
          >
            {group.value?.map((item: any, i: number) => (
              <UI.MenuItemCheckBox
                key={item.id}
                style={{ paddingLeft: '5em' }}
                id={item.id}
                onClick={handleItemUpdate}
                isCheck={isChecked(item.id)}
                label={item.label + (item.value ? ' (' + item.value + ')' : '')}
              />
            ))}
          </UI.SubMenuItem>
        ))}
      </UI.MenuList>
    </CmsPaper>
  );
};

const menuStyle: CSSProperties = {
  border: '2px solid #DDD',
  height: '65vh',
  overflowY: 'auto',
};

const pageStyle: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  gridColumnGap: '1em',
};

/**
 * Page de création et d'édition de groupes
 * @param id id du groupe à éditer
 */
export const UsGroupsCreateEdit: FC = ({ id }: any) => {
  const [group, setGroup] = useState<UsGroups>();
  const [groupList, setGroupList] = useState<any[]>();
  const navigate = useNavigate();
  const canAccess = useMemo(() => AccessFilter([ROLE.SONATA_USER_ADMIN_USER_VIEW]), []);

  useEffect(() => {
    CRUD.getList<any>(APIRoute.UsRole).then(setGroupList);
    if (!id) setGroup({});
    else CRUD.getById<any>(APIRoute.UsGroups, id).then(setGroup);
  }, [setGroupList, id, navigate]);

  if (!group || !groupList) return <LoadingScreen />;

  if (!canAccess) return <UsRolesList id={id} group={group} groupList={groupList} setGroup={setGroup} />;
  return (
    <div style={pageStyle}>
      <UsRolesList id={id} group={group} groupList={groupList} setGroup={setGroup} />
      <UserInThisGroup list={group?.userListSimplified ?? []} />
    </div>
  );
};

const UserInThisGroup: FC<{ list: IdLabel[] }> = ({ list }) => {
  const navigate = useNavigate();
  const goto = (id: number) => navigate(`/sonata/user/user/${id}/show`);
  const [search, setSearch] = useState<string>();
  const memoizedList = useMemo(() => {
    return list.filter((x) => !search || x.label.toUpperCase().includes(search));
  }, [list, search]);

  return (
    <CmsPaper title="Utilisateur(s) lié(s) à ce groupe" style={{ maxHeight: '92vh' }} scrollable>
      <InputUI.DebouncedInput onChange={(x: any) => setSearch(x?.toUpperCase())} label="Rechercher" />
      <UI.MenuList title="" style={{ overflowY: 'auto' }} className="styled-scroll">
        {memoizedList.map(({ id, label }) => (
          <UI.MenuItemClick onClick={() => goto(id)} label={label} />
        ))}
      </UI.MenuList>
    </CmsPaper>
  );
};

interface UsRolesReverseListProps {
  // Liste des rôles/permissions
  roleList: IdLabelValue[];
  // Identifant du rôle sélectionné
  selectedRole: number;
  // Fonction de mise à jour du rôle sélectionné
  setSelectedRole: React.Dispatch<number>;
}

/**
 * Liste des rôles inversée (selectionner un rôle et afficher les groups qui ont ce rôle)
 * @param roleList Liste des rôles/permissions
 * @param selectedRole Rôle sélectionné
 * @param setSelectedRole Fonction de mise à jour du rôle sélectionné
 */
const UsRolesReverseList: FC<UsRolesReverseListProps> = ({ roleList, selectedRole, setSelectedRole }) => {
  const [search, setSearch] = useState<string>();
  const [currentOpenMenu, setCurrentOpenMenu] = useState<number>(-1);
  const memoizedList = useMemo(
    () => roleList?.filter((x: any) => !search || x.label.indexOf(search) > -1),
    [roleList, search],
  );

  const handleSubMenuToggle = (groupId: number) => {
    setCurrentOpenMenu(currentOpenMenu !== groupId ? groupId : -1);
  };

  return (
    <CmsPaper title="Liste des rôles" className="group-container">
      <InputUI.DebouncedInput onChange={(x: any) => setSearch(x?.toUpperCase())} label="Rechercher dans la liste" />
      <UI.MenuList title="" style={menuStyle} className="styled-scroll role-list">
        {memoizedList?.map((group: any, key: number) => (
          <UI.SubMenuItem
            isOpen={currentOpenMenu === group.id}
            key={group.id}
            id={group.id}
            title={
              <h3
                onClick={() => handleSubMenuToggle(group.id)}
                style={{ display: 'inline', marginLeft: '-5em', paddingLeft: '5em' }}
              >
                {group.label}
              </h3>
            }
          >
            {group.value?.map((role: IdLabelValue) => (
              <ListItem
                button
                key={role.id}
                className={'role' + (selectedRole === role.id ? ' selected' : '')}
                onClick={() => setSelectedRole(role.id)}
              >
                {role.label} {role.value ? '(' + role.value + ')' : ''}
              </ListItem>
            ))}
          </UI.SubMenuItem>
        ))}
      </UI.MenuList>
    </CmsPaper>
  );
};

/**
 * Liste des groupes liés à un rôle
 * @param roleId Identifiant du rôle
 */
const UsGroupListByRole: FC<{ roleId: number }> = ({ roleId }) => {
  const [search, setSearch] = useState<string>();
  const [groupList, setGroupList] = useState<UsGroups[]>([]);
  const memoizedList = useMemo(() => {
    return groupList.filter((x) => !search || x.name?.toUpperCase().includes(search));
  }, [groupList, search]);
  const { theming } = useContext(GlobalContext);

  useEffect(() => {
    CRUD.getList<any>(APIRoute.UsGroups).then(setGroupList);
  }, []);

  const handleGroupClick = (id: number, isSubtraction: boolean) => {
    const finalRoleId = isSubtraction ? -roleId : roleId;
    CRUD.put<IdLabelValue>(APIRoute.UsRole + `/${id}`, { id: finalRoleId }).then((result) => {
      const group = groupList.find((x) => x.id === id);
      if (!group) return;
      group.intPermissionList = result.value;
      setGroupList([...groupList]);
      NotificationService.info(`${result.label} ${isSubtraction ? 'retiré' : 'ajouté'} au groupe ${group.name}`);
    });
  };

  if (roleId < 0 || groupList.length === 0) return <LoadingScreen />;

  return (
    <CmsPaper title="Liste des groupes" style={{ maxHeight: '92vh' }} scrollable>
      <InputUI.DebouncedInput onChange={(x: any) => setSearch(x?.toUpperCase())} label="Rechercher" />
      <UI.MenuList title="" style={{ overflowY: 'auto' }} className="styled-scroll reverse-group">
        {memoizedList.map(({ id, name, intPermissionList }) => (
          <UI.MenuItemClick
            icon={
              intPermissionList?.includes(roleId) ? (
                <CheckIcon style={{ color: theming.get().cms.severity.valid }} />
              ) : (
                <CloseIcon style={{ color: theming.get().cms.severity.error }} />
              )
            }
            onClick={() => handleGroupClick(id ?? -1, intPermissionList?.includes(roleId) ?? false)}
            label={name ?? ''}
          />
        ))}
      </UI.MenuList>
    </CmsPaper>
  );
};

/**
 * Page de gestion des rôles inversés
 */
export const UsRoleReverseListPage: FC = () => {
  const [roleList, setRoleList] = useState<any[]>();
  const [selectedRole, setSelectedRole] = useState<number>(-1);

  useEffect(() => {
    CRUD.getList<any>(APIRoute.UsRole).then(setRoleList);
  }, []);

  if (!roleList || roleList.length === 0) return <LoadingScreen />;
  return (
    <div style={pageStyle}>
      <UsRolesReverseList roleList={roleList} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
      {selectedRole > -1 && <UsGroupListByRole roleId={selectedRole} />}
    </div>
  );
};
