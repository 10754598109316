import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { ClCompany } from '../../../interface/ClType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const ClCompanyConfigList: CmsColumnDef<ClCompany>[] = [{ header: 'Nom', id: 'name', Filter: CmsTableFilter.Text }];

export const ClCompanyList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_COMPANY_CREATE])
    ? [<AddNavigationButton title="Ajouter une enseigne" to="/castres/client/company/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des enseignes"
      route={APIRoute.ClCompany}
      columns={ClCompanyConfigList}
      navigateTo={(id: number) => `/castres/client/company/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const ClCompanyCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/client/company/', route: APIRoute.ClCompany, title: 'Enseignes' };
  return (
    <CmsForm id={id} {...props} thin>
      <CmsFormInput.Text id="name" label="Nom" required min={3} max={100} />
    </CmsForm>
  );
};
