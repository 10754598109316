import React, { useMemo, useState } from 'react';
import { TimeReportingFormStep, WkTimeReportingDay } from '../page/timeReporting/TimeReporting';
import { IdLabel } from '../interface/CommonType';
import AccessFilter from '../helper/AccessFilter';
import { authenticationService } from '../service/Authentication.service';
import ROLE from '../constant/role.constant';
import { WkIntervention } from '../interface/WkType';

export class WkTimeReportingWeek {
  // Identifiant de la semaine
  id?: number;
  // Nom du technicien
  technicianName?: string;
  // En cours de chargement
  isReloading: boolean = false;
  // Validé par le RH
  validatedBy?: string;
  // Date de validation
  validatedAt?: Date;
  // Identifiant du technicien
  userId: number;
  // Identifiant du service
  serviceId?: number;
  // Nom du service
  serviceLabel?: string;
  // Date du lundi de la semaine
  mondayDate: Date;
  // Statut de la semaine, se référer à TimeReportingFormStep
  status: TimeReportingFormStep;
  // Liste des jours de la semaine, se référer à WkTimeReportingDay
  dayList: Array<WkTimeReportingDay>;
  // Liste des jours de la semaine (non modifié pour rollback front), se référer à WkTimeReportingDay
  dayListUnModified: Array<WkTimeReportingDay>;
  // Liste des jours de la semaine (non modifié pour rollback back), se référer à WkTimeReportingDay
  originalUserDayList: Array<WkTimeReportingDay>;
  // Commentaire du technicien
  comment?: string;
  // Temps de travail total
  totalWorkedTime?: number;
  // Temps de route total
  totalRouteTime?: number;
  // Temps d'absence total
  totalAbsenceTime?: number;
  // Temps d'absence payé total
  totalPaidAbsenceTime?: number;
  // Temps de travail total
  totalTravelDistance?: number;
  // Comentaire du RH
  rhComment?: string;
  // Comentaire du manager
  supervisorComment?: string;
  // For list only, version string de la date du lundi de la semaine
  mondayDateText?: any | string;
  // à commenter
  weekDayListState?: Array<number>;
  // Nom du manager ayant validé
  supervisorValidation?: string;
  // Nom du RH ayant validé
  rhValidation?: string;
  // Liste des mise en garde calculer par le backend
  warningList?: string[];
  // Temps de pause
  lunchTime?: number;
  // Enumération : 'Non forfaitisé : paie calculé avec temps de trajet / zone' // 'Non forfaitisé : paie uniquement en temps de travail' / 'Forfaitisé'
  // Utilsé pour les calculs de temps de travail
  packageStatus?: number;
  contractualHour?: number;
  haveArea?: boolean;
  fullTravelingPaid?: boolean;
  detached?: boolean;
  validatedWeekTeamUserList?: IdLabel[];

  constructor() {
    this.userId = 0;
    this.mondayDate = new Date();
    this.dayList = new Array<WkTimeReportingDay>();
    this.dayListUnModified = new Array<WkTimeReportingDay>();
    this.originalUserDayList = new Array<WkTimeReportingDay>();
    this.status = 0;
  }
}

/**
 * Contexte du planning de la semaine d'un technicien, à ne pas confondre avec le composant Scheduler / Planning
 */
const PlanningContext = React.createContext({
  planning: new WkTimeReportingWeek(),
  interventionOfThisWeek: [] as WkIntervention[],
  setInterventionOfThisWeek: (intervention: WkIntervention[]) => {},
  setPlanning: (planning: any) => {},
  canEdit: false,
});

/**
 * Provider du planning de la semaine d'un technicien, à ne pas confondre avec le composant Scheduler / Planning
 * @param props Enfant qui sera affiché
 */
const PlanningProvider = (props: { children: React.ReactNode }) => {
  const [planning, setPlanning] = useState<WkTimeReportingWeek>(new WkTimeReportingWeek());
  const [interventionOfThisWeek, setInterventionOfThisWeek] = useState<WkIntervention[]>([]);
  const canEdit = useMemo((): boolean => {
    if (planning.status > 2) return false;
    if (AccessFilter([ROLE.ADMIN_TIMEREPORTINGWEEK_VALIDATION])) return true;
    return (
      AccessFilter([ROLE.USER_IS_MANAGER]) &&
      planning.status < 2 &&
      planning.userId !== authenticationService.getCurrentUser().userId
    );
  }, [planning.status, planning.userId]);

  return (
    <PlanningContext.Provider
      value={{ planning, setPlanning, canEdit, interventionOfThisWeek, setInterventionOfThisWeek }}
    >
      {props.children}
    </PlanningContext.Provider>
  );
};

export { PlanningProvider, PlanningContext };
