import React, { FC } from 'react';
import { CmsColumnDef, CmsBackendTable } from '../../component/table/CmsTable';
import { Box, Link } from '@mui/material';
import APIRoute from '../../constant/API.constant';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../component/table/helper/CmsTableCell';
import { DurationCell, ActionCell } from '../../component/table/helper/customCells';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import DoneIcon from '@mui/icons-material/Done';
import AccessFilter from '../../helper/AccessFilter';
import { Alarm } from 'interface/Alarm';
import { TextEllipse } from 'component/shared/Ui';
import { BASE_URL } from 'constant/API_URL';
import { ClStation } from 'interface/ClType';
import ROLE from '../../constant/role.constant';

const columns: CmsColumnDef<Alarm>[] = [
  {
    header: 'Acquittée',
    id: 'acknowledged',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    defaultFilterValue: false,
    size: 50,
  },
  { header: 'Déclenchement', id: 'triggerDate', Filter: CmsTableFilter.Date, cell: CmsTableCell.Datetime, size: 70 },
  {
    header: 'Durée',
    id: 'duration',
    cell: (props: any) => (
      <DurationCell start={props?.row?.original?.triggerDate} stop={props?.row?.original?.endDate} />
    ),
  },
  {
    header: 'Dernière analyse',
    id: 'lastCheckDate',
    Filter: CmsTableFilter.Date,
    cell: CmsTableCell.RelativeDate,
  },
  {
    header: 'Type',
    id: 'typeCode',
    cell: (props: any) => <TextEllipse text={props?.row?.original?.typeName} title={props?.row?.original?.typeCode} />,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.WkAlarmAlxType,
      optionId: 'code',
      optionLabel: 'nameWithCode',
      // defaultFilterName: 'showOnCms',
      rawEndpoint: true,
      multiple: true,
    },
  },
  { header: 'Équipement', id: 'equipment', Filter: CmsTableFilter.Text, size: 60 },
  { header: 'Valeur', id: 'triggerValue', Filter: CmsTableFilter.Text },
  {
    header: 'Station',
    id: 'stationLvnetName',
    cell: (info) => (
      <Link
        key={info.row.original?.stationId}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/client/station/${info.row.original?.stationId}/show`}
        target="_blank"
      >
        {info.row.original?.stationLvnetName}
      </Link>
    ),
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.WkAlarmAlx}/Station`,
      optionId: 'lvnetName',
      optionLabel: 'label',
      rawEndpoint: true,
      multiple: true,
    },
  },
  {
    header: 'État / Défaut',
    id: 'statusOrDefault',
    Filter: CmsTableFilter.Bool,
    hide: true,
  },
  {
    header: 'Actions',
    id: 'actions',
    cell: (props: any) => (
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <ActionCell
          url="/api/WkAlarmAlx/{id}/ack"
          targetId={props?.row?.original?.id}
          isAvailable={!props?.row?.original?.acknowledged && AccessFilter([ROLE.ADMIN_ALARMALX_EDIT])}
          icon={<DoneIcon />}
          label="Acquitter l'alarme"
          confirmTitle="Confirmer l'acquittement"
          confirmText={`Acquitter l'alarme ${props?.row?.original?.typeName} sur le ${props?.row?.original?.equipment} de ${props?.row?.original?.stationLabel} ?`}
          successNotifText="L'alarme a bien été acquittée."
          cellClick={props.cell.cellClick}
        />
        <ActionCell
          url="/api/WkAlarmAlx/{id}/intervention"
          targetId={props?.row?.original?.id}
          isAvailable={AccessFilter([ROLE.ADMIN_ALARMALX_CREATE])}
          icon={<BuildIcon />}
          label="Créer une intervention"
          confirmTitle="Créer une intervention"
          confirmText={`Créer une intervention pour l'alarme ${props?.row?.original?.typeName} sur le ${props?.row?.original?.equipment} de ${props?.row?.original?.stationLabel} ?`}
          successNotifText="Une intervention vient d'être créée pour cette alarme."
          cellClick={props.cell.cellClick}
          urlOnSuccess={'/castres/work/intervention/{id}/edit'}
        />
        <ActionCell
          url="/api/WkAlarmAlx/{id}"
          method="DELETE"
          targetId={props?.row?.original?.id}
          isAvailable={AccessFilter([ROLE.ADMIN_ALARMALX_DELETE])}
          color="secondary"
          icon={<DeleteIcon />}
          label="Supprimer définitivement l'alarme"
          confirmTitle="Confirmer la suppression"
          confirmText={`Supprimer l'alarme ${props?.row?.original?.typeName} sur le ${props?.row?.original?.equipment} de ${props?.row?.original?.stationLabel} ?`}
          successNotifText="L'alarme a bien été supprimée."
          cellClick={(id: number) => {
            props.cell?.cellClick(id, 'delete');
          }}
        />
      </Box>
    ),
  },
];

export const AlarmList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des alarmes ALX"
      columns={columns}
      route={APIRoute.WkAlarmAlx}
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_ALARMALX_EXPORT])}
    />
  );
};

interface AlarmListStationProps {
  station: ClStation;
}

export const AlarmListStation: FC<AlarmListStationProps> = ({ station }) => {
  return (
    <CmsBackendTable
      title={`Alarmes ALX (${station.lvnetName})`}
      columns={columns.filter((col) => col.id !== 'stationLvnetName')}
      route={`${APIRoute.WkAlarmAlx}/ByStation/${station.id}`}
    />
  );
};
