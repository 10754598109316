import NotificationService from '../service/NotificationService';
import { authenticationService } from '../service/Authentication.service';

/**
 * Gère les réponses du serveur, déconnecte l'utilisateur si la réponse est 401 ou 403
 * Filtres les réponses pour renvoyer un message d'erreur si la réponse est 404
 * @param response La réponse du serveur
 */
export const handleResponse = (response: any) => {
  if (!response.ok) {
    if ([401, 403].includes(response.status) && !['/login', '/loginV2'].includes(window.location.pathname)) {
      // Une réponse 401 ou 403 déconnecte l'utilisateur pour des raisons de sécurité
      // NOTE comment for dev
      authenticationService.logout();
    } else if (response.status === 404) {
      NotificationService.error("Erreur d'adressage, veuillez remonter l'erreur au service informatique");
      throw response;
    }
    const error = response.statusText;
    console.log('error:', error);
    console.log('total response from error:', response);
    if (!!response?.message) NotificationService.error(response.message);
    throw new Error(error);
  }
  return response;
};

/**
 * Gère le parsing de la réponse du serveur en JSON (API REST)
 * @param response La réponse du serveur
 */
export async function handleJsonResponse(response: Response): Promise<any> {
  const handledResponse: Response = handleResponse(response);
  if (!handledResponse) return handledResponse;
  let result;
  try {
    // If the response is a success but the body is empty
    // the .json will throw an error, so we catch it here
    result = await handledResponse.json();
  } catch (e) {
    if (!handledResponse.ok) throw e;
    result = true;
  }
  return result;
}

/**
 * Gère le parsing de la réponse du serveur en texte
 * @param response La réponse du serveur
 */
export const handleTextResponse = (response: Response): Promise<any> => {
  const handledResponse: any = handleResponse(response);
  if (!handledResponse) return handledResponse;
  return handledResponse.text();
};

/**
 * Gère le parsing de la réponse du serveur en blob
 * @param response La réponse du serveur
 */
export const handleBlobResponse = (response: Response): Promise<any> => {
  const handledResponse: any = handleResponse(response);
  if (!handledResponse) return handledResponse;
  return handledResponse.blob();
};

/**
 * Gère la déconnexion ou l'affichage d'une page d'erreur en fonction du code de la réponse
 * @param response La réponse du serveur
 * @param setHttpCodePage La fonction qui permet d'afficher la page d'erreur
 */
export const handleErrorPage = (response: Response, setHttpCodePage: any) => {
  if (response.status === 401 && !['/login', '/loginV2'].includes(window.location.pathname))
    authenticationService.logout();
  else setHttpCodePage(response.status);
};

/**
 * Vérifie le code de la réponse du serveur et renvoie une erreur si le code est supérieur ou égal à 300
 * @param response La réponse du serveur
 */
export const checkStatus = (response: Response) => {
  if (response.status >= 200 && response.status < 400) return response;
  throw response;
};

/**
 * TODO handle new error thrower, this method is used to format notification service response.
 * Gère l'affichage des notifications d'erreur en fonction du code de la réponse.
 * Renvoi un booleen pour savoir si l'erreur a été gérée ou non.
 * @param response La réponse du serveur
 */
export const handleErrorNotif = async (response: Response): Promise<any> => {
  if (response.status === 401 && !['/login', '/loginV2'].includes(window.location.pathname))
    authenticationService.logout();
  if (!response.status) {
    NotificationService.error(`Serveur indisponible, veuillez contacter le service informatique`);
    return 'Serveur indisponible';
  } else if (response.status < 200 || response.status >= 300) {
    if ([405, 500].includes(response.status)) {
      NotificationService.error(`Erreur ${response.status} : ${errorMessage(response.status)}`);
      return errorMessage(response.status);
    }
    let responseContent: any = response;
    try {
      responseContent = await response.json();
    } catch (e) {
      console.error(e);
    }
    if (responseContent.errors && Object.keys(responseContent.errors).length) {
      // Errors from model validations
      const errorFields = Object.values(responseContent.errors);
      errorFields.forEach((errorField: any) => {
        errorField.forEach((message: string) => NotificationService.error(message));
      });
      return 'Multiple errors';
    } else if (responseContent.message) {
      // Custom errors thrown
      if (responseContent.message === 'Doublon détecté') return responseContent.message;
      NotificationService.error(responseContent.message);
      return responseContent.message;
    } else {
      // Default errors
      NotificationService.error(`Erreur ${response.status} : ${errorMessage(response.status)}`);
      return errorMessage(response.status);
    }
  }
  return "Pas d'erreur";
};

/**
 * // Gestion des érreurs pour le service CRUD
 * @param response La réponse du serveur
 * @param omitError Ne pas afficher de notification d'erreur
 */
export const handleCrudErrorNotif = async (response: any, omitError = false) => {
  if (omitError) return;
  const message = await handleErrorNotif(response);
  if (response?.status) throw new Error(response.status + ' : ' + message);
  if (response?.message === 'Internal Server Error') throw new Error('Erreur interne au serveur');
  throw new Error('Serveur indisponible');
};

/**
 * Renvoi un message d'erreur en fonction du code de la réponse
 * @param code Le code de la réponse
 */
const errorMessage = (code: number) => {
  if (code >= 300 && code < 400) return 'Erreur de redirection';
  else if (code === 400) return 'La syntaxe de la requête est erronée';
  else if (code === 403) return "Droits d'accès insuffisants pour effectuer cette action";
  else if (code === 404) return 'Ressource non trouvée';
  else if (code === 405) return 'Requête erronée';
  else if (code === 408) return "Temps d'attente de la requête dépassé";
  else if (code === 503 || code === 502) return 'Service temporairement indisponible';
  else return 'Erreur interne du serveur';
};
