import React, { FC, Fragment } from 'react';

import { NoContentMessage, SectionTitle } from 'component/shared/Ui';
import { confirmUpdate } from 'service/Import.service';
import { handleErrorNotif } from 'helper/handle-response';
import {
  ValidatedImportItem,
  ImpEquStationProcessedUpdate,
  ImpEquUpdate,
  ImpEquBulkUpdate,
  ValidatedBulkUpdate,
} from 'interface/ImportType';
import {
  ImportValidatedAccordion,
  EquipementPerStation,
  ImportItem,
  equals,
  getLabel,
  UpdatedLabelValue,
  UpdatedField,
  BulkUpdateItem,
} from '../ImportUtils';

interface ImportUpdatesProps {
  // Id de l'import
  idImport: string;
  // Équipements à mettre à jour
  updates: ImpEquStationProcessedUpdate[] | undefined;
  // Mises à jour groupées
  bulkUpdates: ImpEquBulkUpdate[] | undefined;
  // Équipements validés
  validatedUpdates: ValidatedImportItem;
  // Fonction pour changer les équipements validés
  setValidatedUpdates: any;
  // Mises à jour groupées validées
  validatedBulks: ValidatedBulkUpdate[];
  // Fonction pour changer les mises à jour groupées validées
  setValidatedBulks: any;
}

/**
 * Composant pour afficher les équipements à mettre à jour
 * @param idImport id de l'import
 * @param updates équipements à mettre à jour
 * @param bulkUpdates mises à jour groupées
 * @param validatedUpdates équipements validés
 * @param setValidatedUpdates fonction pour changer les équipements validés
 * @param validatedBulks mises à jour groupées validées
 * @param setValidatedBulks fonction pour changer les mises à jour groupées validées
 */
const ImportUpdates: FC<ImportUpdatesProps> = ({
  idImport,
  updates,
  bulkUpdates,
  validatedUpdates,
  setValidatedUpdates,
  validatedBulks,
  setValidatedBulks,
}) => (
  <Fragment>
    {updates && bulkUpdates && (updates.length || bulkUpdates.length) ? (
      <Fragment>
        {(!!Object.keys(validatedUpdates).length || !!validatedBulks.length) && (
          <ImportValidatedAccordion validated={Object.keys(validatedUpdates).length + validatedBulks.length}>
            <BulkUpdateItems
              idImport={idImport}
              bulkUpdates={bulkUpdates}
              validatedBulks={validatedBulks}
              setValidatedBulks={setValidatedBulks}
              validated={true}
            />
            <EquipementPerStation
              idImport={idImport}
              stations={updates}
              validatedElements={validatedUpdates}
              setValidatedElements={setValidatedUpdates}
              EquipementComponent={ImportData}
              validated={true}
            />
          </ImportValidatedAccordion>
        )}

        {bulkUpdates.length - validatedBulks.length > 0 && (
          <Fragment>
            <SectionTitle title="Mises à jour groupées" />
            <BulkUpdateItems
              idImport={idImport}
              bulkUpdates={bulkUpdates}
              validatedBulks={validatedBulks}
              setValidatedBulks={setValidatedBulks}
            />
            <SectionTitle title="Mises à jour individuelles" />
          </Fragment>
        )}

        <EquipementPerStation
          idImport={idImport}
          stations={updates}
          validatedElements={validatedUpdates}
          setValidatedElements={setValidatedUpdates}
          EquipementComponent={ImportData}
        />
      </Fragment>
    ) : (
      <NoContentMessage>Aucune mise à jour disponible</NoContentMessage>
    )}
  </Fragment>
);

/**
 * Composant pour afficher les équipements à mettre à jour groupés
 * @param idImport id de l'import
 * @param bulkUpdates mises à jour groupées
 * @param validated si les mises à jour sont validées
 * @param validatedBulks mises à jour groupées validées
 * @param setValidatedBulks fonction pour changer les mises à jour groupées validées
 */
const BulkUpdateItems: FC<{
  idImport: string;
  bulkUpdates: ImpEquBulkUpdate[];
  validated?: boolean;
  validatedBulks: ValidatedBulkUpdate[];
  setValidatedBulks: any;
}> = ({ idImport, bulkUpdates, validated = false, validatedBulks, setValidatedBulks }) => (
  <Fragment>
    {bulkUpdates
      .filter(
        (bulkUpdate: ImpEquBulkUpdate) =>
          validatedBulks.some((validatedBulk) => equals(validatedBulk.modification, bulkUpdate.modification)) ===
          validated,
      )
      .map((bulkUpdate: ImpEquBulkUpdate, index) => {
        const accept = (callback: any) => {
          if (!validated) {
            const stationRefs = bulkUpdate.equipements.map((equipement) => equipement.ref);
            confirmUpdate('equipment', idImport, stationRefs)
              .then(() => {
                if (callback) callback();
              })
              .catch(handleErrorNotif);
          }
        };
        return (
          <BulkUpdateItem
            titleDialog="Équipements dans la mise à jour groupée"
            key={`${index}-${bulkUpdate.modification.attribute}`}
            acceptBulk={accept}
            modification={bulkUpdate.modification}
            elementLength={bulkUpdate.equipements.length}
            validated={validated}
            validatedBulks={validatedBulks}
            setValidatedBulks={setValidatedBulks}
          >
            {bulkUpdate.equipements.map((equipement: ImpEquUpdate, index) => {
              const { ref, label, station, type } = equipement;
              const labelValues = {
                Station: station.label,
                Type: type.label,
              };
              return (
                <ImportItem key={`${index}-${ref}`} label={`${ref} - ${label}`} labelValues={labelValues} noActions />
              );
            })}
          </BulkUpdateItem>
        );
      })}
  </Fragment>
);

/**
 * Composant pour afficher les équipements à mettre à jour
 * @param idImport id de l'import
 * @param equipement équipement à mettre à jour
 * @param validatedElements équipements validés
 * @param setValidatedElements fonction pour changer les équipements validés
 * @param validated si les mises à jour sont validées
 */
const ImportData: FC<{
  idImport: string;
  equipement: ImpEquUpdate;
  validatedElements: ValidatedImportItem;
  setValidatedElements: any;
  validated?: boolean;
}> = ({ idImport, equipement, validatedElements, setValidatedElements, validated = false }) => {
  let accepted = false,
    refused = false;
  if (validated) {
    accepted = validatedElements[equipement.ref] === 'accepted';
    refused = validatedElements[equipement.ref] === 'refused';
  }

  const accept = (ref: string) => {
    if (!validated) {
      confirmUpdate('equipment', idImport, [ref])
        .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'accepted' })))
        .catch(handleErrorNotif);
    }
  };
  const refuse = (ref: string) => {
    if (!validated) setValidatedElements((current: any) => ({ ...current, [ref]: 'refused' }));
  };

  const { ref, label, type, modifications, numeroDePiste, parent } = equipement;
  const labelValues = {
    Type: type.label,
    'Numeros de pistes': numeroDePiste && numeroDePiste.length ? numeroDePiste.join(', ') : null,
    Parent: parent && parent.ref ? parent.ref : null,
  };
  const customFields = modifications.map((modification, index) => {
    const { attribute, attributeLabel, oldValue, newValue } = modification;
    return (
      <UpdatedLabelValue key={`${index}-${attribute}`} label={getLabel(attribute, attributeLabel)}>
        <UpdatedField attribute={attribute} oldValue={oldValue} newValue={newValue} />
      </UpdatedLabelValue>
    );
  });
  return (
    <ImportItem
      label={`${ref} - ${label}`}
      labelValues={labelValues}
      customFields={customFields}
      accepted={accepted}
      refused={refused}
      accept={() => accept(ref)}
      refuse={() => refuse(ref)}
    />
  );
};

export default ImportUpdates;
