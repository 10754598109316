import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useAsyncDebounce } from 'react-table';
import { Grid, Input, MenuItem, Select, Slider, Switch, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { InputUI } from '../../shared';
import Utils from '../../../helper/Utils';
import { IdLabel } from '../../../interface/CommonType';
import { Autocomplete } from '@mui/material';
import { GlobalContext } from '../../../context/Global.context';

interface GloablFilterProps {
  // La valeur du filtre global
  globalFilter: any;
  // La fonction permettant de mettre à jour le filtre global
  setGlobalFilter: any;
}

// Total have to be 12;
const ratioHeader = 4;
const ratioInput = 8;

// Define a default UI for filtering
/**
 * Filtre de type texte, filtre sur toutes les colonnes du tableau sans distinction
 * @param globalFilter La valeur du filtre global
 * @param setGlobalFilter La fonction permettant de mettre à jour le filtre global (react-table)
 */
export const GlobalFilter: FC<GloablFilterProps> = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);

  if (!globalFilter && !!value) setGlobalFilter(value);
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val);
  }, 600);

  return (
    <span className="global-filter">
      <Input
        size="small"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Rechercher sur toutes les colonnes ..."
      />
    </span>
  );
};

/**
 * Filtre de type texte, filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param columns Liste des données de toutes les colonnes du tableau
 */
export const TextColumnFilter: FC<{ column: any; columns: any[] }> = ({ column, columns }) => {
  const [filterType, setType] = React.useState(column.filter || 'contains');
  const [filterValue, setFilterValue] = React.useState(column.filterValue);

  const handleChangeType: any = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value;
    setType(type as string);
    for (let Col of columns) if (Col.Header === column.Header) Col.filter = type;
    column.setFilter(column.filterValue || undefined);
  };

  useEffect(() => {
    if (column.filterValue === filterValue) return;
    const timeOut = setTimeout(() => column.setFilter(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);

  useEffect(() => setFilterValue(column.filterValue), [column.filterValue]);

  const handleChangeValue = (value: string) => {
    setFilterValue(value);
  };

  return (
    <Grid container>
      <Grid item xs={ratioHeader}>
        <Select
          className="text-column-filter-select"
          style={{ textAlign: 'center' }}
          value={filterType}
          onChange={handleChangeType}
        >
          <MenuItem value="contains">Contient</MenuItem>
          <MenuItem value="equals">Égale</MenuItem>
          <MenuItem value="start">Commence</MenuItem>
          <MenuItem value="exclude">Exclus</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={ratioInput}>
        <InputUI.CMSTextField
          style={{ width: '100%' }}
          className="text-column-filter-input"
          value={filterValue || ''}
          onChange={handleChangeValue}
        />
      </Grid>
    </Grid>
  );
};

interface ReactTableColRenderProps {
  // Liste des données de la colonne rendue par ReactTable
  column: any;
  // Liste des données de toutes les colonnes du tableau
  columns: any[];
  // Liste des données de toutes les lignes du tableau sans filtre
  preFilteredRows: any[];
}

/**
 * Filtre de type Autocompletion, filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param columns Liste des données de toutes les colonnes du tableau
 * @param preFilteredRows Liste des données de toutes les lignes du tableau sans filtre
 */
export const SearchColumnFilter: FC<ReactTableColRenderProps> = ({ column, columns, preFilteredRows }) => {
  const [filterType, setType] = React.useState(column.filter || 'contains');
  const [filterValue, setFilterValue] = React.useState(column.filterValue);

  // Force le reremplissage du filtre si ce dernier est vidé par le réactTable alors qu'une valeur existe
  useEffect(() => {
    if (!column.filterValue && !!filterValue) column.setFilter(filterValue);
  }, [filterValue, column, column.filterValue]);

  useEffect(() => setFilterValue(column.filterValue), [column.filterValue]);

  const options: string[] = useMemo(() => {
    const SelectOptions = new Set();
    preFilteredRows.forEach((row: any) => {
      const val = row.values[column.id];
      if (!val || val === '') return;
      SelectOptions.add(val);
    });
    // @ts-ignore
    const valueList = [...SelectOptions.values()];
    const result: Array<string> = [];
    for (const val of valueList) result.push(val?.toUpperCase());
    return result.sort((a, b) => a.localeCompare(b));
  }, [column.id, preFilteredRows]);

  const handleChangeType: any = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value;
    setType(type as string);
    for (let Col of columns) if (Col.Header === column.Header) Col.filter = type;
    column.setFilter(column.filterValue || undefined);
  };

  useEffect(() => {
    if (column.filterValue === filterValue) return;
    const timeOut = setTimeout(() => column.setFilter(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);

  const handleChangeValue = (e: React.ChangeEvent<{ value: string | number | undefined }>) => {
    setFilterValue(e?.target?.value);
  };

  const toUpper = filterValue?.toUpperCase();
  const filteredOptions = options.filter((x) => x.indexOf(toUpper) !== -1);
  const noOptionLabel = filteredOptions.length > 500 ? 'Trop de résultats' : 'Aucun résultat';

  return (
    <Grid container>
      <Grid item xs={ratioHeader}>
        <Select
          className="text-column-filter-select"
          style={{ textAlign: 'center' }}
          value={filterType}
          onChange={handleChangeType}
        >
          <MenuItem value="contains">Contient</MenuItem>
          <MenuItem value="equals">Égale</MenuItem>
          <MenuItem value="start">Commence</MenuItem>
          <MenuItem value="exclude">Exclus</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={ratioInput}>
        <Autocomplete
          value={filterValue ?? ''}
          size="small"
          renderInput={(params) => (
            <TextField
              className="search-column-filter-input"
              {...params}
              onChange={handleChangeValue}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
          noOptionsText={noOptionLabel}
          onChange={(_, value) => setFilterValue(value)}
          options={!filterValue || filterValue.length < 2 || filteredOptions.length > 500 ? [] : filteredOptions}
        />
      </Grid>
    </Grid>
  );
};

/**
 * Filtre de type Date, filtre sur une colonne du tableau, Format UTC
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param columns Liste des données de toutes les colonnes du tableau
 * @param preFilteredRows Liste des données de toutes les lignes du tableau sans filtre
 */
export const UtcDateFilter: FC<ReactTableColRenderProps> = ({ column, columns, preFilteredRows }) => {
  return <DateFilter column={column} columns={columns} preFilteredRows={preFilteredRows} isUtc={true} />;
};

interface DateFilterProps extends ReactTableColRenderProps {
  isUtc?: boolean;
}

/**
 * Liste des types de filtre de date
 */
const dateTypeList = [
  { value: 'exactDate', label: 'Date exacte' },
  { value: 'beforeDate', label: 'Avant le' },
  { value: 'afterDate', label: 'Après le' },
  { value: 'rangeDate', label: 'Plage de date' },
];

/**
 * Filtre de type Date, filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param columns Liste des données de toutes les colonnes du tableau
 * @param isUtc Gerer les filtres en UTC
 */
export const DateFilter: FC<DateFilterProps> = ({ column, columns, isUtc = false }) => {
  const [filterType, setType] = React.useState(column.filter || 'exactDate');
  const [filterValue, setFilterValue] = React.useState(column.filterValue);
  const isRange = filterType === 'rangeDate' || filterType === 'rangeDateUtc';
  const handleChangeType: any = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type = event.target.value;
    const isNewTypeRange = type === 'rangeDate' || type === 'rangeDateUtc';
    setType(type as string);
    for (let Col of columns) if (Col.Header === column.Header) Col.filter = type;
    const payload = isNewTypeRange ? { begin: filterValue ?? null } : filterValue?.begin;
    setFilterValue(payload);
    column.setFilter(payload || undefined);
  };

  useEffect(() => {
    if (column.filterValue === filterValue || (column.filterValue === undefined && filterValue === null)) return;
    const timeOut = setTimeout(() => column.setFilter(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);

  useEffect(() => setFilterValue(column.filterValue), [column.filterValue]);

  const handleChangeValue = (date: Date | null, isStart = true) => {
    if (!isRange) return setFilterValue(date);
    let begin = isStart ? date : filterValue?.begin;
    let end = isStart ? filterValue?.end : date;
    if (!begin || !end) setFilterValue({ begin, end });
    begin = new Date(begin);
    end = new Date(end);
    if (!Utils.Date.isDateValid(begin)) setFilterValue({ begin: null, end });
    else if (!Utils.Date.isDateValid(end)) setFilterValue({ begin, end: null });
    else if (begin > end && isStart) setFilterValue({ begin, end: new Date(begin.getTime() + 86400000) });
    else if (begin > end && !isStart) setFilterValue({ begin: new Date(end.getTime() - 86400000), end });
    else setFilterValue({ begin, end });
  };

  return (
    <Grid container>
      <Grid item xs={ratioHeader}>
        <Select
          className="text-column-filter-select"
          style={{ textAlign: 'center' }}
          value={filterType}
          onChange={handleChangeType}
        >
          {dateTypeList.map((type) => (
            <MenuItem value={type.value + (isUtc ? 'Utc' : '')}>{type.label}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={ratioInput}>
        <InputUI.DatePickerVanilla
          value={isRange ? filterValue?.begin : filterValue}
          label={isRange ? 'du:' : undefined}
          onChange={handleChangeValue}
        />
        {isRange && (
          <InputUI.DatePickerVanilla
            value={filterValue?.end}
            label="au"
            onChange={(date: Date) => handleChangeValue(date, false)}
          />
        )}
      </Grid>
    </Grid>
  );
};

/**
 * Filtre de type Boolean, filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param columns Liste des données de toutes les colonnes du tableau
 */
export const BooleanColumnFilter: FC<ReactTableColRenderProps> = ({ column, columns }) => {
  const [filterValue, setFilterValue] = React.useState(column.filterValue);
  const { theming } = useContext(GlobalContext);

  const handleChangeValue: any = (event: React.ChangeEvent<{ value: unknown }>) => {
    let val = event.target.value;
    val = val === undefined ? undefined : val === 'true';
    setFilterValue(val);
  };

  useEffect(() => setFilterValue(column.filterValue), [column.filterValue]);

  useEffect(() => {
    if (column.filterValue === filterValue) return;
    const timeOut = setTimeout(() => column.setFilter(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);

  let placeholder = '';
  if (filterValue === false || filterValue === true) {
    placeholder = filterValue.toString();
  }

  return (
    <Select className="boolean-filter" value={placeholder || ''} onChange={handleChangeValue}>
      <MenuItem value={undefined}>Tous</MenuItem>
      <MenuItem value="true">
        <CheckIcon style={{ color: theming.get().cms.severity.valid }} />
      </MenuItem>
      <MenuItem value="false">
        <ClearIcon style={{ color: theming.get().cms.severity.error }} />
      </MenuItem>
    </Select>
  );
};

/**
 * Filtre de type Vrai ou null (ne retourne jamais faux), filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param columns Liste des données de toutes les colonnes du tableau
 */
export const TrueOnlyFilter: FC<ReactTableColRenderProps> = ({ column, columns }) => {
  const [filterValue, setFilterValue] = React.useState(column.filterValue);
  useEffect(() => setFilterValue(column.filterValue), [column.filterValue]);
  useEffect(() => {
    if (column.filterValue === filterValue) return;
    const timeOut = setTimeout(() => column.setFilter(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);
  const handleChangeValue = () => setFilterValue(!filterValue ? true : undefined);
  return <Switch color="primary" checked={filterValue ?? false} onChange={handleChangeValue} />;
};

/**
 * Filtre d'auto-complétion de type texte, filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 * @param preFilteredRows Liste des données de la colonne rendu par ReactTable sans filtre
 */
export const SelectColumnFilter: FC<ReactTableColRenderProps> = ({ column, preFilteredRows }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const [filterValue, setFilterValue] = React.useState(column.filterValue);
  const col: any = column;
  const rows = preFilteredRows;
  const options: IdLabel[] = React.useMemo(() => {
    const SelectOptions = new Set();
    rows.forEach((row: any) => {
      const val = row.values[column.id];
      if (!val || val === '') return;
      SelectOptions.add(val);
    });
    // @ts-ignore
    const valueList = [...SelectOptions.values()];
    const result: Array<IdLabel> = [];
    for (const val of valueList) result.push({ id: isNaN(+val) ? val : +val, label: val });
    return result.sort((a, b) => a.label.localeCompare(b.label));
  }, [column.id, rows]);
  const isMultiple = IsFilterArrayType(col.filter);

  useEffect(() => {
    if (column.filterValue === filterValue) return;
    const timeOut = setTimeout(() => column.setFilter(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);

  useEffect(() => setFilterValue(column.filterValue), [column.filterValue]);

  // Render a multi-select box
  return (
    <InputUI.AutoCompletor
      style={{ marginBottom: 0 }}
      value={filterValue || (isMultiple ? [] : '')}
      onChange={(x: any) => setFilterValue(isMultiple ? (x?.length > 0 ? x : null) : x)}
      options={options}
      multiple={isMultiple}
      optionLabel={col.value?.optionLabel ?? 'label'}
      optionValue={col.value?.optionValue ?? 'id'}
    />
  );
};

/**
 * Filtre de type Slider, filtre sur une colonne du tableau
 * @param column Liste des données de la colonne rendu par ReactTable
 */
export const SliderColumnFilter: FC<ReactTableColRenderProps> = ({ column }) => {
  // Calculate the min and max
  // using the preFilteredRowsè
  const [min, max] = React.useMemo(() => {
    let minTemp = column.preFilteredRows.length ? column.preFilteredRows[0].values[column.id] : 0;
    let maxTemp = column.preFilteredRows.length ? column.preFilteredRows[0].values[column.id] : 0;
    column.preFilteredRows.forEach((row: any) => {
      minTemp = Math.min(row.values[column.id], min);
      maxTemp = Math.max(row.values[column.id], max);
    });
    return [minTemp, maxTemp];
  }, [column.id, column.preFilteredRows]);

  return (
    <>
      <Slider
        defaultValue={min}
        onChangeCommitted={(e, value) => column.setFilter(value)}
        aria-labelledby="continuous-slider"
        valueLabelDisplay="auto"
        min={min}
        max={max}
      />
      <button onClick={() => column.setFilter(undefined)}>Off</button>
    </>
  );
};

interface RangeDate {
  // Date de début
  begin?: Date;
  // Date de fin
  end?: Date;
}

/**
 * Objet Contenant tous les filtres programmatique du ReactTable, si vous avez besoin d'un nouveau type de filtre,
 * pensez à l'ajouter ici, le nom de la propriété sera la valeur à indiquer dans le RTColumn.filter
 * (ne pas confondre avec le RTColumn.Filter qui est le composant React d'affichage du filtre)
 */
export const filterTypesProps = {
  columns: undefined,
  data: undefined,
  boolean: (rows: any, id: any, filterValue: any) => rows.filter((row: any) => row.values[id] === filterValue),
  // Or, override the default text filter to use
  // "startWith"
  start: (rows: any, id: any, filterValue: any) => {
    return rows.filter((row: any) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
  exclude: (rows: any, id: any, filterValue: any) => {
    return rows.filter((row: any) => {
      const cellValue = row.values[id];
      if (!cellValue) return true;
      const toLower = cellValue.toString().toLowerCase();
      return toLower.indexOf(filterValue?.toLowerCase()) < 0;
    });
  },
  afterDate: (rows: any, id: any, filterValue: Date) => {
    if (typeof filterValue?.getMonth !== 'function') return rows;
    filterValue = new Date(filterValue.getTime());
    return rows.filter((row: any) => (row.values[id] ? row.values[id] >= filterValue : false));
  },
  afterDateUtc: (rows: any, id: any, filterValue: Date) => {
    const UtcValue = new Date(filterValue.getTime() - filterValue.getTimezoneOffset() * 60000);
    return filterTypesProps.afterDate(rows, id, UtcValue);
  },
  beforeDate: (rows: any, id: any, filterValue: Date) => {
    if (typeof filterValue?.getMonth !== 'function') return rows;
    filterValue = new Date(filterValue.getTime());
    filterValue.setHours(23, 59, 59, 999);
    return rows.filter((row: any) => (row.values[id] ? row.values[id] <= filterValue : false));
  },
  beforeDateUtc: (rows: any, id: any, filterValue: Date) => {
    const UtcValue = new Date(filterValue.getTime() - filterValue.getTimezoneOffset() * 60000);
    return filterTypesProps.beforeDate(rows, id, UtcValue);
  },
  exactDate: (rows: any, id: any, filterValue: Date) => {
    if (!Utils.isDate(filterValue)) return rows;
    filterValue.setHours(0, 0, 0, 0);
    return rows.filter((row: any) => {
      if (!row.values[id] || !Utils.isDate(row?.values[id])) return false;
      const val = new Date(row.values[id].getTime());
      return val.setHours(0, 0, 0, 0) === filterValue.getTime();
    });
  },
  exactDateUtc: (rows: any, id: any, filterValue: Date) => {
    if (!Utils.isDate(filterValue)) return rows;
    filterValue = new Date(filterValue.getTime() - filterValue.getTimezoneOffset() * 60000);
    filterValue.setHours(0, 0, 0, 0);
    return rows.filter((row: any) => {
      if (!row.values[id] || !Utils.isDate(row?.values[id])) return false;
      const val = new Date(row.values[id].getTime() + row.values[id].getTimezoneOffset() * 60000);
      return val.setHours(0, 0, 0, 0) === filterValue.getTime();
    });
  },
  mondayDate: (rows: any, id: any, filterValue: Date) => {
    if (!Utils.isDate(filterValue)) return rows;
    filterValue.setHours(0, 0, 0, 0);
    const day = filterValue.getDay() - 1;
    filterValue.setDate(filterValue.getDate() - day);
    return rows.filter((row: any) => {
      if (!row.values[id] || !Utils.isDate(row?.values[id])) return false;
      const val = new Date(row.values[id].getTime());
      return val.setHours(0, 0, 0, 0) === filterValue.getTime();
    });
  },
  rangeDate: (rows: any, id: any, { begin, end }: RangeDate) => {
    if (!begin || !end || !Utils.isDate(begin) || !Utils.isDate(end)) return rows;
    const realBegin = new Date(begin);
    const realEnd = new Date(end);
    realBegin.setHours(0, 0, 0, 0);
    realEnd.setHours(23, 59, 59, 999);
    return rows.filter((row: any) => {
      const date = row.values[id];
      if (!date || date.getTime() === 0) return false;
      if (realBegin.getTime() > date.getTime()) return false;
      return !(realEnd.getTime() < date.getTime());
    });
  },
  rangeDateUtc: (rows: any, id: any, { begin, end }: RangeDate) => {
    if (!begin || !end || !Utils.isDate(begin) || !Utils.isDate(end)) return rows;
    const realBegin = new Date(begin.setHours(0, 0, 0, 0) - begin.getTimezoneOffset() * 60000);
    const realEnd = new Date(end.setHours(23, 59, 59, 999) - end.getTimezoneOffset() * 60000);
    return rows.filter((row: any) => {
      const date = new Date(row.values[id]);
      if (!date || date.getTime() === 0) return false;
      if (realBegin.getTime() > date.getTime()) return false;
      return !(realEnd.getTime() < date.getTime());
    });
  },
  includeInArray: (rows: any, id: any, filterValue: Array<any> | null) => {
    if (!filterValue || filterValue?.length < 1) return rows;
    return rows.filter((row: any) => filterValue.includes(row.values[id]));
  },
  includeSomeInOtherArray: (rows: any, id: any, filterValue: Array<any> | null) => {
    if (!filterValue || filterValue?.length < 1) return rows;
    if (!Array.isArray(filterValue)) filterValue = [filterValue];
    return rows.filter((row: any) => filterValue?.some((x: any) => row.values[id]?.includes(x)));
  },
  includeEveryInOtherArray: (rows: any, id: any, filterValue: Array<any> | null) => {
    if (!filterValue || filterValue?.length < 1) return rows;
    return rows.filter((row: any) => filterValue.every((x: any) => row.values[id]?.includes(x)));
  },
  lessOrEqual: (rows: any, id: any, filterValue: any) => {
    if (!filterValue) return rows;
    return rows.filter((row: any) => row.values[id] <= filterValue && row.values[id] !== 0);
  },
};

/**
 * Retourne true si le filtre est un filtre de type tableau, il existe plusieurs filtres de type tableau différents
 * @param filter le nom du filtre
 */
export const IsFilterArrayType = (filter?: string) => {
  return (
    filter === 'includeInArray' ||
    filter === 'includeSomeInOtherArray' ||
    filter === 'includeEveryInOtherArray' ||
    filter === 'AutoMultiselect'
  );
};
