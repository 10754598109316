import React, { useContext } from 'react';
import './EquipmentDiagram.scss';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Paper } from '@mui/material';
import { EqActionIcon } from '../EquipementActionIcon';
import { GlobalContext } from 'context/Global.context';

// NOTE: Composant React de rendu des diagrammes des équipements
// Se composant à pour but d'effectuer un rendu visuel schématique "vue du dessus" d'une pompes à essence
// A partir d'un équipement, nous allons récuperer les pistolet lié à ce dernier, puis les organiser
// afin d'effectuer un rendu visuel en fonction des règles métier
export default function EquipmentDiagram(props: any) {
  const { theming } = useContext(GlobalContext);
  if (!props.equipement) return <span>Equipment not found</span>;
  const eq = props.equipement;
  let trackPair;
  let trackImpair;
  //Règle de gestion, si il n'y a qu'une seul face sur les tracks, les afficher à gauche
  if (eq.track1 && eq.track1 !== '0' && eq.track2 && eq.track2 !== '0') {
    trackPair = eq.eqPistolResources.filter((pistol: any) => pistol.track % 2 === 0);
    trackImpair = eq.eqPistolResources.filter((pistol: any) => pistol.track % 2 === 1);
  } else {
    trackImpair = eq.eqPistolResources;
  }

  return (
    <Paper
      elevation={3}
      style={{ backgroundColor: theming.get().cms.main.background }}
      className="eq-diagram-container"
    >
      <div className="diagram-info">
        <h4>{eq.typeLabel + ' - ' + eq.track1 + '/' + eq.track2}</h4>
        <p>{eq.commercialName}</p>
        <p>{eq.constructeurName}</p>
      </div>
      <div className="diagram-body">
        {FilterNullTpi(eq.track1) && eq.tpiTrack1 !== null && <div className="left-title">TPI {eq.track1}</div>}
        {FilterNullTpi(eq.track2) && eq.tpiTrack2 !== null && <div className="right-title">TPI {eq.track2}</div>}
        {eq.rV2 && <div className="top-left-title">RV2</div>}
        {eq.calculatorId && (
          <div className="top-right-title">
            <ArrowUpwardIcon className="arrow-icon" />
            CAL
          </div>
        )}
        <div className="icon-action-list">{EqActionIcon(eq.contractLabel)}</div>
        {FilterNullTpi(eq.track1) && <div className="left-pistol-track">{eq.track1}</div>}
        {FilterNullTpi(eq.track2) && <div className="right-pistol-track">{eq.track2}</div>}
        <div className="left-pistols-container">
          {trackImpair &&
            trackImpair.length > 0 &&
            trackImpair.map((leftPistol: any, i: number) => {
              return <PistolButton key={i} init={i} pistol={leftPistol} isLeftSide={true} />;
            })}
        </div>
        <div className="right-pistols-container">
          {trackPair &&
            trackPair.length > 0 &&
            trackPair.map((rightPistol: any, i: number) => {
              return <PistolButton key={i} init={i} pistol={rightPistol} isLeftSide={false} />;
            })}
        </div>
        <div className="group-name-container">
          {eq.groupNameColor &&
            eq.groupNameColor.map((elem: any, key: number) => (
              <div key={key} className="group-name" style={{ backgroundColor: filterGoYellow(elem.groupColor) }}>
                {elem.groupName}
              </div>
            ))}
        </div>
      </div>
    </Paper>
  );
}

interface pistolProps {
  init: number;
  pistol: any;
  isLeftSide: boolean;
}

// Filtre les TPI null ou 0
function FilterNullTpi(tpi: number | string): boolean {
  return !(!tpi || tpi === 0 || tpi === '0');
}

// Crée l'icone de pistolet sur le diagramme, en spécifiant sa couleur et sont numéro de piste
function PistolButton(props: pistolProps) {
  const { init, pistol, isLeftSide } = props;
  let color = filterGoYellow(pistol.productColor);

  const handleOpen = () => {
    // Méthode en commentaire en attendant le dévelopement de l'édition d'un pistolet
    //setOpen(true);
  };

  return (
    <div className={isLeftSide ? 'left-pistol' : 'right-pistol'} onClick={handleOpen}>
      <div style={{ backgroundColor: color }} className="pistol-number">
        {init + 1}
      </div>
    </div>
  );
}

// Change la couleur jaune vers un orange pour la lisibilité
function filterGoYellow(colorString: string) {
  let color = colorString || '000000';
  return color === 'e8e164' ? '#d2a227' : '#' + color;
}
