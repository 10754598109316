import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import AccessFilter from '../../../helper/AccessFilter';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { SfAbsenceType } from '../../../interface/SfType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const sfAbsenceTypeConfigList: CmsColumnDef<SfAbsenceType>[] = [
  { header: 'Id', id: 'id', Filter: CmsTableFilter.Text },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code évènement SAGE', id: 'sageCode', Filter: CmsTableFilter.Text },
  { header: 'Accès réservé RH', id: 'hrOnly', Filter: CmsTableFilter.Bool },
  { header: 'Jour férié', id: 'holidayCount', Filter: CmsTableFilter.Bool },
  { header: 'Demi-journée', id: 'halfDay', Filter: CmsTableFilter.Bool },
];

/**
 * Composant pour afficher la liste des types d'absences
 */
export const SfAbsenceTypeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_ABSENCETYPE_MASTER])
    ? [<AddNavigationButton title="Ajouter un type d'absence" to="/castres/staff/absencetype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des types d'absence"
      route={APIRoute.SfAbsenceType}
      columns={sfAbsenceTypeConfigList}
      navigateTo={(id: number) => `/castres/staff/absencetype/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Composant pour créer ou éditer un type d'absence
 * @param id id du type d'absence
 * @param setHttpCodePage fonction pour changer le code http de la page
 */
export const SfAbsenceTypeCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/staff/absencetype/', route: APIRoute.SfAbsenceType };
  return (
    <CmsForm
      id={id}
      {...props}
      title="un type d'absence"
      duplicateHandler={{
        columns: sfAbsenceTypeConfigList,
        navigateTo: (id) => `/castres/staff/absencetype/${id}/edit`,
      }}
      deletable
      thin
    >
      <CmsFormInput.Text id="label" label="Nom" required min={2} />
      <CmsFormInput.Text id="sageCode" label="Code évènement SAGE" />
      <CmsFormInput.Switch id="hrOnly" label="Accès réservé RH" />
      <CmsFormInput.Switch id="holidayCount" label="Jour férié" />
      <CmsFormInput.Switch id="halfDay" label="Autorise les saisies de demi-journée" />
    </CmsForm>
  );
};
