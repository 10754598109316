import React, { Fragment, FC, useState, useEffect, ReactNode, useMemo } from 'react';

import { PageTitle, ErrorPaper, InfoPaper } from 'component/shared/Ui';
import LoadingScreen from 'component/LoadingScreen';
import {
  ImpEquResult,
  ImpEquAddition,
  ImpEquUpdate,
  ImpEquipement,
  ImpEquStationProcessedAddition,
  ImpEquStationProcessedUpdate,
  ImpEquStationProcessed,
  ImpEquProcessedResult,
  ImpEquBulkUpdate,
  ModificationCount,
  ValidatedImportItem,
  ValidatedBulkUpdate,
} from 'interface/ImportType';
import { getImportInfos, executeEquipementImport } from 'service/Import.service';
import ImportEquipementAdditions from './ImportEquipementAdditions';
import ImportEquipementUpdates from './ImportEquipementUpdates';
import ImportEquipementDeactivations from './ImportEquipementDeactivations';
import { BulkEquUpdateFields } from 'constant/Import.constant';
import { equals, ImportSteps } from '../ImportUtils';
import { handleErrorPage } from 'helper/handle-response';
import { useNavigate } from 'react-router-dom';

/**
 * Composant de la page d'import d'équipements
 * @param id id de l'import
 * @param setHttpCodePage fonction pour changer le code http de la page
 * @param props props du composant
 */
const ImportEquipement: FC = ({ id, setHttpCodePage, ...props }: any) => {
  const filename = props?.location?.state?.filename;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [importFilename, setImportFilename] = useState<string>();
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isImportOver, setImportOver] = useState<boolean>(false);
  const [isImportAdditionsOver, setImportAdditionsOver] = useState<boolean>(false);
  const [isImportUpdatesOver, setImportUpdatesOver] = useState<boolean>(false);

  const [importResult, setImportResult] = useState<ImpEquProcessedResult>();

  const [validatedAdditions, setValidatedAdditions] = useState<ValidatedImportItem>({});
  const [validatedUpdates, setValidatedUpdates] = useState<ValidatedImportItem>({});
  const [validatedBulks, setValidatedBulks] = useState<ValidatedBulkUpdate[]>([]);
  const [validatedDeactivations, setValidatedDeactivations] = useState<ValidatedImportItem>({});
  const [toNotDeactivateIdList, setToNotDeactivateIdList] = useState<number[]>([]);

  useEffect(() => {
    if (id) {
      if (filename) setImportFilename(filename);
      else
        getImportInfos('equipment', id)
          .then((result) => setImportFilename(result.filename))
          .catch((response) => handleErrorPage(response, setHttpCodePage));

      executeEquipementImport(id)
        .then((result) => {
          setLoaded(true);
          setImportResult(processImportResult(result));
        })
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else {
      navigate('/import-equipement');
    }
  }, [id, filename, navigate, setHttpCodePage]);

  const additionsNumber = calculateNumberEqu(importResult?.additions);
  const updatesNumber = calculateNumberEqu(importResult?.updates) + (importResult?.bulkUpdates.length || 0);
  const deactivationsNumber = calculateNumberEqu(importResult?.deactivations);

  const areAdditionsValidated = Object.keys(validatedAdditions).length >= additionsNumber;
  const areUpdatesValidated = Object.keys(validatedUpdates).length + validatedBulks.length >= updatesNumber;
  const areDeactivationsValidated = Object.keys(validatedDeactivations).length >= deactivationsNumber;
  const areAdditionsNotEmpty = additionsNumber !== 0 && Object.keys(validatedAdditions).length !== 0;
  const areUpdatesNotEmpty = updatesNumber !== 0 && Object.keys(validatedUpdates).length + validatedBulks.length !== 0;
  const areDeactivationsNotEmpty = deactivationsNumber !== 0 && Object.keys(validatedDeactivations).length !== 0;

  // Moving to the next step when needed + checking if import is over
  useEffect(() => {
    if (areAdditionsNotEmpty && !isImportAdditionsOver && areAdditionsValidated) {
      setImportAdditionsOver(true);
      setActiveStep(!areUpdatesValidated ? 1 : 2);
    }
    if (areUpdatesNotEmpty && !isImportUpdatesOver && areUpdatesValidated) {
      setImportUpdatesOver(true);
      setActiveStep(areDeactivationsValidated && !areAdditionsValidated ? 0 : 2);
    }
    if (
      (areAdditionsNotEmpty || areUpdatesNotEmpty || areDeactivationsNotEmpty) &&
      areAdditionsValidated &&
      areUpdatesValidated &&
      areDeactivationsValidated
    ) {
      setImportOver(true);
    }
  }, [
    isImportAdditionsOver,
    isImportUpdatesOver,
    areAdditionsValidated,
    areUpdatesValidated,
    areDeactivationsValidated,
    areAdditionsNotEmpty,
    areUpdatesNotEmpty,
    areDeactivationsNotEmpty,
  ]);

  const stepLabels: string[] = [
    `Ajouts (${additionsNumber})`,
    `Mises à jour (${updatesNumber})`,
    `Désactivations (${deactivationsNumber})`,
  ];

  const handleUpdatedItemIdList = (item: ValidatedImportItem, id: number) => {
    if (!!id) setToNotDeactivateIdList([...toNotDeactivateIdList, id]);
    setValidatedAdditions(item);
  };

  const filteredDeactivationList = useMemo(() => {
    if (!importResult?.deactivations) return [];
    for (let station of importResult.deactivations) {
      if (!station.equipements) continue;
      station.equipements = station.equipements.filter((x) => toNotDeactivateIdList.indexOf(x.id ?? -1) === -1);
    }
    return importResult.deactivations;
  }, [importResult, toNotDeactivateIdList]);

  console.log('testingdeact', importResult?.deactivations, filteredDeactivationList);

  const stepContents: ReactNode[] = [
    <ImportEquipementAdditions
      idImport={id}
      additions={importResult?.additions}
      validatedAdditions={validatedAdditions}
      setValidatedAdditions={handleUpdatedItemIdList}
    />,
    <ImportEquipementUpdates
      idImport={id}
      updates={importResult?.updates}
      bulkUpdates={importResult?.bulkUpdates}
      validatedUpdates={validatedUpdates}
      setValidatedUpdates={setValidatedUpdates}
      validatedBulks={validatedBulks}
      setValidatedBulks={setValidatedBulks}
    />,
    <ImportEquipementDeactivations
      idImport={id}
      deactivations={filteredDeactivationList}
      validatedDeactivations={validatedDeactivations}
      setValidatedDeactivations={setValidatedDeactivations}
    />,
  ];

  return (
    <Fragment>
      {importFilename && <PageTitle>Import de {importFilename}</PageTitle>}
      {isLoaded ? (
        <div>
          <ImportSteps stepLabels={stepLabels} activeStep={activeStep} setActiveStep={setActiveStep} />
          {!!importResult?.errors.length && (
            <ErrorPaper title="Erreurs lors de l'import">
              <ul>
                {importResult.errors.map((error, index) => (
                  <li key={`error-${index}`} style={{ marginLeft: '2em' }}>
                    {error}
                  </li>
                ))}
              </ul>
            </ErrorPaper>
          )}
          {isImportOver && <InfoPaper title="L'import est terminé" style={{ textAlign: 'center' }} />}
          {stepContents[activeStep]}
        </div>
      ) : (
        <div style={{ marginTop: '5em' }}>
          <LoadingScreen />
        </div>
      )}
    </Fragment>
  );
};

/**
 * Méthode permettant de traiter le résultat d'un import d'équipements
 * @param importResult Résultat de l'import
 */
const processImportResult = (importResult: ImpEquResult): ImpEquProcessedResult => {
  const additions: ImpEquStationProcessedAddition[] = [];
  importResult.additions.forEach((addition: ImpEquAddition) => {
    let station: ImpEquStationProcessedAddition | undefined = additions.find((i) => i.id === addition.station.id);
    if (!station) {
      station = { ...addition.station, equipements: [] };
      additions.push(station);
    }
    station.equipements.push(addition);
  });

  const modificationCounts: ModificationCount[] = [];
  importResult.updates
    .filter(
      (update: ImpEquUpdate) =>
        update.modifications.length === 1 && BulkEquUpdateFields.includes(update.modifications[0].attribute),
    )
    .forEach((update: ImpEquUpdate) => {
      const modificationCount = modificationCounts.find(({ modification }) =>
        equals(modification, update.modifications[0]),
      );
      if (modificationCount) modificationCount.count++;
      else modificationCounts.push({ modification: update.modifications[0], count: 1 });
    });
  const bulkUpdates: ImpEquBulkUpdate[] = modificationCounts
    .filter(({ count }) => count > 1)
    .map(({ modification }) => ({ modification, equipements: [] }));
  const updates: ImpEquStationProcessedUpdate[] = [];
  importResult.updates.forEach((update: ImpEquUpdate) => {
    if (update.modifications?.length) {
      const bulkUpdate = bulkUpdates.find(({ modification }) => equals(modification, update.modifications[0]));
      if (update.modifications?.length === 1 && bulkUpdate) {
        bulkUpdate.equipements.push(update);
      } else {
        let station: ImpEquStationProcessedUpdate | undefined = updates.find((i) => i.id === update.station.id);
        if (!station) {
          station = { ...update.station, equipements: [] };
          updates.push(station);
        }
        station.equipements.push(update);
      }
    }
  });

  const deactivations: ImpEquStationProcessed[] = [];
  importResult.deactivations.forEach((deactivation: ImpEquipement) => {
    let station: ImpEquStationProcessed | undefined = deactivations.find((i) => i.id === deactivation.station.id);
    if (!station) {
      station = { ...deactivation.station, equipements: [] };
      deactivations.push(station);
    }
    station.equipements.push(deactivation);
  });
  return {
    additions,
    updates,
    bulkUpdates,
    deactivations,
    errors: importResult.errors,
  };
};

/**
 * Méthode permettant de calculer le nombre d'équipements par station dans une liste d'import d'équipements
 * @param stations
 */
const calculateNumberEqu = (stations: ImpEquStationProcessed[] | undefined): number => {
  if (!stations || !stations.length) return 0;
  return stations.map((station) => station.equipements.length).reduce((a, b) => a + b, 0);
};

export default ImportEquipement;
