import React, { FC, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../constant/API.constant';
import CRUD from '../../service/CRUD.service';
import LoadingScreen from '../../component/LoadingScreen';
import { UI } from '../../component/shared';
import { IdLabel } from '../../interface/CommonType';
import '../user/user.scss';
import { NavigationButton } from '../../component/shared/Buttons';
import AccessFilter from 'helper/AccessFilter';
import NotificationService from 'service/NotificationService';
import { useNavigate } from 'react-router-dom';
import { WkGreenfluxCdr } from 'interface/WkType';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Card } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { CmsPaper } from '../../component/shared/Ui';
import { displayDurationFromSeconds } from 'helper/Utils';
import ROLE from '../../constant/role.constant';

/**
 * Permet d'afficher les CDR par station selon une note de virement sélectionnée
 * @param id de la note de virement choisie
 * @param method permet de déterminer si on affiche les CDR en charge assist, en réseau privé, en roaming ou via TPE
 */
const GreenfluxNoteDeVirementPageList: FC = ({ id, method: cdrType }: any) => {
  const [NDVInfos, setNDVInfos] = useState<NoteDeVirementInfos>();
  const [NDVDatas, setNDVDatas] = useState<NDVDatasStation[]>();
  const [selectedCDR, setSelectedCDR] = useState<number>(-1);
  const [CDR, setCDR] = useState<WkGreenfluxCdr>();

  let title: string = '';
  if (cdrType === 'ChargeAssist') title = 'Suivi de recharge via Charge Assist';
  if (cdrType === 'PrivateNetwork') title = 'Suivi de recharge en réseau privé';
  if (cdrType === 'Roaming') title = 'Suivi de recharge en Roaming';
  if (cdrType === 'TPE') title = 'Suivi de recharge via TPE';

  useEffect(() => {
    CRUD.getCustomObject<NoteDeVirementInfos>(`${APIRoute.WkGreenfluxNoteDeVirement}/${id}`).then(setNDVInfos);
  }, [id]);

  useEffect(() => {
    CRUD.getList<NDVDatasStation>(`${APIRoute.WkGreenfluxNoteDeVirement}/${id}/clients?method=${cdrType}`).then(
      setNDVDatas,
    );
  }, [id, cdrType]);

  const clicked = () => {
    if (CDR === undefined) return;
    let data: NDVValidation = { id: CDR.id ?? -1, ref: '', message: '' };
    CRUD.post<NDVValidation>(APIRoute.WkGreenflux + '/ChangeNdvCdrById', data).then(() => {
      NotificationService.success('Le CDR a bien été affecté à la note de virement suivante');
      const url = `${APIRoute.WkGreenfluxNoteDeVirement}/${id}/clients?method=${cdrType}`;
      CRUD.getList<NDVDatasStation>(url).then(setNDVDatas);
      setSelectedCDR(-1);
    });
  };

  if (!NDVInfos) return <LoadingScreen />;
  return (
    <div>
      <UI.Paper style={{ maxWidth: '600px' }}>
        <NavigationButton title={'Revenir à la liste'} to={`/castres/greenflux/NoteDeVirement/list`} />
      </UI.Paper>
      <div className="NDV-list-cdr">
        <UI.Paper title={'Note de virement - Référence ' + NDVInfos.ref} fluidHeight={true}>
          <span>
            <b>Référence de la note de virement :</b> {NDVInfos.ref}
          </span>
          <span>
            <b>Statut de la note de virement :</b> {NDVInfos.status}
          </span>
          <span>
            <b>Émetteur de la note de virement :</b> {NDVInfos.companySrc}
          </span>
          <span>
            <b>Total TTC en réseau privé :</b> {NDVInfos.totalCostInclVatPrivateNetwork.toFixed(2)} € (
            {NDVInfos.totalCostPrivateNetwork.toFixed(2)} € HT)
          </span>
          <span>
            <b>Total TTC via Charge Assist :</b> {NDVInfos.totalCostInclVatChargeAssist.toFixed(2)} € (
            {NDVInfos.totalCostChargeAssist.toFixed(2)} € HT)
          </span>
          <span>
            <b>Total TTC en Roaming :</b> {NDVInfos.totalCostInclVatRoaming.toFixed(2)} € (
            {NDVInfos.totalCostRoaming.toFixed(2)} € HT)
          </span>
          <span>
            <b>Total TTC via TPE :</b> {NDVInfos.totalCostInclVatTpe.toFixed(2)} € ({NDVInfos.totalCostTpe.toFixed(2)} €
            HT)
          </span>
          <span>
            <UI.ChipInfo icon={<CheckCircleIcon />} label="Note de virement validée" isValid={NDVInfos.isValidated} />
          </span>
        </UI.Paper>
      </div>
      <div className="NDV-list-cdr">
        <ShowNDV
          NDVDatas={NDVDatas}
          NDVInfos={NDVInfos}
          title={title}
          selectedCDR={selectedCDR}
          setSelectedCDR={setSelectedCDR}
          cdrType={cdrType}
        />
        {selectedCDR > -1 && (
          <ShowSingleCDR CDRId={selectedCDR} CDR={CDR} clicked={clicked} setCDR={setCDR} NDVInfos={NDVInfos} />
        )}
      </div>
      {cdrType === 'Roaming' && NDVInfos.isValidated === false && AccessFilter([ROLE.ADMIN_GF_NOTEDEVIREMENT_EDIT]) && (
        <NdvForm id={id} reference={NDVInfos.ref} setNDVInfos={setNDVInfos} />
      )}
    </div>
  );
};

const NdvForm: FC<{ id: number; reference: string; setNDVInfos: any }> = ({ id, reference, setNDVInfos }) => {
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    const valid = { ...data, id: 0, message: '' };
    return CRUD.post<NDVValidation>(`${APIRoute.WkGreenfluxNoteDeVirement}/${id}/validate`, valid)
      .then((result) => {
        NotificationService.success(result.message);
        navigate(`/castres/greenflux/NoteDeVirement/${id}/roaming/show`);
        CRUD.getCustomObject<NoteDeVirementInfos>(`${APIRoute.WkGreenfluxNoteDeVirement}/${id}`).then(setNDVInfos);
      })
      .catch((err) => {});
  };
  const inputRef = `Pour valider la note de virement, veuillez réécrire sa référence : "${reference}"`;
  return (
    <CmsForm currentUrl="" route="" onSubmit={onSubmit}>
      <CmsPaper title="Valider la note de virement" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="ref" label={inputRef} max={60} required />
        <UI.Divider />
        <UI.Button type="submit">Valider</UI.Button>
      </CmsPaper>
    </CmsForm>
  );
};

/**
 * Permet d'afficher les informations d'un cdr lorsqu'il est sélectionné
 * @param CDRId est l'id du CDR qui est sélectionné
 * @param CDR données du CDR sélectionné
 * @param clicked fonction qui permet d'attribuer le CDR à la NDV suivante
 * @param setCDR permet à la réception des données du CDR de l'affecter à CDR
 * @param NDVInfos détient les informations de la NDV générale
 */
const ShowSingleCDR: FC<{
  CDRId: number;
  CDR: WkGreenfluxCdr | undefined;
  clicked: any;
  setCDR: any;
  NDVInfos: NoteDeVirementInfos;
}> = ({ CDRId, CDR, clicked, setCDR, NDVInfos }) => {
  if (CDR === undefined || CDR.id !== CDRId) {
    CRUD.getById<WkGreenfluxCdr>(APIRoute.WkGreenfluxCdr, CDRId).then(setCDR);
    return <LoadingScreen />;
  }

  return (
    <UI.Paper
      title={`Informations sur le CDR : ${CDR.idCdr}`}
      style={{ maxHeight: '92vh', position: 'fixed', right: '22px', width: '34vw', top: '110px' }}
      scrollable
    >
      <UI.LabelValue label="Type">{CDR.type}</UI.LabelValue>
      <UI.LabelValue label="Date de validation Greenflux">{CDR.lastUpdated}</UI.LabelValue>
      <UI.LabelValue label="Date de début de la recharge">{CDR.startDateTime}</UI.LabelValue>
      <UI.LabelValue label="Date de fin de la recharge">{CDR.stopDateTime}</UI.LabelValue>
      <UI.LabelValue label="Méthode d'authentification">{CDR.authMethod}</UI.LabelValue>
      <UI.LabelValue label="ID externe du client">
        <NavigationButton
          title={CDR.driverExternalId}
          to={`/castres/greenflux/cdr/list?t-driverExternalId=${CDR.driverExternalId}`}
          access={[ROLE.ADMIN_GF_CDR_LIST]}
        />
        {!AccessFilter([ROLE.ADMIN_GF_CDR_LIST]) && CDR.driverExternalId}
      </UI.LabelValue>
      <UI.LabelValue label="Nom de la station Greenflux">
        <NavigationButton
          title={CDR.stationGreenfluxName}
          to={`/castres/greenflux/stations/${CDR.stationGreenfluxId}/show`}
          access={[ROLE.ADMIN_GF_STATION_VIEW]}
        />
        {!AccessFilter([ROLE.ADMIN_GF_STATION_VIEW]) && CDR.stationGreenfluxName}
      </UI.LabelValue>
      <UI.LabelValue label="Référence de la station du CMS">
        <NavigationButton
          title={CDR.stationCmsRef}
          to={`/castres/client/station/${CDR.stationCmsId}/show`}
          access={[ROLE.ADMIN_PARK_STATION_LIST]}
        />
        {!AccessFilter([ROLE.ADMIN_PARK_STATION_LIST]) && CDR.stationCmsRef}
      </UI.LabelValue>

      <UI.Divider />

      <UI.LabelValue label="Prix Total de la recharge">{CDR.totalCostInclVat}</UI.LabelValue>
      <Card variant="outlined" className="single-cdr-card">
        <CardHeader title={<Typography variant="body1">Détail de la transaction</Typography>} className="card-header" />
        <CardContent className="card-content">
          <span>Énergie consommée en kWh : {CDR.totalEnergy}</span>
          <span>Temps total de la recharge : {CDR.totalTime}</span>
          <span>Temps total de parking : {CDR.totalParkingTime}</span>
          <span>
            <br />
          </span>
          <span>TVA : {CDR.vat} %</span>
          <span>Prix total TVA : {CDR.totalVat}</span>
          <span>Prix total HT : {(CDR.totalCostInclVat ?? 0) - (CDR.totalVat ?? 0)}</span>
          <span>
            <br />
          </span>
          <span>Prix TTC : {CDR.totalCostInclVat}</span>
        </CardContent>
      </Card>

      <UI.Divider />

      {!NDVInfos.isValidated && <UI.Button onClick={clicked}>Affecter à la note de virement suivante</UI.Button>}
    </UI.Paper>
  );
};

interface ParameterShowNDV {
  // Données à mettre en forme
  NDVDatas?: NDVDatasStation[];
  // Données générales de la note de virement
  NDVInfos?: NoteDeVirementInfos;
  title?: string;
  // Identifiant du rôle sélectionné
  selectedCDR: number;
  // Fonction de mise à jour du rôle sélectionné
  setSelectedCDR: React.Dispatch<number>;
  // Type de Cdr selectionné
  cdrType: string;
}

/**
 * Permet de faire l'affichage de la liste des CDR segmentée par stations
 * @param NDVDatas détient une liste de CDR par station
 * @param NDVInfos détient des informations générales de la NDV
 * @param title est le titre à afficher en fonction de la méthode
 * @param setSelectedCDR permet lors d'un clic sur un CDR de le sélectionner
 * @param selectedCDR contient l'id du CDR choisi
 * @param cdrType type de Cdr selectionné
 */
const ShowNDV: FC<ParameterShowNDV> = ({ NDVDatas, NDVInfos, title, setSelectedCDR, selectedCDR, cdrType }) => {
  const [search] = useState<string>();
  const [currentOpenMenu, setCurrentOpenMenu] = useState<number>(-1);

  // Memoize the filtered list
  const memoizedList = useMemo(
    () => NDVDatas?.filter((x: any) => !search || x.label.indexOf(search) > -1),
    [NDVDatas, search],
  );

  // Calculate the total cost
  const totalRetailCostInclVat = useMemo(() => {
    return NDVDatas?.reduce((acc, station) => acc + station.clientTotalRetailCostInclVat, 0).toFixed(2);
  }, [NDVDatas]);
  const totalRetailCost = useMemo(() => {
    return NDVDatas?.reduce((acc, station) => acc + station.clientTotalRetailCost, 0).toFixed(2);
  }, [NDVDatas]);

  const handleSubMenuToggle = (stationId: number) => {
    setCurrentOpenMenu(currentOpenMenu !== stationId ? stationId : -1);
  };

  if (!NDVDatas) return <LoadingScreen />;

  return (
    <UI.Paper title={title + ' ' + NDVInfos?.ref + ' (calculée automatiquement par le CMS)'}>
      <UI.MenuList
        title={`Total TTC : ${totalRetailCostInclVat} € (${totalRetailCost} HT)`}
        className="styled-scroll role-list"
      >
        {memoizedList?.map((station: NDVDatasStation) => (
          <UI.SubMenuItem
            isOpen={currentOpenMenu === station.stationId}
            key={station.stationId}
            id={station.stationId}
            title={
              <div onClick={() => handleSubMenuToggle(station.stationId)}>
                <span>
                  <b>{station.stationName}</b> ({station.cdrList.length} charge{station.cdrList.length > 1 ? 's' : ''})
                </span>
                <br />
                <span>
                  <b>Client :</b> {station.clientName}
                </span>
                <br />
                <span>
                  <b>Email :</b> {station.clientEmail !== '' ? station.clientEmail : "Pas d'email trouvé"}
                </span>
                <br />
                <span>
                  <b>IBAN client :</b> {station.clientIban}{' '}
                </span>
                <br />
                <span>
                  <b>Prix Total HT :</b> {station.clientTotalRetailCost.toFixed(2)} €
                </span>
                <br />
                <span>
                  <b>Prix Total TTC :</b> {station.clientTotalRetailCostInclVat.toFixed(2)} €
                </span>
              </div>
            }
          >
            {station.cdrList?.map((cdr: NDVDataCdr) => (
              <ListItem
                key={cdr.id}
                onClick={() => setSelectedCDR(cdr.id)}
                className={'role' + (selectedCDR === cdr.id ? ' selected' : '')}
              >
                <div>
                  <b>CDR : {cdr.idCdr}</b> . . . . . . . . . . . Prix TTC :{' '}
                  {cdrType === 'ChargeAssist' ? cdr.totalRetailCostInclVat.toFixed(2) : cdr.totalCostInclVat.toFixed(2)}{' '}
                  € ({cdrType === 'ChargeAssist' ? cdr.totalRetailCost.toFixed(2) : cdr.totalCost} € HT)
                  <br />- Date de validation UTC : {new Date(cdr.validationDate).toLocaleString('fr-FR')}
                  <br />- Temps de la recharge : {displayDurationFromSeconds(cdr.totalTime * 3600, 'Hms')}
                  <br />- Énergie consommée : {cdr.totalEnergy.toFixed(3)} kWh
                  <br />- Identifiant de l'utilisateur : {cdr.driverExternalId}
                  <br />
                </div>
              </ListItem>
            ))}
          </UI.SubMenuItem>
        ))}
      </UI.MenuList>
    </UI.Paper>
  );
};

export interface NoteDeVirementInfos extends IdLabel {
  id: number;
  ref: string;
  status: string;
  companySrc: string;
  totalCostInclVatInvoiceSupplier: number;
  totalCostInclVatChargeAssist: number;
  totalCostInclVatPrivateNetwork: number;
  totalCostInclVatRoaming: number;
  totalCostInclVatTpe: number;
  totalCostChargeAssist: number;
  totalCostPrivateNetwork: number;
  totalCostRoaming: number;
  totalCostTpe: number;
  isValidated: boolean;
}

export interface NDVDatasStation {
  clientName: string;
  clientIban: string;
  clientTotalRetailCost: number;
  clientTotalRetailCostInclVat: number;
  clientEmail: string;
  stationName: string;
  stationId: number;
  cdrList: NDVDataCdr[];
}

export interface NDVDataCdr {
  id: number;
  site: string;
  idCdr: string;
  totalRetailCostInclVat: number;
  totalCostInclVat: number;
  totalRetailCost: number;
  totalCost: number;
  totalTime: number;
  totalEnergy: number;
  validationDate: Date;
  driverExternalId: string;
}

interface NDVValidation {
  message: string;
  ref: string;
  id: number;
}

export default GreenfluxNoteDeVirementPageList;
