import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import MailIcon from '@mui/icons-material/Mail';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Autorenew } from '@mui/icons-material';

// NOTE: Liste d'icone surchargé avec un tooltips servant de label
/**
 * @deprecated : utiliser le composant CmsIcon
 */
const ContractCribeIcon: FC = () => (
  <Tooltip title="Contrat CRIBE" placement="top">
    <AccountBalanceIcon />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 */
const ContractAdIcon: FC = () => (
  <Tooltip title="Contrat Ad" placement="top">
    <LocalGasStationIcon />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 */
const OnCallIcon: FC = () => (
  <Tooltip title="Astreinte" placement="top">
    <AccessTimeIcon />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 */
const SentMailIcon: FC = () => (
  <Tooltip title="Envoi des rapports par mail" placement="top">
    <MailIcon />
  </Tooltip>
);

//pour indiquer qu'une alarme est acquittée
/**
 * @deprecated : utiliser le composant CmsIcon
 */
const AckIcon: FC = () => (
  <Tooltip title="Acquittée" placement="top">
    <CheckIcon />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 */
const ValidIcon: FC = () => (
  <Tooltip title="Actif" placement="top">
    <CheckCircleIcon />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 */
const ReportIcon: FC = () => (
  <Tooltip title="Attention" placement="top">
    <ReportProblemOutlinedIcon />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 */
const GenerateIcon: FC = () => (
  <Tooltip title="Générer" placement="top">
    <Autorenew />
  </Tooltip>
);

/**
 * @deprecated : utiliser le composant CmsIcon
 * TODO: migrer vers le CmsIcon
 */
export const IconWithToolTip = {
  ContractAdIcon,
  SentMailIcon,
  ContractCribeIcon,
  OnCallIcon,
  AckIcon,
  ValidIcon,
  ReportIcon,
  GenerateIcon,
};
