import React, { Fragment, FC, useState, useEffect, ReactNode } from 'react';
import { PageTitle, ErrorPaper, InfoPaper } from 'component/shared/Ui';
import LoadingScreen from 'component/LoadingScreen';
import {
  ImpStaResult,
  ImpStaProcessedResult,
  ValidatedImportItem,
  ValidatedBulkUpdate,
  ModificationCount,
  ImpStaUpdate,
  ImpStaBulkUpdate,
} from 'interface/ImportType';
import { BulkStaUpdateFields } from 'constant/Import.constant';
import { getImportInfos, executeStationImport } from 'service/Import.service';
import { equals, ImportSteps } from '../ImportUtils';
import ImportStationAdditions from './ImportStationAdditions';
import ImportStationUpdates from './ImportStationUpdates';
import { handleErrorPage } from 'helper/handle-response';
import { useNavigate } from 'react-router-dom';

/**
 * Composant de la page d'import de stations
 * @param id id de l'import
 * @param setHttpCodePage fonction pour changer le code http de la page
 * @param props props du composant
 */
const ImportStation: FC = ({ id, setHttpCodePage, ...props }: any) => {
  const filename = props?.location?.state?.filename;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [importFilename, setImportFilename] = useState<string>();
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isImportOver, setImportOver] = useState<boolean>(false);
  const [isImportAdditionsOver, setImportAdditionsOver] = useState<boolean>(false);
  const [isImportUpdatesOver, setImportUpdatesOver] = useState<boolean>(false);

  const [importResult, setImportResult] = useState<ImpStaProcessedResult>();

  const [validatedAdditions, setValidatedAdditions] = useState<ValidatedImportItem>({});
  const [validatedUpdates, setValidatedUpdates] = useState<ValidatedImportItem>({});
  const [validatedBulks, setValidatedBulks] = useState<ValidatedBulkUpdate[]>([]);

  useEffect(() => {
    if (id) {
      if (filename) setImportFilename(filename);
      else
        getImportInfos('station', id)
          .then((result) => setImportFilename(result.filename))
          .catch((response) => handleErrorPage(response, setHttpCodePage));

      executeStationImport(id)
        .then((result) => {
          setLoaded(true);
          setImportResult(processImportResult(result));
        })
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else {
      navigate('/import-station');
    }
  }, [id, filename, navigate, setHttpCodePage]);

  const additionsNumber = importResult?.additions.length || 0;
  const updatesNumber = (importResult?.updates.length || 0) + (importResult?.bulkUpdates.length || 0);

  const areAdditionsValidated = Object.keys(validatedAdditions).length >= additionsNumber;
  const areUpdatesValidated = Object.keys(validatedUpdates).length + validatedBulks.length >= updatesNumber;
  const areAdditionsNotEmpty = additionsNumber !== 0 && Object.keys(validatedAdditions).length !== 0;
  const areUpdatesNotEmpty = updatesNumber !== 0 && Object.keys(validatedUpdates).length + validatedBulks.length !== 0;

  // Moving to the next step when needed + checking if import is over
  useEffect(() => {
    if (areAdditionsNotEmpty && !isImportAdditionsOver && areAdditionsValidated) {
      setImportAdditionsOver(true);
      if (!areUpdatesValidated) setActiveStep(1);
    }
    if (areUpdatesNotEmpty && !isImportUpdatesOver && areUpdatesValidated) {
      setImportUpdatesOver(true);
      if (!areAdditionsValidated) setActiveStep(0);
    }
    if ((areAdditionsNotEmpty || areUpdatesNotEmpty) && areAdditionsValidated && areUpdatesValidated) {
      setImportOver(true);
    }
  }, [
    isImportAdditionsOver,
    isImportUpdatesOver,
    areAdditionsValidated,
    areUpdatesValidated,
    areAdditionsNotEmpty,
    areUpdatesNotEmpty,
  ]);

  const stepLabels: string[] = [`Ajouts (${additionsNumber})`, `Mises à jour (${updatesNumber})`];

  const stepContents: ReactNode[] = [
    <ImportStationAdditions
      idImport={id}
      additions={importResult?.additions}
      validatedAdditions={validatedAdditions}
      setValidatedAdditions={setValidatedAdditions}
    />,
    <ImportStationUpdates
      idImport={id}
      updates={importResult?.updates}
      bulkUpdates={importResult?.bulkUpdates}
      validatedUpdates={validatedUpdates}
      setValidatedUpdates={setValidatedUpdates}
      validatedBulks={validatedBulks}
      setValidatedBulks={setValidatedBulks}
    />,
  ];

  return (
    <Fragment>
      {importFilename && <PageTitle>Import de {importFilename}</PageTitle>}
      {isLoaded ? (
        <div>
          <ImportSteps stepLabels={stepLabels} activeStep={activeStep} setActiveStep={setActiveStep} />
          {!!importResult?.errors.length && (
            <ErrorPaper title="Erreurs lors de l'import">
              <ul>
                {importResult.errors.map((error, index) => (
                  <li key={`error-${index}`} style={{ marginLeft: '2em' }}>
                    {error}
                  </li>
                ))}
              </ul>
            </ErrorPaper>
          )}
          {isImportOver && <InfoPaper title="L'import est terminé" style={{ textAlign: 'center' }} />}
          {stepContents[activeStep]}
        </div>
      ) : (
        <div style={{ marginTop: '5em' }}>
          <LoadingScreen />
        </div>
      )}
    </Fragment>
  );
};

/**
 * Traitement des résultats d'un import
 * @param additions ajout de stations
 * @param updates mises à jour de stations
 * @param errors erreurs
 */
const processImportResult = ({ additions, updates, errors }: ImpStaResult): ImpStaProcessedResult => {
  const modificationCounts: ModificationCount[] = [];
  updates
    .filter(
      (update: ImpStaUpdate) =>
        update.modifications.length === 1 && BulkStaUpdateFields.includes(update.modifications[0].attribute),
    )
    .forEach((update: ImpStaUpdate) => {
      const modificationCount = modificationCounts.find(({ modification }) =>
        equals(modification, update.modifications[0]),
      );
      if (modificationCount) modificationCount.count++;
      else modificationCounts.push({ modification: update.modifications[0], count: 1 });
    });

  const bulkUpdates: ImpStaBulkUpdate[] = modificationCounts
    .filter(({ count }) => count > 1)
    .map(({ modification }) => ({ modification, stations: [] }));

  const processedUpdates: ImpStaUpdate[] = [];
  updates.forEach((update: ImpStaUpdate) => {
    if (update.modifications?.length) {
      const bulkUpdate = bulkUpdates.find(({ modification }) => equals(modification, update.modifications[0]));
      if (update.modifications?.length === 1 && bulkUpdate) {
        bulkUpdate.stations.push(update);
      } else {
        processedUpdates.push(update);
      }
    }
  });

  return {
    additions,
    updates: processedUpdates,
    bulkUpdates,
    errors,
  };
};

export default ImportStation;
