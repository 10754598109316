import React, { FC, Fragment, ReactNode } from 'react';
import { default as dayjs } from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './import.scss';

import { displayDate, downloadFile } from 'helper/Utils';
import {
  BoolIcon,
  CmsDialog,
  CmsPaper,
  LabelValueUI,
  OpenInNewIconButton,
  SectionTitle,
  SimplePre,
} from '../../component/shared/Ui';
import { BooleanFields, DateFields, ImportFieldLabels } from 'constant/Import.constant';
import { downloadImportFile } from 'service/Import.service';
import {
  ImpEquStationProcessed,
  ImportFile,
  ImportStructAnalysis,
  ImpUpdateModification,
  ValidatedBulkUpdate,
  ValidatedImportItem,
} from '../../interface/ImportType';
import theme from 'style/Theme';
import { BASE_URL } from 'constant/API_URL';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import { useNavigate } from 'react-router-dom';

/**
 * Composant affichant le nombre d'éléments validés
 * @param validated nombre d'éléments validés
 * @param children éléments à afficher
 */
export const ImportValidatedAccordion: FC<{ validated: number; children: ReactNode }> = ({ validated, children }) => {
  const [isExpanded, setExpanded] = React.useState(false);
  return (
    <div className="import-validated-accordeon" style={{ marginBottom: '1.5em' }}>
      <Accordion expanded={isExpanded} onChange={() => setExpanded(!isExpanded)}>
        <AccordionSummary>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            <span>{validated} éléments validés</span>
            {isExpanded ? <ExpandMoreIcon /> : <ChevronLeftIcon />}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>{children}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

/**
 * Composant affichant le nombre d'éléments non validés
 * @param idImport id de l'import
 * @param stations stations de l'import
 * @param validatedElements éléments validés
 * @param setValidatedElements fonction de mise à jour des éléments validés
 * @param validated si les éléments sont validés ou non
 * @param EquipementComponent composant d'affichage des équipements
 */
export const EquipementPerStation: FC<{
  idImport: string;
  stations: ImpEquStationProcessed[];
  validatedElements: ValidatedImportItem;
  setValidatedElements: any;
  validated?: boolean;
  EquipementComponent: any;
}> = ({ idImport, stations, validatedElements, setValidatedElements, validated = false, EquipementComponent }) => (
  <Fragment>
    {stations
      .filter((station) => station.equipements.some((equipement) => equipement.ref in validatedElements === validated))
      .map((station, idx) => (
        <CmsPaper key={`${station.id}-${idx}`} title={<StationLabelLink station={station} />}>
          {station.equipements
            .filter((equipement) => equipement.ref in validatedElements === validated)
            .map((equipement, idx) => (
              <EquipementComponent
                idImport={idImport}
                key={`${equipement.ref}-${idx}`}
                equipement={equipement}
                validatedElements={validatedElements}
                setValidatedElements={setValidatedElements}
                validated={validated}
              />
            ))}
        </CmsPaper>
      ))}
  </Fragment>
);

const importDataStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

/**
 * Composant affichant les informations d'un fichier d'import
 * @param label label de l'import
 * @param labelValues valeurs du label
 * @param customFields champs personnalisés
 * @param actions actions (boutons en haut à droite)
 * @param noActions si on affiche les actions ou non
 * @param accepted si l'import est accepté
 * @param refused si l'import est refusé
 * @param accept action d'acceptation
 * @param refuse action de refus
 * @param secondLvl si le composant est à deux niveaux
 * @param style style du composant
 * @param tied si le composant est lié à un autre
 */
export const ImportItem: FC<{
  label: any;
  labelValues?: { [key: string]: string | null };
  customFields?: ReactNode[];
  actions?: ReactNode[];
  noActions?: boolean;
  accepted?: boolean;
  refused?: boolean;
  accept?: any;
  refuse?: any;
  secondLvl?: boolean;
  style?: object;
  tied?: boolean;
}> = ({
  label,
  labelValues = {},
  customFields = [],
  actions = [],
  noActions = false,
  accepted,
  refused,
  accept,
  refuse,
  secondLvl = true,
  style = {},
  tied = false,
}) => (
  <CmsPaper secondLvl={secondLvl} style={{ ...importDataStyle, ...style }} tied={tied}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom style={{ lineHeight: 'inherit', fontSize: '1.1rem' }}>
        {label}
      </Typography>
      {!!Object.keys(labelValues).length &&
        Object.entries(labelValues)
          .filter(([l, value]) => value)
          .filter((value) => !!value)
          .map(([label, value]) => (
            <LabelValueUI key={label} label={label} style={{ marginLeft: '1em', lineHeight: 'normal' }}>
              {value}
            </LabelValueUI>
          ))}
      {customFields}
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
      {!noActions && (
        <Fragment>
          {actions.length ? (
            actions.map((action, i) => <Fragment key={`action-${label}-${i}`}>{action}</Fragment>)
          ) : (
            <Fragment>
              <ValidationButton
                type="accept"
                contained={accepted}
                validated={accepted}
                disabled={refused}
                onClick={accept}
                style={{ marginBottom: '0.5em' }}
              />
              <ValidationButton
                type="refuse"
                contained={refused}
                validated={refused}
                disabled={accepted}
                onClick={refuse}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  </CmsPaper>
);

/**
 * Composant affichant un bouton de validation
 * @param type type de bouton (accepter/refuser)
 * @param contained si le bouton est plein ou non
 * @param disabled si le bouton est désactivé ou non
 * @param validated si le bouton est validé ou non
 * @param onClick action du bouton
 * @param style style du bouton
 * @param label label du bouton
 */
export const ValidationButton: FC<{
  type: 'accept' | 'refuse';
  contained?: boolean;
  disabled?: boolean;
  validated?: boolean;
  onClick?: any;
  style?: object;
  label?: string;
}> = ({ type, contained = false, disabled = false, validated = false, onClick, style = {}, label }) => (
  <Button
    variant={contained ? 'contained' : 'outlined'}
    disabled={disabled}
    color={type === 'accept' ? 'primary' : 'secondary'}
    size="small"
    startIcon={type === 'accept' ? <CheckIcon /> : <ClearIcon />}
    style={{ ...style, pointerEvents: validated ? 'none' : 'auto' }}
    onClick={onClick}
  >
    {label ? label : type === 'accept' ? 'Accepter' : 'Refuser'}
  </Button>
);

/**
 * Determine si deux modifications sont égales
 * @param modif1
 * @param modif2
 */
export const equals = (modif1: ImpUpdateModification, modif2: ImpUpdateModification): boolean => {
  if (modif1 === modif2) return true;
  return (
    modif1.attribute === modif2.attribute && modif1.oldValue === modif2.oldValue && modif1.newValue === modif2.newValue
  );
};

const stepperStyle = {
  backgroundColor: 'inherit',
  padding: '0.5em 0 0.25em 0',
};
/**
 * Composant affichant les étapes d'un import
 * @param stepLabels labels des étapes
 * @param activeStep étape active
 * @param setActiveStep action de changement d'étape
 */
export const ImportSteps: FC<{ stepLabels: string[]; activeStep: number; setActiveStep: any }> = ({
  stepLabels,
  activeStep = 0,
  setActiveStep,
}) => (
  <CmsPaper>
    <Stepper alternativeLabel nonLinear activeStep={activeStep} style={stepperStyle}>
      {stepLabels.map((label: string, index: number) => (
        <Step key={label}>
          <StepButton onClick={() => setActiveStep(index)}>{label}</StepButton>
        </Step>
      ))}
    </Stepper>
  </CmsPaper>
);

interface ImportListItemProps extends ImportFile {
  // la base url du lien vers le fichier
  linkBaseUrl: string;
  // la catégorie de l'import (station ou équipement)
  category: 'station' | 'equipment';
  // les analyses de structure de l'import AD
  structAnalysisAd?: ImportStructAnalysis;
  // les analyses de structure de l'import CRIBE
  structAnalysisCribe?: ImportStructAnalysis;
}

/**
 * Composant affichant un élément de liste d'import
 * @param id id de l'import
 * @param filename nom du fichier
 * @param imported si l'import est terminé ou non
 * @param createdAt date de création
 * @param importedAt date d'import
 * @param linkBaseUrl base url du lien vers le fichier
 * @param category catégorie de l'import (station ou équipement)
 * @param structAnalysisAd analyses de structure de l'import AD
 * @param structAnalysisCribe analyses de structure de l'import CRIBE
 */
export const ImportListItem: FC<ImportListItemProps> = ({
  id,
  filename,
  imported,
  createdAt,
  importedAt,
  linkBaseUrl,
  category,
  structAnalysisAd,
  structAnalysisCribe,
}) => {
  const navigate = useNavigate();
  const download = () => {
    downloadImportFile(category, id).then((blob) => downloadFile(blob, filename));
  };
  const handleClick = () => {
    navigate(linkBaseUrl + '/' + id, { state: { filename: filename } });
  };
  return (
    <CmsPaper secondLvl tied>
      <Grid container spacing={3} alignContent="center" style={{ margin: '-12px' }}>
        <GridItem>
          <InsertDriveFileIcon style={{ marginRight: '0.5em' }} /> {filename}
        </GridItem>
        <GridItem style={{ justifyContent: 'center' }}>{displayDate(createdAt)}</GridItem>
        <GridItem style={{ justifyContent: 'flex-end' }}>
          {imported && importedAt && <small>(déjà importé le {displayDate(importedAt)})</small>}
          {filename.endsWith('.STA') && structAnalysisAd && structAnalysisAd.idImportChanged === id && (
            <Tooltip title={structAnalysisAd.hintChange || ''} placement="top" arrow>
              <ErrorOutlineIcon color="secondary" />
            </Tooltip>
          )}
          {filename.endsWith('.STQ') && structAnalysisCribe && structAnalysisCribe.idImportChanged === id && (
            <Tooltip title={structAnalysisCribe.hintChange || ''} placement="top" arrow>
              <ErrorOutlineIcon color="secondary" />
            </Tooltip>
          )}
          <Tooltip title="Télécharger le fichier d'import" placement="top" arrow>
            <Button size="small" variant="outlined" onClick={download} style={{ minWidth: 'unset', marginLeft: '1em' }}>
              <GetAppIcon fontSize="small" />
            </Button>
          </Tooltip>
          <Button
            size="small"
            variant="contained"
            startIcon={<PublishIcon />}
            color="primary"
            onClick={handleClick}
            style={{ marginLeft: '1em' }}
          >
            Importer
          </Button>
        </GridItem>
      </Grid>
    </CmsPaper>
  );
};

/**
 * Génère un composant GridItem par défaut
 * @param children élément à afficher
 * @param style style à appliquer
 */
export const GridItem: FC<{ children: any; style?: object }> = ({ children, style = {} }) => (
  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', padding: '0.3em 1em', ...style }}>
    {children}
  </Grid>
);

/**
 * Composant affichant un bouton/modal d'inspection
 * @param children élément à afficher
 * @param title titre du bouton
 */
export const InspectDialog: FC<{ children: any; title: string }> = ({ children, title }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Fragment>
      <Button variant="outlined" size="small" onClick={handleClickOpen} startIcon={<VisibilityIcon />}>
        Inspecter
      </Button>
      <CmsDialog open={open} onClose={handleClose} withBackground fullWidth>
        <SectionTitle
          title={title}
          actions={[
            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
        {children}
      </CmsDialog>
    </Fragment>
  );
};

const bulkUpdateItemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '2em',
  paddingRight: '2em',
};

/**
 * Composant affichant un élément de liste de mise à jour en masse
 * @param acceptBulk fonction d'acceptation de la mise à jour
 * @param children élément à afficher
 * @param modification modification à appliquer
 * @param validated si la modification est validée ou non
 * @param validatedBulks liste des modifications validées
 * @param setValidatedBulks fonction de mise à jour de la liste des modifications validées
 * @param elementLength nombre d'éléments à modifier
 * @param titleDialog titre de la modale de validation
 */
export const BulkUpdateItem: FC<{
  acceptBulk: any;
  children: any;
  modification: ImpUpdateModification;
  validated?: boolean;
  validatedBulks: ValidatedBulkUpdate[];
  setValidatedBulks: any;
  elementLength: number;
  titleDialog: string;
}> = ({
  acceptBulk,
  children,
  modification,
  validated = false,
  validatedBulks,
  setValidatedBulks,
  elementLength,
  titleDialog,
}) => {
  let accepted: boolean = false,
    refused: boolean = false;
  if (validated) {
    const validatedBulk = validatedBulks.find((validatedBulk) => equals(validatedBulk.modification, modification));
    accepted = !!validatedBulk && validatedBulk.validated === 'accepted';
    refused = !!validatedBulk && validatedBulk.validated === 'refused';
  }

  const accept = () => {
    if (!validated) {
      acceptBulk(() => setValidatedBulks((current: any[]) => [...current, { modification, validated: 'accepted' }]));
    }
  };
  const refuse = () => {
    if (!validated) setValidatedBulks((current: any[]) => [...current, { modification, validated: 'refused' }]);
  };

  return (
    <CmsPaper style={bulkUpdateItemStyle}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginRight: '2em' }}>
          <FilterNoneIcon fontSize="large" style={{ marginBottom: '0.1em' }} />
          <span>{elementLength}</span>
        </div>
        <span style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
          {getLabel(modification.attribute, modification.attributeLabel)}
        </span>
      </div>
      <span>
        <UpdatedField
          attribute={modification.attribute}
          oldValue={modification.oldValue}
          newValue={modification.newValue}
        />
      </span>
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        <InspectDialog title={titleDialog}>{children}</InspectDialog>
        <ValidationButton
          type="accept"
          contained={accepted}
          disabled={refused}
          style={{ marginLeft: '0.5em' }}
          onClick={accept}
        />
        <ValidationButton
          type="refuse"
          contained={refused}
          disabled={accepted}
          style={{ marginLeft: '0.5em' }}
          onClick={refuse}
        />
      </div>
    </CmsPaper>
  );
};

/**
 * Composant affichant les modifications d'un élément
 * @param label label de la modification
 * @param children élément à afficher
 */
export const UpdatedLabelValue: FC<{ label: string; children: ReactNode }> = ({ label, children }) => (
  <LabelValueUI
    label={<span style={{ color: theme.palette.secondary.main }}>{label}</span>}
    style={{ marginLeft: '1em', lineHeight: 'normal', display: 'flex', alignItems: 'center' }}
  >
    <span style={{ marginLeft: '0.5em' }}>{children}</span>
  </LabelValueUI>
);

/**
 * Composant affichant les modifications d'un élément
 * @param attribute attribut modifié
 * @param oldValue ancienne valeur
 * @param newValue nouvelle valeur
 */
export const UpdatedField: FC<{ attribute: string; oldValue: any; newValue: any }> = ({
  attribute,
  oldValue,
  newValue,
}) => (
  <span style={{ display: 'inline-flex' }}>
    <SimplePre>{getValue(attribute, oldValue)}</SimplePre>
    <span style={{ marginLeft: '2em', marginRight: '2em', color: theme.palette.secondary.main }}>⟶</span>
    <SimplePre>{getValue(attribute, newValue)}</SimplePre>
  </span>
);

/**
 * Récupère le label d'un attribut
 * @param attribute attribut
 * @param attributeLabel label de l'attribut
 */
export const getLabel = (attribute: string, attributeLabel: string | undefined) => {
  return attributeLabel ? attributeLabel : ImportFieldLabels[attribute] ? ImportFieldLabels[attribute] : attribute;
};

/**
 * Récupère la valeur à afficher
 * @param attribute attribut
 * @param value valeur
 */
export const getValue = (attribute: string, value: any) => {
  if (DateFields.includes(attribute)) return displayDate(value);
  if (BooleanFields.includes(attribute)) return <BoolIcon value={value} />;
  return value;
};

/**
 * Lien vers une station
 * @param station station
 */
export const StationLabelLink: FC<{ station: any }> = ({ station }) => (
  <span style={{ display: 'flex' }}>
    {station.ref} - {station.label}
    <OpenInNewIconButton
      title="Ouvrir la station dans un nouvel onglet"
      href={`${BASE_URL}castres/client/station/${station.id}/show`}
      style={{ fontSize: '1.25rem', marginLeft: '0.5em' }}
    />
  </span>
);

/**
 * Fonction d'analyse de la structure d'un import
 * @param allImports tous les imports
 * @param newImports nouveaux imports
 */
export const analyseStructureChange = (
  allImports: ImportFile[],
  newImports: ImportFile[],
): ImportStructAnalysis | null => {
  // Pas de raison de comparer s'il y a qu'un seul import en tout
  if (allImports.length > 1) {
    const { id: importId, structure: newStruct }: ImportFile = findMostRecentImport(allImports);
    // Si l'import le plus récent n'est pas déjà fait
    if (newImports.some((el) => el.id === importId)) {
      const allImportRest: ImportFile[] = allImports.filter((el) => el.id !== importId);
      const { structure: oldStruct }: ImportFile = findMostRecentImport(allImportRest);
      if (newStruct && oldStruct) {
        if (newStruct === oldStruct) return null;
        const newStructArray: string[] = newStruct.split(';');
        const oldStructArray: string[] = oldStruct.split(';');
        if (newStructArray.length !== oldStructArray.length) {
          return {
            idImportChanged: importId,
            hintChange: 'Le nombre de colonne a changé',
          };
        } else {
          for (let i = 0; i < newStructArray.length; i++) {
            if (newStructArray[i] !== oldStructArray[i]) {
              return {
                idImportChanged: importId,
                hintChange: `Différence de colonne à partir de la colonne ${i + 1}`,
              };
            }
          }
        }
      }
    }
  }
  return null;
};

/**
 * Fonction de recherche de l'import le plus récent
 * @param importList liste des imports
 */
const findMostRecentImport = (importList: ImportFile[]) => {
  return importList.reduce((prev, current) =>
    dayjs(prev.createdAt).isAfter(dayjs(current.createdAt)) ? prev : current,
  );
};
