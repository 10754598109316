import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { SfWorktime } from '../../../interface/SfType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const sfWorktimeConfigList: CmsColumnDef<SfWorktime>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Coefficient', id: 'coeff', Filter: CmsTableFilter.Text },
];

/**
 * Page de gestion des types de temps de travail
 */
export const SfWorktimeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_WORKTIME_MASTER])
    ? [<AddNavigationButton title="Ajouter un type de contrat" to="/castres/staff/worktime/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Temps de travail"
      route={APIRoute.SfWorktime}
      columns={sfWorktimeConfigList}
      navigateTo={(id: number) => `/castres/staff/worktime/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Page de création et d'édition des types de temps de travail
 * @param id de l'élément à éditer
 */
export const SfWorktimeCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/staff/worktime/', route: APIRoute.SfWorktime, title: 'Temps de travail' };
  return (
    <CmsForm id={id} {...props} thin>
      <CmsFormInput.Text id="label" label="Nom" required min={2} />
      <CmsFormInput.Text id="slug" label="Slug" required min={2} />
      <CmsFormInput.Number id="coeff" label="Coefficient" required />
    </CmsForm>
  );
};
