import React from 'react';
import HealingIcon from '@mui/icons-material/Healing';
import BuildIcon from '@mui/icons-material/Build';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CachedIcon from '@mui/icons-material/Cached';
import Tooltip from '@mui/material/Tooltip';

// Composant React de rendu des diagrammes des équipements
export const EqActionIcon = (props: any) => {
  // On test pour savoir si la valeur vient de reactTable ou bien d'un composant lambda
  const contractLabelList = props?.cell?.value ? props.cell.value : props;

  const iconForActionList: Array<any> = [
    { label: 'Curatif', icon: <HealingIcon /> },
    { label: 'Préventif (ou réglementaire)', icon: <BuildIcon /> },
    { label: 'Régie contrôlée', icon: <CachedIcon /> },
  ];

  if (!contractLabelList) return '';
  if (contractLabelList.filter((label: string) => label === 'Full').length > 0) {
    return (
      <Tooltip title="Full">
        <ControlCameraIcon />
      </Tooltip>
    );
  }
  return (
    <>
      {iconForActionList.map((iconObj: any) => {
        if (contractLabelList.filter((label: string) => label === iconObj.label).length > 0) {
          return (
            <Tooltip key={iconObj.label} title={iconObj.label}>
              {iconObj.icon}
            </Tooltip>
          );
        }
        return '';
      })}
    </>
  );
};
