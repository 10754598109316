import { css } from '@emotion/core';
import React, { CSSProperties, FC, Fragment } from 'react';

export interface LabelValueIf {
  // Label de la ligne
  label: string;
  // Valeur de la ligne
  value: any;
  // Optionel, si la condition est remplie, on affiche la ligne
  ifIs?: (x: any) => boolean;
}

interface TabStyleDataViewProps {
  // La liste des lignes à afficher
  conf: Array<LabelValueIf>;
  // Optionel, L'objet à partir duquel on va rechercher les valeurs
  mapFrom?: any;
  // Optionel, permet de définir le style de la grille (grid-template-columns) comme la taille des colonnes
  gridTemplateColumns?: string;
  // Optionel, surcharge le css initial
  style?: CSSProperties;
}

const container = css({
  display: 'grid',
  div: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
});

const lightLine = css({
  backgroundColor: 'rgba(255,255,255, 0.1)',
});

const labelStyle = css({
  fontWeight: 'bold',
  paddingRight: '20px',
  paddingLeft: '6px',
});

/**
 * Affiche un tableau de donnée formatter en terme d'affichage.
 * @param conf La liste des lignes à afficher ([{label: 'Label', value: 'Valeur', ifIs: (x) => {return true}}])
 * @param gridTemplateColumns Optionel, permet de définir le style de la grille (grid-template-columns) comme la taille des colonnes
 * @param mapFrom Optionel, L'objet à partir duquel on va rechercher les valeurs (surcharge la valeur de value)
 * @param style Optionel, surcharge le css initial
 */
export const TabStyleDataView: FC<TabStyleDataViewProps> = ({
  conf,
  gridTemplateColumns = 'fit-content(50%) 1fr',
  mapFrom,
  style,
}) => {
  const filteredConf = conf?.filter(({ ifIs }) => !ifIs || ifIs(mapFrom)) ?? [];
  if (filteredConf.length === 0) return <></>;
  return (
    <div css={container} style={{ gridTemplateColumns, ...style }}>
      {filteredConf.map(({ label, value }, i) => (
        <Fragment key={i}>
          <div key={'label-' + label} css={css(i % 2 ? lightLine : '', labelStyle)}>
            {label}
          </div>
          <div key={'value-' + label} css={i % 2 ? lightLine : ''}>
            {!!mapFrom ? value(mapFrom) : value}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
