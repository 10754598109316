import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { ClClientCode } from '../../../interface/ClType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';
import CmsTableCell from 'component/table/helper/CmsTableCell';

export const ClClientCodeConfigList: CmsColumnDef<ClClientCode>[] = [
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Text, cell: (info) => <CmsTableCell.Ref cell={info} /> },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  {
    header: 'Agences',
    id: 'agencyIdList',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClAgency },
    hide: true,
  },
  { header: 'Agences', id: 'agencyListAsString' },
];

export const ClClientCodeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_CLIENTCODE_LIST])
    ? [<AddNavigationButton title="Ajouter un code client" to="/castres/client/clientcode/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des codes clients"
      route={APIRoute.ClClientCode}
      columns={ClClientCodeConfigList}
      navigateTo={(id: number) => `/castres/client/clientcode/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const ClClientCodeCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/client/clientcode/', route: APIRoute.ClClientCode, title: 'Code Client' };
  return (
    <CmsForm id={id} {...props} deletable thin>
      <CmsFormInput.Text id="ref" label="Référence" required max={3} />
      <CmsFormInput.Text id="label" label="Nom" required max={50} />
      <CmsFormInput.Select id="agencyIdList" label="Agences" options={APIRoute.ClAgency + '/Simplified'} multiple />
    </CmsForm>
  );
};
