import React, { FC, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../constant/API.constant';
import { CmsButton, CmsPaper } from '../../component/shared/Ui';
import LoadingScreen from '../../component/LoadingScreen';
import CRUD from '../../service/CRUD.service';
import { UI } from '../../component/shared';
import '../user/user.scss';
import Utils from '../../helper/Utils';
import { IdLabel } from '../../interface/CommonType';
import ListItem from '@mui/material/ListItem';

/**
 * Permet d'afficher la liste des notes de virement validées par l'opérateur
 * comptable, et de pouvoir télécharger les fichiers générés
 */
const GreenfluxNdvListExport: FC = () => {
  const [search] = useState<string>();
  const [currentOpenMenu, setCurrentOpenMenu] = useState<number>(-1);
  const [folders, setFolders] = useState<NdvElementFolder[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [files, setFiles] = useState<IdLabel[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<number>(-1);

  const memoizedListL1 = useMemo(() => {
    return folders.filter((x: NdvElementFolder) => !search || x.label?.toUpperCase().includes(search));
  }, [folders, search]);

  useEffect(() => {
    setIsLoading(true);
    CRUD.getList<any>(APIRoute.WkGreenfluxNoteDeVirement + '/files')
      .then(setFolders)
      .finally(() => setIsLoading(false));
  }, []);

  const handleSubMenuToggle = (folderId: number) => {
    setCurrentOpenMenu(currentOpenMenu !== folderId ? folderId : -1);
  };

  if (isLoading) return <LoadingScreen />;
  return (
    <CmsPaper title="Liste des fichiers générés lors de la validation des notes de virement">
      <div className="NDV-page-style">
        <UI.MenuList
          title="Liste des notes de virement"
          style={{ overflowY: 'auto' }}
          className="styled-scroll reverse-group"
        >
          {memoizedListL1?.map((folder: any) => (
            <UI.SubMenuItem
              onMenuClick={() => handleSubMenuToggle(folder.id)}
              isOpen={currentOpenMenu === folder.id}
              key={folder.id}
              id={folder.id}
              title={<div>{folder.name}</div>}
            >
              {folder.folders.map((subfolder: IdLabel) => (
                <ListItem
                  key={subfolder.id}
                  onClick={() => setSelectedFolder(subfolder.id)}
                  className={'role' + (selectedFolder === subfolder.id ? ' selected' : '')}
                  style={selectedFolder === subfolder.id ? { color: '#49afcd' } : undefined}
                >
                  {subfolder.label}
                </ListItem>
              ))}
            </UI.SubMenuItem>
          ))}
        </UI.MenuList>
        {selectedFolder > -1 && <ShowFiles files={files} folderId={selectedFolder} setFiles={setFiles} />}
      </div>
    </CmsPaper>
  );
};

interface ShowFilesProps {
  files: IdLabel[] | undefined;
  folderId: number;
  setFiles: any;
}

/**
 * Permet l'affichage des fichiers rattachés à une station pour
 * lister les fichiers qui lui sont associés
 * @param files liste des fichiers que la station a selon la note de virement choisie
 * @param folderId ID du dossier, qui correspond à une station
 * @param setFiles permet, lors de la récupération des données du backend, d'éditer "files"
 */
const ShowFiles: FC<ShowFilesProps> = ({ files, folderId, setFiles }) => {
  if (files === undefined || files[0]?.id !== folderId) {
    CRUD.getById<IdLabel>(APIRoute.WkGreenfluxNoteDeVirement + '/export', folderId).then(setFiles);
    return <LoadingScreen />;
  }

  const downloadFile = (index: number) => {
    CRUD.getBlob(`${APIRoute.WkGreenfluxNoteDeVirement}/download/${files[index]?.id}`).then((result) => {
      Utils.downloadFile(result, files[index]?.label);
    });
  };

  return (
    <CmsPaper title={'Liste de fichiers téléchargeables'} className="NDV-list-export-paper">
      {files.map((file, index) => (
        <CmsButton onClick={() => downloadFile(index)} key={index}>
          {file.label}
        </CmsButton>
      ))}
    </CmsPaper>
  );
};

/**
 * Structure de données pour la représentation de l'arborescence
 * pour la structure de dossier
 */
export interface NdvElementFolder {
  id: number;
  label: string;
  folders: IdLabel[];
}

export default GreenfluxNdvListExport;
