import React, { FC, useEffect, useState } from 'react';
import { CmsButton, CmsPaper, WarningBubble } from '../shared/Ui';
import { Buttons, InputUI, UI } from '../shared';
import { TabStyleDataView } from '../shared/TabStyleDataView';
import { checkStatus, handleCrudErrorNotif, handleJsonResponse } from '../../helper/handle-response';
import CmsIcon from '../shared/CmsIcon';
import Utils from '../../helper/Utils';
import codesNaf from '@socialgouv/codes-naf/index.json';
import { Grid } from '@mui/material';
import notificationService from '../../service/NotificationService';
import proj4 from 'proj4';

const enterpriseURl = 'https://annuaire-entreprises.data.gouv.fr/entreprise/';
const webUrl = 'https://annuaire-entreprises.data.gouv.fr/rechercher?terme=';
const searchUrl = (search: string) => `https://recherche-entreprises.api.gouv.fr/search?q=${search}&page=1&per_page=10`;

export const SiretLinkIcon: FC<{ siret?: string }> = ({ siret }) => {
  if (!siret || siret.length !== 14) return <></>;
  const url = enterpriseURl + siret?.slice(0, 9);
  const params = { style: { margin: '-0.3rem 0' }, icon: 'info', onClick: () => window.open(url, '_blank') };
  return <CmsIcon {...(params as any)} tooltip="Voir dans l'annuaire" />;
};

export const InseeNameCityLinkIcon: FC<{ name: string; city: string }> = ({ name, city }) => {
  let url = webUrl + name;
  if (!!city) url = `${webUrl + name}, ${city}`;
  const params = { style: { margin: '-0.3rem 0' }, icon: 'info', onClick: () => window.open(url, '_blank') };
  return <CmsIcon {...(params as any)} tooltip="Voir dans l'annuaire proposé" />;
};

interface ImportClientInseeDataProps {
  form: any;
  mandatory?: boolean;
  getSiege?: boolean;
}

export const ImportClientInseeData: FC<ImportClientInseeDataProps> = ({ form, mandatory, getSiege = true }) => {
  const formData = form.watch();
  const siret = formData.siret;
  let defaultSearch = siret;
  if (!defaultSearch)
    defaultSearch = (
      (formData.companyName ?? formData.name ?? formData.label ?? '') +
      ' ' +
      (formData.postcode ?? '')
    ).trim();
  const [open, setOpen] = useState(false);
  const [firstOpen, setFirstOpen] = useState(true);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState(defaultSearch);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setSearch(defaultSearch);
  }, [defaultSearch]);

  const getData = () => {
    setSearching(true);
    getSiretData(search, getSiege)
      .then(setData)
      .finally(() => setSearching(false));
  };

  if (firstOpen && open) {
    setFirstOpen(false);
    if (!!search) getData();
  }

  const handleImport = () => {
    const lastApiSync = data.lastApiSync;
    data.lastApiSync = null;
    form.reset({ ...form.watch(), ...data });
    // Malheureusement c'est la seule manière de forcer le dirty sur le composant useForm
    setTimeout(() => {
      form.setValue('lastApiSync', lastApiSync, { shouldDirty: true });
    }, 100);
    setOpen(false);
  };

  const handleHelpClick = () => {
    let url = webUrl + (formData?.label ?? formData?.name);
    if (formData?.city) url += ', ' + formData?.city;
    window.open(url, '_blank');
  };

  const handleSearchHelpLink = () => window.open(webUrl + search, '_blank');
  const help = <CmsButton onClick={handleHelpClick}>Annuaire Entreprises</CmsButton>;
  let dataField = getSiege
    ? importDataFields.filter((x) => x.siege !== false)
    : importDataFields.filter((x) => x.siege !== true && x.isSupplier !== false);
  return (
    <>
      <CmsButton className={mandatory ? 'hint-button' : ''} onClick={() => setOpen(true)}>
        Import Insee
      </CmsButton>
      <UI.Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
        <CmsPaper
          title="Import des données INSEE"
          style={{ marginBottom: 0, minWidth: '60vw' }}
          actions={[help, <CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        >
          <div className="flex-h align-center">
            <div style={{ width: '70%' }}>
              <InputUI.CMSTextField value={search} onChange={setSearch} />
            </div>
            <div>
              <Buttons.Default
                style={{ margin: ' 0 0 1rem 1rem' }}
                size="medium"
                disabled={searching || !search}
                onClick={getData}
              >
                Rechercher
              </Buttons.Default>
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item sm={12} lg={6}>
              <h2>Données actuelles</h2>
              <TabStyleDataView conf={dataField} mapFrom={formData} />
            </Grid>
            <Grid item sm={12} lg={6}>
              <h2>Données trouvées</h2>
              {!data?.lastApiSync ? (
                <div>Aucun import effectué</div>
              ) : !data.multipleResults ? (
                <TabStyleDataView conf={dataField} mapFrom={data ?? []} />
              ) : (
                <WarningBubble style={{ marginTop: '1em' }}>
                  Votre recherche a remonté plusieurs résultats, veuillez choisir le bon dans cette liste
                  <CmsButton onClick={handleSearchHelpLink}>Annuaire Entreprises</CmsButton>
                  et coller son SIREN dans le champ de recherche
                </WarningBubble>
              )}
            </Grid>
          </Grid>
          {!!data && !!siret && siret.length === 14 && data.siret !== siret && !data.multipleResults && (
            <UI.WarningBubble style={{ marginTop: '1em' }}>
              Attention ! Le numéro SIRET trouvé ne correspond pas à celui actuel <br />
              (SIRET Actuel : {siret})<br />
              (SIRET Trouvé : {data?.siret})<br />
              Cela peut être dû à un changement de siège de la part de l'entreprise ou à une erreur de saisie. <br />
              Veuillez vérifier les données ici <SiretLinkIcon siret={data?.siret} /> avant d'importer.
            </UI.WarningBubble>
          )}
          <UI.Divider />
          <div className="flex-h end">
            <Buttons.Cancel onClick={() => setOpen(false)}>Annuler</Buttons.Cancel>
            <Buttons.Valid
              style={{ marginLeft: '0.5rem' }}
              disabled={searching || !data || !!data?.multipleResults}
              onClick={handleImport}
            >
              Importer
            </Buttons.Valid>
          </div>
        </CmsPaper>
      </UI.Dialog>
    </>
  );
};

const importDataFields: any[] = [
  { label: 'Raison sociale', value: (data: any) => data.label ?? data.name, siege: true },
  { label: 'Nom', value: (data: any) => data.label ?? data.name, siege: false },
  { label: 'Nom commercial', value: (data: any) => data.commercialName, siege: true },
  { label: 'Adresse', value: (data: any) => data.address },
  { label: 'Code Postal', value: (data: any) => data.postcode },
  { label: 'Ville', value: (data: any) => data.city },
  { label: 'TVA', value: (data: any) => data.tva, isSupplier: false },
  { label: 'APE', value: (data: any) => data.ape, isSupplier: false },
  {
    label: 'Titre APE',
    value: (data: any) => codesNaf.find((x) => x.id === data.ape)?.label ?? 'Non trouvé',
    isSupplier: false,
  },
  {
    label: 'Date création entreprise',
    value: (data: any) => Utils.displayDate(data.companyCreationDate),
    isSupplier: false,
  },
  { label: 'Latitude', value: (data: any) => data.latitude },
  { label: 'Longitude', value: (data: any) => data.longitude },
  { label: 'En activité', value: (data: any) => (data.active ? 'Oui' : 'Non'), isSupplier: false },
  { label: 'SIRET', value: (data: any) => [data.siret, <SiretLinkIcon siret={data.siret} />] },
];

export function getSiretData(siret: string, getSiege = true): Promise<any> {
  return fetch(searchUrl(siret))
    .then(checkStatus)
    .then(handleJsonResponse)
    .then((x) => parseInseeResult(x, getSiege))
    .catch(handleCrudErrorNotif);
}

export function getCompanyLeaderData(companyName: string, city: string, siret: string): Promise<any> {
  return fetch(searchUrl(companyName))
    .then(checkStatus)
    .then(handleJsonResponse)
    .then((x) => parseInseeLeaderResult(x, siret))
    .catch(handleCrudErrorNotif);
}

function parseInseeLeaderResult(inseeResult: any, siret: string): any[] | undefined {
  const company = inseeResult?.results?.find((x: any) => x.siege?.siret === siret);
  if (!!company) return company.dirigeants?.filter((x: any) => x.type_dirigeant === 'personne physique');
  notificationService.error('Aucun résultat trouvé');
}

export function getCompanySupplierData(companyName: string, siret: string): Promise<any> {
  return fetch(searchUrl(companyName) + '&limite_matching_etablissements=100')
    .then(checkStatus)
    .then(handleJsonResponse)
    .then((x) => parseInseeSupplierResult(x, siret))
    .catch(handleCrudErrorNotif);
}

function parseInseeSupplierResult(inseeResult: any, siret: string): any[] | undefined {
  const company = inseeResult?.results?.find((x: any) => x.siege?.siret === siret);
  if (!!company) return (company.matching_etablissements ?? []).filter((x: any) => !x.date_fermeture);
  notificationService.error('Aucun résultat trouvé');
}

function extractTextInParentheses(input?: string) {
  if (!input) return null;
  const match = RegExp(/\(([^)]+)\)/).exec(input);
  return match ? match[1] : null;
}

function parseInseeResult(inseeResult: any, getSiege = true) {
  const resultInseeList = inseeResult?.results;
  if (!Array.isArray(resultInseeList) || resultInseeList.length === 0) return undefined;
  const firstResult = resultInseeList[0];
  let etablissement;
  if (!firstResult) return undefined;
  if (getSiege) etablissement = firstResult.siege;
  else etablissement = firstResult.matching_etablissements[0];
  if (!etablissement) return undefined;

  const result: any = {
    ape: etablissement.activite_principale,
    label: firstResult.nom_raison_sociale ?? firstResult.nom_complet,
    commercialName: etablissement.nom_commercial
      ? etablissement.nom_commercial
      : etablissement.liste_enseignes && etablissement.liste_enseignes.length > 0
        ? etablissement.liste_enseignes[0] + ' ' + etablissement.libelle_commune
        : firstResult.sigle
          ? firstResult.sigle
          : extractTextInParentheses(firstResult.nom_complet),
    companyCreationDate: firstResult.date_creation,
    etablissementCreationDate: etablissement.date_creation,
    city: etablissement.libelle_cedex ?? etablissement.libelle_commune ?? etablissement.libelle_commune_etranger,
    country: etablissement.libelle_pays_etranger,
    postcode: etablissement.cedex ?? etablissement.code_postal,
    active: etablissement.etat_administratif === 'A',
    professional: true,
    siret: etablissement.siret,
    lastApiSync: new Date(),
    multipleResults: resultInseeList.length > 1,
    ...handleLambertCoorDinate(etablissement.latitude, etablissement.longitude),
  };
  //nom du magasin ou à défaut nom de l'entreprise et ville de l'établissement
  if (!getSiege)
    result.label = etablissement.nom_commercial
      ? etablissement.nom_commercial
      : etablissement.liste_enseignes && etablissement.liste_enseignes.length > 0
        ? etablissement.liste_enseignes[0] + ' ' + etablissement.libelle_commune
        : firstResult.sigle
          ? firstResult.sigle + ' ' + etablissement.libelle_commune
          : extractTextInParentheses(firstResult.nom_complet)
            ? extractTextInParentheses(firstResult.nom_complet) + ' ' + etablissement.libelle_commune
            : firstResult.nom_raison_sociale + ' ' + etablissement.libelle_commune;
  result.name = result.label; // pour les fournisseurs

  // construction de l'adresse
  const addressParts = [
    etablissement.complement_adresse,
    [etablissement.numero_voie, etablissement.type_voie, etablissement.libelle_voie].filter(Boolean).join(' '),
    etablissement.distribution_speciale,
  ].filter((part) => part && part.trim().length > 0);
  result.address = addressParts.join('\n');
  if (result.address === '') result.address = etablissement.adresse?.split(result.postcode)[0]?.trim();
  if (!result.address) result.address = etablissement.adresse;
  result.contacts = firstResult.dirigeants.filter((dirigeant: any) => dirigeant.type_dirigeant === 'personne physique');

  // calcul du numéro de TVA pour les entreprises françaises
  if (result.country == null) {
    const siren = firstResult.siren;
    const tvaKey = ((12 + 3 * (siren % 97)) % 97).toString().padStart(2, '0');
    result.tva = `FR${tvaKey}${siren}`;
  }
  return result;
}

proj4.defs(
  'EPSG:2154',
  '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
);

export const handleLambertCoorDinate = (latitude: any, longitude: any) => {
  const lat = parseFloat(latitude);
  const lng = parseFloat(longitude);
  if ((lat <= 180 && lat >= -180 && lng <= 180 && lng >= -180) || isNaN(lat) || isNaN(lng))
    return { latitude, longitude };
  try {
    const [transformedX, transformedY] = proj4('EPSG:2154', 'EPSG:4326', [lat, lng]);
    return { latitude: transformedY, longitude: transformedX };
  } catch (e) {
    notificationService.error('Erreur de conversion des coordonnées');
    console.log('erreur converstion: ', e);
    return {};
  }
};
