import React, { FC } from 'react';
import APIRoute from '../../constant/API.constant';
import AccessFilter from '../../helper/AccessFilter';
import { AddNavigationButton } from '../../component/shared/Buttons';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import { SfTeam } from '../../interface/SfType';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../component/table/helper/CmsTableCell';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../constant/role.constant';

const sfTeamConfigList: CmsColumnDef<SfTeam>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Service', id: 'service', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool, defaultFilterValue: true },
];

/**
 * Liste des équipes
 */
export const SfTeamList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_STAFF_TEAM_CREATE])
    ? [<AddNavigationButton title="Ajouter une équipe" to="/castres/staff/team/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des équipes"
      route={APIRoute.SfTeam}
      columns={sfTeamConfigList}
      navigateTo={(id: number) => `/castres/staff/team/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Formulaire d'édition/création d'une équipe
 * @param id de l'équipe
 */
export const SfTeamCreateEdit: FC<any> = ({ id }) => {
  const props = { currentUrl: '/castres/staff/team/', route: APIRoute.SfTeam, thin: true };
  return (
    <CmsForm id={id} title="une équipe" {...props}>
      <CmsFormInput.Text id="label" label="Nom" required />
      <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} required />
      <CmsFormInput.Color id="color" label="Couleur" required withoutSharp />
      <CmsFormInput.Switch id="active" label="Actif" />
    </CmsForm>
  );
};
