import React from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Stats: NavProps = {
  name: 'Statistiques',
  icon: <BarChartIcon />,
  subList: [
    {
      name: 'Interventions',
      linkV1: 'stat/stat/dashboard',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Analyse des pannes (intervention)',
      linkV1: 'stat/stat/analyseIntervention',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Analyse des pannes',
      linkV1: 'stat/stat/analyse',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Campagnes',
      linkV1: 'stat/stat/campaign',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Stations',
      linkV1: 'stat/stat/station',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Heures de travail',
      linkV1: 'stat/stat/workhour',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Retro cession',
      linkV1: 'stat/stat/retrocession',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Résultat chantier',
      linkV1: 'stat/stat/intervention',
      feature: ROLE.SUPER_ADMIN,
    },
    {
      name: 'Facturation',
      linkV1: 'stat/stat/billing',
      feature: ROLE.SUPER_ADMIN,
    },
  ],
};
