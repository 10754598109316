import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

/**
 * Liste des marques d'outils
 */
export const SkToolMarkList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMARK_CREATE])
    ? [<AddNavigationButton title="Ajouter une marque d'outil" to="/castres/stock/toolmark/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des marques d'outils"
      route={APIRoute.SkToolMark}
      columns={[{ header: 'Nom', id: 'name', Filter: CmsTableFilter.Text }]}
      navigateTo={(id: number) => `/castres/stock/toolmark/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Composant de création et d'édition d'une marque d'outil
 * @param id Id de la marque d'outil
 */
export const SkToolMarkCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/stock/toolmark/', thin: true };
  return (
    <CmsForm id={id} route={APIRoute.SkToolMark} title="une marque d'outil" {...props}>
      <CmsFormInput.Text id="name" label="Nom" required />
    </CmsForm>
  );
};
