import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { SfHabilitationType } from '../../../interface/SfType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableFilterCalculator from '../../../component/table/helper/CmsTableFilterCalculator';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const typeConfig: CmsColumnDef<SfHabilitationType>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Temps de validité (en mois)', id: 'validity', Filter: CmsTableFilter.Number },
  { header: 'Liste des cellules associées', id: 'cellLabelList', size: 400 },
  {
    header: 'Liste des cellules associées',
    id: 'cellList',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true, optionList: APIRoute.SfHabilitationCellPosition },
    filterFn: CmsTableFilterCalculator.containsSome,
  },
  { header: 'Famille électricité', id: 'electricity', Filter: CmsTableFilter.Bool, size: 30 },
  { header: 'Famille véhicule', id: 'vehicle', Filter: CmsTableFilter.Bool, size: 30 },
  { header: 'Autres formations', id: 'isOtherFormation', Filter: CmsTableFilter.Bool, size: 30 },
  { header: 'Certificat obligatoire', id: 'requiredCertificate', Filter: CmsTableFilter.Bool, size: 30 },
];

/**
 * Page de gestion des types d'habilitations
 */
export const SfHabilitationTypeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_TRAININGTYPE_CREATE])
    ? [<AddNavigationButton title="Ajouter un type d'habilitation" to="/castres/staff/habilitationtype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des types d'habilitations"
      route={APIRoute.SfHabilitationType}
      columns={typeConfig}
      actions={actions}
      navigateTo={(id: number) => `/castres/staff/habilitationtype/${id}/edit`}
      setFiltersInUrl
    />
  );
};

/**
 * Page de création ou d'édition d'un type d'habilitation
 * @param id de l'habilitation
 */
export const SfHabilitationTypeCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/staff/habilitationtype/', route: APIRoute.SfHabilitationType };
  const label = !!id ? 'Code (non éditable)' : 'Code';
  return (
    <CmsForm id={id} {...props} title="Types d'habilitation" thin>
      <CmsFormInput.Text id="label" label="Nom" required min={2} max={255} />
      <CmsFormInput.Text id="code" label={label} required min={2} max={10} readOnly={!!id} />
      <CmsFormInput.Number id="validity" label="Temps de validité (en mois)" />
      <CmsFormInput.Switch id="electricity" label="Famille électricité" />
      <CmsFormInput.Switch id="vehicle" label="Famille véhicule" />
      <CmsFormInput.Switch id="isOtherFormation" label="Autres formations" />
      <CmsFormInput.Switch id="requiredCertificate" label="Certificat obligatoire" />
      <CmsFormInput.Select
        id="cellList"
        label="Cellules associées"
        options={APIRoute.SfHabilitationCellPosition}
        multiple
      />
    </CmsForm>
  );
};
