import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WkGreenfluxStation } from '../../interface/WkType';
import LoadingScreen from '../../component/LoadingScreen';
import { handleErrorPage } from 'helper/handle-response';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import StationGFDetailUi from './StationGFDetailUI';

/**
 * Permet de faire la requête au backend pour récupérer les données d'une
 * station en vue de les afficher ultérieurement avec StationGFDetailUI.tsx
 * @param id de la station sélectionnée
 * @param setHttpCodePage fonction de mise à jour du code HTTP de la page
 */
export const StationGfDetail: React.FC = ({ id, setHttpCodePage }: any) => {
  const [station, setStation] = useState<WkGreenfluxStation | undefined>();
  const navigate = useNavigate();

  const returnToList = useCallback(() => {
    navigate('/castres/greenflux/cdr/list');
  }, [navigate]);

  useEffect(() => {
    if (id && !station) {
      setStation({ isLoading: true });
      CRUD.getById<WkGreenfluxStation>(APIRoute.WkGreenfluxStations, id)
        .then((res: WkGreenfluxStation | null) => (res ? setStation(res) : returnToList()))
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else if (!id) returnToList();
    else if (id && station?.id && id !== station.id.toString()) setStation(undefined);
  }, [id, station, navigate, returnToList, setHttpCodePage]);

  if (!station || station.isLoading) return <LoadingScreen />;
  return <StationGFDetailUi station={station} />;
};
