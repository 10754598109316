import React, { FC } from 'react';
import { CmsBackendTable, CmsColumnDef } from '../../../component/table/CmsTable';
import { Link } from '@mui/material';
import AccessFilter from '../../../helper/AccessFilter';
import { WkIntervention } from 'interface/WkType';
import APIRoute from 'constant/API.constant';
import CmsTableFilter from 'component/table/helper/CmsTableFilter';
import { BASE_URL } from 'constant/API_URL';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import CmsIcon from 'component/shared/CmsIcon';
import ROLE from '../../../constant/role.constant';
import { CurrentUser } from '../../../interface/User';
import Utils from '../../../helper/Utils';
import { ColoredFromIndexCell } from 'component/shared/Ui';

const columns: CmsColumnDef<WkIntervention>[] = [
  {
    header: 'Référence',
    id: 'ref',
    cell: (info) => (
      <CmsTableCell.Ref
        cell={info}
        style={{ textAlign: 'center' }}
        url={`${BASE_URL}castres/work/intervention/${info.row.original.id}/show`}
      >
        <div>
          <small>{info.row.original.totalIncidentCode}</small>
        </div>
        <div>{info.row.original.source}</div>
        <div>{info.row.original.agreement}</div>
      </CmsTableCell.Ref>
    ),
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Description',
    id: 'description',
    cell: (info) => (
      <div className="styled-scroll" style={{ maxHeight: '6rem', overflow: 'auto' }}>
        <Link
          key={info.row.original.id}
          style={{ color: 'inherit' }}
          href={`${BASE_URL}castres/work/intervention/${info.row.original.id}/show`}
          target="_blank"
        >
          <div style={{ fontWeight: 'bold' }}>{info.row.original.name}</div>
        </Link>
        <div style={{ whiteSpace: 'pre-wrap' }}>{info.row.original.description}</div>
      </div>
    ),
    size: 320,
  },
  {
    header: 'Domaine / Nature',
    id: 'domainNature',
    cell: (info) => (
      <div style={{ lineHeight: '1rem' }}>
        <div style={{ fontWeight: 'bold' }}>{info.row.original.domain}</div>
        <div>{info.row.original.nature}</div>
      </div>
    ),
    noSorting: true,
  },
  {
    header: 'Site',
    id: 'site',
    noClick: true,
    cell: (info) => (
      <div>
        <CmsTableCell.Ref
          cell={info}
          url={`${BASE_URL}castres/client/station/${info.row.original.siteId}/show`}
          value={info.row.original.siteName}
        />

        <div>{info.row.original.siteRef}</div>
        <div>{info.row.original.company}</div>
        <div>{info.row.original.network}</div>
        <div>{info.row.original.slaLevel}</div>
      </div>
    ),
    size: 250,
  },
  {
    header: 'Ville',
    id: 'city',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Text,
    noSorting: true,
  },
  {
    header: 'Code postal',
    id: 'postcode',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Département',
    id: 'department',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.LoDepartment, optionLabel: 'label', multiple: true },
  },
  {
    header: 'Service',
    id: 'service',
    cell: (info) => (
      <>
        <div style={{ fontWeight: 'bold' }}>{info.row.original.service}</div>
        <div>{info.row.original.agency}</div>
      </>
    ),
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.SfService,
      optionLabel: 'label',
      optionFrom: 'WkIntervention',
      multiple: true,
    },
    defaultFilterValue: (user: CurrentUser) => {
      if (user.serviceCode === 'GBLC' || AccessFilter([ROLE.ADMIN_STOCK_CREATE])) return null;
      return [user.serviceId];
    },
  },
  {
    header: 'Création CMS',
    id: 'createdAt',
    cell: CmsTableCell.Datetime,
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Date de demande',
    id: 'creationDate',
    cell: CmsTableCell.DateBigTime,
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Date mise à jour',
    id: 'updatedAt',
    cell: CmsTableCell.Datetime,
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Date,
    defaultFilterValue: { value: Utils.Date.addDays(new Date(), -366), type: 9 },
  },
  {
    header: 'Délai GTI',
    id: 'gtiDeadline',
    cell: CmsTableCell.DateBigTime,
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Date butoir / GTR',
    id: 'deadline',
    cell: CmsTableCell.DateBigTime,
    Filter: CmsTableFilter.Date,
  },
  {
    header: "Commentaire et suivi d'intervention",
    id: 'chargeableComment',
    cell: (info) => (
      <div style={{ display: 'flex' }}>
        {info.row.original.waitingForParts && (
          <CmsIcon
            icon="shoppingCart"
            tooltip="Pièce en commande"
            style={{ width: '15px', marginRight: '5px' }}
            textPos="right"
          />
        )}
        <div style={{ whiteSpace: 'pre-wrap', maxHeight: '6rem', overflow: 'auto' }}>
          {info.row.original.chargeableComment}
        </div>
      </div>
    ),
    size: 350,
  },
  {
    header: 'Statut',
    id: 'statut',
    cell: (info) => (
      <div style={{ textAlign: 'center' }}>
        <CmsTableCell.Colored color={info.row.original.statusColor} content={info.row.original.statusName} />
        {info.row.original.tasksCount} tâche{info.row.original.tasksCount > 1 ? 's' : ''}
      </div>
    ),
    noSorting: true,
  },

  {
    header: 'N° WOT (TotalEnergies)',
    id: 'totalWorkOrderTask',
    hide: true,
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'N° INC / PLM (TotalEnergies)',
    id: 'totalIncidentCode',
    hide: true,
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Titre',
    id: 'name',
    hide: true,
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Code chantier',
    id: 'workCode',
    hide: true,
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Source',
    id: 'source',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkSource, optionLabel: 'label', multiple: true },
  },
  {
    header: 'Enseigne',
    id: 'company',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClCompany, optionLabel: 'label', multiple: true },
  },
  {
    header: 'Code implant site',
    id: 'siteRef',
    hide: true,
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Nom du site',
    id: 'siteLabel',
    hide: true,
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Manager du site',
    id: 'siteManager',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Text,
  },
  {
    header: 'Domaine',
    id: 'domain',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.WkDomain + '/Simplified?includeInactive=true',
      rawEndpoint: true,
      optionLabel: 'label',
      multiple: true,
    },
    hide: true,
  },
  {
    header: 'Nature',
    id: 'nature',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.WkNature + '/Simplified?includeInactive=true',
      rawEndpoint: true,
      optionLabel: 'label',
      multiple: true,
    },
    hide: true,
  },
  {
    header: 'Agence',
    hide: true,
    id: 'agency',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.ClAgency,
      optionLabel: 'label',
      optionFrom: 'WkIntervention',
      multiple: true,
    },
    defaultFilterValue: (user: CurrentUser) => {
      if (user.serviceCode === 'GBLC' || AccessFilter([ROLE.ADMIN_STOCK_CREATE])) return null;
      return [user.agencyId];
    },
  },
  {
    header: 'Entité juridique',
    hide: true,
    id: 'legalEntity',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: APIRoute.ClLegalEntity,
      optionLabel: 'label',
      optionFrom: 'WkIntervention',
      multiple: true,
    },
  },
  {
    header: 'Type de contrat',
    id: 'agreement',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkAgreement, optionLabel: 'label', multiple: true },
    hide: true,
  },
  {
    header: 'Campagne',
    id: 'campaign',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkCampaignType, optionLabel: 'label', multiple: true },
    hide: 'hiddenByDefault',
    noSorting: true,
  },

  {
    header: "Niveau d'urgence",
    id: 'emergencyLevelIndex',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkEmergencyLevel, rawEndpoint: true, optionLabel: 'name', multiple: true },
    cell: (info) => (
      <ColoredFromIndexCell
        item={info.row.original.emergencyLevel}
        index={info.row.original.emergencyLevelIndex}
        totalItems={info.row.original.emergencyLevelMaxIndex}
      />
    ),
  },
  {
    header: 'Nombre pistolets VP',
    id: 'pistolVP',
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'En attente de pièce',
    id: 'waitingForParts',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Planifiée',
    id: 'plannedDate',
    cell: CmsTableCell.DateTimeOrBoolean,
  },
  {
    header: 'Planifiée',
    id: 'planned',
    Filter: CmsTableFilter.Bool,
    hide: true,
  },
  {
    header: 'Premier passage',
    id: 'startDate',
    cell: CmsTableCell.DateTimeOrBoolean,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Résolue',
    id: 'resolvedDate',
    cell: CmsTableCell.DateTimeOrBoolean,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Résolue par',
    id: 'resolvedByName',
    hide: 'hiddenByDefault',
  },
  {
    header: 'Résolue',
    id: 'resolved',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: true,
  },
  {
    header: 'Clôturée',
    id: 'closedDate',
    cell: CmsTableCell.DateTimeOrBoolean,
    hide: 'hiddenByDefault',
  },
  {
    header: 'Clôturée',
    id: 'closed',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: true,
  },
  {
    header: 'Annulée',
    id: 'canceled',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Refus de résolution',
    id: 'rejected',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Visitée',
    id: 'visited',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'CRI envoyé',
    id: 'criSent',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Rapport envoyé',
    id: 'reportSent',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Rapport sécurité envoyé',
    id: 'reportSecuSent',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Terminée',
    id: 'completed',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Diagnostic correct',
    id: 'diagnosticMatch',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: true,
  },
  {
    header: 'Hors délai',
    id: 'outOfTime',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: true,
  },
  {
    header: 'Avec devis',
    id: 'haveQuotation',
    Filter: CmsTableFilter.Bool,
    cell: CmsTableCell.Bool,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Créé par',
    id: 'createdBy',
    cell: (info) => <>{info.row.original.createdByName}</>,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.WkIntervention}/Creators`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Conducteur de travaux',
    id: 'supervisedBy',
    cell: (info) => <>{info.row.original.supervisedByName}</>,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.WkIntervention}/Supervisors`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Dispatcheur',
    id: 'dispatcher',
    cell: (info) => <>{info.row.original.dispatcherName}</>,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: `${APIRoute.ClStation}/Dispatcher`,
      optionLabel: 'nameReversed',
      rawEndpoint: true,
    },
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Groupe (Travaux)',
    id: 'group',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkInterventionGroup, optionLabel: 'label' },
    hide: true,
  },
  {
    header: 'Tâches',
    id: 'tasksCount',
    cell: (info) => {
      return info.row.original.tasksCount > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}>
          {info.row.original.tasksCount}
        </div>
      ) : (
        <></>
      );
    },
    size: 10,
    hide: 'hiddenByDefault',
  },
];

const columnsToRemoveOnResultList = [
  'siteManager',
  'waitingForParts',
  'chargeableComment',
  'planned',
  'plannedDate',
  'rejected',
  'rejectedDate',
  'visited',
  'criSent',
  'criSentDate',
  'reportSent',
  'reportSentDate',
  'reportSecuSent',
  'completed',
  'notCompleted',
  'pistolVP',
];

const columnsToHideByDefaultOnResultList = ['domainNature', 'createdAt', 'deadline'];

const columnsResult: CmsColumnDef<WkIntervention>[] = [
  ...columns.map((column) => {
    if (columnsToRemoveOnResultList.includes(column.id)) {
      return { ...column, hide: true as 'hiddenByDefault' | true };
    }
    if (columnsToHideByDefaultOnResultList.includes(column.id) && column.hide !== true) {
      return { ...column, hide: 'hiddenByDefault' as 'hiddenByDefault' | true };
    }
    return column;
  }),
  {
    header: 'Achat commandé',
    id: 'orderPlaced',
    cell: CmsTableCell.Price,
    noSorting: true,
  },
  {
    header: 'Achat livré',
    id: 'orderDelivered',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Achat facturé',
    id: 'orderInvoiced',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Entrée Stock',
    id: 'stockIn',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Sortie Stock',
    id: 'stockOut',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Mouvement Stock',
    id: 'stockTotal',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Tâches planifiées',
    id: 'scheduleWorkTime',
    cell: CmsTableCell.DurationHours,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Coût tâches planifiées',
    id: 'scheduleWorkTimeCost',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Temps travail',
    id: 'workTime',
    cell: CmsTableCell.DurationHours,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Coût temps travail',
    id: 'workTimeCost',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Temps route',
    id: 'roadTime',
    cell: CmsTableCell.DurationHours,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Coût temps route',
    id: 'roadTimeCost',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Distance (km)',
    id: 'kilometers',
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Déplacement',
    id: 'travelCost',
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Total MO prévisionnel',
    id: 'totalForecastManpower',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Total MO réel',
    id: 'totalActualManpower',
    cell: CmsTableCell.Price,
    noSorting: true,
  },
  {
    header: 'Nb devis acceptés',
    id: 'acceptedQuotationsCount',
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Nb devis en facturation',
    id: 'invoicedQuotationsCount',
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Nb factures',
    id: 'invoicesCount',
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Montant devis acceptés',
    id: 'acceptedQuotationAmount',
    cell: CmsTableCell.Price,
    noSorting: true,
  },
  {
    header: 'Montant devis en facturation',
    id: 'invoicedQuotationAmount',
    cell: CmsTableCell.Price,
    hide: 'hiddenByDefault',
    noSorting: true,
  },
  {
    header: 'Montant facturé',
    id: 'invoicedAmount',
    cell: CmsTableCell.Price,
    noSorting: true,
  },
  {
    header: 'Bilan prévisionnel',
    id: 'forecastBalance',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Bilan en cours',
    id: 'ongoingBalance',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Bilan réel',
    id: 'actualBalance',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
  },
];

export const InterventionList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des interventions"
      columns={columns}
      route={APIRoute.WkIntervention}
      // navigateTo={(id) => `/castres/work/intervention/${id}/show`}
      invertClick
      rowStyle={(row) => (row.original.statusCode !== 'Canceled' ? undefined : { opacity: 0.4 })}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip={
            <div>
              <h3 style={{ textAlign: 'center' }}>Attention </h3>
              Ce filtre ne s'applique que sur les champs Référence, Titre/Description, Site et Commentaire et suivi
              d'intervention
            </div>
          }
        />
      }
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_WORK_INTERVENTION_EXPORT])}
    />
  );
};

export const InterventionResultList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des résultats d'intervention"
      columns={columnsResult}
      route={APIRoute.WkIntervention + '/Result'}
      invertClick
      rowStyle={(row) => (row.original.statusCode !== 'Canceled' ? undefined : { opacity: 0.4 })}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip={
            <div>
              <h3 style={{ textAlign: 'center' }}>Attention </h3>
              Ce filtre ne s'applique que sur les champs Référence, Titre/Description, Site et Commentaire et suivi
              d'intervention
            </div>
          }
        />
      }
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_WORK_INTERVENTION_EXPORT])}
    />
  );
};
