import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import { ArrowBackNavigationButton } from '../../../component/shared/Buttons';
import { LoDepartment } from '../../../interface/LoType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { CmsPaper } from '../../../component/shared/Ui';

export const LoDepartmentListConfig: CmsColumnDef<LoDepartment>[] = [
  { header: 'Région', id: 'regionName', Filter: CmsTableFilter.Select },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Dispatcher', id: 'dispatcherName', Filter: CmsTableFilter.Select },
];

/**
 * Liste des départements
 */
export const LoDepartmentList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste des départements"
      columns={LoDepartmentListConfig}
      route={APIRoute.LoDepartment}
      navigateTo={(id: number) => `/castres/location/department/${id}/edit`}
      setFiltersInUrl
    />
  );
};

/**
 * Édition d'un département
 * @param id id du département à éditer
 * @param setHttpCodePage fonction pour modifier le code http de la page
 */
export const LoDepartmentCreateEdit: FC = ({ id }: any) => {
  const props = { id, currentUrl: '/castres/location/department/', route: APIRoute.LoDepartment };
  const action = [<ArrowBackNavigationButton title="Retourner à la liste" to={`${props.currentUrl}list`} />];
  return (
    <CmsForm
      {...props}
      renderForm={(form: any) => {
        return (
          <div>
            <CmsPaper title="Département" actions={action}>
              <CmsFormInput.Text id="name" label="Nom" disabled />
              <CmsFormInput.Text id="code" label="Code" disabled />
              <CmsFormInput.Text id="regionName" label="Région" disabled />
              <CmsFormInput.Select
                id="dispatcherId"
                label="Dispatcher"
                options={APIRoute.UsUsers + '/Simplified?roleFilter=ClStation'}
              />
              <CmsFormSubmit type={'editForm'} />
            </CmsPaper>
          </div>
        );
      }}
    ></CmsForm>
  );
};
