import React, { FC, useState } from 'react';
import APIRoute from '../../constant/API.constant';
import { UI } from '../../component/shared';
import CRUD from '../../service/CRUD.service';
import '../user/user.scss';
import { DialogVanillaUI } from '../../component/shared/DialogUI';
import CmsIcon from '../../component/shared/CmsIcon';
import { CmsForm, CmsFormPaper } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import notificationService from '../../service/NotificationService';
import { useNavigate } from 'react-router-dom';

/**
 * Permet de faire l'édition de la station Greenflux pour pouvoir faire le lien
 * à une station du CMS
 * @param id de la station Greenflux sélectionnée
 */
export const WkGreenfluxStationEdit: FC = ({ id }: any) => {
  const [openSubordinatesModal, setOpenSubordinatesModal] = useState<boolean>(false);
  const [subordinates, setSubordinates] = useState<WkGfStationEdit[]>([]);
  const navigate = useNavigate();
  const baseUrl = '/castres/greenflux/stations/';

  const submit = (data: WkGfStationEdit) => {
    return CRUD.post<WkGfStationEdit>(`${APIRoute.WkGreenfluxStations}/${id}`, data).then((result) => {
      if (Array.isArray(result)) {
        setSubordinates(result);
        setOpenSubordinatesModal(true);
      } else {
        notificationService.success('La station a bien été modifiée');
        navigate(`${baseUrl}${id}/show`);
      }
    });
  };

  return (
    <CmsForm currentUrl={baseUrl} route="" onSubmit={submit} defaultValues={{ idGreenflux: id }}>
      <CmsFormPaper title="Édition du lien entre la station Greenflux et la station CMS" thin>
        <CmsFormInput.Select
          id="id"
          label="Référence à une station du CMS"
          options={APIRoute.ClStation + '/Simplified'}
          required
        />
      </CmsFormPaper>
      <DialogAlertSubordinates
        open={openSubordinatesModal}
        subordinates={subordinates}
        handleClose={() => setOpenSubordinatesModal(false)}
      />
    </CmsForm>
  );
};

/**
 * En cas d'erreur, permet d'afficher une pop-up
 * @param open détermine si la fenêtre doit être ouverte
 */
const DialogAlertSubordinates: FC<{
  open: boolean;
  subordinates: WkGfStationEdit[];
  handleClose: any;
}> = ({ open, subordinates, handleClose }) => {
  let key: string = subordinates[0] !== undefined ? subordinates[0].key : '';
  let value: string = subordinates[0] !== undefined ? subordinates[0].value : '';
  return (
    <DialogVanillaUI open={open} onClose={handleClose} maxWidth={'sm'}>
      <UI.Paper
        title="Une erreur est survenue"
        style={{ marginBottom: '0' }}
        actions={[<CmsIcon icon={'close'} onClick={handleClose} key={undefined} />]}
      >
        <h3>Un événement inattendu est survenu :</h3>
        <br />
        <h4>
          Type d'événement : <b style={{ color: 'red' }}>{key}</b>
        </h4>
        <h4 style={{ color: 'red' }}>{value}</h4>
        <br />
        <UI.Button onClick={handleClose} size={'medium'}>
          Confirmer
        </UI.Button>
      </UI.Paper>
    </DialogVanillaUI>
  );
};

export interface WkGfStationEdit {
  id: number; //cms
  idGreenflux: number;
  key: string;
  value: string;
}
