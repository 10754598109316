import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { WkUnit } from '../../../interface/WkType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const wkUnitConfigList: CmsColumnDef<WkUnit>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
];

/**
 * Page de liste des unités de travail
 */
export const WkUnitList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_WORK_UNIT_EDIT])
    ? [<AddNavigationButton title="Ajouter une unité de travail" to="/castres/work/unit/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Unités de travail"
      route={APIRoute.WkUnit}
      columns={wkUnitConfigList}
      navigateTo={(id: number) => `/castres/work/unit/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Page de création et d'édition des unités de travail
 * @param id Id de l'élément à éditer
 */
export const WkUnitCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/work/unit/', route: APIRoute.WkUnit, title: 'unité de travail' };
  return (
    <CmsForm id={id} {...props} deletable thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={10} />
    </CmsForm>
  );
};
