import React, { Fragment, FC, useEffect, useState } from 'react';

import { PageTitle, CmsPaper } from 'component/shared/Ui';
import { OpenInNewNavigationButton, ArrowBackNavigationButton } from 'component/shared/Buttons';
import { ImportFile, ImportStructAnalysis } from 'interface/ImportType';
import { getNewImportFiles, getRecentImportedFiles, getAllImportedFiles } from 'service/Import.service';
import { ImportListItem, analyseStructureChange } from '../ImportUtils';
import { handleErrorNotif } from 'helper/handle-response';

/**
 * Composant pour afficher la liste des fichiers d'import des équipements à effectuer
 */
export const ImportEquipementList: FC = () => {
  const [newImports, setNewImports] = useState<ImportFile[]>([]);
  const [oldImports, setOldImports] = useState<ImportFile[]>([]);
  const [structAnalysisAd, setStructAnalysisAd] = useState<ImportStructAnalysis>();
  const [structAnalysisCribe, setStructAnalysisCribe] = useState<ImportStructAnalysis>();

  useEffect(() => {
    getNewImportFiles('equipment')
      .then((result) => setNewImports(result))
      .catch(handleErrorNotif);
    getRecentImportedFiles('equipment')
      .then((result) => setOldImports(result))
      .catch(handleErrorNotif);
  }, []);

  useEffect(() => {
    // on calcule la différence de structure que s'il y a des import non effectué / nouveaux
    if (!structAnalysisAd && !structAnalysisCribe && newImports.length) {
      const allImports: ImportFile[] = [...newImports, ...oldImports];

      const allImportsAd: ImportFile[] = allImports.filter((el) => el.filename.endsWith('.EQU'));
      const analysisAd = analyseStructureChange(allImportsAd, newImports);
      if (analysisAd) setStructAnalysisAd(analysisAd);

      const allImportsCribe: ImportFile[] = allImports.filter((el) => el.filename.endsWith('.EQQ'));
      const analysisCribe = analyseStructureChange(allImportsCribe, newImports);
      if (analysisCribe) setStructAnalysisCribe(analysisCribe);
    }
  }, [newImports, oldImports, structAnalysisAd, structAnalysisCribe]);

  return (
    <Fragment>
      <PageTitle>Import des équipements</PageTitle>

      <CmsPaper title="Nouveaux fichiers d'import">
        {newImports.map((newImport) => (
          <ImportListItem
            key={newImport.id}
            category="equipment"
            {...newImport}
            linkBaseUrl="/castres/equipment/equipment/import"
            structAnalysisAd={structAnalysisAd}
            structAnalysisCribe={structAnalysisCribe}
          />
        ))}
      </CmsPaper>

      <CmsPaper
        title="Fichiers récemments importés"
        actions={[
          <OpenInNewNavigationButton
            title="Voir tous les fichiers importés"
            to="/castres/equipment/equipment/import/all-imported"
          />,
        ]}
      >
        {oldImports.map((oldImport) => (
          <ImportListItem
            key={oldImport.id}
            category="equipment"
            {...oldImport}
            linkBaseUrl="/castres/equipment/equipment/import"
          />
        ))}
      </CmsPaper>
    </Fragment>
  );
};

/**
 * Composant pour afficher la liste des fichiers d'import des équipements déjà effectués
 */
export const ImportEquipementImportedList: FC = () => {
  const [oldImports, setOldImports] = useState<ImportFile[]>([]);

  useEffect(() => {
    getAllImportedFiles('equipment').then((result) => setOldImports(result));
  }, []);

  return (
    <Fragment>
      <PageTitle>Import des équipements</PageTitle>

      <CmsPaper
        title="Tous les fichiers importés"
        actions={[
          <ArrowBackNavigationButton
            title="Retourner sur les nouveaux imports"
            to="/castres/equipment/equipment/import"
          />,
        ]}
      >
        {oldImports.map((oldImport) => (
          <ImportListItem
            key={oldImport.id}
            category="equipment"
            {...oldImport}
            linkBaseUrl="/castres/equipment/equipment/import"
          />
        ))}
      </CmsPaper>
    </Fragment>
  );
};
