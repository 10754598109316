import { Table as TansTable } from '@tanstack/table-core/build/lib/types';
import React, { FC, useEffect, useState } from 'react';
import '../table.style.scss';
import CmsIcon from '../../shared/CmsIcon';
import { CmsMenuButton } from '../../shared/Menu';
import { Box, Grid, Slider, Tooltip } from '@mui/material';
import MuiInput from '@mui/material/Input';

interface PaginationProps {
  displayPagination?: boolean;
  table: TansTable<any>;
  count?: number;
}

/**
 * Composant de pagination du tableau
 * @param table table ReactTableV8
 * @param displayPagination défini si on doit afficher la pagination
 * @param count nombre de ligne total du tableau (pour gestion de la pagination)
 */
export const CmsPagination: FC<PaginationProps> = ({ table, displayPagination = true, count }) => {
  if (!displayPagination) return <></>;
  const pagination = table.getState().pagination ?? {};
  const totalLine = count ?? table.getFilteredRowModel().rows.length;
  const countPage = (totalLine - (totalLine % pagination.pageSize)) / pagination.pageSize;
  const disableBack = pagination.pageIndex === 0;
  const disableNext = pagination.pageIndex === countPage;
  const handleFastBackward = () => table.setPageIndex(pagination.pageIndex < 5 ? 0 : pagination.pageIndex - 5);
  const handleFastForward = () =>
    table.setPageIndex(pagination.pageIndex + 5 < countPage ? pagination.pageIndex + 5 : countPage);
  let stringCount = pagination.pageIndex * pagination.pageSize + 1 + '-';
  stringCount += pagination.pageIndex === countPage ? totalLine : (pagination.pageIndex + 1) * pagination.pageSize;
  return (
    <div className="small-pagination flex-h align-center item-center">
      <Tooltip placement="top" title="Sélection du nombre de résultat par page">
        <div>
          <CmsMenuButton title={`${stringCount} sur ${totalLine}`} variant="text" buttonStyle={{ fontSize: '.9rem' }}>
            {[10, 20, 50, 100].map((pageSize) => (
              <div key={pageSize} onClick={() => table.setPageSize(pageSize)}>
                {pageSize} résultats par page
              </div>
            ))}
          </CmsMenuButton>
        </div>
      </Tooltip>
      <CmsIcon icon="first" onClick={() => table.setPageIndex(0)} disabled={disableBack} />
      <CmsIcon icon="fastBackward" onClick={handleFastBackward} disabled={disableBack} />
      <CmsIcon icon="prev" onClick={() => table.setPageIndex(pagination.pageIndex - 1)} disabled={disableBack} />
      <Tooltip placement="top" title="Sélection de la page que vous souhaitez">
        <div>
          <PaginationSlider page={pagination.pageIndex} totalPage={countPage} onChange={table.setPageIndex} />
        </div>
      </Tooltip>
      <CmsIcon icon="next" onClick={() => table.setPageIndex(pagination.pageIndex + 1)} disabled={disableNext} />
      <CmsIcon icon="fastForward" onClick={handleFastForward} disabled={disableNext} />
      <CmsIcon icon="last" onClick={() => table.setPageIndex(countPage)} disabled={disableNext} />
    </div>
  );
};

interface PaginationSliderProps {
  // Page actuelle
  page: number;
  // Nombre total de page
  totalPage: number;
  // Fonction à appeler lors du changement de page
  onChange: any;
}

/**
 * Composant de slider pour la pagination, permet de se déplacer rapidement dans les pages grâce à un slider et un input
 * @param page page actuelle
 * @param totalPage nombre total de page
 * @param onChange fonction à appeler lors du changement de page
 */
const PaginationSlider: FC<PaginationSliderProps> = ({ page, totalPage, onChange }) => {
  const [value, setValue] = useState(page ?? 0);
  const [inputValue, setInputValue] = useState<any>(page ?? 0);
  useEffect(() => setValue(page), [page]);
  useEffect(() => setInputValue(page + 1), [page]);

  useEffect(() => {
    const debounced = setTimeout(() => {
      onChange(value < 0 ? 0 : value);
    }, 500);
    return () => clearTimeout(debounced);
  }, [onChange, value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value === '' ? null : parseInt(event.target.value);
    setInputValue(val);
    if (!!val && val > 0 && val <= totalPage + 1) setValue(val - 1);
  };

  const title = `${page + 1} / ${totalPage + 1}`;
  const inputProps = { step: 10, min: 1, max: totalPage + 1, type: 'number', 'aria-labelledby': 'input-slider' };
  return (
    <CmsMenuButton title={title} variant="text" buttonStyle={{ fontSize: '.9rem' }}>
      <Box sx={{ width: 400 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MuiInput
              {...{ inputProps }}
              value={inputValue}
              style={{ width: '4rem' }}
              size="small"
              onChange={handleInputChange}
            />
            /{totalPage + 1}
          </Grid>
          <Grid item xs>
            <Slider
              value={typeof value === 'number' ? value + 1 : 1}
              min={1}
              max={totalPage + 1}
              onChange={(_, v) => {
                setValue((v as number) - 1);
                setInputValue(v as number);
              }}
              aria-labelledby="input-slider"
            />
          </Grid>
        </Grid>
      </Box>
    </CmsMenuButton>
  );
};
