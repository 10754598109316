import React from 'react';
import PaymentIcon from '@mui/icons-material/Payment';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Quotation: NavProps = {
  name: 'Devis',
  icon: <PaymentIcon />,
  subList: [
    {
      name: 'Clients',
      link: '/castres/client/client/list',
      linkV1: 'castres/client/client/list',
      feature: ROLE.ADMIN_CLIENT_LIST,
    },
    {
      name: 'Contacts',
      link: '/castres/client/contact/list',
      linkV1: 'castres/client/contact/list',
      feature: ROLE.ADMIN_CONTACT_LIST,
    },
    {
      name: 'Demande de devis',
      link: '/castres/billing/quotationrequest/list',
      linkV1: 'castres/billing/quotationrequest/list',
      feature: ROLE.ADMIN_QUOTATION_REQUEST_LIST,
    },
    {
      name: 'Devis',
      link: '/castres/billing/quotation/list',
      feature: ROLE.ADMIN_QUOTATION_LIST,
    },
    {
      name: 'Commandes',
      linkV1: 'castres/billing/quotationorder/list',
      feature: ROLE.ADMIN_QUOTATION_ORDER_LIST,
    },
    {
      name: 'Contrats',
      linkV1: 'castres/billing/contract/list',
      feature: ROLE.ADMIN_CONTRACT_LIST,
    },
  ],
};
