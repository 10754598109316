import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlPriceScheduleType } from '../../../interface/BlType';
import ROLE from '../../../constant/role.constant';

const wkUnitConfigList: CmsColumnDef<BlPriceScheduleType>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
];

export const BlPriceScheduleTypeList: FC = () => {
  return (
    <CmsFrontendTable
      title="Type de ligne de bordereau"
      route={APIRoute.BlPriceScheduleType}
      columns={wkUnitConfigList}
      navigateTo={(id: number) => `/castres/billing/pricescheduletype/${id}/edit`}
      setFiltersInUrl
      actions={[
        <AddNavigationButton
          title="Ajouter d'un type de ligne de bordereau"
          to="/castres/billing/pricescheduletype/create"
          access={[ROLE.ADMIN_CONFIGURATION_BILLING_PRICESCHEDULETYPE_CREATE]}
        />,
      ]}
    />
  );
};

export const BlPriceScheduleTypeCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlPriceScheduleType, title: 'type de ligne de bordereau' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/pricescheduletype/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
    </CmsForm>
  );
};
