import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlContractFrequencyInvoice } from '../../../interface/BlType';
import ROLE from '../../../constant/role.constant';

const wkUnitConfigList: CmsColumnDef<BlContractFrequencyInvoice>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Facture par an', id: 'invoicesPerYear', Filter: CmsTableFilter.Number },
];

export const BlContractFrequencyInvoiceList: FC = () => {
  return (
    <CmsFrontendTable
      title="Fréquence de facturation"
      route={APIRoute.BlContractFrequencyInvoice}
      columns={wkUnitConfigList}
      navigateTo={(id: number) => `/castres/billing/contractfrequencyinvoice/${id}/edit`}
      setFiltersInUrl
      actions={[
        <AddNavigationButton
          title="Ajouter une fréquence de facturation"
          to="/castres/billing/contractfrequencyinvoice/create"
          access={[ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_CREATE]}
        />,
      ]}
    />
  );
};

export const BlContractFrequencyInvoiceCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlContractFrequencyInvoice, title: 'fréquence de facturation' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/contractfrequencyinvoice/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
      <CmsFormInput.Number id="invoicesPerYear" label="Facture par an" />
    </CmsForm>
  );
};
