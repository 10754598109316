import React, { FC, useState } from 'react';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { CmsForm, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlPriceScheduleGroup, BlReferenceStudyGroup } from '../../../interface/BlType';
import { CmsPaper } from '../../../component/shared/Ui';
import { Buttons } from '../../../component/shared';
import { FirstLevelProps, MultiLevelForm } from '../../../component/form/MultiLevelForm';
import CRUD from '../../../service/CRUD.service';
import notificationService from '../../../service/NotificationService';
import { MultiLevelAutoComplete, MultiLevelInputNumber } from '../../../component/form/MultiLevelInput';
import { Grid, Link } from '@mui/material';
import CmsFilterCalculator from '../../../component/table/helper/CmsTableFilterCalculator';

const BlReferenceStudyGroupListConfigList: CmsColumnDef<BlReferenceStudyGroup>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Unité', id: 'unitLabel', Filter: CmsTableFilter.Select },
  {
    header: "Ligne de référence d'étude",
    id: 'lineIdList',
    Filter: CmsTableFilter.Select,
    filterFn: CmsFilterCalculator.containsSomeId,
    hide: true,
  },
  {
    header: "Ligne de référence d'étude",
    id: 'blReferenceStudyGroupLine',
    noClick: true,
    cell: (info) => {
      if (!Array.isArray(info.getValue())) return <></>;
      return (
        <ul>
          {info.getValue().map((line: any) => (
            <li key={line.id}>
              <Link href={`/castres/billing/referencestudyline/${line.id}/edit`}>
                {line.quantity}x {line.line?.label}
              </Link>
            </li>
          ))}
        </ul>
      );
    },
  },
];

export const BlReferenceStudyGroupList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.BlReferenceStudyGroup}
      columns={BlReferenceStudyGroupListConfigList}
      title="Liste des groupes de référence d'études"
      navigateTo={(id: number) => `/castres/billing/referencestudygroup/${id}/edit`}
      actions={[
        <AddNavigationButton
          title="Ajouter un groupe de référence d'étude"
          to="/castres/billing/referencestudygroup/create"
          access={[ROLE.ADMIN_STUDY_REFERENCE_EDIT]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const BlReferenceStudyGroupCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/billing/referencestudygroup/', route: APIRoute.BlReferenceStudyGroup };
  const [formState, setFormState] = useState<any>({});

  const onSubmitAll = (data: any) => {
    const payload = { ...data, blReferenceStudyGroupLineFormList: formState.blReferenceStudyGroupLine };
    CRUD.put<BlPriceScheduleGroup>(APIRoute.BlReferenceStudyGroup, payload).then(() => {
      notificationService.success('Les modifications ont bien été enregistrées');
      window.location.reload();
    });
  };

  return (
    <>
      <CmsForm
        id={id}
        {...props}
        onBeforeSubmit={(data) => ({ ...data, blReferenceStudyGroupLine: formState.blReferenceStudyGroupLine })}
        renderForm={(form) => {
          return (
            <Grid container columnSpacing="1rem">
              <Grid item xs={12} lg={4}>
                <CmsPaper title="Groupe de référence d'étude">
                  <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
                  <CmsFormInput.Select id="unitId" label="Unité" options={APIRoute.WkUnit + '/Simplified'} />
                  <CmsFormSubmit type={!form.watch().id ? 'createForm' : 'editForm'} />
                </CmsPaper>
              </Grid>
              <Grid item xs={12} lg={8}>
                {!!id && (
                  <CmsPaper
                    title="Liste des lignes de référence d'études"
                    actions={[
                      <Buttons.Valid onClick={() => onSubmitAll(form.watch())}>
                        Sauvegarder toutes les modifications
                      </Buttons.Valid>,
                    ]}
                  >
                    <MultiLevelForm
                      id={id}
                      getRoute={APIRoute.BlReferenceStudyGroup}
                      config={config}
                      onChange={setFormState}
                    />
                  </CmsPaper>
                )}
              </Grid>
            </Grid>
          );
        }}
      ></CmsForm>
    </>
  );
};

const config: FirstLevelProps = {
  subListTitle: "Liste des lignes de référence d'études",
  attrToList: 'blReferenceStudyGroupLine',
  subItem: {
    subListTitle: "Liste des lignes de référence d'études",
    postRoute: APIRoute.BlReferenceStudyGroup + '/Line',
    deleteRoute: APIRoute.BlReferenceStudyGroup + '/Line',
    attrToList: 'blReferenceStudyGroupLine',
    parentIdName: 'groupId',
    columnList: [
      { id: 'quantity', label: 'Quantité', FormComponent: MultiLevelInputNumber, width: 100 },
      {
        id: 'lineId',
        label: "Ligne de référence d'étude",
        FormComponent: MultiLevelAutoComplete,
        optionRoute: APIRoute.BlReferenceStudyLine + '/Simplified',
      },
    ],
  },
};
