import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { SfHabilitationCellPosition } from '../../../interface/SfType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';

const PositionConfig: CmsColumnDef<SfHabilitationCellPosition>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Nom de la cellule', id: 'cellName', Filter: CmsTableFilter.Text },
  { header: 'Symbole lié à la cellule', id: 'symbol', Filter: CmsTableFilter.Text },
];

export const SfHabilitationCellPositionList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste cellules de la carte d'habilitation"
      route={APIRoute.SfHabilitationCellPosition}
      columns={PositionConfig}
      navigateTo={(id: number) => `/castres/staff/habilitationcellposition/${id}/edit`}
      setFiltersInUrl
    />
  );
};

export const SfHabilitationCellPositionCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/staff/habilitationcellposition/', route: APIRoute.SfHabilitationCellPosition };
  return (
    <CmsForm id={id} title="cellules de la carte d'habilitation" {...props} thin>
      <CmsFormInput.Text id="label" label="Nom" required min={2} />
      <CmsFormInput.Text id="cellName" label="Nom de la cellule" required min={2} max={20} />
      <CmsFormInput.Text id="symbol" label="Symbole lié à la cellule" />
    </CmsForm>
  );
};
