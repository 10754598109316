import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { SfLicenseType } from '../../../interface/SfType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const sfLicenseTypeConfigList: CmsColumnDef<SfLicenseType>[] = [
  { header: 'Id', id: 'id', Filter: CmsTableFilter.Text },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
];

/**
 * Page de gestion des types de permis
 */
export const SfLicenseTypeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_LICENSETYPE_MASTER])
    ? [<AddNavigationButton title="Ajouter un type de permis" to="/castres/staff/licensetype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Types de permis"
      route={APIRoute.SfLicenseType}
      columns={sfLicenseTypeConfigList}
      navigateTo={(id: number) => `/castres/staff/licensetype/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const SfLicenseTypeCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/staff/licensetype/', route: APIRoute.SfLicenseType, title: 'Types de permis' };
  return (
    <CmsForm id={id} {...props} thin>
      <CmsFormInput.Text id="label" label="Nom" required min={2} />
    </CmsForm>
  );
};
