import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { handleErrorPage } from 'helper/handle-response';
import { CmsButton, CmsPaper, ColorDateLimit, HeaderPanel } from 'component/shared/Ui';
import { ArrowBackNavigationButton, EditNavigationButton } from 'component/shared/Buttons';
import AccessFilter from 'helper/AccessFilter';
import { API_URL, BASE_URL } from 'constant/API_URL';
import SkeletonTable from 'component/table/SkeletonTable';
import { displayDate } from 'helper/Utils';
import Typography from '@mui/material/Typography';
import theme from 'style/Theme';
import { Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import NotificationService from '../../service/NotificationService';
import notificationService from '../../service/NotificationService';
import MuiLink from '@mui/material/Link';
import { uploadFile } from '../../helper/handle-request';
import { TabStyleDataView } from '../../component/shared/TabStyleDataView';
import { UI } from '../../component/shared';
import { LabelValue } from '../../interface/CommonType';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { SkTool, SkToolEvent } from '../../interface/SkType';
import { ToolEventListConfig } from './ToolEventList';
import { CmsFrontendTable } from '../../component/table/CmsTable';
import { CmsForm, CmsFormContext } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import './tool.scss';
import ROLE from '../../constant/role.constant';
import { filterOutCol } from '../../component/table/helper/CmsTableFilter';

export type SkStatus = 'DISPO' | 'ATTRIBUE' | 'EN CONTROLE REPARATION' | 'EN PANNE' | 'AU REBUT';

/**
 * Page de détail d'un outil, permets d'ajouter un évènement d'outil (disponible, en cours de réparation, au rebut, etc...)
 * @param id de l'outil
 * @param setHttpCodePage fonction pour changer le code http de la page
 */
const ToolShow: FC = ({ id, setHttpCodePage }: any) => {
  const [tool, setTool] = useState<SkTool>();
  const [loadedTool, setLoadedTool] = useState<boolean>(false);
  const [loadedEvents, setLoadedEvents] = useState<boolean>(false);
  const [events, setToolEvents] = useState<SkToolEvent[]>([]);
  const isJauge: boolean = tool?.toolType?.label === 'Jauge';

  useEffect(() => {
    if (!id) return;
    CRUD.getById<SkTool>(APIRoute.SkTool, id)
      .then((result) => {
        setTool(result);
        setLoadedTool(true);
        fetchEvents(result.id);
      })
      .catch((response) => handleErrorPage(response, setHttpCodePage));
  }, [id, setHttpCodePage]);

  const updateToolData = (id: number) => {
    if (!id) return;
    CRUD.getById<SkTool>(APIRoute.SkTool, id)
      .then((result) => {
        setTool(result);
        setLoadedTool(true);
      })
      .catch((response) => handleErrorPage(response, setHttpCodePage));
  };

  const fetchEvents = (id: number) => {
    if (!id) return;
    CRUD.getList<SkToolEvent>(APIRoute.SkToolEvent, `/?toolId=${id}`).then((result) => {
      setToolEvents(result);
      setLoadedEvents(true);
    });
  };

  // let toolEventListConfig = ToolEventListConfig.filter((item) => item.id !== 'toolModel');
  let conf = filterOutCol(ToolEventListConfig, 'toolModel', 'toolRef', 'toolSerial');
  if (!isJauge)
    conf = filterOutCol(conf, 'expandedUncertainty', 'sensibility', 'lineHeight', 'lineVolume', 'nominalVolume');
  return (
    <div>
      {loadedTool && (
        <Fragment>
          <HeaderPanel
            title="Outil"
            subtitle={`(${tool?.ref})`}
            actions={[
              <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/stock/tool/list" />,
              AccessFilter([ROLE.ADMIN_PARK_STATION_EDIT]) && (
                <EditNavigationButton title="Éditer l'outil" to={`/castres/stock/tool/${tool?.id}/edit`} />
              ),
            ]}
          />

          <Grid container spacing={2}>
            <Grid item sm={12} lg={isJauge ? 4 : 8}>
              <General tool={tool} />
            </Grid>
            {isJauge && (
              <Grid item sm={12} lg={4}>
                <JaugeInfos tool={tool} />
              </Grid>
            )}
            <Grid item sm={12} lg={4}>
              <Validity tool={tool} />
            </Grid>
          </Grid>

          {tool?.toolStatus === 'ATTRIBUE' && tool.user && <Affectation tool={tool} />}

          <FormStatus tool={tool} setTool={setTool} fetchEvent={fetchEvents} updateToolData={updateToolData} />

          {loadedEvents ? (
            <CmsFrontendTable
              title="Évènements"
              route="none"
              columns={conf}
              controlledState={{ state: events, setState: () => {} }}
              downloadable={
                AccessFilter([
                  ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EXPORT,
                  ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EXPORT,
                ]) && 'csv'
              }
            />
          ) : (
            <SkeletonTable columnNumber={6} />
          )}
        </Fragment>
      )}
    </div>
  );
};

interface InputToMapProps {
  id: number;
  // Libellé du bouton de changement de statut
  label: string;
  // Valeur du statut du bouton
  status: SkStatus;
  // Définie si le bouton est désactivé ou non
  selectable: boolean;
}

const inputsToMap: Array<any> = [
  { name: 'nominalVolume', label: 'Volume nominale', placeHolder: 'Litre' },
  { name: 'lineVolume', label: 'Volume au trait', placeHolder: 'cm³' },
  { name: 'lineHeight', label: 'Hauteur du trait', placeHolder: 'millimètre' },
  { name: 'sensibility', label: 'Sensibilité', placeHolder: 'cm³/mm' },
  { name: 'expandedUncertainty', label: 'Incertitude type élargie', placeHolder: '%' },
  { name: 'divider', label: '', placeHolder: '' },
  { name: 'height', label: 'Test hauteur', placeHolder: 'millimètre' },
  { name: 'certificatError', label: 'Test erreur certificat (en %)', placeHolder: '%' },
];

/**
 * Formulaire de changement de statut d'un outil
 * @param tool l'outil
 * @param setTool fonction pour mettre à jour l'outil
 * @param fetchEvent fonction pour mettre à jour la liste des évènements
 * @param updateToolData fonction pour mettre à jour les données de l'outil
 */
const FormStatus: FC<{ tool?: SkTool; setTool: any; fetchEvent: any; updateToolData: any }> = ({
  tool,
  setTool,
  fetchEvent,
  updateToolData,
}) => {
  const isJauge: boolean = tool?.toolType?.label === 'Jauge';
  if (!tool) return <></>;
  const defaultValues: any = { newToolStatusId: tool.toolStatusId, toolId: tool.id };

  const onSubmit = (data: any, form: any) => {
    if (isJauge && [1, 2].includes(data.newToolStatusId) && tool.toolStatusId === 3 && !CheckJaugeValues(data)) {
      NotificationService.error('Les valeurs de jauge ne semblent pas correctes');
      return Promise.resolve();
    }
    const file = data.certificate;
    delete data.certificate;
    return CRUD.post<SkToolEvent>(APIRoute.SkToolEvent, data).then((result) => {
      notificationService.success("L'évènement a bien été créé");
      updateToolData(tool.id);
      setTool({ ...tool, toolStatus: toolStatusList[data.newToolStatusId - 1] });
      form.reset({ toolId: tool.id, newToolStatusId: data.newToolStatusId });
      if (!file || !result?.id) return fetchEvent(tool.id);
      uploadFile({ url: `${API_URL}SkToolEvent/${result.id}/certificate`, file }).then(() => {
        NotificationService.success('Certificat enregistré avec succès');
        fetchEvent(tool.id);
      });
    });
  };

  return (
    <CmsForm currentUrl="none" route={APIRoute.SkToolEvent} defaultValues={defaultValues} onSubmit={onSubmit}>
      <StatusHandler currentStatus={tool.toolStatus} />
      <CmsFormInput.Conditional
        hideIf={(form: any) => !form.newToolStatusId || form.newToolStatusId === tool.toolStatusId}
      >
        <CmsPaper title="Informations de l'évènement">
          <div className="tool-form-event">
            <CmsFormInput.Date id="eventDate" label="Date de l'évènement" required />
            <CmsFormInput.Date
              id="validateStartDate"
              label="Date de début de validité"
              required={!tool.perishable}
              hideIf={(x) => [4, 5].includes(x.newToolStatusId) || tool.toolStatusId !== 3}
            />
            <CmsFormInput.File id="certificate" label="Certificat" pdfOnly hideIf={() => tool.toolStatusId !== 3} />
            <CmsFormInput.Text id="comment" label="Commentaire" multiline />
            <CmsFormInput.Suggest
              id="interventionId"
              label="Ref Intervention"
              route={APIRoute.WkIntervention}
              optionLabel="ref"
              hideIf={(x) => x.newToolStatusId !== 2}
            />
            <CmsFormInput.Select
              id="userId"
              label="Technicien"
              options={APIRoute.UsUsers + '/Simplified?roleFilter=SkTool'}
              hideIf={(x) => x.newToolStatusId !== 2}
              required
            />
            <div />
            <CmsFormInput.Conditional
              hideIf={(x: any) => !isJauge || [4, 5].includes(x.newToolStatusId) || tool.toolStatusId !== 3}
            >
              {inputsToMap.map((inputMap, key) => {
                if (inputMap.name === 'divider') return <UI.Divider key={key} style={{ gridArea: '3/1/3/6' }} />;
                return (
                  <CmsFormInput.Number
                    key={key}
                    id={inputMap.name}
                    label={inputMap.label}
                    placeholder={inputMap.placeHolder}
                    required
                  />
                );
              })}
            </CmsFormInput.Conditional>
          </div>
          <Divider />
          <SubmitButton />
        </CmsPaper>
      </CmsFormInput.Conditional>
    </CmsForm>
  );
};

const SubmitButton: FC = () => {
  const { handleSubmit, formState } = useFormContext();
  const { onSubmit } = useContext(CmsFormContext);
  return (
    <Button
      style={{ marginTop: '1em' }}
      color="primary"
      variant="outlined"
      onClick={handleSubmit((x) => onSubmit(x))}
      disabled={!formState.isValid}
    >
      Créer l'évènement
    </Button>
  );
};

interface StatusHandlerProps {
  currentStatus: SkStatus;
}

const StatusHandler: FC<StatusHandlerProps> = ({ currentStatus }) => {
  const { watch, setValue } = useFormContext();
  const status = watch('newToolStatusId');
  let isDispoSelectable = ['DISPO', 'ATTRIBUE', 'EN CONTROLE REPARATION'].includes(currentStatus);
  let isAttribueSelectable = ['ATTRIBUE', 'DISPO', 'EN CONTROLE REPARATION'].includes(currentStatus);
  let isControlSelectable = true;
  let isPanneSelectable = currentStatus !== 'AU REBUT';
  const isRebutSelectable = true;
  if (!AccessFilter([ROLE.ADMIN_STOCK_MASTER]) && currentStatus === 'AU REBUT')
    isDispoSelectable = isAttribueSelectable = isControlSelectable = isPanneSelectable = false;
  const buttonToMap: Array<InputToMapProps> = [
    { id: 1, label: 'Disponible', status: 'DISPO', selectable: isDispoSelectable },
    { id: 2, label: 'Attribué', status: 'ATTRIBUE', selectable: isAttribueSelectable },
    { id: 3, label: 'En contrôle ou réparation', status: 'EN CONTROLE REPARATION', selectable: isControlSelectable },
    { id: 4, label: 'Hors Service', status: 'EN PANNE', selectable: isPanneSelectable },
    { id: 5, label: 'Au rebut', status: 'AU REBUT', selectable: isRebutSelectable },
  ];
  return (
    <div style={{ display: 'flex', gap: '0.5em', justifyContent: 'center', marginTop: '1.5em', marginBottom: '2em' }}>
      {buttonToMap.map((b, key) => (
        <CmsButton
          key={key}
          disabled={!b.selectable}
          color={status === b.id ? 'primary' : 'inherit'}
          onClick={() => setValue('newToolStatusId', b.id)}
        >
          {b.label}
        </CmsButton>
      ))}
    </div>
  );
};

const toolStatusList = ['DISPO', 'ATTRIBUE', 'EN CONTROLE REPARATION', 'EN PANNE', 'AU REBUT'];

/**
 * Vérifie que les valeurs de la jauge sont correctes
 * @param t les valeurs de la jauge
 */
function CheckJaugeValues(t: SkToolEvent): boolean {
  const Vhight = +t.lineVolume + (+(t.height ?? 0) - +t.lineHeight) * +t.sensibility;
  const error = ((+t.nominalVolume * 1000 - Vhight) * 100) / Vhight;
  const valueToCheck = +(t.certificatError ?? 0) - error;
  return valueToCheck < 0.001 && valueToCheck > -0.001;
}

/**
 * Affiche les informations générales de l'outil
 * @param tool l'outil
 */
const General: FC<{ tool?: SkTool }> = ({ tool }) => {
  if (!tool) return <></>;
  const data: Array<LabelValue> = [
    { label: 'Ref', value: tool.ref },
    { label: 'Numéro de série', value: tool.serial },
    { label: 'Statut', value: tool.toolStatus },
    { label: "Type d'outil", value: tool.toolType?.label },
    { label: 'Marque', value: tool.toolMark?.label },
    { label: 'Modèle', value: tool.toolModel?.label },
    { label: 'Agence', value: tool.agency?.label },
    { label: 'Service', value: tool.service?.label },
    { label: 'Coût Journalier', value: tool?.dailyCost ? tool.dailyCost + ' €' : '' },
  ];
  return (
    <CmsPaper title="Général" style={{ display: 'flex', flexDirection: 'column' }} fluidHeight tied>
      <TabStyleDataView conf={data} />
    </CmsPaper>
  );
};

/**
 * Affiche les informations de la jauge de l'outil (s'il est du type jauge)
 * @param tool l'outil
 */
const JaugeInfos: FC<{ tool?: SkTool }> = ({ tool }) => {
  if (!tool) return <></>;
  const data: Array<LabelValue> = [
    { label: 'Volume nominal', value: `${tool.nominalVolume ?? '(Non-défini)'} L` },
    { label: 'Volume au trait', value: `${tool.lineVolume ?? '(Non-défini)'} cm³` },
    { label: 'Hauteur du trait', value: `${tool.lineHeight ?? '(Non-défini)'} mm` },
    { label: 'Sensibilité', value: `${tool.sensibility ?? '(Non-défini)'} cm³/mm` },
    { label: 'Incertitude type élargie', value: `${tool.expandedUncertainty ?? '(Non-défini)'} %` },
  ];
  return (
    <CmsPaper title="Informations de la Jauge" style={{ display: 'flex', flexDirection: 'column' }} fluidHeight tied>
      <TabStyleDataView conf={data} />
    </CmsPaper>
  );
};

/**
 * Affiche les informations de validité de l'outil
 * @param tool l'outil
 */
const Validity: FC<{ tool?: SkTool }> = ({ tool }) => {
  if (!tool) return <></>;
  const data: Array<LabelValue> = [
    { label: 'Date de première mise en service', value: displayDate(tool.commissioningDate) },
    { label: 'Date de début de validité', value: displayDate(tool.validateStartDate) },
    { label: 'Date de fin de validité', value: <ColorDateLimit date={displayDate(tool.validateEndDate)} /> },
    { label: 'Date de fin de garrantie', value: <ColorDateLimit date={displayDate(tool.warrantyEndDate)} /> },
    { label: 'Date de fin de vie', value: <ColorDateLimit date={displayDate(tool.endOfLifeDate)} /> },
  ];
  return (
    <CmsPaper title="Validité" style={{ display: 'flex', flexDirection: 'column' }} fluidHeight tied>
      <TabStyleDataView conf={data} />
    </CmsPaper>
  );
};

/**
 * Affiche les informations de l'intervention de l'outil et de l'utilisateur qui l'a en possession
 * @param tool l'outil
 */
const Affectation: FC<{ tool: SkTool }> = ({ tool }) => {
  let intervention = '';
  if (tool.intervention) {
    intervention = `${tool.intervention.ref} - ${tool.intervention.name} / ${tool.station?.ref} - ${tool.station?.name}`;
  }
  return (
    <CmsPaper
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.primary.main}`,
        paddingTop: '0.6em',
      }}
      tied
    >
      <Typography variant="h6">Affectation :</Typography>
      <Divider style={{ marginTop: '0.1em', marginBottom: '0.5em' }} />
      {tool?.intervention && (
        <UI.LabelValue label="Nom de l'intervention">
          <MuiLink
            href={`${BASE_URL}castres/work/intervention/${tool.intervention.id}/show`}
            target="_blank"
            style={{ verticalAlign: 'middle' }}
          >
            {intervention}
          </MuiLink>
        </UI.LabelValue>
      )}
      <UI.LabelValue label="Technicien">
        {tool?.user?.firstname} {tool?.user?.lastname}
      </UI.LabelValue>
    </CmsPaper>
  );
};

export default ToolShow;
