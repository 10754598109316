import React, { CSSProperties, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { authenticationService } from '../service/Authentication.service';
import Navigation from '../component/navigation/Navigation';
import { CmsButton, CmsContainer, WarningBubble } from './shared/Ui';
import Utils, { getUserSettings, updateUserSettings } from '../helper/Utils';
import { NotificationProvider } from '../context/Notification.context';
import NotificationComponent from '../page/notification/Notification';

/**
 * Composant permettant d'afficher le layout de l'application, c'est à dire la racine de l'application.
 * Ici on gère l'affichage du menu, des notifications et tout le reste de l'application est affiché dans le children
 * @param children Composant enfant contenant le routing avec toutes les pages de l'application
 */
const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(authenticationService.isLoggedIn);

  const getIsMenuLockedSettings = () => {
    const userSettings = getUserSettings();
    return userSettings?.isMenuLocked || false;
  };
  const [isMenuLocked, setMenuLocked] = useState(getIsMenuLockedSettings());
  const toggleMenu = () => {
    updateUserSettings('isMenuLocked', !isMenuLocked);
    setMenuLocked(!isMenuLocked);
  };

  useEffect(() => {
    const sub = authenticationService.loggedInSubject.subscribe(setLoggedIn);
    return () => sub.unsubscribe();
  }, []);

  if (!isLoggedIn) return <>{children}</>;

  let style: CSSProperties = { paddingLeft: isMenuLocked ? 320 : 60 };
  if (Utils.isMobileBrowser()) style = { paddingTop: 60 };

  return (
    <NotificationProvider>
      <div className="app-workspace" style={style}>
        <NotificationComponent />
        <Navigation menuSettings={{ isMenuLocked, toggleMenu }} />
        <CmsContainer>
          <ImpersonateWarning />
          {children}
        </CmsContainer>
      </div>
    </NotificationProvider>
  );
};

const ImpersonateWarning: FC = () => {
  const user = useMemo(() => authenticationService.getCurrentUser(), []);
  if (!user || !user.originalUserLabel) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }}>
      Vous êtes actuellement connecté en tant que {user.userLabel} à partir de {user.originalUserLabel}. Veuillez vous
      <CmsButton style={{ margin: '0 0.5rem' }} onClick={() => authenticationService.logout()}>
        déconnecter
      </CmsButton>
      pour arrêter cela.
    </WarningBubble>
  );
};

export default Layout;
