export const BulkEquUpdateFields = ['supplier'];

export const BulkStaUpdateFields = ['manager'];

export const ImportFieldLabels: { [key: string]: string } = {
  id: 'ID',
  station: 'Station',
  model: 'Modèle',
  label: 'Libellé',
  supplier: 'Fournisseur',
  parent: 'Parent',
  ref: 'Référence',
  serial: 'Numéro de série',
  type: 'Type',
  manufacturer: 'Constructeur',
  lastPreventiveVisit: 'Date dernière visite préventive',
  lastControlVisit: 'Date dernier contrôle réglementaire',
  commissioningDate: 'Date de mise en service',
  releaseDate: "Date de sortie d'usine",
  warrantyDate: 'Date de garantie',
  active: 'Active',
  lastCriDate: 'Last CRI Date',
  tel: 'Téléphone',
  fax: 'Fax',
  manager: 'Chef de secteur',
  network: 'Reseaux',
  department: 'Departement',
  company: 'Enseigne',
  contractCribe: 'Contract Cribe',
  contractAd: 'Contract AD',
  slaLevel: 'Niveau SLA',
  address: 'Adresse',
};

export const DateFields = [
  'lastPreventiveVisit',
  'lastControlVisit',
  'commissioningDate',
  'releaseDate',
  'warrantyDate',
  'lastCriDate',
];

export const BooleanFields = ['contractAd', 'contractCribe'];
