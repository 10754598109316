import React, { FC, useEffect } from 'react';
import APIRoute from '../../constant/API.constant';
import { Buttons, UI } from '../../component/shared';
import { CmsPaper } from '../../component/shared/Ui';
import CRUD from '../../service/CRUD.service';
import NotificationService from '../../service/NotificationService';
import '../user/user.scss';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import LoadingScreen from '../../component/LoadingScreen';
import { Settings } from 'http2';

/**
 * Permet d'afficher et d'interagir avec les données des paramètres
 * de Greenflux (token API et lien endpoint)
 */
export const WkGreenfluxSetting: FC = () => {
  const [defaultValue, setDefaultValue] = React.useState<Settings>();

  useEffect(() => {
    CRUD.getCustomObject<any>(APIRoute.Other + '/settings').then(setDefaultValue);
  }, []);

  const submit = (data: Settings) => {
    return CRUD.post<Settings>(APIRoute.Other + '/settings', data).then((result) => {
      if (Array.isArray(result)) NotificationService.error('Échec de mise à jour');
      else NotificationService.success('Paramètres mis à jour');
    });
  };

  if (!defaultValue) return <LoadingScreen />;
  return (
    <CmsForm currentUrl="" route={APIRoute.WkGreenflux + '/settings'} onSubmit={submit} defaultValues={defaultValue}>
      <CmsPaper title="Paramètres de Greenflux" className="account-form" style={{ maxWidth: '600px' }}>
        <CmsFormInput.Text id="greenfluxUrl" label="URL de l'API Greenflux" required max={250} />
        <CmsFormInput.Text
          id="greenfluxApiCdr"
          label="Token d'authentification de l'API Greenflux"
          required
          max={250}
        />
        <UI.Divider />
        <Buttons.Valid type="submit">Mettre à jour</Buttons.Valid>
      </CmsPaper>
    </CmsForm>
  );
};
