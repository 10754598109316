import React, { FC, useState } from 'react';
import { CmsButton, CmsDivider, CmsPaper } from '../../component/shared/Ui';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { useFormContext } from 'react-hook-form';
import FileService from '../../service/FileService';
import { ViewNavigationButton } from '../../component/shared/Buttons';
import NotificationService from '../../service/NotificationService';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';

interface TimeReportingReportProps {
  // Année de début
  yearStart?: number;
  // Semaine de début
  weekStart?: number;
  // nombre de semaines (4 ou 5)
  weekTotal?: number;
  // Liste des techniciens selectionnés
  timeReportingUserIdList?: Array<number>;
}

/**
 * Page de génération des rapports de feuille de temps par technicien, attention, à ne pas confondre
 * avec le rapport de feuille de temps par intervention
 */
export const TimeReportingReport: FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const currentYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = -8; i < 1; i++) yearOptions.push({ id: currentYear + i, label: '' + (currentYear + i) });
  const dataSumbit = (data: TimeReportingReportProps) => {
    if ((data.timeReportingUserIdList?.length ?? 0) < 1) {
      NotificationService.error('Vous devez sélectionner au moins un Technicien');
      return Promise.resolve();
    }
    const fileName = `rapport_heures-S${data.weekStart}-A${data.yearStart}.xlsm`;
    setLoading(true);
    return CRUD.getBlob(APIRoute.WkTimeReportingWeek + '/Report', data)
      .then((blob) => FileService.saveBlob(blob, fileName))
      .finally(() => setLoading(false));
  };
  const defaultValues = { weekTotal: 4, yearStart: currentYear, timeReportingUserIdList: [] };
  const actions = [<ViewNavigationButton title="Administration" to={`/castres/timereporting/administration`} />];
  return (
    <CmsForm currentUrl="" route="" onSubmit={dataSumbit} defaultValues={defaultValues} thin>
      <CmsPaper
        isLoading={isLoading}
        title="Génération de rapport mensuel"
        style={{ maxWidth: '600px' }}
        actions={actions}
      >
        <CmsFormInput.Select id="yearStart" label="Année" options={yearOptions} required />
        <CmsFormInput.Number id="weekStart" label="Semaine" required min={-1} max={53} />
        <CmsFormInput.Radio
          id="weekTotal"
          label="Nombre de semaines"
          options={[
            { id: 4, label: '4' },
            { id: 5, label: '5' },
          ]}
        />
        <CmsFormInput.Select
          id="legalEntityId"
          label="Entité Légale"
          options={APIRoute.ClLegalEntity + '/Simplified'}
        />
        <CmsFormInput.Select
          id="timeReportingUserIdList"
          label="Technicien"
          options={APIRoute.UsUsers + '/Simplified'}
          multiple
          required
        />
        <CmsDivider />
        <FormUpdateHandler />
      </CmsPaper>
    </CmsForm>
  );
};

const FormUpdateHandler: FC = () => {
  const [oldValue, setOldValue] = useState<any>();
  const { setValue, watch, formState } = useFormContext();
  if (oldValue !== watch('legalEntityId')) {
    setOldValue(watch('legalEntityId'));
    CRUD.getSimpleList(APIRoute.UsUsers, '/ByLegalEntity/' + watch('legalEntityId')).then((result) => {
      setValue('timeReportingUserIdList', [...result.map((user) => user.id)]);
    });
  }
  return (
    <CmsButton disabled={!formState.isValid} type="submit">
      Générer le rapport
    </CmsButton>
  );
};

export default TimeReportingReport;
