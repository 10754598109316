import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkDomain } from '../../../interface/WkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const WkDomainListConfig: CmsColumnDef<WkDomain>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Active', id: 'active', Filter: CmsTableFilter.Bool },
  { header: 'Groupe', id: 'groupName', Filter: CmsTableFilter.Text },
  { header: 'Type de rapport', id: 'reportTypeLabel', Filter: CmsTableFilter.Text },
];

/**
 * Liste des domaines
 */
export const WkDomainList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_DOMAIN_CREATE])
    ? [<AddNavigationButton title="Ajouter un domaine" to="/castres/work/domain/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des domaines"
      route={APIRoute.WkDomain}
      columns={WkDomainListConfig}
      navigateTo={(id: number) => `/castres/work/domain/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'un domaine
 * @param id Id du domaine à éditer
 * @param setHttpCodePage Fonction pour changer le code http de la page
 */
export const WkDomainCreateEdit: FC = ({ id }: any) => (
  <CmsForm id={id} currentUrl="/castres/work/domain/" route={APIRoute.WkDomain} title="un domaine" thin>
    <CmsFormInput.Text id="name" label="Nom" required max={50} />
    <CmsFormInput.Text id="code" label="Code" required max={50} />
    <CmsFormInput.Select id="groupId" label="Groupe de domaines" options={APIRoute.WkDomainGroup + '/Simplified'} />
    <CmsFormInput.Select id="reportTypeId" label="Type de rapport" options={APIRoute.WkReportType + '/Simplified'} />
    <CmsFormInput.Switch id="active" label="Actif" inline />
  </CmsForm>
);
