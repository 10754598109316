import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PaperLoaderScreen } from './Ui';

interface DialogUIProps {
  // Défini si le DialogUI est ouvert ou non
  open: boolean;
  // Fonction à appeler lors du clic sur le bouton de fermeture
  onClose: React.Dispatch<React.SetStateAction<any>>;
  // Fonction à appeler lors du clic sur le bouton de validation
  onValidation: React.Dispatch<React.SetStateAction<any>>;
  // Titre du DialogUI
  title: string;
  // Contenu du DialogUI, si children est présent, le body sera ignoré
  body?: any;
  // Contenu du DialogUI, si présent, le body sera ignoré
  children?: React.ReactNode;
  // Défini si le DialogUI est en chargement ou non
  isLoading?: boolean;
  // Classe à appliquer sur le DialogUI
  className?: string;
}

/**
 * Composant Modal formatter pour une réponse de type oui/non
 * @param open Défini si le DialogUI est ouvert ou non
 * @param onClose Fonction à appeler lors du clic sur le bouton de fermeture
 * @param onValidation Fonction à appeler lors du clic sur le bouton de validation
 * @param title Titre du DialogUI
 * @param body Contenu du DialogUI, si children est présent, le body sera ignoré
 * @param children Contenu du DialogUI, si présent, le body sera ignoré
 * @param className Classe à appliquer sur le DialogUI
 * @param isLoading Défini si le DialogUI est en chargement ou non
 */
export const DialogUI: FC<DialogUIProps> = ({
  open,
  onClose,
  onValidation,
  title,
  body,
  children,
  className,
  isLoading = false,
}) => {
  return (
    <Dialog
      className={className}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children ? children : body}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Abandonner
        </Button>
        <Button onClick={onValidation} variant="outlined" color="primary" autoFocus>
          Confirmer
        </Button>
      </DialogActions>
      {isLoading && <PaperLoaderScreen />}
    </Dialog>
  );
};

interface PaperDialogUIProps {
  // Défini si le DialogVanillaUI est ouvert ou non
  open: boolean;
  // Fonction à appeler lors du clic sur le bouton de fermeture
  onClose: React.Dispatch<React.SetStateAction<any>>;
  // Taille horizontal maximale du DialogVanillaUI
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  // Contenu du DialogVanillaUI
  children: React.ReactNode;
}

/**
 * Composant Modal non formatter, il à pour but d'abstraire le composant Dialog de Material UI
 * @param open Défini si le DialogVanillaUI est ouvert ou non
 * @param onClose Fonction à appeler lors du clic sur le bouton de fermeture
 * @param maxWidth Taille horizontal maximale du DialogVanillaUI
 * @param children Contenu du DialogVanillaUI
 */
export const DialogVanillaUI: FC<PaperDialogUIProps> = ({ open, onClose, maxWidth = 'md', children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {children}
    </Dialog>
  );
};
