import React, { CSSProperties, FC } from 'react';
import { authenticationService } from '../service/Authentication.service';

const componentStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '90vh',
};
/**
 * NOTE: Page par défault de l'application, elle est prévue pour de futur développemnt telle une page publics ou autre.
 */
export const HomePage: FC = () => {
  if (!authenticationService.isLoggedIn) authenticationService.logout();

  return (
    <div style={componentStyle}>
      <h1 style={{ color: 'white' }}>Bienvenue sur le CMS V2</h1>
    </div>
  );
};
