import React from 'react';
import { SfAbsence, SfAssignment, SfHabilitation } from '../../interface/SfType';
import { SchedulerData } from './Scheduler';
import { BASE_URL } from '../../constant/API_URL';

// Le fichier schedulerFormatter à pour but de centraliser les "fomatter" de donnée du scheduler
// cela permets d'éviter leurs duplications et leur éparpillement à travers le code

/**
 * Formatte les données d'absence pour le scheduler
 * @param absenceList Liste des absences
 * @returns Les données formatées pour le scheduler
 */
function formatAbsence(absenceList: SfAbsence[]): SchedulerData {
  if (!absenceList || absenceList.length < 1) return { groups: [], items: [] };
  const groups = [{ id: 0, title: 'Absences', type: 'absence' }];
  const items = [];
  for (let absence of absenceList) {
    items.push({
      id: absence.id,
      group: 0,
      color: '#5a0fc6',
      title: 'Absence',
      start_time: !!absence.startDate && new Date(absence.startDate),
      end_time: !!absence.endDate && new Date(absence.endDate),
      value: absence,
      type: 'absence',
    });
  }
  return { groups, items };
}

/**
 * Formatte les données d'habilitation pour le scheduler.
 * Attention, il y a besoin de SfHabilitation.SfFormation.SfFormationEvent
 * @param rawData Liste des habilitations
 * @returns Les données formatées pour le scheduler
 */
function formatHabilitation(rawData: SfHabilitation[]): SchedulerData {
  if (!rawData) return { groups: [], items: [] };
  const groups = [];
  const items = [];
  for (let habilitation of rawData) {
    groups.push({
      id: 'hab' + habilitation.typeId,
      title: habilitation.type?.label,
      type: 'habilitation',
      habilitationId: habilitation.id,
    });
    for (let formation of habilitation?.sfFormation ?? []) {
      for (let event of formation?.sfFormationEvent ?? []) {
        items.push({
          id: event.id,
          group: 'hab' + habilitation.typeId,
          color: '#b39231',
          title: 'Formation',
          start_time: new Date(event?.start ?? ''),
          end_time: new Date(event?.end ?? ''),
          type: 'habilitation',
          value: event,
        });
      }
    }
  }
  return { groups, items };
}

/**
 * Formatte les données de tâche pour le scheduler
 * @param rawData Liste des tâches
 * @returns Les données formatées pour le scheduler
 */
function formatTask(rawData: any[]): SchedulerData {
  if (!rawData) return { groups: [], items: [] };
  const groups = [];
  const items = [];
  for (let group of rawData) {
    const link = <a href={`${BASE_URL}castres/work/intervention/${group.id}/show`}>{group.label}</a>;
    groups.push({ id: group.id, title: link, type: 'task' });
    for (let task of group.value) {
      items.push({
        id: task.id,
        group: task.interventionId,
        color: '#0b92c3',
        title: 'Station: ' + task.stationLabel,
        start_time: new Date(task.startDate),
        end_time: new Date(task.endDate),
        type: 'task',
        value: task,
      });
    }
  }
  return { groups, items };
}

/**
 * Formatte les données d'affectation pour le scheduler.
 * Attention, il y besoin de SfAssignment.SfTeam
 * @param rawData Liste des affectations
 * @returns Les données formatées pour le scheduler
 */
function formatAssignment(rawData: SfAssignment[]): SchedulerData {
  if (!rawData) return { groups: [], items: [] };
  const groups: any[] = [];
  const items = [];
  for (let assignment of rawData) {
    if (!groups.find((x) => x.id === assignment.teamId))
      groups.push({ id: assignment.teamId, title: 'Équipe: ' + assignment.team?.label, type: 'assignment' });
    items.push({
      id: assignment.id,
      group: assignment.teamId,
      color: '#eb7b1e',
      title: assignment.team?.label,
      start_time: new Date(assignment.startDate as any),
      end_time: new Date(assignment.endDate as any),
      type: 'assignment',
      value: assignment,
    });
  }
  return { groups, items };
}

// Export des fonctions pour le scheduler
export const schedulerFormatter = {
  formatAbsence,
  formatHabilitation,
  formatTask,
  formatAssignment,
};
