import React, { CSSProperties, FC, useContext, useState } from 'react';
import { InputUI } from '../shared';
import { MultiLevelContext } from './MultiLevelForm';
import APIRoute from '../../constant/API.constant';
import CmsIcon from '../shared/CmsIcon';

/**
 * Props des composants MultiLevelInput
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param optionRoute Route pour récupérer les options (select) de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 */
export interface MultiLevelInputProps {
  value: any;
  row: any;
  setValue?: (value: any) => void;
  optionRoute?: string | APIRoute;
  inputOption?: any;
  defaultValue?: any;
}

/**
 * Composant de base (Type texte) pour les inputs du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 */
export const MultiLevelInput: FC<MultiLevelInputProps> = ({ value, setValue, inputOption }) => {
  return <InputUI.DebouncedInput value={value} valueCanUpdate onChange={setValue} {...inputOption} />;
};

export const MultiLevelInputNumber: FC<MultiLevelInputProps> = ({ value, setValue, inputOption }) => {
  return <InputUI.DebouncedInput value={value} valueCanUpdate onChange={setValue} {...inputOption} type="number" />;
};

export const MultiLevelReadOnly: FC<MultiLevelInputProps> = ({ value }) => {
  return <>{value}</>;
};

interface MultiLevelVanillaProps {
  children: any;
  className?: string;
  style?: CSSProperties;
  cellStyle?: CSSProperties;
}

export const MultiLevelVanilla: FC<MultiLevelVanillaProps> = ({ children, className, style, cellStyle }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <div className={(isFocused ? 'focused ' : '') + 'multi-lvl-vanilla ' + className} style={cellStyle}>
      <CmsIcon className="multi-line-icon" icon="resize" onClick={() => setIsFocused(!isFocused)} />
      <div style={style}>{children}</div>
    </div>
  );
};

/**
 * Composant pour les inputs de type select du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param optionRoute Route pour récupérer les options (select) de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 */
export const MultiLevelAutoComplete: FC<MultiLevelInputProps> = ({ value, setValue, optionRoute, inputOption }) => {
  const { optionList } = useContext(MultiLevelContext);
  if ((!optionRoute || !optionList[optionRoute]) && !inputOption?.options) return <div>Aucune options</div>;
  return (
    <InputUI.AutoCompletor
      value={value}
      onChange={setValue}
      options={optionList[optionRoute ?? 'notfound']}
      {...inputOption}
    />
  );
};

/**
 * Composant pour les inputs de type boolean du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 */
export const MultiLevelBoolean: FC<MultiLevelInputProps> = ({ value, setValue }) => {
  return <InputUI.CmsSwitch value={value} onChange={setValue as any} />;
};

export const MultiLevelDate: FC<MultiLevelInputProps> = ({ value, setValue, inputOption }) => {
  return <InputUI.DatePickerVanilla value={value} onChange={setValue} {...inputOption} />;
};

export const MultiLevelSelect: FC<MultiLevelInputProps> = ({ value, setValue, optionRoute, inputOption }) => {
  const { optionList } = useContext(MultiLevelContext);
  // if (!optionList[optionRoute]) return <div>Aucune options</div>;
  if ((!optionRoute || !optionList[optionRoute]) && !inputOption?.options) return <div>Aucune options</div>;
  return (
    <InputUI.SimpleSelect
      className="simple-select"
      value={value}
      onChange={setValue}
      options={optionList[optionRoute ?? 'notFound']}
      {...inputOption}
    />
  );
};
