import React, { FC, useState, Fragment, ReactNode } from 'react';
import Button from '@mui/material/Button';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { NoContentMessage, CmsDialog, SectionTitle } from 'component/shared/Ui';
import { ImpEquAddition, ValidatedImportItem, ImpEquStationProcessedAddition } from 'interface/ImportType';
import { ImportValidatedAccordion, EquipementPerStation, ImportItem, ValidationButton } from '../ImportUtils';
import { confirmAddition, confirmMatch, getEqPossibleMatchs } from 'service/Import.service';
import { handleErrorNotif } from 'helper/handle-response';
import { displayDate } from 'helper/Utils';

interface ImportAdditionsProps {
  // Id de l'import
  idImport: string;
  // Stations à ajouter
  additions: ImpEquStationProcessedAddition[] | undefined;
  // Stations validées
  validatedAdditions: ValidatedImportItem;
  // Fonction pour changer les stations validées
  setValidatedAdditions: any;
}

/**
 * Composant pour afficher les stations à ajouter
 * @param idImport id de l'import
 * @param additions stations à ajouter
 * @param validatedAdditions stations validées
 * @param setValidatedAdditions fonction pour changer les stations validées
 */
const ImportAdditions: FC<ImportAdditionsProps> = ({
  idImport,
  additions,
  validatedAdditions,
  setValidatedAdditions,
}) => (
  <Fragment>
    {additions && additions.length ? (
      <Fragment>
        {!!Object.keys(validatedAdditions).length && (
          <ImportValidatedAccordion validated={Object.keys(validatedAdditions).length}>
            <EquipementPerStation
              idImport={idImport}
              stations={additions}
              validatedElements={validatedAdditions}
              setValidatedElements={setValidatedAdditions}
              EquipementComponent={ImportData}
              validated={true}
            />
          </ImportValidatedAccordion>
        )}

        <EquipementPerStation
          idImport={idImport}
          stations={additions}
          validatedElements={validatedAdditions}
          setValidatedElements={setValidatedAdditions}
          EquipementComponent={ImportData}
        />
      </Fragment>
    ) : (
      <NoContentMessage>Aucun ajout disponible</NoContentMessage>
    )}
  </Fragment>
);

/**
 * Composant pour afficher les données d'un équipement à ajouter
 * @param idImport id de l'import
 * @param equipement données de l'équipement
 * @param validatedElements données validées
 * @param setValidatedElements fonction pour changer les données validées
 * @param validated si les données sont validées
 */
const ImportData: FC<{
  idImport: string;
  equipement: ImpEquAddition;
  validatedElements: ValidatedImportItem;
  setValidatedElements: any;
  validated?: boolean;
}> = ({ idImport, equipement, validatedElements, setValidatedElements, validated = false }) => {
  let accepted = false,
    refused = false,
    merged = false;
  if (validated) {
    accepted = validatedElements[equipement.ref] === 'accepted';
    refused = validatedElements[equipement.ref] === 'refused';
    merged = validatedElements[equipement.ref] === 'merged';
  }

  const accept = (ref: string) => {
    if (!validated) {
      confirmAddition('equipment', idImport, [ref])
        .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'accepted' })))
        .catch(handleErrorNotif);
    }
  };
  const refuse = (ref: string) => {
    if (!validated) setValidatedElements((current: any) => ({ ...current, [ref]: 'refused' }));
  };
  const merge = (id: number, ref: string) => {
    if (!validated) {
      confirmMatch('equipment', idImport, id, ref)
        .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'merged' }), id))
        .catch(handleErrorNotif);
    }
  };

  const { ref, label, type, commissioningDate, numeroDePiste, parent } = equipement;
  const labelValues = {
    Type: type.label,
    'Mis en service le': displayDate(commissioningDate),
    'Numeros de pistes': numeroDePiste && numeroDePiste.length ? numeroDePiste.join(', ') : null,
    Parent: parent && parent.ref ? parent.ref : null,
  };
  if (parent && parent.ref) labelValues['Parent'] = parent.ref;

  let actions: ReactNode[] = [];

  if (!validated && !!equipement.deactivatedId) {
    actions = [
      <div className="flex-h">
        <Button variant="contained" onClick={() => refuse(equipement.ref)} style={{ marginRight: '1em' }}>
          Refusé
        </Button>
        <Button variant="contained" onClick={() => merge(equipement.deactivatedId ?? 0, equipement.ref)}>
          Matcher et réactiver
        </Button>
      </div>,
    ];
  } else if (!validated && equipement.possibleMatches > 0) {
    actions = [<MergeDialog equipement={equipement} accept={accept} refuse={refuse} merge={merge} />];
  } else if (validated && merged) {
    actions = [
      <Button variant="contained" startIcon={<CompareArrowsIcon />}>
        Fusionné
      </Button>,
    ];
  }
  let finalLabel = `${ref} - ${label}`;
  if (!validated && !!equipement.deactivatedId) finalLabel = '( Désactivé ! ) ' + finalLabel;
  return (
    <ImportItem
      label={finalLabel}
      labelValues={labelValues}
      actions={actions}
      accepted={accepted}
      refused={refused}
      accept={() => accept(ref)}
      refuse={() => refuse(ref)}
    />
  );
};

/**
 * Composant pour afficher la boite de dialogue pour fusionner un équipement
 * @param equipement données de l'équipement
 * @param accept fonction pour accepter l'équipement
 * @param refuse fonction pour refuser l'équipement
 * @param merge fonction pour fusionner l'équipement
 */
const MergeDialog: FC<{ equipement: ImpEquAddition; accept: any; refuse: any; merge: any }> = ({
  equipement,
  accept,
  refuse,
  merge,
}) => {
  const [open, setOpen] = useState(false);
  const [isMergeDiscarded, setMergeDiscarded] = useState(false);
  const [possibleMatches, setPossibleMatches] = useState<ImpEquAddition[]>([]);

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => {
    getEqPossibleMatchs(equipement.station.id, equipement.type.code)
      .then((result) => setPossibleMatches(result))
      .catch(handleErrorNotif);
    setOpen(true);
  };

  const acceptAddition = () => {
    handleClose();
    accept(equipement.ref);
  };
  const refuseAddition = () => {
    handleClose();
    refuse(equipement.ref);
  };
  const mergeItem = (id: number) => {
    handleClose();
    merge(id, equipement.ref);
  };

  const { ref, label, type, serial, commissioningDate } = equipement;
  const labelValues = {
    Type: type.label,
    Serial: serial,
    'Mis en service le': displayDate(commissioningDate),
  };
  return (
    <Fragment>
      <Button variant="outlined" startIcon={<CompareArrowsIcon />} onClick={handleClickOpen}>
        {equipement.possibleMatches} correspondances possibles
      </Button>
      <CmsDialog open={open} onClose={handleClose} withBackground fullWidth>
        <SectionTitle
          title="Équipement à importer"
          actions={[
            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <ImportItem label={`${ref} - ${label}`} labelValues={labelValues} noActions />
        {!isMergeDiscarded && (
          <Fragment>
            <SectionTitle title="Possibles correspondances" />
            {possibleMatches.map((matchEquipement, index) => {
              const actions = [];
              if (matchEquipement.id) {
                actions.push(
                  <Button
                    variant="outlined"
                    startIcon={<CompareArrowsIcon />}
                    onClick={() => mergeItem(matchEquipement.id as number)} // matchEquipement.id is checked tho, TODO: avoid casting and implement a better check
                  >
                    Faire correspondre
                  </Button>,
                );
              }
              const matchEquipementLabelValues = {
                Type: matchEquipement.type.label,
                Serial: matchEquipement.serial,
                'Mis en service le': displayDate(matchEquipement.commissioningDate),
              };
              return (
                <ImportItem
                  key={`${index}-${matchEquipement.ref}`}
                  label={`${matchEquipement.ref} - ${matchEquipement.label}`}
                  labelValues={matchEquipementLabelValues}
                  actions={actions}
                />
              );
            })}
          </Fragment>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', margin: '1.5em 0 1em 0' }}>
          {!isMergeDiscarded ? (
            <Button onClick={() => setMergeDiscarded(true)} variant="outlined">
              Aucune correspondance trouvée
            </Button>
          ) : (
            <Fragment>
              <ValidationButton type="accept" onClick={acceptAddition} label="Accepter l'ajout" />
              <ValidationButton
                type="refuse"
                onClick={refuseAddition}
                label="Refuser l'ajout"
                style={{ marginLeft: '0.5em' }}
              />
            </Fragment>
          )}
        </div>
      </CmsDialog>
    </Fragment>
  );
};

export default ImportAdditions;
