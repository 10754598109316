import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlAccount } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const BlAccountConfigList: CmsColumnDef<BlAccount>[] = [
  { header: 'Numéro de compte', id: 'number', Filter: CmsTableFilter.Search },
  { header: 'Service', id: 'serviceLabel', Filter: CmsTableFilter.Select },
  { header: "Type d'intervention", id: 'agreementLabel', Filter: CmsTableFilter.Select },
];

/**
 * Liste des comptes imputations de vente
 */
export const BlAccountList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_ACCOUNT_LIST])
    ? [<AddNavigationButton title="Ajouter un compte" to="/castres/billing/account/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des comptes d'imputation de ventes"
      route={APIRoute.BlAccount}
      columns={BlAccountConfigList}
      navigateTo={(id: number) => `/castres/billing/account/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const BlAccountCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/billing/account/', route: APIRoute.BlAccount };
  return (
    <CmsForm id={id} title="Compte imputation de vente" {...props} thin>
      <CmsFormInput.Text id="number" label="Numéro de compte" required max={8} />
      <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} required />
      <CmsFormInput.Select
        id="agreementId"
        label="Type d'intervention"
        options={APIRoute.WkAgreement + '/Simplified'}
        required
      />
    </CmsForm>
  );
};
