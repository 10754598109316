/**
 * Méthodes de validation pour les formulaires (useForm)
 */
export const Validation = {
  required: 'Ce champ est requis',
  maxLength: (length: number) => ({
    value: length,
    message: `La longueur maximale est de ${length} caractères`,
  }),
  minLength: (length: number) => ({
    value: length,
    message: `La longueur minimale est de ${length} caractères`,
  }),
};

/**
 * Messages de notification pour les formulaires (useForm)
 */
export const FormNotifMessage = {
  created: 'Élément créé',
  updated: 'Élément mis à jour',
  deactivated: 'Élément désactivé',
  deleted: 'Élément supprimé',
};
