import React, { FC, useContext, useMemo } from 'react';
import { ColoredStatus } from '../rh/SfAbsence.pack';
import APIRoute from '../../constant/API.constant';
import { authenticationService } from '../../service/Authentication.service';
import { NotificationContext } from '../../context/Notification.context';
import { NewNotifBoard } from '../notification/NotificationDashBoard';
import AccessFilter from '../../helper/AccessFilter';
import { AddNavigationButton } from '../../component/shared/Buttons';
import { GlobalContext } from '../../context/Global.context';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import { SfAbsence } from '../../interface/SfType';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import ROLE from '../../constant/role.constant';

/**
 *  Composant pour afficher le dashboard de l'utilisateur
 */
export const UserDashboard = () => {
  const userId = useMemo(() => authenticationService.getCurrentUser()?.userId ?? 0, []);
  const { notifCategories } = useContext(NotificationContext);
  const { theming } = useContext(GlobalContext);

  if (userId === 0) return <></>;
  return (
    <div className="flex-v">
      <h1 style={{ color: theming.get().palette.text.primary, textAlign: 'center', marginBottom: '1em' }}>Bienvenue</h1>
      <NewNotifBoard notifList={notifCategories.newNotif} />
      <AbsenceList />
    </div>
  );
};

//#region AbsenceList

const absenceConfigList: CmsColumnDef<SfAbsence>[] = [
  { header: 'Type', id: 'type' },
  {
    header: 'Statut',
    id: 'statusName',
    cell: (x) => <ColoredStatus status={x.getValue()} />,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: [
        { id: 0, label: 'En attente' },
        { id: 1, label: 'En attente de validation RH' },
        { id: 2, label: 'Validé' },
        { id: 3, label: 'Refusé' },
      ],
    },
  },
  { header: 'Date de début', id: 'startDate', Filter: CmsTableFilter.Date },
  { header: 'Date de fin', id: 'endDate', Filter: CmsTableFilter.Date },
  { header: 'Date de la demande', id: 'requestDate', Filter: CmsTableFilter.Date },
  { header: 'Date validation manager', id: 'grantedManagerDate', Filter: CmsTableFilter.Date },
  { header: 'Date validation finale RH', id: 'grantedDate', Filter: CmsTableFilter.Date },
];

/**
 * Composant pour afficher la liste des absences dans le dashboard
 */
const AbsenceList: FC = () => {
  const actions = [];
  if (AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_CREATE]))
    actions.push(<AddNavigationButton title="Ajouter une absence" to="/castres/staff/absence/create" />);

  return (
    <CmsFrontendTable
      title="Liste des absences"
      actions={actions}
      route={`${APIRoute.SfAbsence}/CurrentUser`}
      columns={absenceConfigList}
      initialSorting={{ id: 'startDate', desc: true }}
      navigateTo={(id: number) => `/castres/staff/absence/${id}/edit`}
    />
  );
};

//#endregion
