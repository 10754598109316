import React, { FC, useContext } from 'react';
import './login.scss';
import Utils from '../../helper/Utils';
import { useParams } from 'react-router-dom';
import { InputUI, UI } from '../../component/shared';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import NotificationService from '../../service/NotificationService';
import { GlobalContext } from '../../context/Global.context';

const ResetPassword: FC<any> = (props) => {
  const { theming } = useContext(GlobalContext);
  const { id } = useParams() as any;
  const [state, setState] = React.useState({ id: id, password: '', confirmPassword: '' });
  if (!state.id) window.location.href = '/login';

  const reinitializePassword = () => {
    if (state.password.length < 8)
      return NotificationService.error('Le mot de passe doit contenir au moins 8 caractères');
    if (state.password !== state.confirmPassword)
      return NotificationService.error('Les mots de passe ne correspondent pas');
    const payload = { label: state.id, value: state.password };
    CRUD.post(APIRoute.Login + '/password/reinitialisation', payload).then(() => {
      NotificationService.info('Votre mot de passe a été réinitialisé');
      window.location.href = '/login';
    });
  };
  return (
    <div className="login">
      <img src={Utils.getEnvIconUrl(theming)} alt="logo" />
      <InputUI.CMSTextField
        name="pwd"
        label="Nouveau mot de passe"
        type="password"
        onChange={(password: string) => setState({ ...state, password })}
      />
      <InputUI.CMSTextField
        name="confirm-pwd"
        label="Confirmer mot de passe"
        type="password"
        onChange={(confirmPassword: string) => setState({ ...state, confirmPassword })}
      />
      <UI.Button id="submitButton" onClick={reinitializePassword}>
        Réinitialiser
      </UI.Button>
    </div>
  );
};

export default ResetPassword;
