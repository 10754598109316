import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { SkToolModel } from '../../../interface/SkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const SkToolModelListConfig: CmsColumnDef<SkToolModel>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: "Type d'outil", id: 'skToolType.name', accessorFn: (x) => x.skToolType?.name, Filter: CmsTableFilter.Text },
];

/**
 * Liste des modèles d'outils
 */
export const SkToolModelList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_TOOLMODEL_CREATE])
    ? [<AddNavigationButton title="Ajouter un modèle d'outil" to="/castres/stock/toolmodel/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des modèles d'outils"
      route={APIRoute.SkToolModel}
      columns={SkToolModelListConfig}
      navigateTo={(id: number) => `/castres/stock/toolmodel/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Composant de création et d'édition d'un modèle d'outil
 * @param id Id du modèle d'outil
 * @param setHttpCodePage Fonction pour changer le code http de la page
 */
export const SkToolModelCreateEdit: FC<any> = ({ id }) => {
  return (
    <CmsForm
      id={id}
      currentUrl="/castres/stock/toolmodel/"
      thin
      title="un modèle d'outil"
      route={APIRoute.SkToolModel}
      onGetEditData={(model) => {
        model.skToolTypeId = model.skToolType?.id;
        return model;
      }}
    >
      <CmsFormInput.Text id="name" label="Nom" required />
      <CmsFormInput.Select
        id="skToolTypeId"
        label="Type d'outil"
        options={APIRoute.SkToolType}
        optionLabel="name"
        required
      />
    </CmsForm>
  );
};
