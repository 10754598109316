import React, { FC, Fragment } from 'react';

import { NoContentMessage, SectionTitle, BoolIconLabelValue } from 'component/shared/Ui';
import { ImpStaUpdate, ImpStaBulkUpdate, ValidatedImportItem, ValidatedBulkUpdate } from 'interface/ImportType';
import { confirmUpdate } from 'service/Import.service';
import { handleErrorNotif } from 'helper/handle-response';
import {
  ImportValidatedAccordion,
  ImportItem,
  equals,
  BulkUpdateItem,
  getLabel,
  UpdatedLabelValue,
  UpdatedField,
  StationLabelLink,
} from '../ImportUtils';

interface ImportUpdatesProps {
  // Id de l'import
  idImport: string;
  // Stations à mettre à jour
  updates: ImpStaUpdate[] | undefined;
  // Mises à jour groupées
  bulkUpdates: ImpStaBulkUpdate[] | undefined;
  // Stations validées
  validatedUpdates: ValidatedImportItem;
  // Fonction pour changer les stations validées
  setValidatedUpdates: any;
  // Mises à jour groupées validées
  validatedBulks: ValidatedBulkUpdate[];
  // Fonction pour changer les mises à jour groupées validées
  setValidatedBulks: any;
}

/**
 * Composant pour afficher les stations à mettre à jour
 * @param idImport id de l'import
 * @param updates stations à mettre à jour
 * @param bulkUpdates mises à jour groupées
 * @param validatedUpdates stations validées
 * @param setValidatedUpdates fonction pour changer les stations validées
 * @param validatedBulks mises à jour groupées validées
 * @param setValidatedBulks fonction pour changer les mises à jour groupées validées
 */
const ImportUpdates: FC<ImportUpdatesProps> = ({
  idImport,
  updates,
  bulkUpdates,
  validatedUpdates,
  setValidatedUpdates,
  validatedBulks,
  setValidatedBulks,
}) => (
  <Fragment>
    {updates && bulkUpdates && (updates.length || bulkUpdates.length) ? (
      <Fragment>
        {/*{(!!Object.keys(validatedUpdates).length || !!validatedBulks.length) && (*/}
        <ImportValidatedAccordion validated={Object.keys(validatedUpdates).length + validatedBulks.length}>
          auieauie
          <BulkUpdateItems
            idImport={idImport}
            bulkUpdates={bulkUpdates}
            validatedBulks={validatedBulks}
            setValidatedBulks={setValidatedBulks}
            validated={true}
          />
          <Stations
            idImport={idImport}
            stations={updates}
            validatedElements={validatedUpdates}
            setValidatedElements={setValidatedUpdates}
            validated={true}
          />
        </ImportValidatedAccordion>
        {/*)}*/}
        {bulkUpdates.length - validatedBulks.length > 0 && (
          <Fragment>
            <SectionTitle title="Mises à jour groupées" />
            <BulkUpdateItems
              idImport={idImport}
              bulkUpdates={bulkUpdates}
              validatedBulks={validatedBulks}
              setValidatedBulks={setValidatedBulks}
            />
            <SectionTitle title="Mises à jour individuelles" />
          </Fragment>
        )}
        <Stations
          idImport={idImport}
          stations={updates}
          validatedElements={validatedUpdates}
          setValidatedElements={setValidatedUpdates}
        />
      </Fragment>
    ) : (
      <NoContentMessage>Aucune mise à jour disponible</NoContentMessage>
    )}
  </Fragment>
);

/**
 * Composant pour afficher les mise à jour groupées
 * @param idImport id de l'import
 * @param bulkUpdates mises à jour groupées
 * @param validated si les mises à jour sont validées
 * @param validatedBulks mises à jour groupées validées
 * @param setValidatedBulks fonction pour changer les mises à jour groupées validées
 */
const BulkUpdateItems: FC<{
  idImport: string;
  bulkUpdates: ImpStaBulkUpdate[];
  validated?: boolean;
  validatedBulks: ValidatedBulkUpdate[];
  setValidatedBulks: any;
}> = ({ idImport, bulkUpdates, validated = false, validatedBulks, setValidatedBulks }) => (
  <Fragment>
    {bulkUpdates
      .filter(
        (bulkUpdate: ImpStaBulkUpdate) =>
          validatedBulks.some((validatedBulk) => equals(validatedBulk.modification, bulkUpdate.modification)) ===
          validated,
      )
      .map((bulkUpdate: ImpStaBulkUpdate) => {
        const accept = (callback: any) => {
          if (!validated) {
            const stationRefs = bulkUpdate.stations.map((station) => station.ref);
            confirmUpdate('station', idImport, stationRefs)
              .then(() => {
                if (callback) callback();
              })
              .catch(handleErrorNotif);
          }
        };
        return (
          <BulkUpdateItem
            titleDialog="Équipements dans la mise à jour groupée"
            key={bulkUpdate.modification.attribute}
            acceptBulk={accept}
            modification={bulkUpdate.modification}
            elementLength={bulkUpdate.stations.length}
            validated={validated}
            validatedBulks={validatedBulks}
            setValidatedBulks={setValidatedBulks}
          >
            {bulkUpdate.stations.map((station: ImpStaUpdate) => (
              <ImportItem
                key={station.ref}
                label={<StationLabelLink station={station} />}
                customFields={[
                  <BoolIconLabelValue
                    key={`${station.ref}-cribe`}
                    label="Contract Cribe"
                    value={station.contractCribe}
                    style={{ marginLeft: '1em', lineHeight: 'normal' }}
                  />,
                  <BoolIconLabelValue
                    key={`${station.ref}-ad`}
                    label="Contract Ad"
                    value={station.contractAd}
                    style={{ marginLeft: '1em', lineHeight: 'normal' }}
                  />,
                ]}
                noActions
              />
            ))}
          </BulkUpdateItem>
        );
      })}
  </Fragment>
);

/**
 * Composant pour afficher les stations à mettre à jour
 * @param idImport id de l'import
 * @param stations stations à mettre à jour
 * @param validatedElements stations validées
 * @param setValidatedElements fonction pour changer les stations validées
 * @param validated si les stations sont validées
 */
const Stations: FC<{
  idImport: string;
  stations: ImpStaUpdate[];
  validatedElements: ValidatedImportItem;
  setValidatedElements: any;
  validated?: boolean;
}> = ({ idImport, stations, validatedElements, setValidatedElements, validated = false }) => (
  <Fragment>
    {stations
      .filter((station) => station.ref in validatedElements === validated)
      .map((station: ImpStaUpdate) => {
        let accepted = false,
          refused = false;
        if (validated) {
          accepted = validatedElements[station.ref] === 'accepted';
          refused = validatedElements[station.ref] === 'refused';
        }

        const accept = (ref: string) => {
          if (!validated) {
            confirmUpdate('station', idImport, [ref])
              .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'accepted' })))
              .catch(handleErrorNotif);
          }
        };
        const refuse = (ref: string) => {
          if (!validated) setValidatedElements((current: any) => ({ ...current, [ref]: 'refused' }));
        };

        const defaultFields = [];
        if (!station.modifications.some((modification) => modification.attribute === 'contractCribe')) {
          defaultFields.push(
            <BoolIconLabelValue
              key={`${station.ref}-cribe`}
              label="Contract Cribe"
              value={station.contractCribe}
              style={{ marginLeft: '1em', lineHeight: 'normal' }}
            />,
          );
        }
        if (!station.modifications.some((modification) => modification.attribute === 'contractAd')) {
          defaultFields.push(
            <BoolIconLabelValue
              key={`${station.ref}-ad`}
              label="Contract Ad"
              value={station.contractAd}
              style={{ marginLeft: '1em', lineHeight: 'normal' }}
            />,
          );
        }

        const modifications = station.modifications.map((modification) => {
          const { attribute, attributeLabel, oldValue, newValue } = modification;
          return (
            <UpdatedLabelValue key={`${station.ref}-${attribute}`} label={getLabel(attribute, attributeLabel)}>
              <UpdatedField attribute={attribute} oldValue={oldValue} newValue={newValue} />
            </UpdatedLabelValue>
          );
        });

        return (
          <ImportItem
            key={station.ref}
            tied
            secondLvl={false}
            label={<StationLabelLink station={station} />}
            style={{ backgroundColor: '#5f5e64' }}
            accepted={accepted}
            refused={refused}
            accept={() => accept(station.ref)}
            refuse={() => refuse(station.ref)}
            customFields={[...defaultFields, ...modifications]}
          />
        );
      })}
  </Fragment>
);

export default ImportUpdates;
