import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { SkToolType } from '../../../interface/SkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const SkToolTypeListConfig: CmsColumnDef<SkToolType>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Période (Nb. jours)', id: 'period', Filter: CmsTableFilter.Text },
  { header: 'Durée de vie (Nb. jours)', id: 'lifespan', Filter: CmsTableFilter.Text },
  { header: 'À envoyer à la tablette', id: 'send', Filter: CmsTableFilter.Bool },
  { header: 'Compteur automatique', id: 'autoIncrement', Filter: CmsTableFilter.Bool },
];

/**
 * Liste des types d'outils
 */
export const SkToolTypeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_TOOLTYPE_CREATE])
    ? [<AddNavigationButton title="Ajouter un type d'outil" to="/castres/stock/tooltype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des types d'outils"
      route={APIRoute.SkToolType}
      columns={SkToolTypeListConfig}
      navigateTo={(id: number) => `/castres/stock/tooltype/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Composant de création et d'édition d'un type d'outil
 * @param id Id du type d'outil
 */
export const SkToolTypeCreateEdit: FC<any> = ({ id }: any) => {
  const props = { thin: true, currentUrl: '/castres/stock/tooltype/' };
  return (
    <CmsForm route={APIRoute.SkToolType} id={id} title="un type d'outil" {...props}>
      <CmsFormInput.Text id="name" label="Nom" required max={50} />
      <CmsFormInput.Text id="code" label="Code" required min={3} max={3} placeholder="3 caractères impératif" />
      <CmsFormInput.Number id="period" label="Période (Nb. jours)" />
      <CmsFormInput.Number id="lifespan" label="Durée de vie (Nb. jours)" />
      <CmsFormInput.Switch id="send" label="À envoyer à la tablette" />
      <CmsFormInput.Switch id="autoIncrement" label="Compteur automatique" />
    </CmsForm>
  );
};
