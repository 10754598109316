import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { WkUnit } from '../../../interface/WkType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const wkUnitConfigList: CmsColumnDef<WkUnit>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
];

export const BlContractTypeInvoiceList: FC = () => {
  return (
    <CmsFrontendTable
      title="Type de facturation"
      route={APIRoute.BlContractTypeInvoice}
      columns={wkUnitConfigList}
      navigateTo={(id: number) => `/castres/billing/contracttypeinvoice/${id}/edit`}
      setFiltersInUrl
      actions={[
        <AddNavigationButton
          title="Ajouter un type de facturation"
          to="/castres/billing/contracttypeinvoice/create"
          access={[ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTFREQUENCYINVOICE_CREATE]}
        />,
      ]}
    />
  );
};

export const BlContractTypeInvoiceCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlContractTypeInvoice, title: 'type de facturation' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/contracttypeinvoice/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
    </CmsForm>
  );
};
