import React, { Fragment, FC, useContext } from 'react';
import MuiSkeleton from '@mui/material/Skeleton';
import { GlobalContext } from '../../context/Global.context';

/**
 * Le skeleton est un composant qui permet de faire un rendu de chargement (remplace le contenu) par un affichage temporaire.
 * @param columnNumber Le nombre de colonnes à afficher
 */
const SkeletonTable: FC<{ columnNumber: number }> = ({ columnNumber }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <Fragment>
      <TabHeader>
        <Skeleton style={{ width: '10%' }} />
        <Skeleton style={{ width: '20%' }} />
      </TabHeader>
      <TabHeader>
        <RowContent columnNumber={columnNumber} />
      </TabHeader>
      {[...Array(5)].map((_, idx) => (
        <Fragment key={idx}>
          <TabRow columnNumber={columnNumber} />
          <TabRow columnNumber={columnNumber} style={{ backgroundColor: theming.get().cms.main.skeleton }} />
        </Fragment>
      ))}
    </Fragment>
  );
};

/**
 * Une ligne du skeleton
 * @param columnNumber Le nombre de colonnes à afficher
 * @param style Le style à appliquer à la ligne
 */
const TabRow: FC<{ columnNumber: number; style?: object }> = ({ columnNumber, style = {} }) => (
  <div
    style={{
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 0.5em',
      ...style,
    }}
  >
    <RowContent columnNumber={columnNumber} />
  </div>
);

/**
 * Une colonne du skeleton (segment de ligne)
 * @param columnNumber Le nombre de colonnes à afficher
 */
const RowContent: FC<{ columnNumber: number }> = ({ columnNumber }) => (
  <Fragment>
    {[...Array(columnNumber)].map((_, idx) => (
      <Skeleton key={idx} style={{ width: `${100 / columnNumber - 2}%` }} />
    ))}
  </Fragment>
);

/**
 * L'entête du skeleton
 * @param children Les enfants à afficher dans l'entête
 */
const TabHeader = ({ children }: any) => {
  const { theming } = useContext(GlobalContext);
  return (
    <div
      style={{
        backgroundColor: theming.get().cms.main.header,
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 0.5em',
        borderBottom: '1px solid #525252',
      }}
    >
      {children}
    </div>
  );
};

/**
 * Surcharge du composant Skeleton de Material UI
 * @param style Le style à appliquer au Skeleton
 */
const Skeleton = ({ style = {} }) => <MuiSkeleton style={{ height: '25px', ...style }} />;

export default SkeletonTable;
