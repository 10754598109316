import React, { CSSProperties, FC, ReactNode } from 'react';
import Utils, { formatCurrency } from '../../../helper/Utils';
import { CellContext } from '@tanstack/react-table';
import { ColorDateLimit, TextEllipse } from '../../shared/Ui';
import '../table.style.scss';
import { Tooltip, Rating, Link } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CmsIcon from 'component/shared/CmsIcon';

/**
 * Cellule de tableau de type date
 * @param info information de la cellule
 */
const DateCell: FC<CellContext<any, any>> = (info) => {
  const result = (!!info.getValue() && Utils.displayDate(info.getValue())) || '';
  return <span>{result === '01/01/1970' || result === '01/01/0001' ? '' : result}</span>;
};

/**
 * Cellule de tableau de type datetime (avec heure)
 * @param info information de la cellule
 */
const DateTimeCell: FC<CellContext<any, any>> = (info) => (
  <div style={{ textAlign: 'center' }}>{info.getValue() && Utils.displayDateTime(info.getValue(), true)}</div>
);

/**
 * Cellule de tableau de type datetime (avec heure en grand en dessous)
 * @param info information de la cellule
 */
const DateBigTimeCell: FC<CellContext<any, any>> = (info) => {
  const dateTimeStr = info.getValue() ? Utils.displayDateTime(info.getValue(), true) : '';
  const lastSpaceIndex = dateTimeStr.lastIndexOf(' ');
  const datePart = dateTimeStr.substring(0, lastSpaceIndex);
  const timePart = dateTimeStr.substring(lastSpaceIndex + 1);

  return (
    <div style={{ textAlign: 'center' }}>
      <div>{datePart}</div>
      <div style={{ fontSize: 'larger', fontWeight: 'bold' }}>{timePart}</div>
    </div>
  );
};

/**
 * Cellule de tableau de type datetime avec heure, affiche un X rouge si pas de date renseignée
 * @param info information de la cellule
 */
const DateTimeOrBooleanCell: FC<CellContext<any, any>> = (info) => {
  if (!info.getValue())
    return (
      <div style={{ textAlign: 'center' }}>
        <ClearIcon color="error" />
      </div>
    );
  const dateTimeStr = info.getValue() ? Utils.displayDateTime(info.getValue(), true) : '';
  const lastSpaceIndex = dateTimeStr.lastIndexOf(' ');
  const datePart = dateTimeStr.substring(0, lastSpaceIndex);
  const timePart = dateTimeStr.substring(lastSpaceIndex + 1);

  return (
    <div style={{ textAlign: 'center' }}>
      <div>{datePart}</div>
      <div style={{ fontSize: 'larger', fontWeight: 'bold' }}>{timePart}</div>
    </div>
  );
};

/**
 * Cellule de tableau de type date avec limite (par défault 15, 30, 90 jours)
 * @param info information de la cellule
 */
const DateWithLimitCell: FC<CellContext<any, any>> = (info) => {
  const date = (!!info.getValue() && Utils.displayDate(info.getValue())) || '';
  if (['01/01/1', '01/01/1970', '01/01/0001'].includes(date)) return <></>;
  return <ColorDateLimit date={date} />;
};

/**
 * Cellule de tableau de type date relative
 * @param info information de la cellule
 */
export const RelativeDateCell: FC<CellContext<any, any>> = (info) => (
  <span>{info.getValue() && Utils.displayRelativeDate(info.getValue())}</span>
);

/**
 * Cellule de tableau affichant une durée à partir d'un nombre de secondes, avec l'unité adaptée à la durée (année, mois, jour, heure/minute, minute, seconde)
 * @param info information de la cellule
 */
export const DurationCell: FC<CellContext<any, any>> = (info) => (
  <span>{info.getValue() && Utils.displayDurationFromSeconds(info.getValue())}</span>
);

/**
 * Cellule de tableau affichant une durée à partir d'un nombre de secondes, sous forme nombre total d'heures et de minutes
 * @param info information de la cellule
 */
export const DurationCellHours: FC<CellContext<any, any>> = (info) => (
  <span>{info.getValue() && Utils.displayDurationFromSeconds(info.getValue(), 'Hm')}</span>
);

/**
 * Cellule de tableau de type booléen
 * @param info information de la cellule
 */
const BooleanCell: FC<CellContext<any, any>> = (info) => {
  if (info.getValue()) return <CheckIcon color="success">valide</CheckIcon>;
  return <ClearIcon color="error">non valide</ClearIcon>;
};

/**
 * Cellule de tableau de type ellipse (texte tronqué)
 * @param info information de la cellule
 */
const EllipseCell: FC<CellContext<any, any>> = (info) => <TextEllipse text={info.getValue() ?? ''} />;

/**
 * Cellule de tableau de type nowrap (texte non tronqué et sur une seule ligne)
 * @param info information de la cellule
 */
const NoWrapCell: FC<CellContext<any, any>> = (info) => {
  return <span style={{ whiteSpace: 'nowrap' }}>{info.getValue()}</span>;
};

/**
 * Cellule de tableau de type prix
 * @param info information de la cellule
 */
const PriceCell: FC<CellContext<any, any>> = (info) => {
  return <div className="price-cell">{formatCurrency(+info.getValue())}</div>;
};

/**
 * Cellule de tableau de type ref
 *
 * Ce composant rend une cellule de tableau contenant un lien.
 *
 * @param cell Information de la cellule de tableau.
 * @param url URL du lien voulu. Si non fourni, le lien n'est pas cliquable.
 * @param children Enfant(s) à ajouter à la cellule en dessous de la valeur principale.
 * @param style Styles CSS supplémentaires à appliquer à la cellule.
 * @param value Valeur à afficher dans la cellule. Si non fourni, la valeur par défaut est celle de la cellule.
 */
interface RefCellProps {
  cell: CellContext<any, any>;
  url?: string;
  children?: ReactNode;
  style?: CSSProperties;
  value?: string;
}

const RefCell: FC<RefCellProps> = ({ cell, url, children, style, value }) => {
  return (
    <Link key={cell.row.original.id} style={{ color: 'inherit' }} href={url} target="_blank">
      <div style={{ lineHeight: '1rem', ...style }}>
        <div style={{ fontWeight: 'bold' }}>{value ?? cell.getValue()}</div>
        {children}
      </div>
    </Link>
  );
};

/**
 * Cellule de tableau de type Bool mais avec une bulle info supplémentaire
 * @param cell information de la cellule
 * @param url lien voulu
 * @param tooltip message a affciher lorsque l'on passe sur la cellule
 */
const BoolAndInfo: FC<{ cell: CellContext<any, any>; url?: string; tooltip?: string }> = ({ cell, url, tooltip }) => {
  return (
    <div className="flex-h align-center">
      <CmsTableCell.Bool {...cell} />
      {cell.getValue() ? <CmsIcon icon="info" tooltip={tooltip} href={url} /> : <></>}
    </div>
  );
};

/**
 * Cellule de tableau de type couleur
 * @param info information de la cellule
 */
const ColorCell: FC<CellContext<any, any>> = (info) => {
  let value = info.getValue();
  if (!value) return <></>;
  if (!value.includes('#')) value = '#' + value;
  return (
    <Tooltip title={'Code: ' + value}>
      <div style={{ backgroundColor: value }} className="color-cell color-only" />
    </Tooltip>
  );
};

/**
 * Cellule de tableau de type couleur avec texte
 * @param content contenu de la cellule
 * @param color couleur de la cellule
 */
const ColoredCell: FC<{ color: string; content: ReactNode }> = ({ content, color }) => {
  const style: CSSProperties = { backgroundColor: (!color?.includes('#') ? '#' : '') + color, color: 'white' };
  style['textShadow'] = '0 0 0.2em black';
  return (
    <div style={style} className="color-cell">
      {content}
    </div>
  );
};

const RatingCell: FC<CellContext<any, any>> = (info) => {
  const value = info.getValue();
  return <Rating name="read-only" value={value} size="small" readOnly />;
};

const AddressCell: FC<CellContext<any, any>> = (info) => {
  const value = info.getValue();
  return (
    <Link
      key={info.row.original.id}
      style={{ color: 'inherit', textAlign: 'left' }}
      href={`/generateMap/${info.row.original.latitude ?? 0}/${info.row.original.longitude ?? 0}`}
      target="_blank"
    >
      <div style={{ textAlign: 'left' }}>{value}</div>
    </Link>
  );
};

const LinkCell: FC<{ label: string; url: string }> = ({ label, url }) => (
  <Link href={url} target="_blank">
    {label}
  </Link>
);

const CmsTableCell = {
  Date: DateCell,
  Datetime: DateTimeCell,
  DateBigTime: DateBigTimeCell,
  DateTimeOrBoolean: DateTimeOrBooleanCell,
  DateWithLimit: DateWithLimitCell,
  RelativeDate: RelativeDateCell,
  Duration: DurationCell,
  DurationHours: DurationCellHours,
  Ellipse: EllipseCell,
  Bool: BooleanCell,
  Price: PriceCell,
  Color: ColorCell,
  Colored: ColoredCell,
  Ref: RefCell,
  NoWrap: NoWrapCell,
  Rating: RatingCell,
  BoolWithInfo: BoolAndInfo,
  Address: AddressCell,
  Link: LinkCell,
};

export default CmsTableCell;
