import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton, ArrowBackNavigationButton } from '../../../component/shared/Buttons';
import { WkSource } from '../../../interface/WkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { CmsPaper } from '../../../component/shared/Ui';
import ROLE from '../../../constant/role.constant';

export const WkSourceListConfig: CmsColumnDef<WkSource>[] = [
  { header: 'Id', id: 'id', Filter: CmsTableFilter.Text },
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
];

/**
 * Liste des sources
 */
export const WkSourceList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_SOURCE_CREATE])
    ? [<AddNavigationButton title="Ajouter une source" to="/castres/work/source/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des sources"
      columns={WkSourceListConfig}
      route={APIRoute.WkSource}
      navigateTo={(id: number) => `/castres/work/source/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'une source
 * @param id id de la source à éditer
 * @param setHttpCodePage fonction pour modifier le code http de la page
 */
export const WkSourceCreateEdit: FC = ({ id }: any) => {
  const props = { id, currentUrl: '/castres/work/source/', route: APIRoute.WkSource };
  const action = [<ArrowBackNavigationButton title="Retourner à la liste" to={`${props.currentUrl}list`} />];
  return (
    <CmsForm
      {...props}
      renderForm={(form: any) => {
        return (
          <div>
            <CmsPaper title="Source" actions={action}>
              <CmsFormInput.Text id="name" label="Nom" required max={50} />
              <CmsFormInput.Text id="code" label="Code" required max={50} />
              <CmsFormSubmit type={!!id ? 'editForm' : 'createForm'} />
            </CmsPaper>
          </div>
        );
      }}
    ></CmsForm>
  );
};
