import React, { CSSProperties, FC, useContext } from 'react';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import Utils, { setToKebabDate } from '../../helper/Utils';
import { WkTimeReportingWeek } from '../../context/TimeReport.context';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AdjustIcon from '@mui/icons-material/Adjust';
import { ViewNavigationButton } from '../../component/shared/Buttons';
import CmsIcon from '../../component/shared/CmsIcon';
import { GlobalContext } from '../../context/Global.context';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import { CellContext } from '@tanstack/react-table';
import ROLE from '../../constant/role.constant';

/**
 * Liste des feuilles de temps
 */
const TimeReportAdminList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste des Feuilles de temps"
      route={APIRoute.WkTimeReportingWeek}
      columns={TRAdminList}
      invertClick
      initialSorting={{ id: 'mondayDate', desc: true }}
      navigateTo={(_, x) =>
        `/castres/timereporting/administration/${x.row.original.userId}/${setToKebabDate(x.row.original.mondayDate)}`
      }
      actions={[
        <ViewNavigationButton
          access={[ROLE.ADMIN_TIMEREPORTINGWEEK_EXPORT]}
          title="Rapport d'intervention"
          to={`/castres/timereporting/administration/intervention_report`}
        />,
        <ViewNavigationButton
          access={[ROLE.ADMIN_TIMEREPORTINGWEEK_EXPORT]}
          title="Rapport de feuille de temps"
          to={`/castres/timereporting/administration/report`}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

const statusCellStyle: CSSProperties = {
  borderRadius: '20px',
  width: 'fit-content',
  padding: '2px 8px',
};

/**
 * Cellule de statut
 * @param value valeur du statut
 */
const StatusCell: FC<{ value: number }> = ({ value }) => {
  const { theming } = useContext(GlobalContext);
  const options = [
    { title: 'À remplir par le technicien', color: theming.get().cms.color.red },
    { title: 'À valider par le manager', color: theming.get().cms.color.orange },
    { title: 'À valider par le RH', color: theming.get().cms.color.blue },
    { title: 'Validé', color: theming.get().cms.color.green },
  ];
  const result = (value < 5 && value > -1 && options[value]) || { title: 'non défini', color: '' };
  return <div style={{ ...statusCellStyle, backgroundColor: result.color }}>{result.title}</div>;
};

/**
 * Cellule de date (affiche les dates de la semaine comme des bulles vide ou remplis en fonction des journées validées)
 * @param cell cellule du CmsTable
 */
const DayAbstract = (cell: CellContext<WkTimeReportingWeek, any>) => {
  const weekStatus = cell.row.original.status;
  const value = cell.getValue();
  if (value?.length !== 7) return <>???</>;
  return (
    <span>
      {value.map((x: number, i: number) => (
        <span key={i}>
          {x === -1 && <RadioButtonUncheckedIcon />}
          {x > 0 && <CheckCircleIcon color="primary" />}
          {x === 0 && weekStatus > 0 && <CancelIcon color="error" />}
          {x === 0 && weekStatus === 0 && <AdjustIcon color="secondary" />}
        </span>
      ))}
    </span>
  );
};

const TRAdminList: CmsColumnDef<WkTimeReportingWeek>[] = [
  { header: 'Technicien', id: 'technicianName', Filter: CmsTableFilter.Select },
  { header: 'Service', id: 'serviceLabel', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  {
    header: 'Statut',
    id: 'status',
    cell: (x) => <StatusCell value={x.getValue()} />,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: [
        { id: 0, label: 'Non validé' },
        { id: 1, label: 'En attente manager' },
        { id: 2, label: 'En attente Ressource humaine' },
        { id: 3, label: 'Validé' },
        { id: 4, label: 'Cloturé' },
      ],
    },
  },
  { header: 'Semaine du lundi:', id: 'mondayDate', Filter: CmsTableFilter.Date },
  { header: 'Résumé', id: 'weekDayListState', cell: DayAbstract },
  {
    header: 'Télécharger',
    noClick: true,
    id: 'id',
    cell: (x) => (
      <CmsIcon
        tooltip="Télécharger la feuille d'heures"
        onClick={(event: any) => downloadTimeReportingPDF(event, x.row.original.userId, x.row.original.mondayDate)}
        icon="download"
      />
    ),
  },
];

const downloadTimeReportingPDF = (event: any, userId: number, dateOfWeek: Date) => {
  CRUD.getBlob(`${APIRoute.WkTimeReportingWeek}/ExportPdf/${userId}?dateOfWeek=${dateOfWeek.toISOString()}`).then(
    (blob) => {
      Utils.downloadFile(blob, `Feuille_Heures_User_${userId}.pdf`);
    },
  );
};

export default TimeReportAdminList;
