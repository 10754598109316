import React, { FC, useEffect } from 'react';
import { authenticationService } from '../../service/Authentication.service';
import NotificationService from 'service/NotificationService';
import './login.scss';
import Utils from '../../helper/Utils';
import { InputUI, UI } from '../../component/shared';
import { useNavigate, useLocation } from 'react-router-dom';
import { DialogUI } from '../../component/shared/DialogUI';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import CircularProgress from '@mui/material/CircularProgress';
import { GlobalContext } from '../../context/Global.context';
import { darkTheme, lightTheme } from '../../component/Theming';

const Login: FC = () => {
  const { theming } = React.useContext(GlobalContext);
  const [state, setState] = React.useState({ login: '', password: '', isSubmitting: false, modal: false });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const username = params.get('username');
    if (username) {
      setState((prevState) => ({ ...prevState, login: username }));
      if (location.pathname === '/reset/request') {
        handleResetPwd(username);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const failedAuth = () => {
    NotificationService.error('Connexion échouée');
    setState({ ...state, isSubmitting: false });
  };

  const authenticate = () => {
    const { login, password } = state;
    if (login?.length < 3 || password?.length < 3) {
      NotificationService.error('Vous devez remplir tous les champs');
      return;
    }
    setState({ ...state, isSubmitting: true });
    authenticationService
      .login({ login, password })
      .then((result: any) => {
        if (!!result) {
          if (!!result.preferences?.theme && theming.get().palette.mode !== result.preferences.theme)
            theming.set(result.preferences.theme === 'dark' ? darkTheme : lightTheme);
          const memoUrl = localStorage.getItem('memo-url');
          localStorage.removeItem('memo-url');
          navigate('/' + (memoUrl ?? 'castres/user/dashboard'));
        } else failedAuth();
      })
      .finally(() => setState({ ...state, isSubmitting: false }));
  };

  const handleResetPwd = (username = state.login) => {
    setState({ ...state, isSubmitting: true });
    CRUD.post(APIRoute.Login + '/password/reset', { username })
      .then(() => {
        NotificationService.info(
          "Un email de réinitialisation de mot de passe a été envoyé à votre adresse mail professionnelle (si l'utilisateur renseigné existe)",
        );
      })
      .finally(() => setState({ ...state, isSubmitting: false, modal: false }));
  };

  const handleEnterKey = (e: any) => e.key === 'Enter' && authenticate();

  const handleResetRequest = () => {
    if (!state.login || state.login.length < 3)
      return NotificationService.error('Vous devez saisir votre login pour réinitialiser votre mot de passe');
    setState({ ...state, modal: true });
  };

  return (
    <div className="login">
      <img src={Utils.getEnvIconUrl(theming)} alt="logo" />
      <InputUI.CMSTextField
        name="login"
        label="Login"
        value={state.login}
        onChange={(login: string) => setState({ ...state, login })}
        onKeyPress={handleEnterKey}
      />
      <InputUI.CMSTextField
        name="pwd"
        label="Mot de passe"
        type="password"
        onChange={(password: string) => setState({ ...state, password })}
        onKeyPress={handleEnterKey}
      />
      <div onClick={handleResetRequest} className="forgot-pwd">
        Mot de passe oublié ?
      </div>
      <UI.Button id="submitButton" onClick={authenticate}>
        {state.isSubmitting ? <CircularProgress size="2em" /> : 'Connexion'}
      </UI.Button>
      <DialogUI
        isLoading={state.isSubmitting}
        className="reset-pwd"
        open={state.modal}
        onClose={() => setState({ ...state, modal: false })}
        onValidation={() => handleResetPwd(state.login)}
        title={`Réinitialiser le mot de passe de ${state.login} ?`}
      ></DialogUI>
    </div>
  );
};

export default Login;
