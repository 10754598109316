import React, { FC } from 'react';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';

const BlStudyCategoryConfigList: CmsColumnDef<any>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Taux de marge', id: 'markupFactor', Filter: CmsTableFilter.Text },
];

export const BlStudyCategoryList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.BlStudyCategory}
      columns={BlStudyCategoryConfigList}
      title="Liste des catégories d'étude"
      navigateTo={(id: number) => `/castres/billing/studycategory/${id}/edit`}
      actions={[
        <AddNavigationButton
          title="Ajouter une catégorie d'étude"
          to="/castres/billing/studycategory/create"
          access={[ROLE.ADMIN_STUDY_REFERENCE_EDIT]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const BlStudyCategoryCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/billing/studycategory/', route: APIRoute.BlStudyCategory };
  return (
    <CmsForm id={id} {...props} title="Catégorie d'étude" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={3} max={10} />
      <CmsFormInput.Number id="markupFactor" label="Taux de marge" />
    </CmsForm>
  );
};
