import { BehaviorSubject } from 'rxjs';
import { CmsGlobalThemeProps, darkTheme, lightTheme } from './Theming';

const themeSubject = new BehaviorSubject(localStorage.getItem('theme') === 'light' ? lightTheme : darkTheme);

const themingService = {
  get: () => themeSubject.value as CmsGlobalThemeProps,
  set: (theme: any) => themeSubject.next(theme),
  asObservable: () => themeSubject.asObservable(),
};

export default themingService;
