import React, { FC, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CMSNotification, NotificationContext } from '../../context/Notification.context';
import { NotificationModSelector, NotificationScrollableBody, SendNotifDialog } from './Notification';
import { UI } from '../../component/shared';
import './Notification.scss';

/**
 * Page de gestion des notifications (page et non le composant lateral droit)
 */
const NotificationDashBoard: FC = () => {
  const { notifCategories } = useContext(NotificationContext);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <UI.HeaderPanel
        wrap
        title="Centre de notification"
        actions={[<UI.Button onClick={() => setOpenModal(true)}>Créer une notification</UI.Button>]}
      />
      <UI.Dialog onClose={() => setOpenModal(false)} open={openModal}>
        <SendNotifDialog openModal={openModal} setOpenModal={setOpenModal} />
      </UI.Dialog>
      <div className="notif-dashboard-container">
        <Grid className="grid-notif-container" container spacing={2}>
          <Grid item sm={12} lg={6} className="grid-child">
            <NewNotifBoard notifList={notifCategories.newNotif} />
          </Grid>
          <Grid item sm={12} lg={6} className="grid-child">
            <ArchiveNotifBoard notifList={notifCategories.oldNotif} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

/**
 * Composant affichant les notifications non lues
 * @param notifList Liste des notifications
 */
export const NewNotifBoard: FC<{ notifList: Array<CMSNotification> }> = ({ notifList }) => {
  const [mod, setMod] = useState<number>(0);
  const nbrUrgencies = notifList.filter((x) => x.type === 3)?.length ?? 0;
  return (
    <UI.Paper
      title="Nouvelles notifications"
      className="grid-notif-container"
      scrollable
      tied
      headerStyle={{ flexWrap: 'wrap' }}
      style={{ maxHeight: '100%' }}
      actions={[<NotificationModSelector value={mod} onChange={setMod} nbrUrgency={nbrUrgencies} />]}
    >
      <NotificationScrollableBody mod={mod} notifList={notifList} isDashBoard />
    </UI.Paper>
  );
};

/**
 * Composant affichant les notifications lues
 * @param notifList Liste des notifications
 */
const ArchiveNotifBoard: FC<{ notifList: Array<CMSNotification> }> = ({ notifList }) => {
  const [mod, setMod] = useState<number>(0);
  const nbrUrgencies = notifList.filter((x) => x.type === 3)?.length ?? 0;
  return (
    <UI.Paper
      title="Archives"
      className="grid-notif-container"
      scrollable
      tied
      headerStyle={{ flexWrap: 'wrap' }}
      style={{ maxHeight: '100%' }}
      actions={[<NotificationModSelector value={mod} onChange={setMod} nbrUrgency={nbrUrgencies} />]}
    >
      <NotificationScrollableBody mod={mod} notifList={notifList} isDashBoard isOldNotif />
    </UI.Paper>
  );
};

export default NotificationDashBoard;
