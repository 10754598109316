import React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Command: NavProps = {
  name: 'Commandes',
  icon: <ViewListIcon />,
  subList: [
    {
      name: 'Commandes',
      linkV1: 'castres/billing/order/list',
      feature: ROLE.ADMIN_ORDER_LIST,
    },
    {
      name: 'Livraisons',
      linkV1: 'castres/billing/delivery/list',
      feature: ROLE.ADMIN_ORDER_DELIVERY_LIST,
    },
    {
      name: 'Articles Fournisseurs',
      linkV1: 'castres/stock/article/list',
      feature: ROLE.ADMIN_STOCK_ARTICLE_LIST,
    },
  ],
};
