import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkReportType } from '../../../interface/WkType';
import { CmsPaper } from '../../../component/shared/Ui';
import { FirstLevelProps, MultiLevelForm } from '../../../component/form/MultiLevelForm';
import { MultiLevelAutoComplete, MultiLevelBoolean, MultiLevelInput } from '../../../component/form/MultiLevelInput';
import Grid from '@mui/material/Grid';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const WkReportTypeListConfig: CmsColumnDef<WkReportType>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
  { header: 'Type', id: 'type', Filter: CmsTableFilter.Text },
  { header: "Code d'identification unique", id: 'uniq' },
];

/**
 * Liste des types de rapport d'intervention
 */
export const WkReportTypeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_REPORTTYPE_CREATE])
    ? [<AddNavigationButton title="Ajouter une ligne de rapport d'intervention" to="/castres/work/reporttype/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des types de rapport d'intervention"
      route={APIRoute.WkReportType}
      columns={WkReportTypeListConfig}
      navigateTo={(id: number) => `/castres/work/reporttype/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'un type de rapport d'intervention
 * @param id Id du type de rapport d'intervention
 */
export const WkReportTypeCreateEdit: FC = ({ id }: any) => {
  const props = { id, currentUrl: '/castres/work/reporttype/', route: APIRoute.WkReportType };
  return (
    <>
      <CmsForm {...props} title="un type de rapport d'intervention">
        <Grid container spacing={2}>
          <Grid item sm={12} lg={4} className="grid-child">
            <CmsFormInput.Text id="label" label="Nom" required max={50} />
            <CmsFormInput.Text id="code" label="Code" required max={20} />
            {!id && <CmsFormInput.Text id="uniq" label="Code d'identification unique" required min={3} max={30} />}
          </Grid>
          <Grid item sm={12} lg={4} className="grid-child">
            <CmsFormInput.Text id="type" label="Type" required max={4} />
            <CmsFormInput.Color id="color" label="Couleur" withoutSharp />
          </Grid>
          <Grid item sm={12} lg={4} className="grid-child">
            <CmsFormInput.Select
              id="domainIdList"
              label="Domaines"
              options={APIRoute.WkDomain + '/Simplified'}
              multiple
            />
            <CmsFormInput.Select
              id="campaignTypeIdList"
              label="Type de campagne"
              options={APIRoute.WkCampaignType + '/Simplified'}
              multiple
            />
          </Grid>
        </Grid>
      </CmsForm>
      <ReportCategoryPanel id={id} />
    </>
  );
};

const config: FirstLevelProps = {
  subListTitle: 'Liste des IBE catégories',
  attrToList: 'wkReportType',
  subItem: {
    subListTitle: 'Liste des IBE lignes',
    postRoute: APIRoute.WkReportCategory,
    attrToList: 'wkReportCategory',
    parentIdName: 'typeId',
    columnList: [
      {
        id: 'typeId',
        label: 'Type',
        FormComponent: MultiLevelAutoComplete,
        optionRoute: APIRoute.WkReportType + '/Simplified',
      },
      { id: 'name', label: 'Nom', FormComponent: MultiLevelInput },
    ],
    subItem: {
      attrToList: 'wkReportItem',
      postRoute: APIRoute.WkReportItem,
      parentIdName: 'categoryId',
      columnList: [
        {
          id: 'categoryId',
          label: 'Catégorie',
          FormComponent: MultiLevelAutoComplete,
          optionRoute: APIRoute.WkReportCategory + '/Simplified',
        },
        { id: 'name', label: 'Nom', FormComponent: MultiLevelInput },
        { id: 'description', label: 'Description', FormComponent: MultiLevelInput },
        { id: 'active', label: 'Active', FormComponent: MultiLevelBoolean, width: 100 },
      ],
    },
  },
};

const ReportCategoryPanel: FC<{ id: number }> = ({ id }) => {
  if (!id) return <></>;
  return (
    <CmsPaper title="Catégories">
      <MultiLevelForm id={id} getRoute={APIRoute.WkReportType} config={config} />
    </CmsPaper>
  );
};
