import React from 'react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Park: NavProps = {
  name: 'Parc',
  icon: <AccountTreeIcon />,
  subList: [
    {
      name: 'Sites',
      link: '/castres/client/station/list',
      feature: ROLE.ADMIN_PARK_STATION_LIST,
    },
    {
      name: 'Alarmes',
      link: '/castres/work/alarmalx/list',
      feature: ROLE.ADMIN_ALARMALX_LIST,
    },
    {
      name: 'Equipements',
      linkV1: 'castres/equipment/equipment/list',
      feature: ROLE.ADMIN_PARK_EQUIPMENT_LIST,
    },
    {
      name: 'Vignettes',
      linkV1: 'castres/equipment/sticker/list',
      feature: ROLE.ADMIN_PARK_STICKER_LIST,
    },
    {
      name: 'Lot de vignettes',
      linkV1: 'castres/equipment/stickerpack/list',
      feature: ROLE.ADMIN_PARK_STICKERPACK_LIST,
    },
    {
      name: 'Pistolets',
      linkV1: 'castres/equipment/pistol/list',
      feature: ROLE.ADMIN_CONFIGURATION_EQ_PISTOL_LIST,
    },
    {
      name: 'Suivi flexibles',
      linkV1: 'equipment/flexible/monitoring',
      feature: [
        ROLE.ADMIN_PARK_STATION_LIST,
        ROLE.ADMIN_PARK_EQUIPMENT_LIST,
        ROLE.ADMIN_PARK_STICKER_LIST,
        ROLE.ADMIN_PARK_STICKERPACK_LIST,
        ROLE.ADMIN_CONFIGURATION_EQ_PISTOL_LIST,
      ],
    },
    {
      name: 'Statistiques vignettes',
      linkV1: 'castres/equipment/sticker/statistic',
      feature: [
        ROLE.ADMIN_PARK_STATION_LIST,
        ROLE.ADMIN_PARK_EQUIPMENT_LIST,
        ROLE.ADMIN_PARK_STICKER_LIST,
        ROLE.ADMIN_PARK_STICKERPACK_LIST,
        ROLE.ADMIN_CONFIGURATION_EQ_PISTOL_LIST,
      ],
    },
    {
      name: 'Import Total',
      subList: [
        {
          name: 'Stations',
          link: '/castres/client/station/import',
          feature: [ROLE.ADMIN_PARK_STATION_MASTER],
        },
        {
          name: 'Equipements',
          link: '/castres/equipment/equipment/import',
          feature: [ROLE.ADMIN_PARK_EQUIPMENT_MASTER],
        },
      ],
    },
  ],
};
