import { useLocation } from 'react-router-dom';

/**
 * Hook Personnalisé pour génerer automatiquement les attribut présent dans l'url dans un objet
 */
export function useLocationToGetParams() {
  const location = useLocation();
  const result: any = {};
  new URLSearchParams(location.search).forEach((value, key) => (result[key] = value));
  return result;
}
