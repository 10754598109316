import { ImportFile, ImpEquResult, ImpStaResult, ImpStation, ImpEquAddition } from 'interface/ImportType';
import { API_URL } from '../constant/API_URL';
import { fetchWithAuth } from '../helper/handle-request';
import { handleJsonResponse, handleBlobResponse } from '../helper/handle-response';

// Coder au début de projet, possible refacto

/**
 * Récupère les informations d'un fichier d'import
 * @param category catégorie de l'import (station, equipement, ...)
 * @param id id du fichier d'import
 */
export const getImportInfos = (category: string, id: string | number): Promise<ImportFile> => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}/${id}`,
  }).then(handleJsonResponse);
};

/**
 * Récupère les nouveaux fichiers d'import
 * @param category catégorie de l'import (station, equipement, ...)
 */
export const getNewImportFiles = (category: string): Promise<ImportFile[]> => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}?imported=false`,
  }).then(handleJsonResponse);
};

/**
 * Récupère les fichiers d'import récents
 * @param category catégorie de l'import (station, equipement, ...)
 * @param limit nombre maximum de fichiers à récupérer
 */
export const getRecentImportedFiles = (category: string, limit = 5): Promise<ImportFile[]> => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}?imported=true&limit=${limit}`,
  }).then(handleJsonResponse);
};

/**
 * Récupère tous les fichiers d'import
 * @param category catégorie de l'import (station, equipement, ...)
 */
export const getAllImportedFiles = (category: string): Promise<ImportFile[]> => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}?imported=true`,
  }).then(handleJsonResponse);
};

/**
 * Exécute l'import des équipements (d'un fichier)
 * @param id id du fichier d'import
 */
export const executeEquipementImport = (id: string | number): Promise<ImpEquResult> => {
  return fetchWithAuth({
    url: `${API_URL}import/equipment/${id}`,
    method: 'POST',
  }).then(handleJsonResponse);
};

/**
 * Exécute l'import des stations (d'un fichier)
 * @param id id du fichier d'import
 */
export const executeStationImport = (id: string | number): Promise<ImpStaResult> => {
  return fetchWithAuth({
    url: `${API_URL}import/station/${id}`,
    method: 'POST',
  }).then(handleJsonResponse);
};

/**
 * Télécharge le fichier d'import
 * @param category catégorie de l'import (station, equipement, ...)
 * @param id id du fichier d'import
 */
export const downloadImportFile = (category: string, id: string | number): Promise<Blob> => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}/${id}/download-file`,
  }).then(handleBlobResponse);
};

/**
 * Effectue l'ajout d'un équipement/station
 * @param category catégorie de l'import (station, equipement, ...)
 * @param id id du fichier d'import
 * @param refs références de l'objet à ajouter
 */
export const confirmAddition = (category: string, id: string | number, refs: string[]) => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}/${id}/add`,
    method: 'POST',
    body: JSON.stringify(refs),
    headers: { 'Content-Type': 'application/json' },
  });
};

/**
 * Effectue la mise à jour d'un équipement/station
 * @param category catégorie de l'import (station, equipement, ...)
 * @param id id du fichier d'import
 * @param refs références de l'objet à mettre à jour
 */
export const confirmUpdate = (category: string, id: string | number, refs: string[]) => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}/${id}/update`,
    method: 'PUT',
    body: JSON.stringify(refs),
    headers: { 'Content-Type': 'application/json' },
  });
};

/**
 * Effectue la correspondance d'un équipement/station
 * @param category catégorie de l'import (station, equipement, ...)
 * @param id id du fichier d'import
 * @param idItem id de l'objet à matcher
 * @param ref référence de l'objet à matcher
 */
export const confirmMatch = (category: string, id: string | number, idItem: string | number, ref: string) => {
  return fetchWithAuth({
    url: `${API_URL}import/${category}/${id}/match/${idItem}`,
    method: 'PUT',
    body: JSON.stringify(ref),
    headers: { 'Content-Type': 'application/json' },
  });
};

/**
 * Effectue la désactivation d'un équipement
 * @param ids ids des équipements à désactiver
 */
export const confirmEquDeactivation = (ids: number[]) => {
  return fetchWithAuth({
    url: `${API_URL}import/equipment/deactivate`,
    method: 'DELETE',
    body: JSON.stringify(ids),
    headers: { 'Content-Type': 'application/json' },
  });
};

/**
 * Récupère les correspondances possibles pour un équipement
 * @param idStation id de la station de l'équipement
 * @param codeType code du type d'équipement
 */
export const getEqPossibleMatchs = (idStation: string | number, codeType: string): Promise<ImpEquAddition[]> => {
  return fetchWithAuth({
    url: `${API_URL}EqEquipment/possibleMatch/${idStation}?eqTypeCode=${codeType}`,
  }).then(handleJsonResponse);
};

/**
 * Récupère les correspondances possibles pour une station
 * @param departmentCode code du département de la station
 */
export const getStaPossibleMatchs = (departmentCode: string): Promise<ImpStation[]> => {
  return fetchWithAuth({
    url: `${API_URL}ClStation/possibleMatch/${departmentCode}`,
  }).then(handleJsonResponse);
};
