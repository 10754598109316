import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { CmsButton } from '../shared/Ui';
import { InputUI, UI } from '../shared';
import { CmsColumnDef, CmsFrontendTable } from './CmsTable';
import CRUD from '../../service/CRUD.service';
import { Cell } from '@tanstack/react-table';
import APIRoute from '../../constant/API.constant';
import { Breakpoint } from '@mui/system';
import CmsTableFilter from './helper/CmsTableFilter';
import LoadingScreen from '../LoadingScreen';
import notificationService from '../../service/NotificationService';
import Utils from '../../helper/Utils';

interface JoinTableHandlerProps {
  originId: string;
  buttonLabel: string;
  tableTitle?: string;
  route: APIRoute;
  originType: APIRoute;
  columns: any;
  onGetData?: (data: any[]) => any[];
  maxModalWidth?: Breakpoint | false;
  onUpdate?: () => void;
  actions?: ReactNode[];
}

export const JoinTableHandler: FC<JoinTableHandlerProps> = ({
  originId,
  originType,
  columns,
  buttonLabel,
  tableTitle,
  route,
  onGetData = (x: any[]) => x as any[],
  maxModalWidth = 'xl',
  onUpdate,
  actions = [],
}) => {
  const [firstRender, setFirstRender] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<any[]>([]);
  const [isSending, setIsSending] = React.useState(false);

  useEffect(() => {
    if (!open || !firstRender) return;
    setFirstRender(false);
    CRUD.getList<any>(`${route}/ManyToMany/${originType}/${originId}`).then((x) => {
      setState(Utils.orderListByAttr(onGetData(x), 'selected', true));
    });
  }, [firstRender, onGetData, open, originId, originType, route]);

  const col: CmsColumnDef<any>[] = useMemo(() => {
    const cell = (info: any) => <InputUI.CmsSwitch value={info.getValue() ?? false} onChange={() => {}} />;
    return [{ header: 'Sélection', id: 'selected', size: 0, cell, Filter: CmsTableFilter.Bool }, ...columns] as any[];
  }, [columns]);

  const handleCellClick = (cell: Cell<any, any>) => {
    const id = cell.row.original.id;
    const item = state.find((x) => x.id === id);
    item.selected = !item.selected;
    setState([...state]);
  };

  const handleSave = () => {
    setIsSending(true);
    const payload = { id: originId, list: state.filter((x) => x.selected).map((x) => x.id) };
    CRUD.put<any>(`${originType}/ManyToMany/${route}`, payload)
      .then(() => {
        notificationService.success('Mise à jour effectuée');
        setOpen(false);
        if (onUpdate) onUpdate();
      })
      .finally(() => setIsSending(false));
  };
  const button = <CmsButton onClick={() => setOpen(true)}>{buttonLabel}</CmsButton>;
  if (firstRender) return button;
  if (!firstRender && state.length === 0) return [button, <LoadingScreen fullScreen />];
  return (
    <>
      {button}
      <UI.Dialog fullWidth maxWidth={maxModalWidth} open={open} onClose={() => setOpen(false)}>
        <CmsFrontendTable
          paperStyle={{ marginBottom: 0 }}
          actions={[
            ...actions,
            <CmsButton color="inherit" onClick={() => setOpen(false)}>
              Abandonner
            </CmsButton>,
            <CmsButton disabled={isSending} onClick={handleSave}>
              Mettre à jour
            </CmsButton>,
          ]}
          onCellClick={handleCellClick}
          columns={col}
          title={tableTitle ?? buttonLabel}
          initialPageSize={10}
          route="none"
          controlledState={{ state, setState }}
          disableResetPageIndex
        />
      </UI.Dialog>
    </>
  );
};
