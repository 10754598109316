import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { WkReportCategory } from '../../../interface/WkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const WkReportCategoryListConfig: CmsColumnDef<WkReportCategory>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Type', id: 'typeLabel', Filter: CmsTableFilter.Text },
];

/**
 * Liste des catégories de rapport d'intervention
 */
export const WkReportCategoryList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_REPORTCATEGORY_CREATE])
    ? [
        <AddNavigationButton
          title="Ajouter une catégorie de rapport d'intervention"
          to="/castres/work/reportcategory/create"
        />,
      ]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des catégories de rapport d'intervention"
      route={APIRoute.WkReportCategory}
      columns={WkReportCategoryListConfig}
      navigateTo={(id: number) => `/castres/work/reportcategory/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou modification d'une catégorie de rapport d'intervention
 * @param id Id de la catégorie de rapport d'intervention
 * @param setHttpCodePage Fonction pour modifier le code de la page
 */
export const WkReportCategoryCreateEdit: FC = ({ id }: any) => {
  const props = { id, currentUrl: '/castres/work/reportcategory/', route: APIRoute.WkReportCategory };
  return (
    <CmsForm {...props} title="une catégorie de rapport d'intervention" thin>
      <CmsFormInput.Text id="name" label="Nom" required max={50} />
      <CmsFormInput.Select id="typeId" label="Type de rapport" options={APIRoute.WkReportType + '/Simplified'} />
    </CmsForm>
  );
};
