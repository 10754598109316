import React, { FC } from 'react';
import { CmsButton, CmsDivider, CmsPaper } from '../../component/shared/Ui';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { useFormContext } from 'react-hook-form';
import FileService from '../../service/FileService';
import { ViewNavigationButton } from '../../component/shared/Buttons';
import { setToKebabDate } from '../../helper/Utils';
import { IdLabel } from '../../interface/CommonType';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';

interface TimeReportingInterventionReportProps {
  // Date de début
  beginDate: Date;
  // Date de fin
  endDate: Date;
  // Agence / service / tous, dont un veut le rapport de feuille de temps
  scope: number;
  // Liste des services
  serviceList: Array<number>;
  // Liste des agences
  agencyList: Array<number>;
}

const scopeOptions: Array<IdLabel> = [
  { id: 0, label: 'Tous' },
  { id: 1, label: "liste d'agences" },
  { id: 2, label: 'liste de services' },
];

/**
 * Page de génération des rapports de feuille de temps par intervention, attention, à ne pas confondre
 * avec le rapport de feuille de temps par technicien
 */
export const TimeReportingInterventionReport: FC = () => {
  const onSubmit = (data: TimeReportingInterventionReportProps) => {
    if (data.scope === 1) data.serviceList = [];
    if (data.scope === 2) data.agencyList = [];
    const begin = setToKebabDate(data.beginDate);
    const end = setToKebabDate(data.endDate);
    const fileName = `rapport-intervention-du-${begin}-au-${end}.xlsx`;
    return CRUD.getBlob(APIRoute.WkTimeReportingWeek + '/InterventionReport', data).then((blob) =>
      FileService.saveBlob(blob, fileName),
    );
  };
  const paperProps = { title: 'Génération de rapport par intervention', style: { maxWidth: '600px' } };
  const defaultValues = { beginDate: new Date(), endDate: new Date(), scope: 0, agencyList: [], serviceList: [] };
  const actions = [<ViewNavigationButton title="Administration" to={`/castres/timereporting/administration`} />];
  return (
    <CmsForm currentUrl="" route="" onSubmit={onSubmit} defaultValues={defaultValues}>
      <CmsPaper {...paperProps} actions={actions}>
        <CmsFormInput.Date id="beginDate" label="Du" required />
        <CmsFormInput.Date id="endDate" label="À" required />
        <CmsFormInput.Select id="scope" label="Scope" options={scopeOptions} required />
        <CmsFormInput.Select
          id="agencyList"
          label="Agences"
          options={APIRoute.ClAgency + '/Simplified'}
          hideIf={(x) => x.scope !== 1}
          required
          multiple
        />
        <CmsFormInput.Select
          id="serviceList"
          label="Services"
          options={APIRoute.SfService + '/Simplified'}
          hideIf={(x) => x.scope !== 2}
          required
          multiple
        />
        <CmsDivider />
        <SubmitButton />
      </CmsPaper>
    </CmsForm>
  );
};

const SubmitButton: FC = () => {
  const { formState } = useFormContext();
  return (
    <CmsButton disabled={!formState.isValid} type="submit">
      Générer le rapport
    </CmsButton>
  );
};
