import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../constant/API.constant';
import AccessFilter from '../../helper/AccessFilter';
import { AddNavigationButton, ArrowBackNavigationButton, ListNavigationButton } from '../../component/shared/Buttons';
import { Buttons, InputUI, UI } from '../../component/shared';
import { IdLabel, IdLabelValue } from '../../interface/CommonType';
import CRUD from '../../service/CRUD.service';
import { useNavigate } from 'react-router-dom';
import {
  ContextOptions,
  Scheduler,
  SchedulerData,
  SchedulerDialogProps,
  SchedulerFilter,
} from '../../component/scheduler/Scheduler';
import { SfAbsence } from '../../interface/SfType';
import Utils from '../../helper/Utils';
import { OpenInNewIconButton, WarningBubble } from '../../component/shared/Ui';
import NotificationService from '../../service/NotificationService';
import { WkTask } from '../../interface/WkType';
import { Grid } from '@mui/material';
import './rh.scss';
import { BASE_URL, ENV_ISDEV } from '../../constant/API_URL';
import { LabelValueIf, TabStyleDataView } from '../../component/shared/TabStyleDataView';
import CmsIcon from '../../component/shared/CmsIcon';
import { authenticationService } from '../../service/Authentication.service';
import { Popper } from '../../component/scheduler/Poppers';
import LoadingScreen from '../../component/LoadingScreen';
import { GCElement, GlobalContext, GlobalPlanningType } from '../../context/Global.context';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../component/shared/shared.scss';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter, { CmsTableFilterType } from '../../component/table/helper/CmsTableFilter';
import CmsFilterCalculator from '../../component/table/helper/CmsTableFilterCalculator';
import { CellContext } from '@tanstack/react-table';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../constant/role.constant';

//#region List

export const sfAbsenceConfigList: CmsColumnDef<SfAbsence>[] = [
  {
    header: 'Utilisateur',
    id: 'user',
  },
  {
    header: 'Utilisateur',
    id: 'userId',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: APIRoute.UsUsers + '/Simplified?roleFilter=SfAbsence',
      rawEndpoint: true,
    },
    hide: true,
  },
  {
    header: 'Service',
    id: 'serviceName',
  },
  {
    header: 'Service',
    id: 'serviceId',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: {
      multiple: true,
      optionList: APIRoute.SfService + '/Simplified?from=SfAbsence',
      rawEndpoint: true,
    },
  },
  { header: 'Type', id: 'type' },
  {
    header: 'Statut',
    id: 'statusName',
    cell: (x) => <ColoredStatus status={x.getValue()} />,
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
    defaultFilterValue: ['En attente', 'En attente validation RH'],
  },
  { header: 'Date de début', id: 'startDate', Filter: CmsTableFilter.Date },
  { header: 'Date de fin', id: 'endDate', Filter: CmsTableFilter.Date },
  { header: 'Date de la demande', id: 'requestDate', Filter: CmsTableFilter.Date },
  { header: 'Date validation manager', id: 'grantedManagerDate', Filter: CmsTableFilter.Date },
  {
    header: 'Validé par manager',
    id: 'grantedManagerName',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
  },
  { header: 'Date validation finale RH', id: 'grantedDate', Filter: CmsTableFilter.Date },
  { header: 'Validé par', id: 'grantedByName', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  {
    header: 'Type',
    id: 'typeId',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true, optionList: APIRoute.SfAbsenceType },
  },
  {
    header: 'Entité juridique',
    id: 'legalEntityName',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
    hide: true,
  },
  {
    header: 'Utilisateur actif',
    id: 'userEnabled',
    Filter: CmsTableFilter.Bool,
    hide: true,
    defaultFilterValue: true,
  },
  {
    header: 'Subordonné(s)',
    id: 'subordinateLevel',
    hide: true,
    defaultFilterValue: 1,
    Filter: CmsTableFilter.Select,
    filterFn: CmsFilterCalculator.lessOrEqual,
    filterOptions: {
      optionList: [
        { id: 1, label: 'Subordonné(s) direct(s)' },
        { id: 2, label: 'Tous les subordonnés' },
      ],
    },
  },
  {
    header: 'Action',
    id: 'id',
    cell: (x) => (
      <div className="flex-h">
        <DialogTableValidation cell={x} />
        {!!x?.row?.original?.comment && <CmsIcon icon="description" tooltip={x?.row?.original?.comment} />}
        {!!x?.row?.original?.refusedComment && (
          <CmsIcon icon="info" tooltip={'Commentaire de refus: ' + x?.row?.original?.refusedComment} />
        )}
      </div>
    ),
  },
];

/**
 * Filtre les colonnes de la liste en fonction de l'utilisateur connecté
 * @param from page d'où est appelée la fonction
 */
export const filterPlanningConf = (from?: string): CmsColumnDef<SfAbsence>[] => {
  const user = authenticationService.currentUserValue;
  if (!Array.isArray(user?.subordinate) || user.subordinate.length === 0)
    return sfAbsenceConfigList.filter((x) => x.id !== 'subordinateLevel');
  const userPageConf = ['subordinateLevel', 'userEnabled', 'user', 'userId', 'serviceId', 'serviceName'];
  if (from === 'userPage') return sfAbsenceConfigList.filter((x) => !userPageConf.includes(x.id));
  return sfAbsenceConfigList;
};

/**
 * Modales de validation/refus d'une absence depuis la liste (ReactTable), elle affiche également le bouton
 * dans la cellule de la colonne action
 * @param cols colonnes de la ligne
 */
const DialogTableValidation: FC<{ cell: CellContext<SfAbsence, any> }> = ({ cell }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [absence, setAbsence] = useState<SfAbsence>();
  const { setGlobalContextList, setGlobalPlanning } = useContext(GlobalContext);
  const id = cell.getValue();
  const status = cell.row.original.status;

  const getAbsence = () => {
    CRUD.getById<SfAbsence>(APIRoute.SfAbsence + '/WithData', id).then((result) => {
      setAbsence(result);
      setIsOpen(true);
    });
  };

  const edit = (
    <OpenInNewIconButton
      style={{ marginBottom: 0 }}
      title="Éditer une absence"
      href={`/castres/staff/absence/${id}/edit`}
    />
  );
  const valid = <CmsIcon icon="OK" tooltip="Validation/Refus" onClick={getAbsence} />;

  if (status && status > 1) return edit;
  if (!absence)
    return (
      <div className="flex-h align-center">
        {edit}
        {valid}
      </div>
    );

  const handleValid = () => {
    CRUD.put<SfAbsence>(APIRoute.SfAbsence + '/Valid', absence)
      .then(() => {
        NotificationService.success('Absence validée');
        setGlobalContextList({ target: GCElement.ABSENCE, configList: filterPlanningConf() });
        setGlobalPlanning({ target: GlobalPlanningType.ABSENCE_PLANNING, refresh: true });
      })
      .finally(() => setIsOpen(false));
  };
  const handleRefuse = () => {
    if (!absence.refusedComment) {
      NotificationService.error('Vous devez commenter un motif de refus');
      return;
    }
    CRUD.put<SfAbsence>(APIRoute.SfAbsence + '/Refuse', absence)
      .then(() => {
        NotificationService.warning('Absence refusée');
        setGlobalContextList({ target: GCElement.ABSENCE, configList: filterPlanningConf() });
        setGlobalPlanning({ target: GlobalPlanningType.ABSENCE_PLANNING, refresh: true });
      })
      .finally(() => setIsOpen(false));
  };

  return (
    <>
      {edit}
      {valid}
      <UI.Dialog open={isOpen} onClose={() => setIsOpen(false)} title="Validation/Refus">
        <UI.Paper
          title={absence.type}
          className="absence-planning-modal"
          actions={[<CmsIcon icon="close" onClick={() => setIsOpen(false)} />]}
        >
          <Grid container spacing={2} style={{ width: '800px' }}>
            <Grid item xs={12} lg={8}>
              <h2>Validation</h2>
              <TabStyleDataView conf={absenceDialogConf} mapFrom={absence} gridTemplateColumns="1fr 1fr" />
              {!!absence.taskCollision && (
                <WarningBubble type="warn">Collision avec tâche detecté, faites attention.</WarningBubble>
              )}
              {!!absence.assignmentCollision && (
                <WarningBubble type="warn">
                  Collision avec une assignation d'équipe détecté, valider la demande d'absence désassignera le
                  technicien sur la période de l'absence
                </WarningBubble>
              )}
              <div className="flex-h center valid-absence">
                <Buttons.Valid onClick={handleValid}> Accepter </Buttons.Valid>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <UI.FormField>
                <h2>Refus</h2>
                <UI.Label label={'Date de substitution'} />
                <InputUI.DatePickerVanilla
                  value={absence.refusedDateProposal}
                  onChange={(x: Date) => setAbsence({ ...absence, refusedDateProposal: x })}
                />
              </UI.FormField>
              <UI.FormField>
                <UI.Label label={'Commentaire de refus (obligatoire)'} />
                <InputUI.DebouncedInput
                  multiline
                  value={absence.refusedComment}
                  onChange={(x: string) => setAbsence({ ...absence, refusedComment: x })}
                />
              </UI.FormField>
              <div className="flex-h center">
                <Buttons.Cancel onClick={handleRefuse}>Refuser</Buttons.Cancel>
              </div>
            </Grid>
          </Grid>
        </UI.Paper>
      </UI.Dialog>
    </>
  );
};

/**
 * Page de la liste des absences (Show).
 * On y affiche le planning et le tableau des absences pour avoir deux types d'affichage
 */
export const SfAbsenceList: FC = () => {
  const [filters, setFilters] = useState<Array<any>>([]);
  const [filtersBackup, setFiltersBackup] = useState<Array<any>>([]);
  const [syncTable, setSyncTable] = useState(false);

  const handleSync = (planningFilters: any[]) => {
    setFiltersBackup(planningFilters);
    syncTable && setFilters(planningFilters);
  };

  const handleSyncTable = () => {
    !syncTable && setFilters(filtersBackup);
    setSyncTable(!syncTable);
  };

  const actions = [
    <Buttons.Default
      startIcon={<RefreshIcon className={syncTable ? 'icon-rotate' : ''} />}
      color={syncTable ? 'primary' : undefined}
      onClick={handleSyncTable}
    >
      {'Synchronis' + (syncTable ? 'é' : 'er') + ' avec les filtres du planning'}
    </Buttons.Default>,
  ];
  if (ENV_ISDEV && AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_EXPORT]))
    actions.push(<ListNavigationButton title="Export Sage" to="/castres/staff/absence/export" />);
  if (AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_CREATE]))
    actions.push(<AddNavigationButton title="Ajouter une absence" to="/castres/staff/absence/create" />);
  return (
    <div className="absence-pages">
      <AbsencePlanning setFilters={handleSync} />
      <CmsFrontendTable
        title="Liste des absences"
        route={APIRoute.SfAbsence}
        globalTable={{ target: GCElement.ABSENCE, configList: filterPlanningConf() }}
        controlledFilter={{ filterList: filters, setFilterList: setFilters }}
        initialSorting={{ id: 'requestDate', desc: false }}
        downloadable={AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_EXPORT]) ? 'csv&excel' : undefined}
        actions={actions}
        columns={filterPlanningConf()}
        setFiltersInUrl
        autoRefresh
      />
    </div>
  );
};

//#endregion

//#region Planning

const initialFilters: Array<SchedulerFilter> = [
  {
    title: 'Date de début',
    accessor: 'minDate',
    value: new Date(),
    isBackendFilter: true,
    isStartFilter: true,
    filter: 'date',
  },
  {
    title: 'Date de fin',
    accessor: 'maxDate',
    value: Utils.Date.addDays(new Date(), 30),
    isBackendFilter: true,
    isEndFilter: true,
    filter: 'date',
  },
  {
    title: 'Utilisateur',
    accessor: 'userId',
    filter: 'number',
    FilterComponent: ({ filter, setFilter }) => {
      const [options, setOptions] = useState<IdLabel[]>([]);
      useEffect(() => {
        CRUD.getSimpleList(APIRoute.UsUsers, '?roleFilter=SfAbsence').then(setOptions);
      }, [setOptions]);
      return (
        <InputUI.AutoCompletor options={options ?? []} value={filter.value} onChange={setFilter} label="Utilisateur" />
      );
    },
    isBackendFilter: true,
  },
  {
    title: 'Service',
    accessor: 'serviceId',
    filter: 'AutoMultiselect',
    isBackendFilter: true,
    FilterComponent: ({ filter, setFilter }) => {
      const [options, setOptions] = useState<IdLabel[]>([]);
      useEffect(() => {
        CRUD.getSimpleList(APIRoute.SfService, '?from=SfAbsence').then(setOptions);
      }, [setOptions]);
      return (
        <InputUI.AutoCompletor
          options={options ?? []}
          value={filter.value}
          onChange={setFilter}
          label="Agence - Service"
          multiple
        />
      );
    },
  },
  {
    title: 'Statut',
    accessor: 'status',
    filter: 'AutoMultiselect',
    isBackendFilter: true,
    FilterComponent: ({ filter, setFilter }) => {
      return (
        <InputUI.AutoCompletor
          options={[
            { id: 0, label: 'En attente' },
            { id: 1, label: 'En attente validation RH' },
            { id: 2, label: 'Validé' },
          ]}
          value={filter.value}
          onChange={setFilter}
          label="Statut"
          multiple
        />
      );
    },
  },
  {
    title: 'Son équipe uniquement',
    accessor: 'subordinateLevel',
    filter: 'number',
    isBackendFilter: true,
    value: 1,
    FilterComponent: ({ filter, setFilter }) => {
      return (
        <InputUI.AutoCompletor
          options={[
            { id: 1, label: 'Subordonné(s) direct(s)' },
            { id: 2, label: 'Tous les subordonnés' },
          ]}
          value={filter.value}
          onChange={setFilter}
          label="Subordonné(s)"
        />
      );
    },
  },
];

/**
 * Planning des absences, composant scheduler utilisé pour générer le planning
 * @param setFilters Fonction permettant de récupérer les filtres du planning
 */
const AbsencePlanning: FC<{ setFilters: any }> = ({ setFilters }) => {
  const navigate = useNavigate();
  const canInvalidate = AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_VALIDATION]);
  const { setGlobalContextList } = useContext(GlobalContext);
  const finalFilters: SchedulerFilter[] = useMemo(() => {
    const user = authenticationService.currentUserValue;
    if (Array.isArray(user?.subordinate) && user.subordinate.length > 0) return initialFilters;
    return initialFilters.filter((x) => x.accessor !== 'subordinateLevel');
  }, []);

  const handleDevalidate = (x: any): void => {
    CRUD.put<SfAbsence>(APIRoute.SfAbsence + '/Invalidate', { id: x.item.id }).then((result) => {
      NotificationService.warning('Absence dévalidé');
      x.item.value = { ...x.item.value, status: result.status };
      !!x.updateItem && x.updateItem({ ...x.item, color: absenceStatusColor(result.statusName) });
      setGlobalContextList({ target: GCElement.ABSENCE, configList: filterPlanningConf() });
    });
  };

  const contextAbsence: ContextOptions[] = [
    {
      label: 'Editer',
      if: (item) => item.type === 'absence' && item.value.status < 2,
      onClick: (x) => navigate(`/castres/staff/absence/${x?.item?.id}/edit`),
    },
    {
      label: "Voir l'intervention",
      if: (item) => item.type === 'task',
      onClick: (x) => {
        const url = `${BASE_URL}castres/work/intervention/${x.item?.value?.interventionId}/show`;
        if (x.isMiddleClick) Utils.openInNewTab(url);
        else window.location.href = url;
      },
    },
    {
      label: 'Dévalider',
      if: (item: any) =>
        item.type === 'absence' &&
        item.value.status === 2 &&
        canInvalidate &&
        !item.value.exported &&
        !item.value.isTimeReported,
      onClick: handleDevalidate,
    },
    {
      label: 'Dévalider (ATTENTION) Validé dans la feuille de temps',
      if: (item: any) => item.type === 'absence' && item.value.isTimeReported && canInvalidate && !item.value.exported,
      onClick: handleDevalidate,
    },
    {
      label: "Dévalider (ATTENTION) export de l'absence déjà effectué",
      if: (item: any) => item.type === 'absence' && item.value.status === 2 && canInvalidate && !!item.value.exported,
      onClick: handleDevalidate,
    },
    {
      label: "Voir l'utilisateur",
      isGroupClick: true,
      onClick: (x) => {
        const link = `sonata/user/user/${x.group?.id}/show`;
        if (x.isMiddleClick) Utils.openInNewTab(link);
        else navigate('/' + link);
      },
    },
  ];

  const actions = [];
  if (AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_CREATE]))
    actions.push(<AddNavigationButton title="Ajouter une absence" to="/castres/staff/absence/create" />);
  return (
    <Scheduler
      globalScheduler={{ target: GlobalPlanningType.ABSENCE_PLANNING }}
      actions={[
        ...actions,
        <StatusLegend />,
        <CmsIcon icon="info" href="/help/Absences/validation" tooltip="Aide Validation des absences" target="_blank" />,
      ]}
      columnTitle="Utilisateur(s)"
      paperTitle="Planning des Absences"
      initialFilters={finalFilters}
      handleFiltering={handleFiltering}
      onFiltersChange={(x) => onFiltersChanges(x, setFilters)}
      fetch={[
        {
          url: `${APIRoute.SfAbsence}`,
          formatter: formatAbsenceForPlanning,
          type: 'task,absence',
          itemPopper: Popper.AbsenceAndTaskPopper,
          contextOptionList: contextAbsence,
        },
      ]}
      itemDialog={DialogSchedulerAbsence}
      setFilterInUrl
    />
  );
};

/**
 * Gestion des filtres du planning ainsi que la condition d'une journée minimum à afficher
 * @param filterList Liste des filtres
 */
function handleFiltering(filterList: SchedulerFilter[]): SchedulerFilter[] {
  const findIn = findInAccessor(
    filterList,
    'accessor',
    'minDate',
    'maxDate',
    'serviceId',
    'userId',
    'subordinateLevel',
  );
  const { minDate, maxDate, serviceId, userId, subordinateLevel } = findIn;
  const serviceHaveValue = Array.isArray(serviceId?.value) && serviceId.value.length > 0;
  let maxDayRange = !!userId?.value ? 1000000 : serviceHaveValue || subordinateLevel?.value === 1 ? 125 : 40;
  const minTime = new Date(minDate.value).getTime();
  const maxTime = new Date(maxDate.value).getTime();
  if (maxTime - minTime < maxDayRange * 86400000) return filterList;
  if (maxDate.isUpdating) minDate.value = new Date(maxTime - maxDayRange * 86400000);
  else maxDate.value = new Date(minTime + maxDayRange * 86400000);
  return filterList;
}

/**
 * Traduit les filtres de planning pour le reactTable permettant ainsi de synchroniser les filtres
 * @param filterList Liste des filtres du planning
 * @param setFilters Fonction permettant de mettre à jour les filtres du reactTable
 */
function onFiltersChanges(filterList: SchedulerFilter[], setFilters: any): void {
  const findIn = findInAccessor(
    filterList,
    'accessor',
    'minDate',
    'maxDate',
    'serviceId',
    'userId',
    'subordinateLevel',
    'status',
  );
  const { minDate, maxDate, serviceId, userId, subordinateLevel, status } = findIn;
  const idList = !serviceId?.value || serviceId.value.length === 0 ? null : serviceId.value;
  const newFilters: any[] = [
    { id: 'startDate', value: { value: new Date(minDate.value), type: CmsTableFilterType.DateGreater } },
    { id: 'endDate', value: { value: new Date(maxDate.value), type: CmsTableFilterType.DateLess } },
  ];
  if (idList) newFilters.push({ id: 'serviceId', value: idList });
  if (!!userId?.value) newFilters.push({ id: 'userId', value: [userId.value] });
  if (!!status?.value) newFilters.push({ id: 'status', value: status.value });
  if (!!subordinateLevel) newFilters.push({ id: 'subordinateLevel', value: subordinateLevel.value });
  setFilters(newFilters);
}

/**
 * Permet de récupérer un objet dans un tableau d'objet filtré par un attribut accesseur et une valeur paramètre
 * @param list Liste d'objet
 * @param attr Attribut accesseur
 * @param params Liste des valeurs paramètres
 */
function findInAccessor(list: any[], attr: string, ...params: string[]) {
  const result: any = {};
  for (let param of params) result[param] = list.find((x) => x[attr] === param);
  return result;
}

/**
 * Légende des couleurs des absences
 */
const StatusLegend: FC = () => {
  return (
    <div className="flex-h item-center">
      <span>Légendes couleurs:</span>
      <UI.WarningBubble customColor={absenceStatusColor('En attente')}>En attente</UI.WarningBubble>
      <UI.WarningBubble customColor={absenceStatusColor('En attente validation RH')}>En attente RH</UI.WarningBubble>
      <UI.WarningBubble customColor={absenceStatusColor('Validée')}>Validé</UI.WarningBubble>
      <HelpPopper />
    </div>
  );
};

const legendList = [
  { label: 'Ctrl + molette souris', value: 'Zoom temporel' },
  { label: 'Maj (Shift) + molette souris', value: 'Ascenseur horizontal temporel' },
  { label: 'Molette souris', value: 'Ascenseur vertical' },
  { label: 'Double click sur une absence', value: 'Modal de validation / refus' },
  { label: 'Double click sur une tâche', value: 'Rien' },
  { label: 'Click droit', value: 'Menu contextuel' },
  {
    label: 'Rafraîchir les données',
    value:
      'Si vous avez décalé la plage temporelle dans le planning, rafraîchir les ' +
      'données appliquera le décalage sur les filtres des dates',
  },
];

/**
 * Popper d'aide pour les raccourcis
 */
const HelpPopper: FC = () => {
  const [pop, setPop] = useState(false);

  return (
    <>
      <CmsIcon icon="help" onClick={() => setPop(true)} tooltip="Aide pour les raccourcis" />
      <UI.Dialog open={pop} onClose={() => setPop(false)}>
        <UI.Paper style={{ marginBottom: 0 }} title="Aide">
          <TabStyleDataView conf={legendList} />
        </UI.Paper>
      </UI.Dialog>
    </>
  );
};

/**
 * Permet de formatter les données des tâches pour le planning (affiché si sont en collision avec une absence)
 * @param taskList Liste des tâches
 * @param userId Id de l'utilisateur
 */
function formatWkTaskForPlanning(taskList: WkTask[], userId: number): any[] {
  if (!taskList || taskList.length < 1) return [];
  const items: any[] = [];
  for (let wkTask of taskList) {
    items.push({
      id: `${userId}-${wkTask.id}`,
      group: userId,
      title: wkTask.interventionName,
      color: '#888888',
      start_time: new Date(wkTask.startDate ?? ''),
      end_time: new Date(wkTask.endDate ?? ''),
      value: wkTask,
      type: 'task',
    });
  }
  return items;
}

/**
 * Permet de formatter les données des absences pour le planning
 * @param absenceList Liste des absences
 */
function formatAbsenceForPlanning(absenceList: SfAbsence[]): SchedulerData {
  if (!absenceList || absenceList.length < 1) return { groups: [], items: [] };
  let groups: Array<any> = [];
  let items: any[] = [];
  for (let absence of absenceList) {
    if (!groups.find((x: any) => x.id === absence.userId))
      groups.push({ id: absence.userId, title: absence.user, stackItems: true, type: 'absence' });
    items.push({
      id: absence.id,
      group: absence.userId,
      title: absence.type,
      color: absenceStatusColor(absence.statusName),
      start_time: new Date(absence.startDate),
      end_time: new Date(absence.endDate),
      value: absence,
      type: 'absence',
    });
    if (!absence.taskList) continue;
    const taskPlanning = formatWkTaskForPlanning(absence.taskList, absence.userId ?? 0);
    items = [...items, ...taskPlanning];
  }
  return { groups: Utils.orderListByAttr(groups, 'title'), items };
}

/**
 * Couleur des absences
 * @param s Statut de l'absence
 */
const absenceStatusColor = (status?: string): string => statusList.find((x) => x.label === status)?.color ?? '#000000';
const statusList = [
  { label: 'En attente', color: '#ffff00' },
  { label: 'En attente validation RH', color: '#08769d' },
  { label: 'Validée', color: '#449a1f' },
  { label: 'Refusée', color: '#ba1212' },
  { label: 'Validé et exporté', color: '#296b0d' },
];

/**
 * Permet de colorer le statut d'une absence
 * @param status Statut de l'absence
 */
export const ColoredStatus: FC<{ status?: string }> = ({ status = '' }) => {
  const color = absenceStatusColor(status);
  return (
    <span
      className="colored-cell"
      style={{ backgroundColor: color, color: Utils.isHexColorDark(color) ? 'white' : 'black' }}
    >
      {status}
    </span>
  );
};

const absenceDialogConf: LabelValueIf[] = [
  { label: 'Utilisateur', value: (x: SfAbsence) => x.user },
  { label: 'Statut', value: (x: SfAbsence) => <ColoredStatus status={x.statusName} /> },
  { label: 'Du', value: (x: SfAbsence) => formatAbsenceDate(x.startDate) },
  { label: 'Au', value: (x: SfAbsence) => formatAbsenceDate(x.endDate) },
  {
    label: 'Jours de congés restant avant validation par les RH',
    value: (x: SfAbsence) => x.remainingPaidLeave,
    ifIs: (x: SfAbsence) => x.typeLabel === 'Congés Payés',
  },
  {
    label: 'Jours de congés restant estimés',
    value: (x: SfAbsence) => x.remainingFuturPaidLeave,
    ifIs: (x: SfAbsence) => x.typeLabel === 'Congés Payés',
  },
  {
    label: 'Jours de RTT restant avant validation par les RH',
    value: (x: SfAbsence) => x.remainingRtt,
    ifIs: (x: SfAbsence) => x.typeLabel === 'RTT',
  },
  {
    label: 'Jours de RTT restant estimés',
    value: (x: SfAbsence) => x.remainingFuturRtt,
    ifIs: (x: SfAbsence) => x.typeLabel === 'RTT',
  },
  { label: 'Commentaire', value: (x: SfAbsence) => x.comment, ifIs: (x: SfAbsence) => !!x.comment },
];

/**
 * Permet de formatter la date d'une absence (ex: Lundi 01/01/2021)
 * @param date Date de l'absence
 */
function formatAbsenceDate(date: Date | string) {
  const dateWithHours = new Date(date);
  return Utils.getDayOfTheWeek(dateWithHours) + ' ' + Utils.displayDate(dateWithHours, true);
}

/**
 * Modale de validation/refus d'une absence dans le planning, a ne pas confondre avec la modale de
 * validation/refus d'une absence du reactTable
 * @param item Absence à validé/refusé
 */
const DialogSchedulerAbsence = (item: SchedulerDialogProps) => {
  const currentUser = authenticationService.getCurrentUser();
  const { setGlobalContextList } = useContext(GlobalContext);

  const canValid = AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_VALIDATION]);
  const haveRight = AccessFilter([ROLE.ADMIN_STAFF_ABSENCE_MANAGER_VALIDATION]);
  const isManager = !!currentUser.subordinate && currentUser.subordinate.includes(item.value.userId);
  const cant = !canValid && !haveRight && !isManager;
  if (!item?.value || item.type === 'task' || item.value.status > 1 || cant || (item.value.status === 1 && !canValid)) {
    item.handleClose();
    return <></>;
  }
  const { fluidState, setFluidState } = item;
  const absence: SfAbsence = item.value;
  if (fluidState?.id !== absence.id) setFluidState({ ...absence });
  const handleValid = () => {
    CRUD.put<SfAbsence>(APIRoute.SfAbsence + '/Valid', absence).then((value) => {
      NotificationService.success('Absence validée');
      item.updateItem({ ...item, value, color: absenceStatusColor(value.statusName) });
      item.handleClose();
      setGlobalContextList({ target: GCElement.ABSENCE, configList: filterPlanningConf() });
    });
  };
  const handleRefuse = () => {
    if (!fluidState.refusedComment) {
      NotificationService.error('Vous devez commenter un motif de refus');
      return;
    }
    CRUD.put<SfAbsence>(APIRoute.SfAbsence + '/Refuse', fluidState).then((value) => {
      NotificationService.warning('Absence refusée');
      item.updateItem({ ...item, value, color: absenceStatusColor(value.statusName) });
      item.handleClose();
      setGlobalContextList({ target: GCElement.ABSENCE, configList: filterPlanningConf() });
    });
  };

  return (
    <UI.Paper
      title={absence.type}
      className="absence-planning-modal"
      actions={[<CmsIcon icon="close" onClick={() => item.handleClose()} />]}
    >
      <Grid container lg={12} xs={6} spacing={2} style={{ width: '800px' }}>
        <Grid item xs={8}>
          <h2>Validation</h2>
          <TabStyleDataView conf={absenceDialogConf} mapFrom={absence} gridTemplateColumns="1fr 1fr" />
          {isAbsenceCollideWithTask(item) && (
            <WarningBubble type="warn">Collision avec tâche detecté, faites attention.</WarningBubble>
          )}
          {!!absence.assignmentCollision && (
            <WarningBubble type="warn">
              Collision avec une assignation d'équipe détecté, valider la demande d'absence désassignera le technicien
              sur la période de l'absence
            </WarningBubble>
          )}
          <div className="flex-h center valid-absence">
            <Buttons.Valid onClick={handleValid}> Accepter </Buttons.Valid>
          </div>
        </Grid>
        {!!fluidState && (
          <Grid item xs={4}>
            <UI.FormField>
              <h2>Refus</h2>
              <UI.Label label={'Date de substitution'} />
              <InputUI.DatePickerVanilla
                value={fluidState.refusedDateProposal}
                onChange={(x: Date) => setFluidState({ ...fluidState, refusedDateProposal: x })}
              />
            </UI.FormField>
            <UI.FormField>
              <UI.Label label={'Commentaire de refus (obligatoire)'} />
              <InputUI.DebouncedInput
                multiline
                value={fluidState.refusedComment}
                onChange={(x: string) => setFluidState({ ...fluidState, refusedComment: x })}
              />
            </UI.FormField>
            <div className="flex-h center">
              <Buttons.Cancel onClick={handleRefuse}>Refuser</Buttons.Cancel>
            </div>
          </Grid>
        )}
      </Grid>
    </UI.Paper>
  );
};

/**
 * Permet de savoir si une absence est en collision avec une tâche
 * @param currentItem Absence à tester
 */
function isAbsenceCollideWithTask(currentItem: SchedulerDialogProps): boolean {
  if (!currentItem || !currentItem.data) return false;
  for (let item of currentItem.data.items) {
    if (item.group !== currentItem.group || item.type !== 'task') continue;
    if (Utils.Date.isDateSpanColliding(currentItem.start_time, currentItem.end_time, item.start_time, item.end_time))
      return true;
  }
  return false;
}

//#endregion

//#region CreateEdit

const morning = [
  { id: 8, label: 'Du matin' },
  { id: 14, label: "À partir de l'après midi" },
];
const afternoon = [
  { id: 12, label: "Jusqu'à midi" },
  { id: 18, label: "Jusqu'en fin de journée" },
];

/**
 * Page de création/édition d'une absence
 * @param id Id de l'absence à éditer
 */
export const SfAbsenceCreateEdit: FC<{ id?: number }> = ({ id }) => {
  const [userList, setUserList] = useState<IdLabelValue[]>();
  const [typeList, setTypeList] = useState<IdLabelValue[]>();
  const [absence, setAbsence] = useState<SfAbsence>();
  const [doesUserHaveRtt, setDoesUserHaveRtt] = useState<boolean>(false);
  const currentUser = useMemo(() => authenticationService.getCurrentUser(), []);

  const finalTypeList = useMemo(() => {
    return doesUserHaveRtt || !!id ? typeList : typeList?.filter((x) => x.id !== 4);
  }, [doesUserHaveRtt, id, typeList]);

  const handleHalfDay = (absence: SfAbsence) => {
    let isHalfDay = typeList?.find((x) => x.id === absence.typeId)?.value === true;
    if (isHalfDay && absence.typeId === 1 && !userList?.find((x) => x.id === absence.userId)?.value) isHalfDay = false;
    return !isHalfDay;
  };

  useEffect(() => {
    CRUD.getList<IdLabelValue>(APIRoute.SfAbsenceType + '/Simplified').then(setTypeList);
    CRUD.getList<IdLabelValue>(APIRoute.UsUsers + '/Simplified?roleFilter=SfAbsence').then(setUserList);
  }, []);

  if (!typeList || !userList) return <LoadingScreen />;

  const handleGet = (absence: any) => {
    absence.startDate = new Date(absence.startDate);
    absence.startTime = absence.startDate.getHours();
    absence.endDate = new Date(absence.endDate);
    absence.endTime = absence.endDate.getHours();
    return absence;
  };

  const handleUserChange = (fromForm: SfAbsence) => {
    if (!fromForm.userId || fromForm?.userId === absence?.userId) return;
    setAbsence(fromForm);
    CRUD.getById<IdLabelValue>(APIRoute.UsUsers + '/Rtt', fromForm.userId).then((x) => setDoesUserHaveRtt(x.value > 0));
  };

  const init = { userId: currentUser.userId, startTime: 8, endTime: 18, startDate: new Date(), endDate: new Date() };
  return (
    <CmsForm
      id={id}
      title="une absence"
      currentUrl="/castres/staff/absence/"
      route={APIRoute.SfAbsence}
      onChange={handleUserChange}
      defaultValues={init}
      onGetEditData={handleGet}
      thin
      actions={[
        <CmsIcon icon="info" href="/help/Absences/saisie" tooltip="Aide Saisie des absences" target="_blank" />,
      ]}
      renderForm={(form) => {
        const onDateChange = (date: any, isEndDate = false) => {
          if (isEndDate && date < form.getValues('startDate')) form.setValue('startDate', date);
          if (!isEndDate && form.getValues('endDate') < date) form.setValue('endDate', date);
        };
        return (
          <>
            <CmsFormInput.Select
              label="Utilisateur"
              id="userId"
              options={userList ?? []}
              required
              readOnlyIf={() => !!id}
            />
            <CmsFormInput.Select
              id="typeId"
              label="Type d'absence"
              options={finalTypeList ?? []}
              required
              readOnlyIf={() => !!id}
            />
            <ShowRemainingRttAndCp absence={form.watch() as any} />
            <CmsFormInput.Date label="Date de début" id="startDate" {...{ onDateChange }} required />
            <CmsFormInput.Radio id="startTime" label="Heure de début" hideIf={handleHalfDay} options={morning} />
            <CmsFormInput.Date label="Date de fin" id="endDate" required onDateChange={(x) => onDateChange(x, true)} />
            <CmsFormInput.Radio id="endTime" label="Heure de fin" hideIf={handleHalfDay} options={afternoon} />
            <CmsFormInput.Text label="Commentaire" id="comment" multiline />
            <ShowRefusedPart absence={form.watch() as any} />
          </>
        );
      }}
    />
  );
};

/**
 * Affiche les raisons de refus d'une absence si elle est refusée, ainsi que la date de substitution proposée
 * @param absence L'absence à afficher
 */
const ShowRefusedPart = ({ absence }: { absence: SfAbsence }) => {
  if (!absence || absence.status !== 3) return <></>;
  return (
    <>
      <UI.Divider />
      <InputUI.CMSTextField
        style={{ marginTop: '1em' }}
        label="Motif de refus"
        value={absence?.refusedComment ?? ''}
        onChange={() => {}}
        readOnly
      />
      <InputUI.DatePickerVanilla
        label="Date de substitution"
        value={absence.refusedDateProposal ?? new Date()}
        onChange={() => {}}
        readOnly
      />
    </>
  );
};

const showRttConf: LabelValueIf[] | any[] = [
  { label: 'Jours de RTT restant avant validation par les RH', value: (x: any) => x.rtt, rtt: true },
  { label: 'Jours de RTT restant estimés', value: (x: any) => x.futurRtt, rtt: true },
  { label: 'Jours de congés restant avant validation par les RH:', value: (x: any) => x.paidLeave, cp: true },
  { label: 'Jours de congés restant estimés:', value: (x: any) => x.futurPaidLeave, cp: true },
  { label: '(Estimation non contractuelle)', value: () => '' },
];

/**
 * Affiche les jours de rtt et cp restant estimé si l'utilisateur en as.
 * @param absence L'absence à afficher
 */
const ShowRemainingRttAndCp: FC<{ absence: SfAbsence }> = ({ absence }) => {
  const [currentVal, setCurrentVal] = useState<any | undefined>();
  const [futurVal, setFuturVal] = useState<any | undefined>();

  useEffect(() => {
    if (!Utils.isDate(absence.startDate) || !Utils.isDate(absence.endDate)) return;
    if (!absence.userId || !absence.typeId || (absence.typeId !== 1 && absence.typeId !== 4)) return;
    let start: any = new Date(absence.startDate);
    start.setHours(absence.startTime ?? 8, 0, 0, 0);
    start = Utils.toGmtIsoString(start);
    let end: any = new Date(absence.endDate);
    end.setHours(absence.endTime ?? 18, 0, 0, 0);
    end = Utils.toGmtIsoString(end);
    let payload = `${absence.userId}?minDate=${start}&maxDate=${end}&typeId=${absence.typeId}`;
    if (absence.id) payload += `&absenceId=${absence.id}`;
    CRUD.getById<SfAbsence>(APIRoute.SfAbsence + '/UserPaidLeave', payload).then(setCurrentVal);
    CRUD.getById<SfAbsence>(APIRoute.SfAbsence + '/UserPaidLeave', payload + '&isFutur=true').then((result) => {
      setFuturVal(!!result?.userId ? result : null);
    });
  }, [absence]);

  if (!Utils.isDate(absence.startDate) || !Utils.isDate(absence.endDate)) return <></>;
  if (absence.typeId !== 1 && absence.typeId !== 4) return <></>;
  if (!currentVal || !futurVal) return <></>;

  const finalConf = showRttConf.filter((x) => (absence.typeId === 1 ? !x.rtt : !x.cp));
  const currentTaxYear = `${currentVal?.paidLeaveTaxYear} - ${currentVal?.paidLeaveTaxYear + 1}`;
  const year = futurVal?.paidLeaveTaxYear;
  const futurTaxYear = year === 'calcul' ? 'calcul' : `${year} - ${year + 1}`;
  const mapFrom = {
    rtt: `${currentVal?.remainingRtt} jours (${currentVal?.rttTaxYear})`,
    futurRtt: `${futurVal?.remainingRtt} jours (${futurVal?.rttTaxYear})`,
    paidLeave: `${currentVal?.remainingPaidLeave} jours (${currentTaxYear})`,
    futurPaidLeave: `${futurVal?.remainingPaidLeave} jours (${futurTaxYear})`,
  };
  return <UI.TabStyleDataView style={{ marginBottom: '1em' }} conf={finalConf} mapFrom={mapFrom} />;
};

//#endregion

//#region Export

/**
 * Export des absences pour intégration dans le logiciel de paie (Sage)
 */
export const SfAbsenceExport: FC = () => {
  const [serviceList, setServiceList] = useState<IdLabel[]>([]);
  const [agencyList, setAgencyList] = useState<IdLabel[]>([]);
  const [legalEntityList, setLegalEntityList] = useState<IdLabel[]>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [filteredUserList, setFilteredUserList] = useState<any[]>([]);
  const [state, setState] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    CRUD.getSimpleList(APIRoute.SfService).then(setServiceList);
    CRUD.getSimpleList(APIRoute.ClAgency).then(setAgencyList);
    CRUD.getSimpleList(APIRoute.ClLegalEntity).then(setLegalEntityList);
    CRUD.getList<any>(APIRoute.UsUsers + '/Light').then(setUserList);
  }, []);

  if (userList.length === 0) return <LoadingScreen />;

  const filterUserList = (attr: string, valueList: number[]) => {
    let filterList = userList.filter((x) => valueList.includes(x[attr + 'Id']));
    for (let x of filterList) x.selected = true;
    setState({ [attr]: valueList });
    setFilteredUserList(filterList);
  };

  const exportList = () => {
    if (filteredUserList.length === 0) {
      NotificationService.error('Aucun utilisateur selectionné');
      return;
    }
    const idList = [];
    for (let x of filteredUserList) idList.push(x.id);
    setIsSubmitting(true);
    CRUD.getBlob(APIRoute.SfAbsence + '/ExportSage', { idList })
      .then((blob) => {
        if (!blob) return;
        Utils.downloadFile(blob, 'export_absence.txt');
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="flex-h">
      <UI.Paper
        isLoading={isSubmitting}
        title="Export des absences"
        style={{ maxWidth: '600px', marginRight: '1em' }}
        actions={[<ArrowBackNavigationButton title="Retourner à la liste" to="/castres/staff/absence/list" />]}
      >
        <InputUI.AutoCompletor
          label="Entité juridique"
          value={state.legalEntity}
          onChange={(x: any) => filterUserList('legalEntity', x)}
          options={legalEntityList}
          multiple
        />
        <InputUI.AutoCompletor
          label="Agence"
          value={state.agency}
          onChange={(x: any) => filterUserList('agency', x)}
          options={agencyList}
          multiple
        />
        <InputUI.AutoCompletor
          label="Service"
          value={state.service}
          onChange={(x: any) => filterUserList('service', x)}
          options={serviceList}
          multiple
        />
      </UI.Paper>
      <UI.Paper
        scrollable
        isLoading={isSubmitting}
        title="Liste des utilisateurs à exporter"
        style={{ maxWidth: '600px', maxHeight: '90vh' }}
        actions={[
          <UI.Button type="submit" onClick={exportList}>
            Exporter
          </UI.Button>,
        ]}
      >
        <UI.MenuList title="" style={{ overflowY: 'auto' }} className="styled-scroll reverse-group">
          {filteredUserList.map(({ id, getFormattedName, sageNumber }) => (
            <UI.MenuItemClick onClick={() => {}} label={getFormattedName ?? ''} />
          ))}
        </UI.MenuList>
      </UI.Paper>
    </div>
  );
};

//#endregion
