import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlAccountOrder } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const BlAccountOrderConfigList: CmsColumnDef<BlAccountOrder>[] = [
  { header: 'Numéro de compte', id: 'number', Filter: CmsTableFilter.Search },
  { header: 'Service', id: 'serviceLabel', Filter: CmsTableFilter.Select },
  { header: 'Nature', id: 'natureLabel', Filter: CmsTableFilter.Select },
];

/**
 * Liste des comptes imputations d'achat
 */
export const BlAccountOrderList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_ACCOUNTORDER_CREATE])
    ? [<AddNavigationButton title="Ajouter un compte" to="/castres/billing/accountorder/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des comptes d'imputation d'achats"
      route={APIRoute.BlAccountOrder}
      columns={BlAccountOrderConfigList}
      navigateTo={(id: number) => `/castres/billing/accountorder/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const BlAccountOrderCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/billing/accountorder/', route: APIRoute.BlAccountOrder };
  return (
    <CmsForm id={id} {...props} title="Compte imputation d'achat" thin>
      <CmsFormInput.Text id="number" label="Numéro de compte" required max={8} />
      <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} required />
      <CmsFormInput.Select id="natureId" label="Nature" options={APIRoute.BlNature + '/Simplified'} required />
    </CmsForm>
  );
};
