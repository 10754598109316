import React, { FC } from 'react';
import { CmsBackendTable, CmsColumnDef } from '../../../component/table/CmsTable';
import APIRoute from '../../../constant/API.constant';
import { BlDelivery } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { BASE_URL } from 'constant/API_URL';
import { AddNavigationButton } from 'component/shared/Buttons';
import CmsIcon from '../../../component/shared/CmsIcon';
import ROLE from '../../../constant/role.constant';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import Grid from '@mui/material/Grid';
import { Link } from '@mui/material';
import AccessFilter from '../../../helper/AccessFilter';

const columns: CmsColumnDef<BlDelivery>[] = [
  {
    header: 'Référence',
    id: 'ref',
    Filter: CmsTableFilter.Text,
  },
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  {
    header: 'Fournisseur',
    id: 'companyLabel',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SkCompany },
    noClick: true,
    cell: (info) => (
      <Link
        key={info.row.original?.companyId}
        style={{ color: 'inherit' }}
        href={`${BASE_URL}castres/stock/company/${info.row.original?.companyId}/show`}
        target="_blank"
      >
        <div style={{ fontWeight: 'bold' }}>{info.getValue()}</div>
      </Link>
    ),
  },
  {
    header: 'Famille',
    id: 'familyLabel',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SkFamily },
  },
  { header: 'Prix', id: 'price', Filter: CmsTableFilter.Number, cell: CmsTableCell.Price },
  { header: 'Prix moyen', id: 'priceAvg', hide: 'hiddenByDefault', cell: CmsTableCell.Price },
  { header: 'Prix min', id: 'priceMin', hide: 'hiddenByDefault', cell: CmsTableCell.Price },
  { header: 'Prix max', id: 'priceMax', hide: 'hiddenByDefault', cell: CmsTableCell.Price },
  { header: 'Eco taxe', id: 'ecoTax', hide: 'hiddenByDefault', cell: CmsTableCell.Price },
  { header: 'Dernière commande', id: 'lastOrderDate', hide: 'hiddenByDefault', Filter: CmsTableFilter.Date },
  { header: 'Mis à jour le', id: 'updatedAt', Filter: CmsTableFilter.Date },
  { header: 'Unité', id: 'unit' },
  { header: 'TVA', id: 'vatLabel', hide: 'hiddenByDefault' },
  { header: 'Contrepartie', id: 'contrepartie', Filter: CmsTableFilter.Bool },
  { header: 'Actif', id: 'active', hide: 'hiddenByDefault', Filter: CmsTableFilter.Bool, defaultFilterValue: true },
  // { header: 'Validé', id: 'validated', hide: 'hiddenByDefault', Filter: CmsTableFilter.Bool },
];

export const SkArticleList: FC = () => {
  return (
    <CmsBackendTable
      title="Liste des articles fournisseurs"
      actions={[
        <AddNavigationButton
          key="nav"
          title="Créer un article fournisseur"
          href={`${BASE_URL}castres/stock/article/create`}
          access={[ROLE.ADMIN_STOCK_ARTICLE_CREATE]}
        />,
      ]}
      columns={columns}
      route={APIRoute.SkArticle}
      navigateTo={(id) => `/castres/stock/article/${id}/edit`}
      globalFilterIcon={
        <CmsIcon
          icon="warning"
          typeColor="primary"
          tooltip="Attention, ce filtre ne s'applique que sur le champ Référence"
        />
      }
      setFiltersInUrl
      canExport={AccessFilter([ROLE.ADMIN_STOCK_ARTICLE_EXPORT])}
    />
  );
};

export const SkArticleCreateEdit: FC = ({ id }: any) => {
  return (
    <CmsForm
      id={id}
      title="un article"
      currentUrl="/castres/stock/article/"
      route={APIRoute.SkArticle}
      paperStyle={{ maxWidth: '1200px' }}
      defaultValues={{ active: true, validated: false, contrepartie: false }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6}>
          <h2 style={{ textAlign: 'center' }}>Général</h2>
          <CmsFormInput.Select
            id="companyId"
            label="Fournisseur"
            required
            options={APIRoute.SkCompany + '/Simplified'}
          />
          <CmsFormInput.Text id="ref" label="Référence" required max={255} />
          <CmsFormInput.Text id="label" label="Libellé" required max={255} />
          <CmsFormInput.Select id="familyId" label="Famille" options={APIRoute.SkFamily + '/Simplified'} />
        </Grid>
        <Grid item sm={12} lg={6}>
          <h2 style={{ textAlign: 'center' }}>Propriétés</h2>
          <CmsFormInput.Number id="price" label="Prix" />
          <CmsFormInput.Number id="priceMin" label="Prix minimum" />
          <CmsFormInput.Number id="priceMax" label="Prix maximum" />
          <CmsFormInput.Number id="ecoTax" label="Eco Taxe" />
          <CmsFormInput.Number id="unit" label="Unité" />
          <CmsFormInput.Date id="lastOrderDate" label="Date de dernière commande" dateOnlyFormat />
          <CmsFormInput.Select id="vat" label="TVA" options={APIRoute.BlVat + '/Simplified'} />
          <CmsFormInput.Switch id="contrepartie" label="Contrepartie" />
          <CmsFormInput.Switch id="active" label="Actif" />
          <CmsFormInput.Switch id="validated" label="Validé" disabled={true} />
        </Grid>
      </Grid>
    </CmsForm>
  );
};
