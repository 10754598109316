import React, { FC } from 'react';
import { CmsButton } from './Ui';
import { Menu, MenuItem } from '@mui/material';

interface CmsMenuButtonProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  preventClose?: boolean;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  buttonStyle?: React.CSSProperties;
}

/**
 * Composant permettant de gérer les boutons de menu déroulant, surtout utilisé pour packager les boutons de navigation
 * @param title Titre du bouton du menu
 * @param children Contenu du menu
 * @param preventClose Empêche la fermeture du menu après un clic
 * @param disabled Désactive le bouton
 * @param variant Type de bouton
 * @param buttonStyle Style du bouton
 */
export const CmsMenuButton: FC<CmsMenuButtonProps> = ({
  title,
  children,
  preventClose,
  disabled,
  variant = 'outlined',
  buttonStyle,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  return [
    <CmsButton variant={variant} disabled={disabled} onClick={handleClick} style={buttonStyle}>
      {title}
    </CmsButton>,
    <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
      {React.Children.toArray(children).map((child: any, index: number) => (
        <MenuItem key={index}>{child}</MenuItem>
      ))}
    </Menu>,
  ];
};
