import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlVat } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const BlVatConfigList: CmsColumnDef<BlVat>[] = [
  { header: 'Id', id: 'id' },
  { header: 'Nom', id: 'label' },
  { header: 'Taux', id: 'value', Filter: CmsTableFilter.Text },
  { header: "Compte d'achat", id: 'accountBuy' },
  { header: 'Compte de vente', id: 'accountSell' },
];

export const BlVatList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_VAT_CREATE])
    ? [<AddNavigationButton title="Ajouter une TVA" to="/castres/billing/vat/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des TVA"
      route={APIRoute.BlVat}
      columns={BlVatConfigList}
      navigateTo={(id: number) => `/castres/billing/vat/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const BlVatCreateEdit: FC = ({ id }: any) => (
  <CmsForm id={id} currentUrl="/castres/billing/vat/" route={APIRoute.BlVat} title="une TVA" thin>
    <CmsFormInput.Text id="label" label="Nom" required min={2} max={50} />
    <CmsFormInput.Number id="value" label="Taux" required />
    <CmsFormInput.Text id="description" label="Description" max={255} />
    <CmsFormInput.Text id="accountBuy" label="Compte d'achat" required min={1} max={10} />
    <CmsFormInput.Text id="taxCodeSell" label="Code taxe de vente" min={1} max={4} />
    <CmsFormInput.Text id="taxCodeBuy" label="Code taxe d'achat" min={1} max={4} />
    <CmsFormInput.Text id="accountSell" label="Compte de vente" min={1} max={10} />
  </CmsForm>
);
