import React, { FC, Fragment, ReactNode, useState } from 'react';
import Button from '@mui/material/Button';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { NoContentMessage, CmsDialog, SectionTitle, BoolIconLabelValue } from 'component/shared/Ui';
import { ImpStaAddition, ValidatedImportItem, ImpStation } from 'interface/ImportType';
import { ImportValidatedAccordion, ImportItem, ValidationButton, StationLabelLink } from '../ImportUtils';
import { confirmAddition, confirmMatch, getStaPossibleMatchs } from 'service/Import.service';
import { handleErrorNotif } from 'helper/handle-response';

interface ImportAdditionsProps {
  // Id de l'import
  idImport: string;
  // Stations à ajouter
  additions: ImpStaAddition[] | undefined;
  // Stations validées
  validatedAdditions: ValidatedImportItem;
  // Fonction pour changer les stations validées
  setValidatedAdditions: any;
}
const ImportAdditions: FC<ImportAdditionsProps> = ({
  idImport,
  additions,
  validatedAdditions,
  setValidatedAdditions,
}) => (
  <Fragment>
    {additions && additions.length ? (
      <Fragment>
        {!!Object.keys(validatedAdditions).length && (
          <ImportValidatedAccordion validated={Object.keys(validatedAdditions).length}>
            <Stations
              idImport={idImport}
              stations={additions}
              validatedElements={validatedAdditions}
              setValidatedElements={setValidatedAdditions}
              validated={true}
            />
          </ImportValidatedAccordion>
        )}

        <Stations
          idImport={idImport}
          stations={additions}
          validatedElements={validatedAdditions}
          setValidatedElements={setValidatedAdditions}
        />
      </Fragment>
    ) : (
      <NoContentMessage>Aucun ajout disponible</NoContentMessage>
    )}
  </Fragment>
);

/**
 * Composant pour afficher les stations à ajouter
 * @param idImport id de l'import
 * @param stations stations à ajouter
 * @param validatedElements stations validées
 * @param setValidatedElements fonction pour changer les stations validées
 * @param validated si les stations sont validées ou non
 */
const Stations: FC<{
  idImport: string;
  stations: ImpStaAddition[];
  validatedElements: ValidatedImportItem;
  setValidatedElements: any;
  validated?: boolean;
}> = ({ idImport, stations, validatedElements, setValidatedElements, validated = false }) => (
  <Fragment>
    {stations
      .filter((station) => station.ref in validatedElements === validated)
      .map((station: ImpStaAddition) => {
        let accepted = false,
          refused = false,
          merged = false;
        if (validated) {
          accepted = validatedElements[station.ref] === 'accepted';
          refused = validatedElements[station.ref] === 'refused';
          merged = validatedElements[station.ref] === 'merged';
        }

        const accept = (ref: string) => {
          if (!validated) {
            confirmAddition('station', idImport, [ref])
              .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'accepted' })))
              .catch(handleErrorNotif);
          }
        };
        const refuse = (ref: string) => {
          if (!validated) setValidatedElements((current: any) => ({ ...current, [ref]: 'refused' }));
        };
        const merge = (id: number, ref: string) => {
          if (!validated) {
            confirmMatch('station', idImport, id, ref)
              .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'merged' })))
              .catch(handleErrorNotif);
          }
        };

        let actions: ReactNode[] = [];
        if (!validated && station.possibleMatches > 0) {
          actions = [<MergeDialog station={station} accept={accept} refuse={refuse} merge={merge} />];
        } else if (validated && merged) {
          actions = [
            <Button variant="contained" startIcon={<CompareArrowsIcon />}>
              Fusionné
            </Button>,
          ];
        }
        return (
          <ImportItem
            key={station.ref}
            tied
            secondLvl={false}
            label={<StationLabelLink station={station} />}
            style={{ backgroundColor: '#5f5e64' }}
            actions={actions}
            accepted={accepted}
            refused={refused}
            accept={() => accept(station.ref)}
            refuse={() => refuse(station.ref)}
            customFields={[
              <BoolIconLabelValue
                key={`${station.ref}-cribe`}
                label="Contract Cribe"
                value={station.contractCribe}
                style={{ marginLeft: '1em', lineHeight: 'normal' }}
              />,
              <BoolIconLabelValue
                key={`${station.ref}-ad`}
                label="Contract Ad"
                value={station.contractAd}
                style={{ marginLeft: '1em', lineHeight: 'normal' }}
              />,
            ]}
          />
        );
      })}
  </Fragment>
);

/**
 * Modal pour fusionner une station
 * @param station station à fusionner
 * @param accept accepter la fusion
 * @param refuse refuser la fusion
 * @param merge action de fusion
 */
const MergeDialog: FC<{ station: ImpStaAddition; accept: any; refuse: any; merge: any }> = ({
  station,
  accept,
  refuse,
  merge,
}) => {
  const [open, setOpen] = useState(false);
  const [isMergeDiscarded, setMergeDiscarded] = useState(false);
  const [possibleMatches, setPossibleMatches] = useState<ImpStation[]>([]);

  const handleClickOpen = () => {
    getStaPossibleMatchs(station.departmentCode)
      .then((result) => setPossibleMatches(result))
      .catch(handleErrorNotif);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const acceptAddition = () => {
    handleClose();
    accept(station.ref);
  };
  const refuseAddition = () => {
    handleClose();
    refuse(station.ref);
  };
  const mergeItem = (id: number) => {
    handleClose();
    merge(id, station.ref);
  };

  const { label, contractCribe, contractAd } = station;
  const labelValues = {
    'Contract Cribe': `${contractCribe ? 1 : 0}`,
    'Contract Ad': `${contractAd ? 1 : 0}`,
  };
  return (
    <Fragment>
      <Button variant="outlined" startIcon={<CompareArrowsIcon />} onClick={handleClickOpen}>
        {station.possibleMatches} correspondances possibles
      </Button>
      <CmsDialog open={open} onClose={handleClose} withBackground fullWidth>
        <SectionTitle
          title="Station à importer"
          actions={[
            <IconButton aria-label="close" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <ImportItem label={label} labelValues={labelValues} noActions />
        {!isMergeDiscarded && (
          <Fragment>
            <SectionTitle title="Possibles correspondances" />
            {possibleMatches.map((matchStation) => {
              const actions = [];
              if (matchStation.id) {
                actions.push(
                  <Button
                    variant="outlined"
                    startIcon={<CompareArrowsIcon />}
                    onClick={() => mergeItem(matchStation.id as number)} // matchStation.id is checked tho, TODO: avoid casting and implement a better check
                  >
                    Faire correspondre
                  </Button>,
                );
              }
              const matchStationLabelValues = {
                'Contract Cribe': `${matchStation.contractCribe ? 1 : 0}`,
                'Contract Ad': `${matchStation.contractAd ? 1 : 0}`,
              };
              return (
                <ImportItem
                  key={matchStation.ref}
                  label={matchStation.ref + ' - ' + matchStation.label}
                  labelValues={matchStationLabelValues}
                  actions={actions}
                />
              );
            })}
          </Fragment>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', margin: '1.5em 0 1em 0' }}>
          {!isMergeDiscarded ? (
            <Button onClick={() => setMergeDiscarded(true)} variant="outlined">
              Aucune correspondance trouvée
            </Button>
          ) : (
            <Fragment>
              <ValidationButton type="accept" onClick={acceptAddition} label="Accepter l'ajout" />
              <ValidationButton
                type="refuse"
                onClick={refuseAddition}
                label="Refuser l'ajout"
                style={{ marginLeft: '0.5em' }}
              />
            </Fragment>
          )}
        </div>
      </CmsDialog>
    </Fragment>
  );
};

export default ImportAdditions;
