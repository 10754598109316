import { authenticationService } from '../service/Authentication.service';
import { User } from '../interface/User';
import ROLE from '../constant/role.constant';

/**
 * Verifie si l'utilisateur a au moins une des features passées en paramètre
 * @param accessFeaturesToCheck Liste des features à vérifier
 */
export default function AccessFilter(accessFeaturesToCheck: Array<string>): boolean {
  const user: User = authenticationService.currentUserValue;
  if (!user) authenticationService.logout();
  if (!user.roles) return false;
  return (
    user.roles?.includes(ROLE.SUPER_ADMIN) || accessFeaturesToCheck.some((feature) => user.roles?.includes(feature))
  );
}

/**
 * Vérifie si l'utilisateur à au moins une des groupes passés en paramètre
 * @param accessFeaturesToCheck Liste des groupes à vérifier
 */
export function GroupFilter(groupsToCheck: Array<string>): boolean {
  const user: User = authenticationService.currentUserValue;
  if (!user) authenticationService.logout();
  if (!user.groupNameList) return false;
  return user.roles?.includes(ROLE.SUPER_ADMIN) || groupsToCheck.some((g) => user.groupNameList?.includes(g));
}
