import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { WkJob } from '../../../interface/WkType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const wkJobConfigList: CmsColumnDef<WkJob>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
  { header: 'Taux horaire', id: 'hourlyRate', Filter: CmsTableFilter.Number },
];

/**
 * Page de liste des corps d'états
 * @param props React props
 */
export const WkJobList: FC = (props: any) => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_JOB_CREATE])
    ? [<AddNavigationButton title="Ajouter un corps d'état" to="/castres/work/job/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Corps d'état"
      route={APIRoute.WkJob}
      columns={wkJobConfigList}
      navigateTo={(id: number) => `/castres/work/job/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Page de création et d'édition des corps d'états
 * @param id Id de l'élément à éditer
 */
export const WkJobCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/work/job/', route: APIRoute.WkJob, title: "corps d'état" };
  return (
    <CmsForm id={id} {...props} thin>
      <CmsFormInput.Text id="name" label="Nom" required min={2} />
      <CmsFormInput.Color id="color" label="Couleur" required withoutSharp />
      <CmsFormInput.Number id="hourlyRate" label="Taux horaire" />
    </CmsForm>
  );
};
