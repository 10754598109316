import React, { FC, Fragment } from 'react';

import { NoContentMessage } from 'component/shared/Ui';
import { ValidatedImportItem, ImpEquStationProcessed, ImpEquipement } from 'interface/ImportType';
import { ImportValidatedAccordion, EquipementPerStation, ImportItem } from '../ImportUtils';
import { confirmEquDeactivation } from 'service/Import.service';
import { handleErrorNotif } from 'helper/handle-response';

interface ImportDeactivationsProps {
  // Id de l'import
  idImport: string;
  // Stations à mettre à désactiver
  deactivations: ImpEquStationProcessed[] | undefined;
  // Stations Désactivées
  validatedDeactivations: ValidatedImportItem;
  // Fonction pour changer les stations Désactivées
  setValidatedDeactivations: any;
}

/**
 * Composant pour afficher les stations à mettre à désactiver
 * @param idImport id de l'import
 * @param deactivations stations à mettre à désactiver
 * @param validatedDeactivations stations Désactivées
 * @param setValidatedDeactivations fonction pour changer les stations Désactivées
 */
const ImportDeactivations: FC<ImportDeactivationsProps> = ({
  idImport,
  deactivations,
  validatedDeactivations,
  setValidatedDeactivations,
}) => (
  <Fragment>
    {deactivations?.length ? (
      <Fragment>
        {!!Object.keys(validatedDeactivations).length && (
          <ImportValidatedAccordion validated={Object.keys(validatedDeactivations).length}>
            <EquipementPerStation
              idImport={idImport}
              stations={deactivations}
              validatedElements={validatedDeactivations}
              setValidatedElements={setValidatedDeactivations}
              EquipementComponent={ImportData}
              validated={true}
            />
          </ImportValidatedAccordion>
        )}

        <EquipementPerStation
          idImport={idImport}
          stations={deactivations}
          validatedElements={validatedDeactivations}
          setValidatedElements={setValidatedDeactivations}
          EquipementComponent={ImportData}
        />
      </Fragment>
    ) : (
      <NoContentMessage>Aucune désactivation disponible</NoContentMessage>
    )}
  </Fragment>
);

/**
 * Composant pour afficher les données d'un équipement et ses actions (valider/refuser)
 * @param equipement équipement à afficher
 * @param validatedElements éléments validés
 * @param setValidatedElements fonction pour changer les éléments validés
 * @param validated si l'équipement est validé ou non
 */
const ImportData: FC<{
  idImport: string;
  equipement: ImpEquipement;
  validatedElements: ValidatedImportItem;
  setValidatedElements: any;
  validated?: boolean;
}> = ({ equipement, validatedElements, setValidatedElements, validated = false }) => {
  let accepted = false,
    refused = false;
  if (validated) {
    accepted = validatedElements[equipement.ref] === 'accepted';
    refused = validatedElements[equipement.ref] === 'refused';
  }

  const accept = (ref: string, id: number) => {
    if (!validated) {
      confirmEquDeactivation([id])
        .then(() => setValidatedElements((current: any) => ({ ...current, [ref]: 'accepted' })))
        .catch(handleErrorNotif);
    }
  };
  const refuse = (ref: string) => {
    if (!validated) setValidatedElements((current: any) => ({ ...current, [ref]: 'refused' }));
  };

  const { id, ref, label, type, numeroDePiste, parent } = equipement;
  const labelValues = {
    Type: type?.label ?? '',
    'Numeros de pistes': numeroDePiste && numeroDePiste.length ? numeroDePiste.join(', ') : null,
    Parent: parent && parent.ref ? parent.ref : null,
  };
  return (
    <ImportItem
      label={`${ref} - ${label}`}
      labelValues={labelValues}
      accepted={accepted}
      refused={refused}
      accept={() => accept(ref, id as number)}
      refuse={() => refuse(ref)}
    />
  );
};

export default ImportDeactivations;
