import React, { FC } from 'react';
import { GOOGLE_API_KEY } from '../../constant/API_URL';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

// Coordonnées GPS
interface LatLng {
  // Latitude
  lat?: number;
  // Longitude
  lng?: number;
}

interface MapCustomProps {
  // Coordonnées GPS
  setCenter: LatLng;
  // Hauteur du conteneur (par défaut 300px)
  containerHeight?: string;
}

/**
 * Carte Google Map
 * @param setCenter Coordonnées GPS pour centrer la carte
 * @param containerHeight Hauteur du conteneur (par défaut 300px)
 */
const MapCustom: FC<MapCustomProps> = ({ setCenter, containerHeight }) => {
  const loadConfig = { id: 'google-map-script', googleMapsApiKey: GOOGLE_API_KEY };
  const { isLoaded } = useJsApiLoader(loadConfig);
  const mapContainerStyle = { width: '100%', height: containerHeight ?? '300px' };
  if (!setCenter || !setCenter.lat || !setCenter.lng) return <>Aucune coordonnées fournies</>;
  if (!isLoaded) return <></>;
  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} center={setCenter as any} zoom={8}>
      <Marker position={setCenter as any} />
    </GoogleMap>
  );
};

export default React.memo(MapCustom);
