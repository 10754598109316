import React from 'react';
import { ClClient, ClStation } from '../interface/ClType';
import APIRoute from '../constant/API.constant';
import { CMSNotificationState } from './Notification.context';
import { RTColumn } from '../interface/RTColumn';
import { SfAbsence } from '../interface/SfType';
import { SchedulerData, SchedulerFetch, SchedulerFilter } from '../component/scheduler/Scheduler';
import { Theme } from '@mui/material';
import { CmsGlobalThemeProps, darkTheme } from '../component/Theming';
import { WkGreenfluxCdr } from 'interface/WkType';
import { CmsColumnDef } from '../component/table/CmsTable';

/**
 * Liste des éléments du contexte global
 */
export enum GCElement {
  STATION = 'station',
  ABSENCE = 'sfAbsence',
  CLIENT = 'clClient',
}

/**
 * Liste des types de planning global, différent de GCElement car les plannings n'ont pas le même fonctionnement
 */
export enum GlobalPlanningType {
  ABSENCE_PLANNING = 'sfAbsencePlanning',
}

/**
 * Classe de gestion du contexte global.
 * Ici nous initialisons les éléments du contexte global et nous les mettons à jour.
 * Le contexte global est l'équivalent du store Redux, il fonctionne comme un singleton accessible à tout endroit de
 * l'application.
 */
export class GlobalContextProps {
  station: GlobalContextElemProps<ClStation>;
  notification: GlobalContextElemProps<CMSNotificationState>;
  sfAbsence: GlobalContextElemProps<SfAbsence>;
  sfAbsencePlanning: GlobalContextElemProps<SfAbsence>;
  cdr: GlobalContextElemProps<WkGreenfluxCdr>;
  clClient: GlobalContextElemProps<ClClient>;

  constructor() {
    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);
    this.station = new GlobalContextElemProps<ClStation>(APIRoute.ClStation, true);
    this.notification = new GlobalContextElemProps<CMSNotificationState>(APIRoute.UsNotification, false);
    this.sfAbsence = new GlobalContextElemProps<SfAbsence>(APIRoute.SfAbsence, true);
    this.sfAbsencePlanning = new GlobalContextElemProps<SfAbsence>(APIRoute.SfAbsence + '', true);
    this.cdr = new GlobalContextElemProps<WkGreenfluxCdr>(APIRoute.WkGreenfluxCdr, true);
    this.clClient = new GlobalContextElemProps<ClClient>(APIRoute.ClClient, true);
  }
}

export class GlobalContextElemProps<T> {
  /** indique si la liste peut être mise à jour en passant lastUpdated à l'API */
  isUpdatable: boolean;
  /** endpoint de l'API */
  route: APIRoute | string;
  /** date de dernière mise à jour */
  lastUpdated?: Date;
  /** liste des paramètres qui doivent être transmis à l'API */
  customParams?: Array<string>;
  /** doit-on effacer les paramètres (customParams) après la première récupération des données (paramètres utilisés uniquement à l'initialisation) */
  clearParams?: boolean;
  /** liste des objets qui est triée si sort est passé à setGlobalContextList */
  list?: Array<T>;
  /** liste des objets non triée utilisée pour la mise à jour */
  rawList?: Array<T>;
  /** liste des attributs devant être convertis en date */
  stringToDateList?: Array<string>;
  /** liste de configuration du tableau */
  configList?: Array<RTColumn>;

  constructor(
    route: APIRoute | string,
    isUpdatable: boolean,
    initialLastUpdated?: Date,
    customParams?: Array<string>,
    clearParams?: boolean,
  ) {
    this.isUpdatable = isUpdatable;
    this.route = route;
    // Permet de fixer par défaut une date minimum dans les requêtes faites au serveur
    this.lastUpdated = initialLastUpdated;
    this.customParams = customParams;
    this.clearParams = clearParams;
  }
}

export interface GlobalContextListProps {
  /** élément à mettre à jour dans le global context */
  target: GCElement;
  /** pour spécifier un endpoint non statique (par exemple WkAlarmAlx/ByStation/ID au lieu de WkAlarmAlx) */
  route?: string;
  /** fonction à utiliser pour trier les éléments de la liste (sinon laissé dans l'ordre fourni par l'API) */
  sort?: (elem1: any, elem2: any) => number;
  /** liste des attributs devant être convertis en date */
  stringToDateList?: Array<string>;
  /** liste de configuration du tableau */
  configList?: RTColumn[] | CmsColumnDef<any>[];
  dataBuilder?: (data: any) => any;
}

export interface GlobalContextSchedulerProps {
  /** élément à mettre à jour dans le global context */
  target: GlobalPlanningType;
  // Les nouvelles données à ajouter au planning
  data?: any;
  // Doit-on rafraichir les filtres
  refreshFilter?: boolean;
  // Liste des filtres à appliquer
  filterList?: Array<SchedulerFilter>;
  // Liste des fetch à appliquer pour récupérer les données depuis l'API
  fetchList?: SchedulerFetch[];
  // Fonction à appeler pour récupérer les données depuis l'API
  fetchFunction?: (fetchList: SchedulerFetch[], filters: SchedulerFilter[]) => Promise<SchedulerData>;
  // Doit-on rafraichir le planning
  refresh?: boolean;
  // Le planning est-il en cours de chargement
  isLoading?: boolean;
  // Date depuis la dernière mise à jour depuis l'API, permets à l'API de ne pas renvoyer les données déjà présentes
  since?: Date;
}

/**
 * Classe de gestion du contexte global des plannings.
 */
export class GlobalPlanningContext {
  sfAbsencePlanning: GlobalContextSchedulerProps = { target: GlobalPlanningType.ABSENCE_PLANNING };
}

/**
 * Initialisation à vide du contexte global
 */
const GlobalContext = React.createContext({
  globalContext: new GlobalContextProps(),
  globalPlanning: new GlobalPlanningContext(),
  setGlobalContextElem: (elem: GlobalContextElemProps<any>, elemType: GCElement) => {},
  setGlobalContextList: (props: GlobalContextListProps) => {},
  setGlobalPlanning: (elem: GlobalContextSchedulerProps) => {},
  payload: {} as any,
  setPayload: (payload: any) => {},
  globalHistory: {
    get: (path: any, getCustomDefaultFilterOnly = false) => '',
    set: (path: any, value: string) => {},
    clean: () => {},
    setSaveFilter: (history: any, saveIt: boolean, isCustomDefaultFilter = false) => {},
    isFilterSaved: (path: any): boolean => false,
  },
  theming: {
    set: (newTheme: Theme): void => undefined,
    get: (): CmsGlobalThemeProps => darkTheme as CmsGlobalThemeProps,
  },
});

export { GlobalContext };
