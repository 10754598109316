import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { UsNotifFamily } from '../../../interface/Notification';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const UsNotifFamilyListConfig: CmsColumnDef<UsNotifFamily>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
];

/**
 * Liste des familles de notifications
 */
export const UsNotifFamilyList: FC = () => {
  const actions = AccessFilter([ROLE.SUPER_ADMIN])
    ? [<AddNavigationButton title="Ajouter une famille" to="/castres/configuration/notification/family/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des familles de notifications"
      route={APIRoute.UsNotification + '/family'}
      columns={UsNotifFamilyListConfig}
      navigateTo={(id: number) => `/castres/configuration/notification/family/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Composant de création et d'édition d'une famille de notification
 * @param id - id de la famille de notification
 * @param setHttpCodePage - fonction pour changer le code http de la page
 */
export const UsNotifFamilyCreateEdit: FC = ({ id }: any) => {
  const props = { id, currentUrl: '/castres/configuration/notification/family/' };
  return (
    <CmsForm {...props} route={APIRoute.UsNotification + '/family'} title="une famille de notification" thin>
      <CmsFormInput.Text id="label" label="Nom" required max={100} />
    </CmsForm>
  );
};
