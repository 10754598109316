import React, { FC, useContext } from 'react';
import { LabelCalc } from '../../../interface/CommonType';
import { GlobalContext } from '../../../context/Global.context';

let indicatorStyle = {
  container: {
    borderRadius: '10px',
    padding: '2px',
  },
  holder: {
    backgroundColor: 'white',
    borderRadius: '9px',
    padding: '5px 10px',
    height: '100%',
  },
  icon: {
    margin: ' 0, 5px, 0, 5px',
  },
};

export interface IndicatorProps {
  // Liste des indicateurs à afficher, se référer à l'interface LabelCalc
  indicatorList: Array<LabelCalc>;
  // Liste des lignes à analyser, seuls les lignes filtrées sont passées (afin de mettre à jour les indicateurs en fonction des filtres)
  rows: Array<any>;
}

/**
 * Composant permettant d'afficher des indicateurs
 * @param indicatorList Liste des indicateurs à afficher, se référer à l'interface LabelCalc
 * @param rows Liste des lignes à analyser, seuls les lignes filtrées sont passées (afin de mettre à jour les indicateurs en fonction des filtres)
 */
export const Indicator: FC<IndicatorProps> = ({ indicatorList, rows }) => {
  const { theming } = useContext(GlobalContext);
  if (!indicatorList) return <></>;
  const green = 'rgba(172,37,22,1)';
  const red = 'rgba(2,190,33,1)';
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
      {indicatorList.map((item, key) => {
        const val = item.calc(rows);
        const validColor = item.revertColor ? green : red;
        const errorColor = item.revertColor ? red : green;
        const direction = item.revertColor ? '-90' : '90';
        const isOneValue = val.count === undefined;
        let backGround = 'rgba(2,190,33,1)';
        if (val.ratio !== undefined) {
          const ratio = Math.round(val.ratio * 100);
          backGround = `linear-gradient(${direction}deg, ${validColor} 0%, ${validColor} ${ratio}% , ${errorColor} ${
            ratio + 1
          }%, ${errorColor} 100%`;
        }
        return (
          <div key={key} style={{ ...indicatorStyle.container, background: backGround, marginBottom: '10px' }}>
            <div style={{ ...indicatorStyle.holder, backgroundColor: theming.get().cms.main.paper }}>
              <h4 style={{ textAlign: 'center' }}>{item.label}</h4>
              {isOneValue && <span>{val}</span>}
              {!isOneValue && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span>{val.count}</span>
                  <span style={{ margin: '0 6px' }}>-</span>
                  <span>{(val.ratio * 100)?.toString().substring(0, 4)} %</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
