import React, { FC, useContext, useEffect, useState } from 'react';
import { IconWithToolTip } from '../../../component/IconWithToolTip';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { IdLabel } from '../../../interface/CommonType';
import { useFormContext } from 'react-hook-form';
import { NotificationConfiguration, UsNotificationAction } from '../../../interface/Notification';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import CRUD from '../../../service/CRUD.service';
import LoadingScreen from '../../../component/LoadingScreen';
import { BASE_URL } from '../../../constant/API_URL';
import { HeaderPanel, CmsPaper, CmsButton } from '../../../component/shared/Ui';
import '../shared-configuration.scss';
import { NotificationTypeList } from '../../notification/Notification';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm, CmsFormContext } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { useNavigate } from 'react-router-dom';
import ROLE from '../../../constant/role.constant';

export const UsNotifConfListConfig: CmsColumnDef<any>[] = [
  { header: 'Déclencheur', id: 'actionLabel', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Titre', id: 'title', Filter: CmsTableFilter.Text },
  { header: 'Famille', id: 'family.label', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Description', id: 'description', Filter: CmsTableFilter.Text },
  { header: 'Url', id: 'url', Filter: CmsTableFilter.Text },
  { header: 'Actif', id: 'enabled', Filter: CmsTableFilter.Bool, defaultFilterValue: true, size: 52 },
  {
    header: IconWithToolTip.SentMailIcon,
    filterHeader: 'Envoi des rapports par mail',
    id: 'sendMail',
    Filter: CmsTableFilter.Bool,
    size: 52,
  },
];

/**
 * Liste des configurations de notifications
 */
export const UsNotifConfList: FC = () => {
  const actions = AccessFilter([ROLE.SUPER_ADMIN])
    ? [<AddNavigationButton title="Ajouter une configuration" to="/castres/configuration/notification/create" />]
    : [];
  return (
    <CmsFrontendTable
      route={APIRoute.UsNotification + '/configuration'}
      columns={UsNotifConfListConfig}
      title="Liste des configurations de notifications"
      navigateTo={(id: number) => `/castres/configuration/notification/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const InitialScopeList: Array<IdLabel> = [
  { id: 0, label: 'Utilisateur' },
  { id: 1, label: 'Service' },
  { id: 2, label: 'Agence' },
];

export const NotificationScopeList: Array<IdLabel> = [
  { id: 0, label: 'Par défaut (initial)' },
  { id: 1, label: 'Utilisateur courant' },
  { id: 2, label: 'Manager' },
  { id: 3, label: 'Service' },
  { id: 4, label: 'Agence' },
  { id: 5, label: 'Tous' },
  { id: 6, label: 'Exceptionnels et spéciaux uniquement' },
];

export const lockedRolesOptionIdList = [0, 1, 2, 6];

/**
 * Converti une chaine de caractère en tableau d'entier
 * @param str Chaine de caractère à convertir
 */
function stringToArray(str?: string) {
  const arr = str?.split(',') ?? [];
  const result = [];
  for (let a of arr) if (!isNaN(parseInt(a))) result.push(parseInt(a));
  return result;
}

const typeList = NotificationTypeList().filter((x: any) => x.show);
/**
 * Page de création et d'édition de configuration de notification
 * @param id Id de la configuration à éditer
 */
export const UsNotifConfCreateEdit: FC<any> = ({ id }: any) => {
  const [actionList, setActionList] = useState<Array<UsNotificationAction>>();
  const [recipientList, setRecipientList] = useState<Array<IdLabel>>([]);
  const [mailRecipientList, setMailRecipientList] = useState<Array<IdLabel>>([]);

  useEffect(() => {
    CRUD.getList<UsNotificationAction>(APIRoute.UsNotification + '/Action').then((result) => {
      for (let item of result) item.label = item.title;
      setActionList(result);
    });
  }, []);

  const onGetData = (data: any) => {
    data.usGroupIdList = data.groupList?.split(',').map((x: any) => +x) ?? [];
    const action = actionList?.find((x) => x.id === data.actionId);
    setRecipientList(mapIdLabel(action?.availableSpecialRecipients));
    setMailRecipientList(mapIdLabel(action?.availableSpecialMailRecipients));
    data.speMailRecipients = stringToArray(data.specialMailRecipients);
    data.speRecipients = stringToArray(data.specialRecipients);
    data.groupList = stringToArray(data.groupList);
    return data;
  };

  const onBeforeSubmit = (data: any) => {
    data.specialRecipients = setIntArrayToString(data.speRecipients);
    data.specialMailRecipients = setIntArrayToString(data.speMailRecipients);
    data.groupList = setIntArrayToString(data.groupList);
    return data;
  };

  const componentBaseUrl = '/castres/configuration/notification/';
  if (!actionList) return <LoadingScreen />;
  return (
    <CmsForm
      id={id}
      currentUrl={componentBaseUrl}
      route={APIRoute.UsNotification + '/Configuration'}
      onGetEditData={onGetData}
      onBeforeSubmit={onBeforeSubmit}
    >
      <SubmitButtonPanel componentBaseUrl={componentBaseUrl} id={id} />
      <div className="notif-conf">
        <CmsPaper title="Général">
          <CmsFormInput.Switch id="enabled" label="Activé" />
          <CmsFormInput.Select
            id="actionId"
            label="Action déclencheur"
            required
            readOnlyIf={() => !!id}
            options={APIRoute.UsNotification + '/Action'}
            optionLabel="title"
          />
          <CmsFormInput.Text id="title" label="Titre" required />
          <CmsFormInput.Text id="description" label="Description" multiline />
          <CmsFormInput.Select
            id="familyId"
            label="Famille de notification"
            required
            options={APIRoute.UsNotification + '/Family'}
          />
          <CmsFormInput.SimpleSelect id="type" label="Type de notification" options={typeList} required />
        </CmsPaper>
        <CmsPaper title="Destinataire(s)">
          <ShowInitialScope actionList={actionList} />
          <CmsFormInput.SimpleSelect id="scope" label="Scope" required options={NotificationScopeList} />
          <CmsFormInput.Select
            id="groupList"
            label="Groupe(s) visé(s) (aucun = tous)"
            options={APIRoute.UsGroups + '/Simplified'}
            multiple
          />
          <CmsFormInput.Select
            id="speRecipients"
            label="Utilisateurs spéciaux"
            options={recipientList}
            multiple
            hideIf={() => !recipientList || recipientList.length === 0}
          />
          <CmsFormInput.Select
            id="usNotifConfUserIdList"
            label="Utilisateurs exceptionnels"
            options={APIRoute.UsUsers + '/Simplified'}
            multiple
          />
        </CmsPaper>
        <CmsPaper title="Message">
          <ShowConfAttribute actionList={actionList} />
          <CmsFormInput.Text id="messageTitle" label="Titre de la notification" required />
          <CmsFormInput.Text id="message" label="Message de la notification" required multiline />
          <CmsFormInput.Text id="url" label="URL" multiline requiredIf={(x) => x.type === 6} />
        </CmsPaper>
        <CmsPaper title="E-mail">
          <CmsFormInput.Switch id="noNotification" label="Ne pas envoyer de notification (email uniquement)" inline />
          <CmsFormInput.Switch id="sendMail" label="Envoi d'email" inline />
          <CmsFormInput.Text id="mailTitle" label="Titre du mail" />
          <CmsFormInput.Text id="mailBody" label="Corps du mail" multiline />
          <CmsFormInput.Select
            id="speMailRecipients"
            label="Emails spéciaux (Service/Agence/etc...), mettre la liste exhaustive des destinataires principaux de l'email"
            options={mailRecipientList}
            hideIf={() => !mailRecipientList || mailRecipientList.length === 0}
            multiple
          />
        </CmsPaper>
      </div>
      <SubmitButtonPanel componentBaseUrl={componentBaseUrl} id={id} />
      <FormDataHandler
        actionList={actionList}
        id={id}
        setRecipientList={setRecipientList}
        setMailRecipientList={setMailRecipientList}
      />
    </CmsForm>
  );
};

const SubmitButtonPanel: FC<{ id?: any; componentBaseUrl: string }> = ({ id, componentBaseUrl }) => {
  const navigate = useNavigate();
  const { formState, handleSubmit } = useFormContext();
  const { onSubmit, returnToList } = useContext(CmsFormContext);
  const handleReturn = () => {
    if (!returnToList?.startsWith('http')) navigate(returnToList ?? '');
    else window.location.href = returnToList;
  };
  return (
    <HeaderPanel
      title={`${!!id ? 'Mettre à jour' : 'Créer'} une notification`}
      actions={[
        <CmsButton color="inherit" onClick={handleReturn}>
          Abandonner
        </CmsButton>,
        <CmsButton disabled={!formState.isValid} onClick={handleSubmit((x) => onSubmit(x, true))} type="submit">
          {(!!id ? 'Mettre à jour' : 'Créer') + ' et retourner à la liste'}
        </CmsButton>,
        <CmsButton disabled={!formState.isValid} onClick={handleSubmit((x) => onSubmit(x))} type="submit">
          {!!id ? 'Mettre à jour' : 'Créer'}
        </CmsButton>,
      ]}
    />
  );
};

const ShowInitialScope: FC<{ actionList?: any[] }> = ({ actionList }) => {
  const { watch } = useFormContext();
  const action = actionList?.find((x) => x.id === watch('actionId'));
  if (!action || action.initialScope === undefined) return <>{"Sélectionner une action d'abord"}</>;
  return <>{'Scope Initial: ' + InitialScopeList[action.initialScope]?.label ?? 'erreur'} </>;
};

const ShowConfAttribute: FC<{ actionList?: any[] }> = ({ actionList }) => {
  const [oldActionId, setOldActionId] = useState<number>();
  const [argumentList, setArgumentList] = useState<LabelValueIf[]>([]);
  const { watch } = useFormContext();
  const actionId = watch('actionId');
  if (!actionList) return <></>;
  if (oldActionId !== actionId) {
    setOldActionId(actionId);
    const action = actionList.find((x) => x.id === actionId);
    const args = new Array<LabelValueIf>();
    for (let i = 0; i < action.availableArguments.length; i++) {
      const arg = action.availableArguments[i].split('::');
      args.push({ label: arg[0], value: arg[1] });
    }
    setArgumentList(args);
  }
  return <TabStyleDataView style={{ margin: '.5em 0 1em 0' }} conf={argumentList} />;
};

interface FormDataHandlerProps {
  actionList?: any[];
  id?: number;
  setRecipientList: any;
  setMailRecipientList: any;
}

const mapIdLabel = (data?: any[]) => data?.map((x, i) => ({ id: i, label: x })) ?? [];

const FormDataHandler: FC<FormDataHandlerProps> = ({ actionList, id, setRecipientList, setMailRecipientList }) => {
  const [oldActionId, setOldActionId] = useState<number>();
  const { watch, reset } = useFormContext();
  console.log('watch', watch());
  const actionId = watch('actionId');
  if (oldActionId === actionId || !!id) return <></>;
  setOldActionId(actionId);
  let action = actionList?.find((x) => x.id === actionId);
  if (!action) return <></>;
  setRecipientList(mapIdLabel(action?.availableSpecialRecipients));
  setMailRecipientList(mapIdLabel(action?.availableSpecialMailRecipients));
  const fillConf: NotificationConfiguration = {
    actionId: action.id,
    title: action.title,
    description: action.description,
    sendMail: action.sendMail ?? 0,
    initialScope: action.initialScope,
    url: BASE_URL + action.url,
    type: action.type ?? 0,
    messageTitle: action.title,
    message: action.message,
    mailTitle: action.mailTitle,
    mailBody: action.mailBody,
    familyId: 1,
    enabled: true,
    scope: 0,
  };
  reset(fillConf);
  return <></>;
};

/**
 * Transforme un tableau d'entier en string
 * @param arr tableau d'entier
 */
function setIntArrayToString(arr: any[]): string {
  if ((arr?.length ?? 0) < 1) return '';
  let result = '';
  for (let i = 0; i < arr.length; i++) result += arr[i].toString() + (i + 1 === arr.length ? '' : ',');
  return result;
}
