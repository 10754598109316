import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { SkStatus } from '../../../interface/SkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const SkStatusListConfig: CmsColumnDef<SkStatus>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
];

/**
 * Liste des états d'articles
 */
export const SkStatusList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_STATUS_CREATE])
    ? [<AddNavigationButton title="Ajouter un état d'article" to="/castres/stock/status/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des états d'articles"
      route={APIRoute.SkStatus}
      columns={SkStatusListConfig}
      navigateTo={(id: number) => `/castres/stock/status/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou modification d'un état d'article
 * @param id id du état d'article à modifier
 */
export const SkStatusCreateEdit: FC = ({ id }: any) => (
  <CmsForm id={id} currentUrl="/castres/stock/status/" route={APIRoute.SkStatus} title="un état d'article" thin>
    <CmsFormInput.Text id="label" label="Nom" required max={50} />
    <CmsFormInput.Text id="code" label="Code" required max={50} />
  </CmsForm>
);
