import React, { CSSProperties, FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './shared/shared.scss';
interface LoadingScreenProps {
  size?: number;
  style?: CSSProperties;
  over?: boolean;
  fullScreen?: boolean;
}

/**
 * Écran de chargement avec un spinner animé
 * @param size taille du spinner
 * @param style surcharge du style
 * @param over si true, l'écran de chargement est positionné en absolute et prend toute la page
 * @param fullScreen si true, l'écran de chargement prend toute la page
 */
const LoadingScreen: FC<LoadingScreenProps> = ({ size, style, over = false, fullScreen = false }) => {
  const classes = 'loading-screen' + (over ? ' over' : '') + (fullScreen ? ' full-screen' : '');
  return (
    <div className={classes} style={style}>
      <CircularProgress className="loader-icon" size={size ?? 120} />
    </div>
  );
};

export default LoadingScreen;
