import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { SENTRY_KEY, SENTRY_DSN, BASE_URL, FRONTEND_VERSION, SENTRY_SAMPLE_RATE } from 'constant/API_URL';

//si Sentry est configuré
if (SENTRY_KEY) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: BASE_URL.replace(/https?:/, '').replace(/\//g, ''),
    release: FRONTEND_VERSION,
    tracesSampleRate: SENTRY_SAMPLE_RATE,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: true,
      }),
    ],
  });
}

/**
 * Rendu de l'application React
 */
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
