import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';
import { EqAction } from 'interface/EqType';
import { Link } from '@mui/material';
import { BASE_URL } from 'constant/API_URL';

const EqActionConfigList: CmsColumnDef<EqAction>[] = [
  {
    header: 'Nom',
    id: 'name',
    Filter: CmsTableFilter.Search,
  },
  {
    header: 'Domaine',
    id: 'domainName',
    Filter: CmsTableFilter.Select,
    noClick: true,
    cell: (info) => (
      <Link
        key={info.row.original.id}
        style={{ color: 'inherit', textAlign: 'center' }}
        href={`${BASE_URL}castres/equipment/domain/${info.row.original.domainId}/edit`}
        target="_blank"
      >
        <div style={{ textAlign: 'left', fontWeight: 'bold' }}>{info.getValue()}</div>
      </Link>
    ),
  },

  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool },
  { header: 'VPrim (non utilisé)', id: 'vprim', Filter: CmsTableFilter.Bool },
  { header: 'Descellé (non utilisé)', id: 'unsealed', Filter: CmsTableFilter.Bool },
];

/**
 * Liste des comptes imputations de vente
 */
export const EqActionList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_EQ_ACTION_CREATE])
    ? [<AddNavigationButton title="Ajouter une action" to="/castres/equipment/action/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des actions"
      route={APIRoute.EqAction}
      columns={EqActionConfigList}
      navigateTo={(id: number) => `/castres/equipment/action/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const EqActionCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/equipment/action/', route: APIRoute.EqAction };
  return (
    <CmsForm id={id} title="Création d'une action" {...props} thin>
      <CmsFormInput.Text id="name" label="Nom" required />
      <CmsFormInput.Select id="domainId" label="Domaine" options={APIRoute.EqDomain} optionLabel="name" required />
      <CmsFormInput.Switch id="active" label="Actif" />
      <CmsFormInput.Switch id="vprim" label="VPrim (non utilisé)" />
      <CmsFormInput.Switch id="unsealed" label="Descellé (non utilisé)" />
    </CmsForm>
  );
};
