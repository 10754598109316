import CmsTableFilter from 'component/table/helper/CmsTableFilter';
import React, { FC, useState } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';
import { BlFixedCostContribution } from 'interface/BlType';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import { FirstLevelProps, MultiLevelForm } from 'component/form/MultiLevelForm';
import { CmsPaper } from 'component/shared/Ui';
import { MultiLevelInputNumber } from 'component/form/MultiLevelInput';
import CRUD from 'service/CRUD.service';
import NotificationService from 'service/NotificationService';
import { Buttons } from 'component/shared';
import { useNavigate } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';

export const BlFixedCostContributionListConfig: CmsColumnDef<BlFixedCostContribution>[] = [
  {
    header: 'Service',
    id: 'serviceId',
    cell: (info) => <CmsTableCell.Ref cell={info} value={info.row.original.serviceLabel} />,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService, optionLabel: 'label', multiple: true },
  },
  {
    header: 'Dates de début de validité',
    id: 'startDate',
    Filter: CmsTableFilter.Date,
  },
  {
    header: 'Dates de fin de validité',
    id: 'endDate',
    Filter: CmsTableFilter.Date,
  },
];

export const BlFixedCostContributionList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_CREATE])
    ? [<AddNavigationButton title="Ajouter une définition de coûts fixes" to="/castres/billing/fixedcost/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des définitions de coûts fixes"
      route={APIRoute.BlFixedCostContribution}
      columns={BlFixedCostContributionListConfig}
      navigateTo={(id: number) => `/castres/billing/fixedcost/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

const config: FirstLevelProps = {
  subListTitle: "Liste des Tranches de chiffre d'affaire",
  attrToList: 'blFixedCostContribution',
  subItem: {
    subListTitle: '',
    postRoute: APIRoute.BlFixedCostContributionLine,
    attrToList: 'blFixedCostContributionLine',
    parentIdName: 'blFixedCostContributionId',
    columnList: [
      { id: 'caMin', label: "Chiffre d'affaire minimum", FormComponent: MultiLevelInputNumber },
      { id: 'fixedCostPercentage', label: 'Pourcentage de coût fixe (en %)', FormComponent: MultiLevelInputNumber },
    ],
  },
};

export const BlFixedCostContributionCreateEdit: FC = ({ id }: any) => {
  const [formState, setFormState] = useState<any>({});
  const navigate = useNavigate();
  const onSubmitAll = () => {
    CRUD.put<BlFixedCostContribution>(APIRoute.BlFixedCostContribution + '/All', formState).then(() => {
      NotificationService.success('Les modifications ont bien été enregistrées');
      window.location.reload();
    });
  };

  return (
    <>
      <CmsForm
        id={id}
        currentUrl="/castres/billing/fixedcost/"
        route={APIRoute.BlFixedCostContribution}
        title="une définition de coûts fixes"
        thin
        onSubmit={(data: any, form: UseFormReturn) => {
          return CRUD.post<BlFixedCostContribution>(APIRoute.BlFixedCostContribution, data, !!id).then((result) => {
            if (!!id) onSubmitAll();
            navigate(`/castres/billing/fixedcost/${result.id}/edit`);
          });
        }}
      >
        <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} required />
        <CmsFormInput.Date id="startDate" label="Date de début de validité" required />
      </CmsForm>
      {!!id && (
        <CmsPaper
          title="Coût par tranches de chiffre d'affaires"
          actions={[<Buttons.Valid onClick={onSubmitAll}>Sauvegarder toutes les modifications</Buttons.Valid>]}
        >
          <MultiLevelForm
            id={id}
            getRoute={(id) => `${APIRoute.BlFixedCostContribution}/${id}`}
            config={config}
            onChange={setFormState}
          />
        </CmsPaper>
      )}
    </>
  );
};
