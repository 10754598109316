import React from 'react';
import AppRouter from './AppRouter';
import 'react-toastify/dist/ReactToastify.css';
import { authenticationService } from './service/Authentication.service';
import { dateToJsonHandler } from './helper/dateToJsonHandler';
import { cacheService } from './service/CacheService';
import setMomentToFrench from './helper/frenchMomentSetter';
import { CmsTheme } from './component/Theming';
import { GlobalProvider } from './context/GlobalProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';

// Globally Configure MomemtJS to french traduction
setMomentToFrench();
// Configure Json.stringify of Date for the entire app
dateToJsonHandler();

/**
 * Premier noeud de l'application, il s'occupe de la gestion du thème et de la gestion des authentifications
 */
const App: React.FC = () => {
  authenticationService.checkTokenExpiration();
  cacheService();
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <GlobalProvider>
          <CmsTheme>
            <AppRouter />
          </CmsTheme>
        </GlobalProvider>
      </LocalizationProvider>
    </div>
  );
};

export default App;
