import React, { FC } from 'react';
import APIRoute from '../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';

/**
 * Permet d'afficher la liste des stations de Greenflux sous forme de tableau
 */
const GreenfluxStationsList: FC = () => (
  <CmsFrontendTable
    title="Liste des stations de Greenflux"
    route={APIRoute.WkGreenfluxStations}
    columns={StationsListConfig}
    navigateTo={(id: string | number) => `/castres/greenflux/stations/${id}/show`}
    autoRefresh
    showFooter
    setFiltersInUrl
  />
);

/**
 * Structure des données affichées par station dans la liste
 */
const StationsListConfig: CmsColumnDef<any>[] = [
  { header: 'ID de la station', id: 'locationId', Filter: CmsTableFilter.Search },
  { header: 'Adresse de la station', id: 'address', Filter: CmsTableFilter.Select },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Select },
  { header: 'Code postal', id: 'postalCode', Filter: CmsTableFilter.Select },
  { header: 'Nom de la station', id: 'name', Filter: CmsTableFilter.Select },
  { header: 'Référence de la station du CMS', id: 'stationCms', Filter: CmsTableFilter.Select },
];

export default GreenfluxStationsList;
