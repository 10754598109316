import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlPaymentMode } from '../../../interface/BlType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

const BlPaymentModeConfigList: CmsColumnDef<BlPaymentMode>[] = [
  { header: 'Id', id: 'id' },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Select },
  { header: 'Nombre de jours', id: 'days' },
  { header: 'Fin de mois', id: 'eom', Filter: CmsTableFilter.Bool },
  { header: 'Par défaut', id: 'default', Filter: CmsTableFilter.Bool },
];

/**
 * Liste des modes de paiement
 */
export const BlPaymentModeList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_PAYMENTMODE_CREATE])
    ? [<AddNavigationButton title="Ajouter un mode de paiement" to="/castres/billing/paymentmode/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des modes de paiement"
      route={APIRoute.BlPaymentMode}
      columns={BlPaymentModeConfigList}
      navigateTo={(id: number) => `/castres/billing/paymentmode/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const BlPaymentModeCreateEdit: FC = ({ id }: any) => {
  const props = { currentUrl: '/castres/billing/paymentmode/', route: APIRoute.BlPaymentMode };
  return (
    <CmsForm id={id} title="un mode de paiement" {...props} thin>
      <CmsFormInput.Text id="label" label="Nom" required min={2} max={50} />
      <CmsFormInput.Number id="days" label="Nombre de jours" required />
      <CmsFormInput.Switch id="eom" label="Fin de mois" />
      <CmsFormInput.Switch id="default" label="Par défaut" />
    </CmsForm>
  );
};
