import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import CRUD from '../../service/CRUD.service';
import { MdGallery, MdMedia } from '../../interface/MdType';
import APIRoute from '../../constant/API.constant';
import LoadingScreen from '../../component/LoadingScreen';
import { HeaderPanel } from '../../component/shared/Ui';
import { Buttons, InputUI, UI } from '../../component/shared';
import Utils from '../../helper/Utils';
import { Card, ListItem, ListItemText } from '@mui/material';
import { ArrowBackNavigationButton } from '../../component/shared/Buttons';
import NotificationService from '../../service/NotificationService';
import List from '@mui/material/List';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MoreHoriz } from '@mui/icons-material';
import AccessFilter from '../../helper/AccessFilter';
import './station.scss';
import { IdLabel } from '../../interface/CommonType';
import Collapse from '@mui/material/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CmsIcon from '../../component/shared/CmsIcon';
import { GlobalContext } from '../../context/Global.context';
import { useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ROLE from '../../constant/role.constant';

interface MediaContextProps {
  // Liste des galeries d'une station
  galleryList?: MdGallery[];
  // Liste des médias(images) d'une galerie
  mediaList: MdMedia[];
  // La galerie sélectionnée
  gallery?: MdGallery;
  // Mets à jour la galerie sélectionnée
  setGallery: (g: MdGallery) => void;
  // Mets à jour la liste des galeries
  getGalleryList: (id: number) => void;
  // Mets à jour la liste des médias d'une galerie à la selection d'une galerie
  setMediaFromGallery: (gallery: MdGallery) => void;
  // Mets à jour la liste des médias
  refreshMediaList: () => void;
  // Réinitialise tout les boutons (checkbox) de la liste des médias
  clearMediaCheckBox: () => void;
  // Mets à jour la liste des médias dans le composant d'affichage des médias
  updateMediaList: (mediaList: MdMedia[]) => void;
  setMediaList: (x: MdMedia[]) => void;
  setGalleryList: (x: MdGallery[]) => void;
}

/**
 * Création du contexte des médias afin d'éviter le minage de props (prop drilling) dans la page
 */
const MediaContext = React.createContext<MediaContextProps>({
  galleryList: [],
  mediaList: [],
  gallery: undefined,
  setGalleryList: (x: MdGallery[]) => {},
  setGallery: (g: MdGallery) => {},
  setMediaList: (x: MdMedia[]) => {},
  getGalleryList: (id: number) => {},
  setMediaFromGallery: (gallery: MdGallery) => {},
  refreshMediaList: () => {},
  clearMediaCheckBox: () => {},
  updateMediaList: (mediaList: MdMedia[]) => {},
});

/**
 * Permet de récupérer le contexte des médias
 * @param props Props du composant, contient l'enfant passer en paramètre
 */
const MediaProvider = (props: { children: React.ReactNode }) => {
  const [galleryList, setGalleryList] = useState<MdGallery[]>();
  const [id, setId] = useState<number>();
  const [mediaList, setMediaList] = useState<MdMedia[]>([]);
  const [gallery, setGallery] = useState<MdGallery>();
  // const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const refreshMediaList = useCallback(() => {
    setMediaList([...mediaList]);
  }, [mediaList]);

  const setMediaFromGallery = useCallback(
    (gallery?: MdGallery) => {
      if (!gallery) return;
      let result = gallery.mediaList ?? [];
      if (gallery.subList) {
        for (const sub of gallery.subList) {
          result = [...result, ...(sub.mediaList ?? [])];
        }
      }
      setMediaList(result);
    },
    [setMediaList],
  );

  useEffect(() => {
    setMediaFromGallery(gallery);
    // setIsLoaded(false);
  }, [gallery, setMediaFromGallery]);

  useEffect(() => {
    if (!gallery) return;
    let target = `/castres/client/station/${id}/media`;
    if (gallery.origin === 'intervention') target += '?intervention=' + gallery.originId;
    else if (gallery.origin === 'task') target += '?task=' + gallery.originId;
    navigate(target);
  }, [gallery, navigate, id]);

  // useEffect(() => {
  //   if (isLoaded) return;
  //   const refresh = setInterval(() => {
  //     setMediaList([...mediaList]);
  //   }, 2000);
  //   return () => clearInterval(refresh);
  // }, [isLoaded, mediaList]);

  const clearMediaCheckBox = useCallback(() => {
    const updatedMediaList = mediaList.map((x) => ({ ...x, isChecked: false }));
    setMediaList(updatedMediaList);
  }, [mediaList]);

  const getGalleryList = useCallback(
    (newId: number) => {
      if (gallery || !newId || newId.toString() === id?.toString()) return;
      setId(newId);
      CRUD.getList<MdGallery>(`${APIRoute.MdGallery}/${newId}`).then((x) => setGalleryList(fillStationMedialist(x)));
    },
    [gallery, id, setId, setGalleryList],
  );

  const updateMediaList = useCallback(
    (updatedList: MdMedia[]) => {
      let isChanged = false;
      const newMediaList = mediaList.map((media) => {
        const updatedMedia = updatedList.find((m) => m.id === media.id);
        if (updatedMedia) {
          isChanged = true;
          return { ...media, ...updatedMedia };
        }
        return media;
      });

      if (isChanged) setMediaList(newMediaList);
    },
    [mediaList, setMediaList],
  );

  const providerValue = useMemo(
    () => ({
      galleryList,
      setGalleryList,
      clearMediaCheckBox,
      refreshMediaList,
      mediaList,
      setMediaList,
      gallery,
      setGallery,
      getGalleryList,
      setMediaFromGallery,
      updateMediaList,
    }),
    [
      galleryList,
      mediaList,
      gallery,
      clearMediaCheckBox,
      getGalleryList,
      refreshMediaList,
      updateMediaList,
      setMediaFromGallery,
    ],
  );

  return <MediaContext.Provider value={providerValue}>{props.children}</MediaContext.Provider>;
};

/**
 * Page des médias (show)
 * @param id Id de la station
 */
export const MediaPage: FC = ({ id }: any) => (
  <MediaProvider>
    <MediaPageWorkSpace id={id} />
  </MediaProvider>
);

/**
 * Espace de travail de la page des médias, contient les composants de la page
 * @param id Id de la station
 */
const MediaPageWorkSpace: FC<{ id: any }> = ({ id }: any) => {
  const { galleryList, getGalleryList, gallery, setGallery } = useContext(MediaContext);
  const [search, setSearch] = useState<string>();
  const [defaultKey, setDefaultKey] = useState<IdLabel>();
  const location = useLocation();

  useEffect(() => getGalleryList(id), [id, getGalleryList]);

  useEffect(() => {
    if (!galleryList || !!gallery) return;
    let urlData = location.search;
    const splittedData = urlData.slice(1, urlData.length).split('=');
    if ((splittedData[0] !== 'intervention' && splittedData[0] !== 'task') || !splittedData[1]) {
      setGallery(galleryList[0]);
      return;
    }
    let gal: MdGallery | undefined = undefined;
    let parent: MdGallery | undefined = undefined;
    if (splittedData[0] === 'intervention')
      gal = galleryList.find((x) => x.origin === 'intervention' && x.originId === +splittedData[1]);
    else if (splittedData[0] === 'task') {
      parent = galleryList.find((x) => !!x.subList?.find((y) => y.originId === +splittedData[1]));
      gal = parent?.subList?.find((y) => y.originId === +splittedData[1]);
    }
    if (!gal) return;
    setGallery(gal);
    setDefaultKey({ id: parent ? parent.originId : gal.originId, label: parent ? parent.label : gal.label });
  }, [gallery, galleryList, location.search, setGallery]);

  const filteredGallery: Array<MdGallery> = useMemo(() => {
    if (!galleryList) return [];
    if (!search) return galleryList;
    return galleryList.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
  }, [galleryList, search]);

  if (!galleryList) return <LoadingScreen />;
  return (
    <div className="media-pages">
      <MediaHeader stationId={id} setSearch={setSearch} />
      <div className="media-grid">
        {!!filteredGallery && <GalleryList filteredGallery={filteredGallery} defaultKey={defaultKey} />}
        {!!gallery && <GalleryView gallery={gallery} />}
      </div>
    </div>
  );
};

/**
 * Rempli la liste des médias de la station avec les médias des sous galeries
 * @param galleryList Liste des galeries
 */
function fillStationMedialist(galleryList: MdGallery[]): MdGallery[] {
  if (!galleryList || !galleryList[0]) return [];
  const stationGallery: MdGallery = galleryList[0];
  stationGallery.mediaList = stationGallery.mediaList ?? [];
  if (galleryList.length < 2) return galleryList;
  for (let i = 1; i < galleryList.length; i++) {
    stationGallery.mediaList = [...(stationGallery.mediaList ?? []), ...(galleryList[i].mediaList ?? [])];
    for (let subGallery of galleryList[i].subList ?? [])
      stationGallery.mediaList = [...(stationGallery.mediaList ?? []), ...(subGallery.mediaList ?? [])];
  }
  return galleryList;
}

/**
 * Composant de la barre de navigation
 * @param stationId Id de la station
 * @param setSearch Fonction de recherche par nom de galerie
 */
const MediaHeader: FC<{ stationId: number; setSearch: any }> = ({ stationId, setSearch }) => {
  return (
    <HeaderPanel
      title="Galerie"
      actions={[
        <CmsIcon href="/help/Galerie_photo/" icon="info" tooltip="Aide" target="_blank" />,
        <InputUI.DebouncedInput
          style={{ width: 300, marginBottom: 0 }}
          onChange={setSearch}
          placeholder="Rechercher une galerie ..."
        />,
        <ArrowBackNavigationButton
          access={[ROLE.ADMIN_PARK_STATION_LIST]}
          title="Voir le site"
          to={`/castres/client/station/${stationId}/show`}
        />,
      ]}
    />
  );
};

/**
 * Composant de la liste des galeries
 * @param filteredGallery Liste des galeries filtrées par la recherche
 * @param defaultKey Clé de la galerie par défaut
 */
const GalleryList: FC<{ filteredGallery: MdGallery[]; defaultKey?: IdLabel }> = ({ filteredGallery, defaultKey }) => {
  const { setGallery, clearMediaCheckBox } = useContext(MediaContext);
  const [keyOpen, setKeyOpen] = useState<IdLabel>();
  const [showEmptyGallery, setShowEmptyGallery] = useState(false);

  useEffect(() => {
    if (!!defaultKey) setKeyOpen(defaultKey);
  }, [defaultKey]);

  const handleMenuClick = (gallery: MdGallery) => {
    clearMediaCheckBox();
    setGallery(gallery);
    setKeyOpen(gallery.originId === keyOpen?.id ? {} : { id: gallery.originId, label: gallery.origin });
  };

  const fullTitle = (gallery: MdGallery): string => {
    let result = gallery.mediaList?.length ?? 0;
    for (let sub of gallery?.subList ?? []) result += sub.mediaList?.length ?? 0;
    return `${GalleryOrigin(gallery.origin)} - ${result} photo(s)`;
  };

  const filteredGalleryList = useMemo(() => {
    if (showEmptyGallery) return filteredGallery;
    return filteredGallery?.filter((x: MdGallery) => !fullTitle(x).includes('- 0 photo'));
  }, [filteredGallery, showEmptyGallery]);

  return (
    <UI.Paper
      title="Liste des galeries"
      style={{ marginBottom: '0px' }}
      scrollable
      actions={[
        <Buttons.ShowHideIcon
          toolTip="Montrer/Cacher les galleries vides"
          onClick={setShowEmptyGallery}
          active={showEmptyGallery}
        />,
      ]}
    >
      <List disablePadding dense>
        {!!filteredGalleryList &&
          filteredGalleryList.map((gallery, index) => (
            <Fragment key={gallery.originId ?? index}>
              <ListItem button key={gallery.originId ?? index} onClick={() => handleMenuClick(gallery)}>
                {index > 0 && (
                  <span>{keyOpen?.id === gallery.originId ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</span>
                )}
                <ListItemText primary={fullTitle(gallery)} secondary={gallery.name} />
              </ListItem>
              {!!gallery.subList && (
                <Collapse
                  key={'collapse' + gallery.originId}
                  in={keyOpen?.id === gallery.originId}
                  timeout="auto"
                  unmountOnExit
                  className="sub-list"
                >
                  <List component="div" disablePadding dense>
                    {gallery.subList.map((item) => (
                      <ListItem key={'media' + item.id} button onClick={() => setGallery(item)}>
                        <ListItemText className="media-task-list" primary={fullTitle(item)} secondary={item.name} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </Fragment>
          ))}
      </List>
    </UI.Paper>
  );
};

/**
 * Origine de la galerie (Site, Tâche, Intervention)
 * @param origin Origine de la galerie (Site, Tâche, Intervention)
 * @constructor
 */
const GalleryOrigin = (origin?: string): string =>
  origin === 'station' ? 'Site' : origin === 'task' ? 'Tâche' : 'Intervention';

/**
 * Selectionne la liste des médias sélectionnés par checkbox par l'utilisateur
 * @param mediaList Liste des médias
 */
function GetSelectedMedia(mediaList: MdMedia[]): number[] {
  const idList: any[] = [];
  for (let m of mediaList) if (m.isChecked) idList.push(m.id);
  if (idList.length === 0) NotificationService.error('Aucun fichier sélectionné');
  return idList;
}

/**
 * Composant de la liste des médias
 * @param gallery Galerie de media à afficher
 */
const GalleryView: FC<{ gallery: MdGallery }> = ({ gallery }) => {
  const { mediaList, refreshMediaList, clearMediaCheckBox, updateMediaList } = useContext(MediaContext);
  const [modal, setModal] = useState<MdMedia | undefined>();
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [openList, setOpenList] = useState<string[]>([]);
  const isAdmin = useRef(AccessFilter([ROLE.SUPER_ADMIN]));
  const navigate = useNavigate();

  const groupedMediaList = useMemo(() => {
    const favList = mediaList.filter((x) => x.isFavorite);
    let result = Utils.groupBySelector(mediaList, 'createdAt', 'createdAt');
    result = Utils.orderListByAttr(result, 'id', true);
    return favList.length > 0 ? [{ id: 'fav', label: 'Favoris', list: favList }, ...result] : result;
  }, [mediaList]);
  const orderedMediaList = useMemo(() => {
    let result: MdMedia[] = [];
    for (let item of groupedMediaList) result = [...result, ...item.list];
    return result;
  }, [groupedMediaList]);
  useEffect(() => {
    if (openList.find((x) => groupedMediaList.find((y) => y.id.toString() === x))) return;
    if (!groupedMediaList || groupedMediaList.length === 0) return;
    const result: string[] = [];
    for (let i = 0; i < 5; i++) result.push(groupedMediaList[i]?.id?.toString());
    setOpenList(result);
  }, [groupedMediaList, openList]);

  const handleCheckBox = (id: number) => {
    const media = mediaList.find((x) => x.id === id);
    media!.isChecked = !media!.isChecked;
    refreshMediaList();
  };

  const handleGroup = (type: string) => {
    const idList = GetSelectedMedia(mediaList);
    if (idList.length === 0) return;
    CRUD.put<any>(APIRoute.MdMedia + '/Group', { idList, type }).then((result) => {
      clearMediaCheckBox();
      updateMediaList(result);
      NotificationService.success('Modification effectuée');
    });
  };

  const downloadList = () => {
    const fileList: string[] = [];
    for (let m of mediaList) if (m.isChecked) fileList.push(m.path);
    if (fileList.length === 0) {
      NotificationService.error('Aucun fichier sélectionné');
      return;
    }
    if (fileList.length === 1) {
      CRUD.getBlob(`${APIRoute.MdMedia}/${fileList[0]}`).then((result) => {
        Utils.downloadFile(result, fileList[0]);
      });
      return;
    }
    CRUD.getBlob(APIRoute.MdMedia + '/Zip', fileList).then((result) => {
      Utils.downloadFile(result, 'photo_media.zip');
    });
  };

  const downloadAll = () => {
    const fileList = mediaList.map((m) => m.path);
    CRUD.getBlob(APIRoute.MdMedia + '/Zip', fileList).then((result) => {
      Utils.downloadFile(result, 'photo_media.zip');
    });
  };

  const goTo = () => {
    let target = `work/report/upload/${gallery.originId}`;
    if (gallery.origin === 'station') target = `/castres/client/station/${gallery.originId}/show`;
    else if (gallery.origin === 'intervention') target = `castres/work/intervention/${gallery.originId}/show`;
    gallery.origin === 'station' ? navigate(target) : Utils.openInSameTab(target);
  };

  const handleOpenList = (id: string) => {
    const isOpen = !!openList.find((x) => x === id);
    if (isOpen) setOpenList(openList.filter((x) => x !== id));
    else setOpenList([...openList, id]);
  };
  let arrowBackTitle = 'Rapport de la tâche';
  if (gallery.origin === 'station') arrowBackTitle = 'Voir le site';
  else if (gallery.origin === 'intervention') arrowBackTitle = "Voir l'intervention";

  const galleryName = `${GalleryOrigin(gallery.origin)} - ${gallery.name} - ${mediaList?.length ?? 0} photos`;
  return (
    <UI.Paper
      style={{ marginBottom: '0px' }}
      title={galleryName}
      onTitleClick={goTo}
      scrollable
      actions={[
        <CmsIcon icon="arrowBack" onClick={goTo} tooltip={arrowBackTitle} />,
        <CmsIcon icon="visible" onClick={() => handleGroup('privateOrPublic')} tooltip="Rendre public / privé" />,
        <CmsIcon icon="description" onClick={() => handleGroup('diagOrDesc')} tooltip="Diagnostic / Description" />,
        <CmsIcon icon="download" onClick={downloadList} tooltip="Télécharger" />,
        <CmsIcon icon="zip" onClick={downloadAll} tooltip="Tout Télécharger" visible={mediaList?.length > 0} />,
        <CmsIcon icon="upload" onClick={() => setUploadModal(true)} tooltip="Ajouter des photos" />,
        <CmsIcon icon="delete" onClick={() => handleGroup('active')} tooltip="Désactiver" />,
      ]}
    >
      <div>
        {!!groupedMediaList &&
          groupedMediaList.map((entry) => {
            return (
              <div key={'key' + entry.id.toString()} className="group-by-day">
                <h2 onClick={() => handleOpenList(entry.id.toString())}>
                  <span>{!!openList.find((x) => x === entry.id) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}</span>
                  {entry.label === 'Favoris' ? 'Favoris' : Utils.displayDate(entry.label)}
                </h2>
                <Collapse
                  key={'collapse-media-' + entry.id}
                  in={!!openList.find((x) => x === entry.id)}
                  timeout="auto"
                  unmountOnExit
                  className="sub-list"
                >
                  <div className="flex-h wrap">
                    {!!entry.list &&
                      entry.list.map((x: any) => (
                        <MediaShow
                          setModal={setModal}
                          media={x}
                          key={x.id}
                          onCheckboxClick={() => handleCheckBox(x.id)}
                          isAdmin={isAdmin.current}
                        />
                      ))}
                  </div>
                </Collapse>
              </div>
            );
          })}
      </div>
      <GalleryDialogView
        modal={modal}
        setModal={setModal}
        mediaList={orderedMediaList}
        updateMediaList={updateMediaList}
      />
      <UploadModal setUploadModal={setUploadModal} uploadModal={uploadModal} gallery={gallery} />
    </UI.Paper>
  );
};

interface GalleryDialogViewProps {
  // Media à afficher
  modal?: MdMedia;
  // Fonction pour fermer la modale
  setModal: (x: MdMedia | undefined) => void;
  // Liste des médias
  mediaList: MdMedia[];
  // Fonction pour mettre à jour la liste des médias
  updateMediaList: (medias: MdMedia[]) => void;
}

/**
 * Affiche une modale avec une image (média) avec des boutons pour la rotation et le zoom et de navigation entre les médias
 * @param modal Media à afficher
 * @param setModal Fonction pour fermer la modale
 * @param mediaList Liste des médias
 * @param updateMediaList Fonction pour mettre à jour la liste des médias
 */
const GalleryDialogView: FC<GalleryDialogViewProps> = ({ modal, setModal, mediaList, updateMediaList }) => {
  const [imgSize, setImgSize] = useState<any>({ h: 0, v: 0 });
  const [rotation, setRotation] = useState(0);
  const [reloadId, setReloadId] = useState(new Date().getTime());

  if (!modal) return <></>;

  const handleNavDiapo = (toTheRight: boolean) => {
    let index = mediaList.findIndex((x) => x.id === modal?.id);
    index = toTheRight ? ++index : --index;
    const max = mediaList.length - 1;
    index = index < 0 ? max : index > max ? 0 : index;
    setModal(mediaList[index]);
    setRotation(0);
  };

  const handleUpdateMediaList = (payload: any) => {
    setRotation(0);
    setReloadId(new Date().getTime());
    updateMediaList(payload);
  };

  let img = new Image();
  img.src = `/api/${APIRoute.MdMedia}/${modal.path}`;
  if (!!modal.forceUpdate) img.src += `?forceUpdate=${reloadId}`;
  img.onload = () => {
    if (imgSize.h === img.width && imgSize.v === img.height) return;
    setImgSize({ h: img.width, v: img.height });
  };
  const transform = `rotate(${rotation}deg)`;
  const isVertical = rotation === 0 || rotation === 180 || rotation === -180;
  const width = 80 * (imgSize.h / imgSize.v) + 'vh';
  const height = '80vh'; // isVertical ? '80vh' : 80 * (imgSize.v / imgSize.h) + 'vh';
  const style = { backgroundImage: `url(${img.src}`, height, width, transform };
  return (
    <UI.Dialog open={!!modal} onClose={() => setModal(undefined)}>
      <div
        className="pic-rotation-container flex-center"
        style={{ height: isVertical ? height : width, width: isVertical ? width : height }}
      >
        <div className="modal-container" style={style} />
      </div>
      <div className="nav-diapo right flex-center" onClick={() => handleNavDiapo(true)}>
        <ChevronRightIcon />
      </div>
      <div className="nav-diapo left flex-center" onClick={() => handleNavDiapo(false)}>
        <ChevronLeftIcon />
      </div>
      <MediaDialogToolBar
        media={modal}
        rotation={rotation}
        setRotation={setRotation}
        updateMediaList={handleUpdateMediaList}
      />
    </UI.Dialog>
  );
};

interface MediaToolBarProps {
  // Media à afficher
  media: MdMedia;
  // Rotation de l'image
  rotation: number;
  // Fonction pour mettre à jour la rotation de l'image
  setRotation: (rotation: number) => void;
  // Fonction pour mettre à jour la liste des médias
  updateMediaList: (medias: MdMedia[]) => void;
}

/**
 * Barre d'outils pour la modale d'affichage d'une image
 * @param media Media à afficher
 * @param rotation Rotation de l'image
 * @param setRotation Fonction pour mettre à jour la rotation de l'image
 * @param updateMediaList Fonction pour mettre à jour la liste des médias
 */
const MediaDialogToolBar: FC<MediaToolBarProps> = ({ media, rotation, setRotation, updateMediaList }) => {
  const [comment, setComment] = useState(media.comment);
  const [isFavorite, setIsFavorite] = useState(media.isFavorite);
  const [privateOrPublic, setPrivateOrPublic] = useState(media.privateOrPublic);
  const [diagOrDesc, setDiagOrDesc] = useState(media.diagOrDesc);
  const { theming } = useContext(GlobalContext);

  useEffect(() => {
    setRotation(0);
    setComment(media.comment);
    setPrivateOrPublic(media.privateOrPublic);
    setDiagOrDesc(media.diagOrDesc);
  }, [media, setRotation]);

  const handleSave = () => {
    const form = { id: media.id, rotation, privateOrPublic, comment, diagOrDesc, isFavorite };
    CRUD.put<MdMedia>(APIRoute.MdMedia, form).then(() => {
      NotificationService.success('Sauvegarde effectuée');
      media.comment = comment;
      media.privateOrPublic = privateOrPublic;
      media.diagOrDesc = diagOrDesc;
      media.forceUpdate = Math.random();
      media.updatedAt = new Date();
      media.isFavorite = isFavorite;
      updateMediaList([media]);
    });
  };

  const toSave =
    rotation !== 0 ||
    comment !== media.comment ||
    privateOrPublic !== media.privateOrPublic ||
    diagOrDesc !== media.diagOrDesc ||
    isFavorite !== media.isFavorite;
  return (
    <div style={{ background: theming.get().cms.main.paper }} className="media-tool-bar flex-h item-center">
      <Buttons.Icon icon="rotateLeft" toolTip="rotation" onClick={() => setRotation((rotation - 90) % 360)} />
      <Buttons.Icon icon="rotateRight" toolTip="rotation" onClick={() => setRotation((rotation + 90) % 360)} />
      <CmsIcon
        icon={diagOrDesc ? 'search' : 'description'}
        tooltip={diagOrDesc ? 'Diagnostic' : 'Description'}
        visible={media.haveReportItem}
        onClick={() => setDiagOrDesc(!diagOrDesc)}
      />
      <CmsIcon
        icon={privateOrPublic ? 'notVisible' : 'visible'}
        tooltip={privateOrPublic ? 'Privé' : 'Public'}
        visible={media.haveReportItem}
        onClick={() => setPrivateOrPublic(!privateOrPublic)}
      />
      <CmsIcon
        icon={isFavorite ? 'favorite' : 'favoriteBorder'}
        tooltip="Favoris"
        onClick={() => setIsFavorite(!isFavorite)}
      />
      <InputUI.DebouncedInput
        placeholder="Commentaire"
        value={comment}
        onChange={setComment}
        valueCanUpdate
        style={{ margin: '0 1rem', width: 'calc(40vw - 300px)' }}
      />
      <Buttons.Valid disabled={!toSave} onClick={handleSave}>
        Sauvegarder
      </Buttons.Valid>
    </div>
  );
};

interface UploadModalProps {
  // Fonction pour ouvrir ou fermer la modale
  setUploadModal: any;
  // Ouverture ou fermeture de la modale
  uploadModal: boolean;
  // Galerie dans laquelle on upload
  gallery: MdGallery;
}

interface MediaWithAttributes {
  // Fichier à uploader
  file: File;
  // Si le média est public ou privé
  privateOrPublic?: boolean;
  // Si le média est un diagnostic ou une description
  diagOrDesc?: boolean;
  // Commentaire du média
  comment: string;
  // Url du média
  url?: string;
  // Id de l'origine du média
  originId?: number;
  // Id de la galerie
  galleryId?: number;
}

/**
 * Modale de téléversement de média
 * @param setUploadModal Fonction pour ouvrir ou fermer la modale
 * @param uploadModal Ouverture ou fermeture de la modale
 * @param gallery Galerie dans laquelle on upload
 */
const UploadModal: FC<UploadModalProps> = ({ setUploadModal, uploadModal, gallery }) => {
  const [fileToUpload, setFileToUpload] = useState<MediaWithAttributes[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const { theming } = useContext(GlobalContext);

  const handleUpload = () => {
    if (isUploading) return;
    if (!fileToUpload || fileToUpload.length === 0) return;
    const result: any = [];
    for (let item of fileToUpload) {
      if (item.file.type !== 'image/jpeg' && item.file.type !== 'image/png') {
        NotificationService.error(`Le fichier ${item.file.name} n'est pas un jpg ou un png`);
        return;
      }
      result.push({ ...item, galleryId: gallery.id, origin: gallery.origin, originId: gallery.originId });
    }
    CRUD.postMultiple(APIRoute.MdMedia, result).then((success) => {
      if (!success) return;
      NotificationService.success('Photo téléversée avec succès');
      navigate(0);
    });
    setIsUploading(true);
  };

  useEffect(() => {
    if (!fileToUpload.find((x) => !x.url)) return;
    for (let item of fileToUpload) {
      item.diagOrDesc = false;
      item.privateOrPublic = true;
      item.url = URL.createObjectURL(item.file);
    }
    setFileToUpload([...fileToUpload]);
  }, [fileToUpload, setFileToUpload]);

  const handleFileToUpload = (files: File[]) => {
    const result: MediaWithAttributes[] = [];
    for (let file of files) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        NotificationService.error(`Le fichier ${file.name} n'est pas un jpg ou un png`);
        setFileToUpload([]);
        return;
      }
      result.push({ file, comment: '' });
    }
    setFileToUpload(result);
  };

  const handleCommentUpdate = (index: number, comment: string) => {
    fileToUpload[index].comment = comment;
    setFileToUpload([...fileToUpload]);
  };

  const handleCloseModal = () => {
    setFileToUpload([]);
    setIsUploading(false);
    setUploadModal(false);
  };

  const handleRadioChange = (index: number, value: number, type: string) => {
    // @ts-ignore
    if (fileToUpload[index]) fileToUpload[index][type] = value === 0;
    setFileToUpload([...fileToUpload]);
  };

  return (
    <UI.Dialog open={uploadModal} onClose={handleCloseModal}>
      <UI.Paper
        isLoading={isUploading}
        title="Téléverser des images"
        className="media-upload-modal"
        actions={[<Buttons.Icon icon="close" onClick={handleCloseModal} />]}
        scrollable
      >
        <InputUI.InputFile name="user-pic" id="input-file" onFileSelected={handleFileToUpload} image multiple />
        <UI.Divider />
        {fileToUpload.map((item, index) => (
          <Card style={{ background: theming.get().cms.main.paperLighter }} className="flex-h-bet media-row-to-upload">
            <div
              className={`media-to-upload ${!!gallery.haveReportWithNoScan ? 'report' : ''}`}
              style={{ backgroundImage: `url(${item.url})` }}
            />
            <div className="flex-v">
              <InputUI.DebouncedInput label="Commentaire" onChange={(x: string) => handleCommentUpdate(index, x)} />
              {!!gallery.haveReportWithNoScan && (
                <>
                  <InputUI.CmsRadio
                    defaultValue={0}
                    className="flex-h important nowrap"
                    value={fileToUpload[index].diagOrDesc ? 0 : 1}
                    onChange={(x: any) => handleRadioChange(index, x, 'diagOrDesc')}
                    options={[
                      { id: 0, label: 'Diagnostique' },
                      { id: 1, label: 'Description' },
                    ]}
                  />
                  <UI.Divider />
                  <InputUI.CmsRadio
                    defaultValue={1}
                    className="flex-h important nowrap"
                    value={fileToUpload[index].privateOrPublic ? 0 : 1}
                    onChange={(x: any) => handleRadioChange(index, x, 'privateOrPublic')}
                    options={[
                      { id: 0, label: 'Privé' },
                      { id: 1, label: 'Public' },
                    ]}
                  />
                </>
              )}
            </div>
          </Card>
        ))}
        <UI.Divider />
        <div className="flex-h-around">
          <Buttons.Valid disabled={!fileToUpload || fileToUpload.length === 0 || isUploading} onClick={handleUpload}>
            Téléverser
          </Buttons.Valid>
          <Buttons.Cancel onClick={handleCloseModal}>Abandonner</Buttons.Cancel>
        </div>
      </UI.Paper>
    </UI.Dialog>
  );
};

interface MediaShowProps {
  // Média à afficher
  media: MdMedia;
  // Fonction pour ouvrir/fermer la modale
  setModal: any;
  // Fonction pour cocher/décocher un média
  onCheckboxClick: any;
  // Si l'utilisateur est admin (change l'affichage des images cachées)
  isAdmin: boolean;
}

/**
 * Affiche une image
 * @param media le média à afficher
 * @param setModal fonction pour ouvrir/fermer la modale
 * @param onCheckboxClick fonction pour cocher/décocher un média
 * @param isAdmin si l'utilisateur est admin (change l'affichage des images cachées)
 */
const MediaShow: FC<MediaShowProps> = ({ media, setModal, onCheckboxClick, isAdmin }) => {
  const { active, path, comment, isChecked, haveReportItem, privateOrPublic, diagOrDesc, updatedAt } = media;

  if (!active && !isAdmin) return null;
  const key = `${media.id}-${updatedAt}`;
  let picUrl = `/api/${APIRoute.MdMedia}/mini/${path}`;
  if (media.forceUpdate) picUrl += `?forceUpdate=${media.forceUpdate}`;
  return (
    <div
      key={key + '-pic-container'}
      className="media-pic-container"
      style={{ backgroundColor: active ? '#aaa' : '#a00' }}
    >
      <div className="media-loading-container">
        <LazyLoadImage
          key={key}
          alt={path}
          effect="opacity"
          src={picUrl}
          className={`media-container${path ? ' loaded' : ''}`}
        />
        {comment ? (
          <div className="media-comment" onClick={() => setModal(media)}>
            <div className="flex-center">{comment}</div>
            <MoreHoriz />
          </div>
        ) : (
          <div className="modal-clicker" onClick={() => setModal(media)} />
        )}
        {haveReportItem && (
          <>
            <div className="media-icon-menu view flex-center">
              <CmsIcon
                icon={privateOrPublic ? 'notVisible' : 'visible'}
                tooltip={privateOrPublic ? 'Privé' : 'Public'}
              />
            </div>
            <div className="media-icon-menu diag flex-center">
              <CmsIcon
                icon={diagOrDesc ? 'search' : 'description'}
                tooltip={diagOrDesc ? 'Diagnostic' : 'Description'}
              />
            </div>
          </>
        )}
        <div className="media-icon-menu flex-center">
          <UI.CheckboxCustom id={media.id} onClick={() => onCheckboxClick(media.id)} checkState={!!isChecked} />
        </div>
      </div>
    </div>
  );
};
