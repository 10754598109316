import React from 'react';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Users: NavProps = {
  name: 'Utilisateurs',
  icon: <GroupAddIcon />,
  subList: [
    {
      name: 'Utilisateurs',
      link: '/sonata/user/user/list',
      feature: [ROLE.SONATA_USER_ADMIN_USER_LIST, ROLE.USER_IS_MANAGER],
    },
    {
      name: 'Groupes',
      link: '/sonata/user/group/list',
      feature: ROLE.SONATA_USER_ADMIN_GROUP_LIST,
    },
    {
      name: 'Roles',
      link: '/sonata/user/roles',
      feature: ROLE.SONATA_USER_ADMIN_GROUP_MASTER,
    },
  ],
};
