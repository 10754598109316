import React, { FC, useEffect, useState, useMemo } from 'react';
import { UsUsers } from '../../../interface/UsType';
import CRUD from '../../../service/CRUD.service';
import APIRoute from '../../../constant/API.constant';
import { IdLabel } from '../../../interface/CommonType';
import LoadingScreen from '../../../component/LoadingScreen';
import { InputUI, UI } from '../../../component/shared';
import NotificationService from '../../../service/NotificationService';
import { UserPackageStatus } from '../../user/User.pack';

/**
 * Page de gestion d'attribut des utilisateurs
 * Permet d'appliquer certaine valeur à un groupe d'utilisateur
 */
export const UsUserMacroHandling: FC = () => {
  const [userTotalList, setUserTotalList] = useState<UsUsers[]>([]);
  const [serviceList, setServiceList] = useState<IdLabel[]>([]);
  const [agencyList, setAgencyList] = useState<IdLabel[]>([]);
  const [legalEntityList, setLegalEntityList] = useState<IdLabel[]>([]);
  const [finalList, setFinalList] = useState<number[]>([]);
  const [state, setState] = useState<any>({});
  const [toUpdate, setToUpdate] = useState<any>({ cp: -1, rtt: -1, idList: [] });

  useEffect(() => {
    CRUD.getSimpleList(APIRoute.SfService).then(setServiceList);
    CRUD.getSimpleList(APIRoute.ClAgency).then(setAgencyList);
    CRUD.getSimpleList(APIRoute.ClLegalEntity).then(setLegalEntityList);
    CRUD.getList<any>(APIRoute.UsUsers + '/Light').then(setUserTotalList);
  }, []);

  const userList: UsUsers[] = useMemo(() => {
    if (!state.status) return userTotalList;
    return userTotalList.filter((x) => x.packageStatus === +state.status);
  }, [state, userTotalList]);

  if (userTotalList.length === 0) return <LoadingScreen />;

  const filterUserList = (attr: string, valueList: number[]): void => {
    let filterList = userList.filter((x: any) => valueList.includes(x[attr + 'Id']));
    setState({ [attr]: valueList, status: state.status });
    setFinalList(filterList.map((x) => x.id));
  };

  const apply = () => {
    if (!toUpdate.rtt && !toUpdate.cp) {
      NotificationService.error('Aucune valeur à appliquer');
      return;
    }
    if (finalList.length === 0) {
      NotificationService.error('Aucun utilisateur selectionné');
      return;
    }
    let userIdList = finalList;
    if (!!state.status) userIdList = finalList.filter((x) => !!userList.find((y) => y.id === x));
    CRUD.post(APIRoute.UsUsers + '/Macro', { ...toUpdate, userIdList }).then(() => {
      NotificationService.success('Information modifié');
    });
  };

  return (
    <div className="flex-h">
      <UI.Paper title="Filtres de sélections" style={{ width: '400px', marginRight: '1em' }}>
        <InputUI.AutoCompletor
          label="Entité juridique"
          value={state.legalEntity}
          onChange={(x: any) => filterUserList('legalEntity', x)}
          options={legalEntityList}
          multiple
        />
        <InputUI.AutoCompletor
          label="Agence"
          value={state.agency}
          onChange={(x: any) => filterUserList('agency', x)}
          options={agencyList}
          multiple
        />
        <InputUI.AutoCompletor
          label="Service"
          value={state.service}
          onChange={(x: any) => filterUserList('service', x)}
          options={serviceList}
          multiple
        />
      </UI.Paper>
      <UI.Paper
        title="Mise à jour des informations"
        style={{ maxWidth: '1200px', marginRight: '1em' }}
        actions={[<UI.Button onClick={apply}>Appliquer</UI.Button>]}
      >
        <InputUI.CMSTextField
          type="number"
          label="RTT"
          onChange={(rtt: any) => setToUpdate({ ...toUpdate, rtt: rtt !== '' ? +rtt : -1 })}
        />
        <InputUI.CMSTextField
          type="number"
          label="Congés payés"
          onChange={(cp: any) => setToUpdate({ ...toUpdate, cp: cp !== '' ? +cp : -1 })}
        />
        <UI.Divider />
        <InputUI.AutoCompletor
          label="Forfaitisé"
          value={state.status}
          onChange={(status?: any) => setState({ ...state, status })}
          options={UserPackageStatus}
        />
        <UI.Divider />
        <InputUI.AutoCompletor
          label="Utilisateur(s) sélectionné(s)"
          value={finalList}
          onChange={setFinalList}
          options={userList}
          optionLabel="getFormattedName"
          multiple
        />
      </UI.Paper>
    </div>
  );
};
