import React, { CSSProperties, FC, useContext } from 'react';
import { GlobalContext } from '../../../context/Global.context';
import { CmsPaper } from '../../shared/Ui';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material/';
import Utils from '../../../helper/Utils';

/**
 * Composant de synthèse par défaut pour les tableaux contenant des données de synthèse
 * Gère l'affichage des titre, quantité et montant total (si présent)
 * @param data Données de synthèse
 */
export const CmsSummaryTable: FC<{ summaryList: any[] }> = ({ summaryList }) => {
  const { theming } = useContext(GlobalContext);
  const getColor = (color?: string) => (/[0-9A-Fa-f]+/.test(color ?? '') ? '3px solid #' + color : 'none');
  const titleStyle: CSSProperties = { textAlign: 'center', fontWeight: 'bold' };
  const totalCount = summaryList.reduce((acc, item) => acc + (item.count ?? 0), 0);
  const getAmount = (amount?: number) => {
    if (!amount && amount !== 0) return <></>;
    return Intl.NumberFormat('fr', { notation: 'compact' }).format(amount) + '€';
  };
  const getPercentage = (percentage?: number): string => {
    return ' (' + (totalCount ? (((percentage ?? 0) / totalCount) * 100).toFixed(2) : '0') + '%)';
  };
  return (
    <CmsPaper style={{ padding: 0, backgroundColor: theming.get().cms.main.paperDif }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {summaryList.map((item, i) => (
              <TableCell size="small" style={{ borderBottom: getColor(item.statusColor), ...titleStyle }} key={i}>
                {item.statusLabel}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell size="small" style={{ fontWeight: 'bold' }}>
              Nombre
            </TableCell>
            {summaryList.map((item, i) => (
              <TableCell size="small" style={{ textAlign: 'center' }} key={i}>
                {(!!item.count || item.count === 0) && Utils.ThousandSpacing(item.count)}
                {!!item.count && getPercentage(item.count)}
              </TableCell>
            ))}
          </TableRow>
          {!!summaryList.find((item) => item.totalAmount) && (
            <TableRow>
              <TableCell size="small" style={{ fontWeight: 'bold' }}>
                Montant
              </TableCell>
              {summaryList.map((item, i) => (
                <TableCell size="small" style={{ textAlign: 'center' }} key={i}>
                  {getAmount(item.totalAmount)}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CmsPaper>
  );
};
