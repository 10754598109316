interface TitleWithAttr {
  title: string;
  attr: (x: any) => any | string;
}

/**
 * Export de données au format CSV
 * @param data Les données à exporter
 * @param columnList La liste des colonnes à exporter (titre et attribut)
 * @param fileName Le nom du fichier à télécharger
 */
function CsvExport(data: any[], columnList: TitleWithAttr[], fileName: string) {
  let csv = columnList.map((column) => `"${column.title}"`).join(';') + '\n';
  csv += data
    .map((row) => {
      return columnList
        .map((column) => {
          let value = typeof column.attr === 'function' ? column.attr(row) : row[column.attr];
          if (typeof value === 'string') value = value.replace(/"/g, '""');
          if (typeof value === 'boolean') value = value ? 'VRAI' : 'FAUX';
          if (value instanceof Date) value = value.toLocaleString();
          return value === undefined || value === null ? '' : `"${value}"`;
        })
        .join(';');
    })
    .join('\n');
  const link = window.document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv));
  link.setAttribute('download', fileName + '.csv');
  link.click();
  link.remove();
}

export default CsvExport;
