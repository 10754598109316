import { createTheme } from '@mui/material';

/**
 * Création du thème par défault de l'application
 */
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#61daff',
    },
    secondary: {
      main: '#ff8661',
    },
    text: {
      primary: '#DDDDDD',
      secondary: '#DDDDDD',
    },
  },
});

export const CmsColor = {
  blue: '#2196f3',
  red: '#f44336',
  orange: '#ff9800',
  green: '#4caf50',
  purple: '#650eb1',
  grey: '#0180d8',
};

export default theme;
