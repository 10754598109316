import BuildIcon from '@mui/icons-material/Build';
import React from 'react';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Material: NavProps = {
  name: 'Matériel',
  icon: <BuildIcon />,
  subList: [
    {
      name: 'Outils',
      link: '/castres/stock/tool/list',
      feature: ROLE.ADMIN_STOCK_TOOL_LIST,
    },
    {
      name: 'Historique des évènements',
      link: '/castres/stock/toolevent/list',
      feature: ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_LIST,
    },
  ],
};
