import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { SkFamily } from '../../../interface/SkType';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const SkFamilyListConfig: CmsColumnDef<SkFamily>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Parent', id: 'parentName', Filter: CmsTableFilter.Text },
];

/**
 * Liste des familles d'articles
 */
export const SkFamilyList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_FAMILY_CREATE])
    ? [<AddNavigationButton title="Ajouter une famille d'articles" to="/castres/stock/family/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des familles d'articles"
      route={APIRoute.SkFamily}
      columns={SkFamilyListConfig}
      navigateTo={(id: number) => `/castres/stock/family/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Créer ou modifier une famille d'articles
 * @param id Id de la famille d'articles à modifier
 */
export const SkFamilyCreateEdit: FC = ({ id }: any) => (
  <CmsForm id={id} currentUrl="/castres/stock/family/" route={APIRoute.SkFamily} title="une famille d'article" thin>
    <CmsFormInput.Text id="name" label="Nom" required max={50} />
    <CmsFormInput.Select id="parentId" label="Parent" options={APIRoute.SkFamily + '/Simplified'} />
  </CmsForm>
);
