import React from 'react';
import { NavProps } from './index';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ROLE from '../../../constant/role.constant';

export const Greenflux: NavProps = {
  name: 'Greenflux',
  icon: <AccountTreeIcon />,
  subList: [
    {
      name: 'CDR',
      link: '/castres/greenflux/cdr/list',
      feature: ROLE.ADMIN_GF_CDR_LIST,
    },
    {
      name: 'Stations',
      link: '/castres/greenflux/stations/list',
      feature: ROLE.ADMIN_GF_STATION_LIST,
    },
    {
      name: 'Notes de virement',
      link: '/castres/greenflux/NoteDeVirement/list',
      feature: ROLE.ADMIN_GF_NOTEDEVIREMENT_LIST,
    },
    {
      name: 'Exportation des notes de virement',
      link: '/castres/greenflux/NoteDeVirement/1/export',
      feature: ROLE.ADMIN_GF_NOTEDEVIREMENT_EXPORT,
    },
    {
      name: 'Paramètres',
      link: '/castres/greenflux/setting',
      feature: ROLE.ADMIN_GF_SETTING_VIEW,
    },
  ],
};
