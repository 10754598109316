import React, { FC, useContext, useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Utils from 'helper/Utils';
import { CmsButton, ColorDateLimit } from '../../shared/Ui';
import { InputUI } from '../../shared';
import { fetchPDF, fetchWithAuth, uploadFile } from '../../../helper/handle-request';
import NotificationService from '../../../service/NotificationService';
import { DialogUI } from '../../shared/DialogUI';
import { handleErrorNotif } from '../../../helper/handle-response';
import { css } from '@emotion/core';
import { Tooltip } from '@mui/material';
import { TextEllipse } from '../../shared/Ui';
import { GlobalContext } from '../../../context/Global.context';
import { useNavigate } from 'react-router-dom';

/**
 * Cellule affichant une date au format dd/MM/yyyy
 * @param value Date à afficher, si null, undefined ou 01/01/1970, affiche une chaine de caractères vide
 */
export const DateCell: FC<{ value: any }> = ({ value }) => {
  const result = (!!value && Utils.displayDate(value)) || '';
  return <span>{result === '01/01/1970' ? '' : result}</span>;
};

/**
 * Cellule affichant une date au format dd/MM/yyyy HH:mm (UTC)
 * @param value Date à afficher
 * @param withTime Affiche l'heure si true
 */
export const UtcDateCell: FC<{ value: any; withTime?: boolean }> = ({ value, withTime = true }) => {
  const result = (!!value && Utils.Date.displayUtcDate(value, withTime)) || '';
  return <span>{result === '01/01/1970 00:00 (UTC)' ? '' : result}</span>;
};

/**
 * Cellule affichant une date au format dd/MM/yyyy HH:mm
 * @param value Date à afficher
 * @param humanize mets la date au format : "sam. 10 mars 08:46"
 */
export const DateTimeCell: FC<{ value: any; humanize: boolean }> = ({ value = '', humanize = false }) => (
  <span>{value && Utils.displayDateTime(value, humanize)}</span>
);

/**
 * Cellule affichant une date relative au jour actuel (exemple : il y a 5 jours)
 * @param value Date à calculer pour afficher la date relative
 */
export const RelativeDateCell: FC<{ value: any }> = ({ value = '' }) => (
  <span>{value && Utils.displayRelativeDate(value)}</span>
);

/**
 * Cellule affichant une durée exprimée par la méthode Utils.displayDuration
 * @param start Date de début
 * @param stop Date de fin
 */
export const DurationCell: FC<{ start: any; stop?: any }> = ({ start = '', stop = '' }) => (
  <span>{Utils.displayDuration(start, stop)}</span>
);

/**
 * Cellule affichant un booleen sous forme d'icone
 * @param cell Cellule contenant la valeur à afficher
 */
export const BooleanCell = ({ cell }: any) => {
  const { theming } = useContext(GlobalContext);
  return cell.value ? (
    <CheckIcon style={{ color: theming.get().cms.severity.valid }}>valide</CheckIcon>
  ) : (
    <ClearIcon color="error">non valide</ClearIcon>
  );
};

/**
 * Cellule affichant un TextEllipse, se référez à la documentation de ce composant pour plus d'informations
 * @param value Valeur à afficher
 */
export const EllipseCell: FC<{ value: any }> = ({ value = '' }) => <TextEllipse text={value} />;

/**
 * Cellule affichant un date avec une couleur en fonction de temps restant avant aujourd'hui (rouge si date dépassée)
 * @param value Date à afficher
 */
export const DateWithLimitCell: FC<{ value: any }> = ({ value = '' }) => {
  const date = Utils.displayDate(value);
  if (['01/01/1', '01/01/1970', '01/01/0001'].includes(date)) return <></>;
  return <ColorDateLimit date={date} />;
};

/**
 * Cellule affichant un texte centré sur la colonne
 * @param value Valeur à afficher
 */
export const CenteredCell = ({ value }: any) => <div style={{ textAlign: 'center' }}>{value}</div>;

/**
 * Cellule affichant un booleen centré sur la colonne
 * @param cell Cellule contenant la valeur à afficher
 */
export const CenteredBooleanCell = ({ cell }: any) => <CenteredCell value={<BooleanCell cell={cell} />} />;

const ColorCellCss = css({
  borderRadius: '6px',
  height: '14px',
  width: '100%',
  maxWidth: '100px',
});

/**
 * Cellule affichant une couleur
 * @param value Code hexadécimal de la couleur à afficher
 */
export const ColorCell = ({ value }: any) => {
  if (!value) return <></>;
  if (!value.includes('#')) value = '#' + value;
  return (
    <Tooltip title={'Code: ' + value}>
      <div style={{ backgroundColor: value }} css={ColorCellCss} />
    </Tooltip>
  );
};

/**
 * Cellule affichant un lien clickable permettant de télécharger un fichier ou de naviguer vers une autre page
 * @param value Valeur à afficher
 * @param path Chemin vers lequel naviguer
 * @param row Ligne de la table contenant l'id de l'élément à afficher
 * @param url Url vers laquelle naviguer, si non renseigné, path + row_id sera utilisé
 */
export const NavigationCell: FC = ({ value, path, row, url }: any) => {
  //url : when link is not path + row_id
  const navigate = useNavigate();
  const id = row?.original?.id;
  if (!id && !url) return value;
  if (!url) url = path + id;

  return (
    <div style={{ cursor: 'pointer' }}>
      <Link onClick={() => navigate(url)}>{value}</Link>
    </div>
  );
};

interface FileCellDataProps {
  // Url vers lequel envoyer la requête de téléchargement / téléversement, si l'url contient un paramètre {id},
  // il sera remplacé par le targetId de l'élément à télécharger / téléverser
  url: string;
  // Id de l'élément à télécharger / téléverser
  targetId?: string | number;
  // Affiche un bouton de téléversement / téléchargement
  isUpload: boolean;
  // Surcharge l'attribut isUpload pour afficher un bouton de téléchargement
  isDownLoad: boolean;
  // Nom de l'attribut contenant le nom du fichier
  name?: string;
  // Nom du fichier après téléchargement
  filename?: string;
  // Libellé à afficher
  label?: string;
  // Affiche un bouton de suppression
  isDeletable?: boolean;
  // Titre de la modale de suppression
  deletionTitle?: string;
  // Texte de la modale de suppression
  deletionWarningText?: any;
  // Texte de la notification de suppression
  deleteSuccessNotifText?: string;
  // Texte de la notification de téléversement
  uploadSucessNotifText?: string;
}

/**
 * Cellule affichant un bouton de téléversement / téléchargement / suppression
 * @param deleteSuccessNotifText Texte de la notification de suppression
 * @param deletionTitle Titre de la modale de suppression
 * @param deletionWarningText Texte de la modale de suppression
 * @param filename Nom du fichier après téléchargement
 * @param isDeletable Affiche un bouton de suppression
 * @param isDownLoad Surcharge l'attribut isUpload pour afficher un bouton de téléchargement
 * @param isUpload Affiche un bouton de téléversement / téléchargement
 * @param label Libellé à afficher
 * @param name Nom de l'attribut contenant le nom du fichier
 * @param targetId Id de l'élément à télécharger / téléverser
 * @param uploadSucessNotifText Texte de la notification de téléversement
 * @param url Url vers lequel envoyer la requête de téléchargement / téléversement, si l'url contient un paramètre {id},
 */
export const FileCell: FC<FileCellDataProps> = ({
  deleteSuccessNotifText = 'Fichier supprimer avec succès',
  deletionTitle = 'Suppression',
  deletionWarningText = 'Êtes vous sûre de vouloir supprimé cet élément ?',
  filename = 'filename',
  isDeletable = false,
  isDownLoad,
  isUpload,
  label = 'Envoyé',
  name = 'name',
  targetId,
  uploadSucessNotifText = 'Fichier enregistré avec succès',
  url,
}) => {
  const [file, setFile] = useState<any>(null);
  const [upload, setUpload] = useState<boolean>(isUpload);
  const [downLoad, setdownload] = useState<boolean>(isDownLoad);
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    setUpload(isUpload);
    setdownload(isDownLoad);
  }, [isUpload, isDownLoad]);

  if (!isDownLoad && !isUpload) return <></>;
  let parsedUrl;
  if (targetId) {
    parsedUrl = url.split('{id}');
    url = parsedUrl[0] + targetId + parsedUrl[1];
  }
  const handleUploadClick = () => {
    if (!file) return;
    uploadFile({ url, file }).then(() => {
      NotificationService.success(uploadSucessNotifText);
      setdownload(true);
      setUpload(false);
    });
  };

  const handleDownloadClick = () => {
    fetchPDF({ url, filename }).then(() => {});
  };

  const handleDeleteConfirmation = () => {
    fetchWithAuth({ url, method: 'DELETE' })
      .then((result) => {
        NotificationService.warning(deleteSuccessNotifText);
      })
      .catch(handleErrorNotif)
      .then(() => {
        setUpload(true);
        setdownload(false);
      });
    setModal(false);
  };

  if (downLoad) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '240px' }}>
        <CmsButton name="downloadBlob" onClick={handleDownloadClick}>
          Télécharger
        </CmsButton>
        {isDeletable && (
          <CmsButton style={{ marginLeft: '5px' }} color="secondary" name="downloadBlob" onClick={() => setModal(true)}>
            Supprimer
          </CmsButton>
        )}
        <DialogUI
          open={modal}
          onClose={() => setModal(false)}
          onValidation={handleDeleteConfirmation}
          title={deletionTitle}
          body={deletionWarningText}
        />
      </div>
    );
  }

  if (upload) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '240px' }}>
        <InputUI.InputFile name={name} id={targetId?.toString()} onFileSelected={setFile} height="30px" pdfOnly />
        <CmsButton style={{ marginLeft: '5px' }} name={name} onClick={handleUploadClick}>
          {label}
        </CmsButton>
      </div>
    );
  }
  return <></>;
};

interface ActionCellDataProps {
  // URL sur l'API de l'action à effectuer
  url: string;
  // ID de l'élement à modifier, remplace le {id} de l'URL
  targetId?: number | string;
  // Méthode HTTP à utiliser pour l'action (default=POST)
  method?: string;
  // Icône à afficher sur le bouton (sinon label)
  icon?: any;
  // Nom du bouton affiché en passant la souris dessus, ou directement s'il n'y a pas d'icône
  label?: string | any;
  // Condition pour l'affichage du bouton (rôle, état de l'élément)
  isAvailable?: boolean;
  // Mettre à false pour désactiver la demande de confirmation
  isConfirmable?: boolean;
  // Cellule à afficher si isAvailable est à false
  defaultCell?: any;
  // Couleur du MuiButton
  color?: any;
  // Titre de la demande de confirmation
  confirmTitle?: string;
  // Texte de la demande de confirmation
  confirmText?: any;
  // Message affiché en cas de succès de l'action
  successNotifText?: string;
  // Fonction exécutée lors du succès de l'appel au serveur permettant de modifier les données
  cellClick?: any;
  // URL à ouvrir en cas de succès de l'action (dans un nouvel onglet) - le {id} sera remplacé par le id du result reçu
  urlOnSuccess?: string;
}

/**
 * Cellule d'action, bouton avec modal de confirmation que l'action doit bien être effectuée
 * @param cellClick Fonction exécutée lors du succès de l'appel au serveur permettant de modifier les données
 * @param color Couleur du MuiButton
 * @param confirmText Texte de la demande de confirmation
 * @param confirmTitle Titre de la demande de confirmation
 * @param defaultCell Cellule à afficher si isAvailable est à false
 * @param icon Icône à afficher sur le bouton (sinon label)
 * @param isAvailable Condition pour l'affichage du bouton (rôle, état de l'élément)
 * @param isConfirmable Mettre à false pour désactiver la demande de confirmation
 * @param label Nom du bouton affiché en passant la souris dessus, ou directement s'il n'y a pas d'icône
 * @param method Méthode HTTP à utiliser pour l'action (default=POST)
 * @param successNotifText Message affiché en cas de succès de l'action
 * @param targetId ID de l'élement à modifier, remplace le {id} de l'URL
 * @param url URL sur l'API de l'action à effectuer
 * @param urlOnSuccess URL à ouvrir en cas de succès de l'action (dans un nouvel onglet)
 */
export const ActionCell: FC<ActionCellDataProps> = ({
  cellClick,
  color,
  confirmText = 'Êtes-vous sûr de vouloir effectuer cette action ?',
  confirmTitle = "Confirmation de l'action",
  defaultCell = <></>,
  icon,
  isAvailable = true,
  isConfirmable = true,
  label = 'Action',
  method = 'POST',
  successNotifText = 'Action effectuée avec succès.',
  targetId,
  url,
  urlOnSuccess,
}) => {
  const [modal, setModal] = useState<boolean>(false);

  if (!isAvailable) return defaultCell;

  let parsedUrl;
  if (targetId) {
    parsedUrl = url.split('{id}');
    url = parsedUrl[0] + targetId + parsedUrl[1];
  }

  const handleConfirmation = () => {
    fetchWithAuth({ url, method })
      .then(async (result: Response) => {
        if (cellClick) cellClick(targetId);
        NotificationService.success(successNotifText);
        if (urlOnSuccess && result.ok) {
          const bodyResult = await result.json();
          const resultId = bodyResult.id;
          urlOnSuccess = urlOnSuccess.replace('{id}', resultId.toString());
          window.open(urlOnSuccess, '_blank');
        }
      })
      .catch(handleErrorNotif);
    setModal(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '240px' }}>
      <Tooltip title={label}>
        <div>
          <CmsButton
            style={{ marginLeft: '5px' }}
            color={color}
            onClick={() => (isConfirmable ? setModal(true) : handleConfirmation())}
          >
            {icon ? icon : label}
          </CmsButton>
        </div>
      </Tooltip>
      <DialogUI
        open={modal}
        onClose={() => setModal(false)}
        onValidation={handleConfirmation}
        title={confirmTitle}
        body={confirmText}
      />
    </div>
  );
};

const ColorLabelCellCss = css({
  display: 'inline-block',
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  textAlign: 'center',
  verticalAlign: 'middle',
  borderRadius: '6px',
});

export const ColorLabelCell = ({ label, color }: any) => {
  if (!color && !label) return <></>;
  if (!color.includes('#')) color = '#' + color;
  return (
    <h5 style={{ backgroundColor: color }} css={ColorLabelCellCss}>
      {label}
    </h5>
  );
};
