import React, { FC } from 'react';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlStatusOrder } from '../../../interface/BlType';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import ROLE from '../../../constant/role.constant';

export const BlStatusOrderConfigList: CmsColumnDef<BlStatusOrder>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Description', id: 'description', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
];

export const BlStatusOrderList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste des statuts des commandes"
      route={APIRoute.BlStatusOrder}
      columns={BlStatusOrderConfigList}
      navigateTo={(id: number) => `/castres/billing/statusorder/${id}/edit`}
      actions={[
        <AddNavigationButton
          key="nav"
          title="Créer un statut de commande"
          to={`/castres/billing/statusorder/create`}
          access={[ROLE.ADMIN_CONFIGURATION_BILLING_STATUSORDER_CREATE]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

export const BlStatusOrderCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlStatusOrder, title: 'Statuts des commmandes' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/statusorder/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="description" label="Description" required multiline max={255} />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
      <CmsFormInput.Color id="color" label="Couleur" withoutSharp required />
    </CmsForm>
  );
};
