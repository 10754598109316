import React from 'react';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const RH: NavProps = {
  name: 'RH',
  icon: <PermIdentityIcon />,
  subList: [
    {
      name: 'Absences',
      link: '/castres/staff/absence/list',
      feature: ROLE.ADMIN_STAFF_ABSENCE_VIEW,
    },
    {
      name: 'Équipes',
      link: '/castres/staff/team/list',
      feature: ROLE.ADMIN_STAFF_TEAM_LIST,
    },
    {
      name: "Feuille d'heure",
      link: '/castres/timereporting/',
      // Droit différent pour le bouton Feuille d'heure dans le menu activité
      // Celui-ci est réservé au RH et ceux qui ont les droits d'édition
      feature: [ROLE.USER_IS_MANAGER, ROLE.ADMIN_TIMEREPORTINGWEEK_OPERATOR, ROLE.ADMIN_TIMEREPORTINGWEEK_MASTER],
    },
    {
      name: "Admin feuille d'heure",
      link: '/castres/timereporting/administration',
      feature: [ROLE.USER_IS_MANAGER, ROLE.ADMIN_TIMEREPORTINGWEEK_OPERATOR, ROLE.ADMIN_TIMEREPORTINGWEEK_MASTER],
    },
    {
      name: 'Habilitation',
      link: '/castres/staff/habilitation/list',
      feature: ROLE.ADMIN_STAFF_TRAINING_LIST,
    },
    {
      name: 'Formation',
      link: '/castres/staff/formation/list',
      feature: ROLE.ADMIN_STAFF_TRAINING_LIST,
      tabulation: true,
    },
    {
      name: 'Mise à jour globale CP et RTT ',
      link: '/sonata/user/user/macro',
      feature: ROLE.SONATA_USER_ADMIN_USER_MASTER,
    },
  ],
};
