import React, { FC } from 'react';
import AccessFilter from 'helper/AccessFilter';
import APIRoute from '../../constant/API.constant';
import { ActionCell, FileCell } from '../../component/table/helper/customCells';
import { API_URL } from '../../constant/API_URL';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import { SkToolEvent } from '../../interface/SkType';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import DeleteIcon from '@mui/icons-material/Delete';
import ROLE from '../../constant/role.constant';
import CmsTableCell from '../../component/table/helper/CmsTableCell';

const unitValue = (value: any, unit: string) => (value ? `${value} ${unit}` : '');

export const ToolEventListConfig: CmsColumnDef<SkToolEvent>[] = [
  {
    header: 'Référence outil',
    id: 'toolRef',
    Filter: CmsTableFilter.Text,
    noClick: true,
    cell: (x) => <CmsTableCell.Link label={x.getValue()} url={`/castres/stock/tool/${x.row.original.toolId}/show`} />,
  },
  { header: 'N° Serie outil', id: 'toolSerial', Filter: CmsTableFilter.Text },
  { header: 'Date', id: 'eventDate', Filter: CmsTableFilter.Date },
  { header: 'Modèle', id: 'toolModel', Filter: CmsTableFilter.Text },
  { header: 'Changement de statut', id: 'statusChange', Filter: CmsTableFilter.Text },
  { header: 'Technicien', id: 'user', Filter: CmsTableFilter.Text },
  { header: 'Date de validité', id: 'validateStartDate', Filter: CmsTableFilter.Date },
  { header: 'Commentaire', id: 'comment', Filter: CmsTableFilter.Text },
  { header: 'Créé par', id: 'updatedByName' },
  { header: 'Volume nominal', id: 'nominalVolume', cell: (x) => unitValue(x.getValue(), 'L') },
  { header: 'Volume au trait', id: 'lineVolume', cell: (x) => unitValue(x.getValue(), 'cm³') },
  { header: 'Hauteur du trait', id: 'lineHeight', cell: (x) => unitValue(x.getValue(), 'mm') },
  { header: 'Sensibilité', id: 'sensibility', cell: (x) => unitValue(x.getValue(), 'cm³/mm') },
  { header: 'Incertitude type élargie', id: 'expandedUncertainty', cell: (x) => unitValue(x.getValue(), '%') },
  {
    header: 'Certificat',
    id: 'certificate',
    noClick: true,
    cell: (x) =>
      FileCell({
        ...x.getValue(),
        url: `${API_URL}SkToolEvent/{id}/certificate`,
        filename: 'certificat-outil',
        isDeletable: AccessFilter([ROLE.SUPER_ADMIN]),
        deletionTitle: 'Suppression de certificat',
        deletionWarningText: (
          <div>
            <div>Êtes-vous sûr de vouloir supprimer ce certificat ?</div>
            <div className="error-color">Attention cette action est irréversible</div>
          </div>
        ),
      }),
  },
  {
    header: '',
    id: 'notUsed',
    noClick: true,
    cell: (x) => (
      <ActionCell
        url={`${API_URL}SkToolEvent/{id}`}
        isAvailable={AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_DELETE])}
        method="delete"
        targetId={x?.row?.original?.id}
        icon={<DeleteIcon />}
        label="Supprimer un évènement"
        confirmTitle="Supprimer un évènement"
        confirmText="Êtes-vous sûr de vouloir supprimer cet évènement d'outil ?"
        successNotifText="Évènement supprimé avec succès"
        // cellClick={x.cell.cellClick}
      />
    ),
  },
  { header: 'Identifiant', id: 'id', hide: true },
];

/**
 * Liste des événements d'outils
 */
const ToolEventList: FC = () => {
  return (
    <CmsFrontendTable
      title="Liste des événements d'outils"
      route={APIRoute.SkToolEvent}
      navigateTo={(_, cell) => `/castres/stock/tool/${cell.row.original.toolId}/show`}
      columns={ToolEventListConfig}
      downloadable={
        AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EXPORT, ROLE.ADMIN_CONFIGURATION_STOCK_TOOLEVENT_EXPORT])
          ? 'csv'
          : undefined
      }
      setFiltersInUrl
    />
  );
};

export default ToolEventList;
