import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  ListNavigationButton,
  PayLoadButton,
  ViewNavigationButton,
} from '../../../component/shared/Buttons';
import { CmsButton, CmsPaper, HeaderPanel, WarningBubble } from '../../../component/shared/Ui';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { ClClient, ClContact, ClStation } from '../../../interface/ClType';
import CRUD from '../../../service/CRUD.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { IdLabel, LabelValue } from '../../../interface/CommonType';
import LoadingScreen from '../../../component/LoadingScreen';
import Grid from '@mui/material/Grid';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { GCElement, GlobalContext } from '../../../context/Global.context';
import { CmsForm, CmsFormSubmit, FormSkeleton } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import notificationService from '../../../service/NotificationService';
import CmsFilterCalculator from '../../../component/table/helper/CmsTableFilterCalculator';
import { ClContactConfigList } from '../contact/ClContact.pack';
import { ImportClientInseeData, InseeNameCityLinkIcon, SiretLinkIcon } from '../../../component/other/InseeImport';
import Utils from '../../../helper/Utils';
// import MapCustom from '../../../component/map/GoogleMapCustom';
import { UI } from '../../../component/shared';
import { SkCompany } from 'interface/SkType';
import { JoinTableHandler } from '../../../component/table/JoinTableHandler';
import CmsIcon from '../../../component/shared/CmsIcon';
import { StationListConfig } from '../../station/StationList';
import { CMS_FRONTEND_ENVIRONMENT } from '../../../constant/API_URL';
import { CmsMenuButton } from '../../../component/shared/Menu';
import ROLE from '../../../constant/role.constant';
import { ImportClientContact, ImportClientStation } from './ClClientInsee';
import { OsmMapShow } from '../../../component/map/OsmMap';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import { BASE_URL } from 'constant/API_URL';

const ShowClientProspect: FC<{ value: any }> = ({ value }) => {
  if (value === '_client') return <>Client</>;
  if (value === '_prospect') return <>Prospect</>;
  return <></>;
};

export const ClClientConfigList: CmsColumnDef<ClClient>[] = [
  { header: 'Raison sociale', id: 'label', Filter: CmsTableFilter.Text, size: 200 },
  { header: 'Nom commercial', id: 'commercialName', Filter: CmsTableFilter.Text },
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Search, size: 50 },
  { header: 'Téléphone', id: 'tel', Filter: CmsTableFilter.Text, size: 50 },
  { header: 'Code Postal', id: 'postcode', Filter: CmsTableFilter.Text, size: 50 },
  { header: 'Adresse de facturation', id: 'billingAddress', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Adresse siège social', id: 'address', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  {
    header: 'Type',
    id: 'type',
    Filter: CmsTableFilter.Select,
    filterOptions: {
      optionList: [
        { label: 'Client', id: '_client' },
        { label: 'Prospect', id: '_prospect' },
      ],
    },
    cell: (info) => <ShowClientProspect value={info.getValue()} />,
    size: 50,
  },
  {
    header: 'Rattachement client',
    id: 'codeId',
    Filter: CmsTableFilter.Select,
    hide: true,
    filterOptions: { optionList: APIRoute.ClClientCode, multiple: true },
  },
  {
    header: 'Enseignes liées',
    id: 'companyList',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterFn: CmsFilterCalculator.containsSomeId,
    filterOptions: { optionList: APIRoute.ClCompany, multiple: true },
    cell: (x) =>
      x
        .getValue()
        .map((y: any) => y.label)
        .join(', '),
  },
  { header: 'SIRET', id: 'siret', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Date synchronisation API', id: 'lastApiSync', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  {
    header: 'Synchronisation API par',
    id: 'lastApiSyncByName',
    Filter: CmsTableFilter.Select,
    hide: 'hiddenByDefault',
  },
  { header: 'Synchronisé API', id: 'isApiSync', Filter: CmsTableFilter.Bool, size: 20, hide: 'hiddenByDefault' },
  {
    header: <div className="text-center w100">Annuaire... </div>,
    id: 'none1',
    backgroundColor: {
      dark: 'rgb(48,53,66)',
      light: 'rgb(213,208,181)',
    },
    columns: [
      {
        header: 'Choisi',
        id: 'none2',
        cell: (info) => <SiretLinkIcon siret={info.row.original.siret} />,
        size: 0,
        noClick: true,
      },
      {
        header: 'Proposé',
        id: 'none3',
        cell: (info) => <InseeNameCityLinkIcon name={info.row.original.label} city={info.row.original.city} />,
        size: 0,
        noClick: true,
      },
    ],
  },
  { header: 'SIRET valide', id: 'haveSiret', Filter: CmsTableFilter.Bool, size: 20, hide: true },
  { header: 'SIRET mis à jour le', id: 'sirenUpdatedAt', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour par', id: 'sirenUpdatedByName', Filter: CmsTableFilter.Select, hide: 'hiddenByDefault' },
  { header: 'Professionnel', id: 'professional', Filter: CmsTableFilter.Bool, size: 20, hide: 'hiddenByDefault' },
  { header: 'Bloqué', id: 'locked', Filter: CmsTableFilter.Bool, hide: 'hiddenByDefault', defaultFilterValue: false },
  {
    header: 'En activité',
    id: 'active',
    Filter: CmsTableFilter.Bool,
    hide: 'hiddenByDefault',
    defaultFilterValue: true,
  },
  {
    header: (
      <div className="flex-h align-center">
        Dupliqué
        <CmsIcon
          icon="info"
          tooltip="Client en doublon dont il en reste à bloquer pour qu'ils puissent être fusionnés plus tard (seules les entités ayant fait l'objet d'un import INSEE seront fusionnés)"
        />
      </div>
    ),
    id: 'haveDuplicatedSiret',
    hide: 'hiddenByDefault',
    size: 0,
    Filter: CmsTableFilter.Bool,
    noClick: true,
    cell: (info) => (
      <CmsTableCell.BoolWithInfo
        cell={info}
        tooltip={info.row.original?.duplicatedSiret?.map(({ label }) => `${label}`).join(' || ')}
        url={`${BASE_URL}castres/client/client/list?t-siret=${info.row.original?.siret}=1`}
      />
    ),
  },
];

export const ClClientList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CLIENT_CREATE])
    ? [<AddNavigationButton title="Ajouter un client" to="/castres/client/client/create" />]
    : [];
  return (
    <CmsFrontendTable
      route={APIRoute.ClClient}
      columns={ClClientConfigList}
      title="Liste des clients"
      navigateTo={(id: number) => `/castres/client/client/${id}/show`}
      actions={[
        <CmsIcon href="/help/Client/" icon="info" tooltip="Aide" target="_blank" />,
        <DownLoadClientPdf />,
        ...actions,
      ]}
      setFiltersInUrl
      downloadable={AccessFilter([ROLE.ADMIN_CLIENT_EXPORT]) && 'csv&excel'}
      globalTable={{ target: GCElement.CLIENT }}
      autoRefresh
    />
  );
};

const DownLoadClientPdf: FC = () => {
  let env = CMS_FRONTEND_ENVIRONMENT.replace('tmp_value', ''); //pour que ce soit conservé à la minification
  const handleC4AndRousseau = () => {
    if (env === 'c4energies') handleDownload('C4_Ouverture_Compte_clients.pdf');
    else handleDownload('ROUSSEAU_Ouverture_Compte_clients.pdf');
  };
  const handleDownload = (url: string) => Utils.downloadFile('/staticFiles/' + url, url);
  if (env === 'c4energies' || env === 'rousseau')
    return <CmsButton onClick={handleC4AndRousseau}>Télécharger PDF ouverture client</CmsButton>;
  return (
    <CmsMenuButton title="Télécharger PDF ouverture client" preventClose>
      <span onClick={() => handleDownload('Castres_Ouverture_Compte_clients.pdf')}>Castres</span>
      <span onClick={() => handleDownload('Euro_Stations_Ouverture_Compte_clients.pdf')}>Euro Station</span>
      <span onClick={() => handleDownload('Sid_Ouverture_Compte_clients.pdf')}>Sid</span>
    </CmsMenuButton>
  );
};

export const ClClientShow: FC = ({ id }: any) => {
  const [client, setClient] = React.useState<ClClient | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/castres/client/client/list');
    else CRUD.getById<ClClient>(APIRoute.ClClient, id).then(setClient);
  }, [navigate, id]);

  if (!client) return <LoadingScreen />;
  return (
    <>
      <HeaderPanel
        title={`${client?.label}`}
        subtitle={`(${client.ref})`}
        actions={[
          <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/client/client/list" />,
          AccessFilter([ROLE.ADMIN_CLIENT_EDIT]) && (
            <EditNavigationButton title="Éditer le client" to={`/castres/client/client/${client?.id}/edit`} />
          ),
        ]}
      />
      <Locker client={client} />
      {!client.locked && <WarnDuplicateSiret siret={client.siret} id={id} />}
      {!!client.latitude && !!client.longitude && (
        <CmsPaper>
          <OsmMapShow setCenter={{ lat: client.latitude, lng: client.longitude }} />
        </CmsPaper>
      )}
      <Grid container spacing={2}>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Contact" className="client-contact">
            <TabStyleDataView conf={ClientContactConfig} mapFrom={client} />
          </CmsPaper>
        </Grid>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Général" className="client-general">
            <TabStyleDataView conf={ClientGeneralConfig} mapFrom={client} />
          </CmsPaper>
        </Grid>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Administratif" className="client-bank" actions={[<SiretLinkIcon siret={client.siret} />]}>
            <TabStyleDataView conf={ClientBankConfig} mapFrom={client} />
          </CmsPaper>
        </Grid>
      </Grid>
      <ClClientContactList client={client} />
      <ClClientStationList client={client} />
    </>
  );
};

function getApeTrad(ape?: string): string {
  if (!ape) return '';
  if (ape.length > 2 && ape[2] !== '.') ape = ape.slice(0, 2) + '.' + ape.slice(2);
  const result = codesNaf.find((x) => x.id === ape);
  return !result ? ape + '(Erreur) Code non trouvé' : `${ape} - ${result.label}`;
}

const ClientContactConfig: LabelValueIf[] = [
  { label: 'Manager', value: (client: ClClient) => client.manager },
  { label: 'Adresse de facturation', value: (client: ClClient) => <UI.Multiline text={client.billingAddress} /> },
  { label: 'Adresse siège social', value: (client: ClClient) => <UI.Multiline text={client.address} /> },
  { label: 'Code Postal', value: (client: ClClient) => client.postcode },
  { label: 'Ville', value: (client: ClClient) => client.city },
  { label: 'Pays', value: (x: SkCompany) => x.country, ifIs: (x: SkCompany) => !!x.country },
  { label: 'Téléphone', value: (client: ClClient) => <UI.PhoneLink phone={client.tel} /> },
  { label: 'Email contact comptabilité', value: (client: ClClient) => <UI.MailLink mail={client.email} /> },
  { label: 'Site Web', value: (client: ClClient) => client.website },
];

const ClientGeneralConfig: LabelValue[] = [
  { label: 'Type', value: (client: ClClient) => <ShowClientProspect value={client.type} /> },
  { label: 'Code', value: (client: ClClient) => client.codeLabel },
  { label: 'Agences rattachées', value: (client: ClClient) => client.agencyLabelList },
  { label: 'Raison sociale', value: (client: ClClient) => client.label },
  { label: 'Nom commercial', value: (client: ClClient) => client.commercialName },
  { label: 'En activité', value: (client: ClClient) => (client.active ? 'Oui' : 'Non') },
  { label: 'Date création entreprise', value: (client: ClClient) => Utils.displayDate(client.companyCreationDate) },
  { label: 'Bloqué', value: (client: ClClient) => (client.locked ? 'Oui' : 'Non') },
  { label: 'Professionnel', value: (client: ClClient) => (client.professional ? 'Oui' : 'Non') },
  {
    label: 'Synchronisation API',
    value: (client: ClClient) => (client.lastApiSync ? Utils.displayDate(client.lastApiSync) : 'Jamais effectuée'),
  },
  { label: 'Fait par', value: (c: ClClient) => c.lastApiSyncByName },
];

const ClientBankConfig: LabelValue[] = [
  { label: 'APE', value: (client: ClClient) => getApeTrad(client.ape) },
  { label: 'Numéro SIRET', value: (client: ClClient) => client.siret },
  { label: 'SIRET mis à jour par', value: (client: ClClient) => client.sirenUpdatedByName },
  { label: 'SIRET mis à jour le', value: (client: ClClient) => Utils.displayDate(client.sirenUpdatedAt) },
  { label: 'TVA', value: (client: ClClient) => client.tva },
  { label: 'Nom Banque', value: (client: ClClient) => client.bankLabel },
  { label: 'Ville Banque', value: (client: ClClient) => client.bankCity },
  { label: 'BIC', value: (client: ClClient) => client.bic },
  { label: 'IBAN', value: (client: ClClient) => client.iban },
];

const ClClientContactList: FC<{ client: ClClient }> = ({ client }) => {
  const [state, setState] = React.useState<ClContact[]>([]);

  useEffect(() => {
    CRUD.getList<ClContact>(APIRoute.ClContact + '/ByClient/' + client.id).then(setState);
  }, [client.id]);

  const config = ClContactConfigList.filter((x) => x.id !== 'clientLabel' && x.id !== 'clientId');
  if (AccessFilter([ROLE.ADMIN_CLIENT_EDIT])) {
    config.push({
      header: 'Action',
      id: 'none',
      size: 0,
      noClick: true,
      cell: (info) => (
        <FusionClientContact toFuse={info.row.original} existingContact={state} setExistingContact={setState} />
      ),
    });
  }

  return [
    <CmsFrontendTable
      actions={[
        <ImportClientContact client={client} existingContact={state} setExistingContact={setState} />,
        <PayLoadButton
          title="Ajouter un contact"
          to="/castres/client/contact/create"
          payload={{ clientId: client.id }}
        />,
      ]}
      initialPageSize={10}
      columns={config}
      navigateTo={(id: number) => `/castres/client/contact/${id}/show`}
      title="Liste des contacts"
      controlledState={{ state: state, setState }}
      route="none"
    />,
  ];
};

interface FusionClientContactProps {
  existingContact: ClContact[];
  setExistingContact: (x: ClContact[]) => void;
  toFuse: ClContact;
}

const FusionClientContact: FC<FusionClientContactProps> = ({ existingContact, setExistingContact, toFuse }) => {
  const [open, setOpen] = React.useState(false);
  const [openValidation, setOpenValidation] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState<ClContact | null>(null);

  if (!existingContact || existingContact.length < 2) return <></>;

  const handleFusion = () => {
    const payload = { toFusionId: toFuse?.id, originId: selectedContact?.id };
    CRUD.post<ClContact>(APIRoute.ClContact + '/Fusion', payload)
      .then((result) => {
        setExistingContact([result, ...existingContact.filter((x) => x.id !== toFuse.id)]);
        setSelectedContact(null);
        notificationService.info('Fusion effectuée');
      })
      .finally(() => setOpen(false));
  };

  const handleOpenValidation = (data: any) => {
    setSelectedContact(data);
    setOpenValidation(true);
  };

  const columns = ClContactConfigList.filter((x) => x.id !== 'clientLabel' && x.id !== 'clientId');
  if (AccessFilter([ROLE.ADMIN_CLIENT_EDIT])) {
    columns.push({
      header: 'Action',
      id: 'none',
      noClick: true,
      cell: (info) => <CmsButton onClick={() => handleOpenValidation(info.row.original)}>Fusionner</CmsButton>,
    });
  }

  return [
    <CmsButton toolTip="Fusion de contact" toolTipPlacement="left" onClick={() => setOpen(true)}>
      Fusionner
    </CmsButton>,
    <UI.Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
      <CmsFrontendTable
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        paperStyle={{ marginBottom: 0, minWidth: '90rem' }}
        controlledState={{ state: existingContact.filter((x) => x.id !== toFuse.id), setState: setExistingContact }}
        title={`Fusionner le contact ${toFuse?.name} avec ...`}
        route="none"
        columns={columns}
      />
      <UI.Dialog open={openValidation} onClose={() => setOpenValidation(false)}>
        <CmsPaper title="Attention" style={{ marginBottom: 0 }}>
          Vous vous apprêtez à fusionner le contact {toFuse?.name} avec {selectedContact?.name}.<br />
          Le contact {toFuse?.name} sera désactivé et les informations du contact {selectedContact?.name} seront
          conservées.
          <br />
          <UI.ButtonHolder divider>
            <CmsButton color="warning" onClick={() => setOpenValidation(false)}>
              Annuler
            </CmsButton>
            <CmsButton onClick={handleFusion}>Confirmer</CmsButton>
          </UI.ButtonHolder>
        </CmsPaper>
      </UI.Dialog>
    </UI.Dialog>,
  ];
};

const ClClientStationList: FC<{ client: ClClient }> = ({ client }) => {
  const [clientStation, setClientStation] = React.useState<ClStation[] | null>([]);

  useEffect(() => {
    CRUD.getList<ClStation>(APIRoute.ClStation + '/ListByClient/' + client.id).then(setClientStation);
  }, [client.id]);

  let manyToMany: ReactNode[] = [
    <JoinTableHandler
      buttonLabel="Gérer les sites du client"
      originId={client.id}
      route={APIRoute.ClStation}
      columns={StationListConfig}
      originType={APIRoute.ClClient}
      onUpdate={() => CRUD.getList<ClStation>(APIRoute.ClStation + '/ListByClient/' + client.id).then(setClientStation)}
    />,
  ];
  return (
    <CmsFrontendTable
      actions={[
        <ImportClientStation
          client={client}
          existingStation={clientStation ?? []}
          setExistingStation={setClientStation}
        />,
        manyToMany,
      ]}
      route={APIRoute.ClStation + '/ListByClient/' + client.id}
      controlledState={{ state: clientStation ?? [], setState: setClientStation }}
      columns={StationListConfig}
      title="Liste des sites du client"
      navigateTo={(id: number) => `/castres/client/station/${id}/show`}
      setFiltersInUrl
    />
  );
};

const typeOptions = [
  { label: 'Client', id: '_client' },
  { label: 'Prospect', id: '_prospect' },
];

const codesNaf: IdLabel[] = require('@socialgouv/codes-naf/index.json');
const codesNafWithRef = codesNaf.map((x) => ({ id: x.id, label: `${x.id} - ${x.label}` }));

export const ClClientCreateEdit: FC = ({ id }: any) => {
  const title = (!id ? 'Création' : 'Édition') + " d'un client";
  const canEditFull = useMemo(() => AccessFilter([ROLE.ADMIN_CLIENT_EDIT_FULL]), []);
  return (
    <CmsForm
      id={id}
      defaultValues={{ type: '_prospect', active: true, lastApiSync: null }}
      currentUrl="/castres/client/client/"
      route={APIRoute.ClClient}
      goToOnValidForm={(id) => `/castres/client/client/${id}/show`}
      onGetEditData={(data: ClClient) => {
        if (data.haveDuplicatedSiret)
          notificationService.warning(
            'Attention, un autre client possède déjà ce SIRET (les modifications ont tout de même été enregistrées)',
          );
        return data;
      }}
      renderForm={(form) => {
        const data: ClClient = form.getValues() as any;
        const siret = data.siret;
        const isSiretValid = !!siret && siret.length === 14;
        if (!isSiretValid && data.professional) form.reset({ ...data, professional: false });
        const readOnly = !!data.lastApiSync;
        let siretTitle: any = 'SIRET';
        if (isSiretValid) siretTitle = ['SIRET', <SiretLinkIcon siret={siret} />];
        const see = [
          <ImportClientInseeData form={form} mandatory={data.professional && !data.lastApiSync} />,
          <ListNavigationButton title="Retourner à la liste" to="/castres/client/client/list" />,
        ];
        if (!!id) see.push(<ViewNavigationButton title="Voir" to={`/castres/client/client/${id}/show`} />);
        return (
          <CmsPaper title={title} actions={see}>
            {!data.locked && <WarnDuplicateSiret siret={siret} id={id} />}
            <FormSkeleton isLoading={!!id && !data?.id} fieldNumber={13} columnNumber={3}>
              <Grid container spacing={2}>
                <Grid item sm={12} lg={4}>
                  <h2 style={{ textAlign: 'center' }}>Contact</h2>
                  <CmsFormInput.Text id="manager" label="Manager" />
                  <CmsFormInput.Text
                    id="billingAddress"
                    label="Adresse de facturation complète (si différente du siège social)"
                    multiline
                  />
                  <CmsFormInput.Text
                    id="address"
                    label="Adresse siège social"
                    required
                    max={255}
                    readOnly={readOnly}
                    multiline
                  />
                  <CmsFormInput.Text id="postcode" label="Code Postal" required max={10} readOnly={readOnly} />
                  <CmsFormInput.Text id="city" label="Ville" required max={100} readOnly={readOnly} />
                  <CmsFormInput.Text id="country" label="Pays (si hors France)" />
                  <CmsFormInput.Text id="tel" label="Téléphone" />
                  <CmsFormInput.Text id="email" label="Email contact comptabilité" />
                  <CmsFormInput.Text id="website" label="Site web" />
                  <CmsFormInput.Number id="latitude" label="Latitude (via import INSEE)" readOnly={readOnly} />
                  <CmsFormInput.Number id="longitude" label="Longitude (via import INSEE)" readOnly={readOnly} />
                </Grid>
                <Grid item sm={12} lg={4}>
                  <h2 style={{ textAlign: 'center' }}>Général</h2>
                  <CmsFormInput.Select id="type" label="Type" options={typeOptions} disabled={!canEditFull} required />
                  <CmsFormInput.Select
                    id="codeId"
                    label="Agence"
                    options={APIRoute.ClClientCode + '/Simplified'}
                    updateFieldIf={(dataForm: any, optionList: any[]) => {
                      if (optionList?.length !== 2 || !!dataForm.codeId) return { update: false, value: null };
                      const notNational = optionList.find((x) => x.label !== 'Client National');
                      if (notNational) return { update: true, value: notNational.id };
                      return { update: false, value: null };
                    }}
                    required
                  />
                  <CmsFormInput.Text id="label" label="Raison sociale" required max={255} readOnly={readOnly} />
                  <CmsFormInput.Text id="commercialName" label="Nom commercial" max={255} />
                  <CmsFormInput.Date
                    id="companyCreationDate"
                    label="Date création entreprise (via import INSEE)"
                    readOnly={readOnly}
                    dateOnlyFormat
                  />
                  <CmsFormInput.Switch id="professional" label="Professionnel" disabled={!isSiretValid} />
                  <CmsFormInput.Switch id="active" label="En activité" readOnly={readOnly} />
                  <CmsFormInput.Switch id="locked" label="Bloqué" />
                  <CmsFormInput.Text
                    id="lockComment"
                    label="Commentaire de blocage"
                    disabled={data.locked && data.lockComment?.includes('Doublon')}
                    max={255}
                    multiline
                  />
                </Grid>
                <Grid item sm={12} lg={4}>
                  <h2 style={{ textAlign: 'center' }}>Administratif {canEditFull ? '' : '(Non modifiable)'}</h2>
                  <CmsFormInput.Select
                    id="ape"
                    label="APE"
                    readOnly={readOnly}
                    options={codesNafWithRef}
                    disabled={!canEditFull}
                  />
                  <CmsFormInput.Text id="siret" label={siretTitle} readOnly={readOnly} disabled={!canEditFull} />
                  <CmsFormInput.Text id="tva" label="TVA" readOnly={readOnly} disabled={!canEditFull} />
                  <CmsFormInput.Text id="bankLabel" label="Nom Banque" disabled={!canEditFull} />
                  <CmsFormInput.Text id="bankCity" label="Ville Banque" disabled={!canEditFull} />
                  <CmsFormInput.Text id="bic" label="BIC" disabled={!canEditFull} />
                  <CmsFormInput.Text id="iban" label="IBAN" disabled={!canEditFull} />
                </Grid>
              </Grid>
            </FormSkeleton>
            <CmsFormSubmit type={!data.id ? 'createForm' : 'editForm'} />
          </CmsPaper>
        );
      }}
    />
  );
};

const ClClientDuplicatesConfigList: CmsColumnDef<ClClient>[] = [
  {
    header: 'Email',
    id: 'email',
    size: 0,
    noClick: true,
    cell: (info) => <UI.MailLink mail={info.row.original.email} />,
  },
  { header: 'IBAN', id: 'iban', size: 0 },
  {
    header: <span className="text-center w100">Nombre de ...</span>,
    id: 'none',
    backgroundColor: {
      dark: 'rgb(48,53,66)',
      light: 'rgb(213,208,181)',
    },
    columns: [
      { header: 'Contacts', id: 'nbContact', size: 0 },
      { header: 'Sites', id: 'nbStation', size: 0 },
    ],
  },
  {
    header: <span className="text-center w100">Date du dernier et nombre de ...</span>,
    backgroundColor: {
      dark: 'rgb(54,60,77)',
      light: 'rgb(201,196,167)',
    },
    id: 'none2',
    columns: [
      {
        header: 'Devis',
        id: 'lastQuotationDate',
        size: 0,
        cell: (info) => {
          const { lastQuotationDate, nbQuotation } = info.row.original;
          const date = Utils.displayDate(lastQuotationDate);
          if (!date) return nbQuotation ?? 0;
          return `${nbQuotation ?? 0} (${date})`;
        },
      },
      {
        header: 'Contrats',
        id: 'lastContractDate',
        size: 0,
        cell: (info) => {
          const { lastContractDate, nbContract } = info.row.original;
          const date = Utils.displayDate(lastContractDate);
          if (!date) return nbContract ?? 0;
          return `${nbContract ?? 0} (${date})`;
        },
      },
      {
        header: 'Factures',
        id: 'lastInvoiceDate',
        size: 0,
        cell: (info) => {
          const { lastInvoiceDate, nbInvoice } = info.row.original;
          const date = Utils.displayDate(lastInvoiceDate);
          if (!date) return nbInvoice ?? 0;
          return `${nbInvoice ?? 0} (${date})`;
        },
      },
    ],
  },
  {
    header: 'Action',
    id: 'none3',
    size: 0,
    noClick: true,
    cell: (info) => <LockerButton client={info.row.original} title="Bloquer" />,
  },
];

const WarnDuplicateSiret: FC<{ siret: string; id: number }> = ({ siret, id }) => {
  const [duplicateList, setDuplicateList] = React.useState<ClClient[]>([]);
  const { theming } = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const config = useMemo(() => {
    return [...ClClientConfigList.filter((x) => x.id !== 'siret2'), ...ClClientDuplicatesConfigList];
  }, []);

  useEffect(() => {
    if (!siret) return;
    CRUD.getList<ClClient>(APIRoute.ClClient + '/BySiret/' + siret).then((result) => {
      let notLocked: any[] = result.filter((x) => !x.locked);
      if (notLocked.find((x) => x.id === +id))
        notLocked.find((x) => x.id === +id).rowColor = theming.get().cms.color.blue;
      setDuplicateList(notLocked);
    });
  }, [id, siret, theming]);

  if (!siret || siret.length !== 14 || duplicateList.length < 2) return <></>;
  if (location.search.includes('toBlock=true')) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} customColor="#FF0000">
      <span style={{ fontWeight: 'bold' }}>Attention ! </span>
      <span>
        Ce SIRET est utilisé sur plusieurs clients. S'il s'agit de doublons, veuillez les bloquer pour n'en garder
        qu'un, sinon corrigez les SIRET.
      </span>
      <CmsButton className="hint-button" style={{ marginLeft: '0.5rem' }} color="inherit" onClick={() => setOpen(true)}>
        Voir la liste
      </CmsButton>
      <UI.Dialog fullWidth maxWidth={false} open={open} onClose={() => setOpen(false)}>
        <CmsFrontendTable
          columns={config}
          title="Doublons potentiels trouvés (vous êtes actuellement sur le client en bleu)"
          route="none"
          invertClick
          navigateTo={(id: number) => `/castres/client/client/${id}/show?toBlock=true`}
          paperStyle={{ marginBottom: 0 }}
          controlledState={{ state: duplicateList, setState: setDuplicateList }}
        />
      </UI.Dialog>
    </WarningBubble>
  );
};

const Locker: FC<{ client: ClClient }> = ({ client }) => {
  const location = useLocation();
  if (!location.search.includes('toBlock=true') || client.locked) return <></>;
  if (!AccessFilter([ROLE.ADMIN_CLIENT_EDIT])) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} customColor="#FF0000">
      <span style={{ fontWeight: 'bold' }}>Attention ! </span>
      <span> Ce client est potentiellement un doublon. </span>
      <LockerButton client={client} title="Bloquer le client" />
    </WarningBubble>
  );
};

const LockerButton: FC<{ client: ClClient; title: string }> = ({ client, title }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleBlock = () => {
    const payload = { id: client.id };
    CRUD.put<ClClient>(APIRoute.ClClient + '/Lock', payload).then(() => {
      setOpen(false);
      notificationService.info('Client bloqué');
      navigate(0);
    });
  };
  return (
    <>
      <CmsButton onClick={() => setOpen(true)}>{title}</CmsButton>
      <UI.Dialog open={open} onClose={() => setOpen(false)}>
        <CmsPaper title="Bloquer un client en doublon" style={{ marginBottom: 0 }}>
          <p>Assurez-vous qu'il s'agit bien d'un client en doublon.</p>
          <p>Le champ "Commentaire de blocage" sera rempli avec : "Doublon bloqué le XX/XX/20XX par XXX".</p>
          <p> </p>
          <p>
            Cette action n'est pas définitive. Pour revoir un client bloqué, accédez à la liste des clients et décochez
            "Bloqué" dans le filtre avancé.
            <br /> Vous pourrez alors le débloquer.
          </p>
          <UI.ButtonHolder divider>
            <CmsButton onClick={() => setOpen(false)}>Annuler</CmsButton>
            <CmsButton onClick={handleBlock}>Bloquer</CmsButton>
          </UI.ButtonHolder>
        </CmsPaper>
      </UI.Dialog>
    </>
  );
};
