import React, { FC, useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Menu, MenuItem } from '@mui/material';
import '../../shared/shared.scss';

// Note : transféré depuis src/page/station/StationList.tsx

interface ReloadDataOptionProps {
  // Fonction appeler pour rafraîchir les données, se déclenche automatiquement après un certain temps
  updateListAction: any;
  // Temps en secondes avant le prochain rafraîchissement automatique
  timerInSecond?: number;
}

/**
 * Bouton permettant de rafraîchir les données de la liste ReactTable, il se déclenche automatiquement après un certain temps
 * @param updateListAction Fonction appeler pour rafraîchir les données
 * @param timerInSecond Temps en secondes avant le prochain rafraîchissement automatique (120s par défaut)
 */
const ReloadDataOption: FC<ReloadDataOptionProps> = ({ updateListAction, timerInSecond = 120 }) => {
  const [counter, setCounter] = useState(timerInSecond);
  const [enabled, setEnabled] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const handleUpdateAction = useCallback(() => {
    if (!enabled) return;
    updateListAction();
    setCounter(timerInSecond);
  }, [enabled, updateListAction, timerInSecond]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter - 1);
      if (counter < 1) handleUpdateAction();
    }, 1000);
    return () => clearInterval(timer);
  }, [counter, handleUpdateAction, timerInSecond]);

  const moduloTime = (timeInSecond: number): string => {
    const minutes = (timeInSecond - (timeInSecond % 60)) / 60;
    const second = timeInSecond % 60;
    return minutes + ':' + (second < 10 ? '0' + second : second);
  };

  const handleRightClick = (e: any) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClick = (e: any) => {
    setEnabled(!enabled);
    setAnchorEl(null);
  };
  let title = `Prochain rafraîchissement dans ${moduloTime(counter)}`;
  if (!enabled) title = 'Pour réactiver, faite un clique droit';
  return (
    <>
      <Tooltip title={title} placement="left" arrow>
        <div onContextMenu={handleRightClick}>
          <Button
            disabled={!enabled}
            onClick={handleUpdateAction}
            startIcon={<RefreshIcon className={enabled ? 'icon-rotate speed3' : ''} />}
            size="small"
            variant="outlined"
          >
            Rafraîchir les données
          </Button>
        </div>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={handleMenuClick}>{enabled ? 'Désactiver' : 'Activer'}</MenuItem>
      </Menu>
    </>
  );
};

export default ReloadDataOption;
