import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WkGreenfluxCdr } from '../../interface/WkType';
import LoadingScreen from '../../component/LoadingScreen';
import { handleErrorPage } from 'helper/handle-response';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import CdrDetailUi from './CdrDetailUI';

/**
 * Permet d'afficher les données d'un CDR, cette fonction récupère
 * le CDR en base pour faire la mise en forme avec cdrDetailUI.tsx
 */
export const CdrDetail: React.FC = ({ id, setHttpCodePage }: any) => {
  const [cdr, setCdr] = useState<WkGreenfluxCdr | undefined>();
  const navigate = useNavigate();

  const returnToList = useCallback(() => {
    navigate('/castres/greenflux/cdr/list');
  }, [navigate]);

  useEffect(() => {
    if (id && !cdr) {
      setCdr({ isLoading: true });
      CRUD.getById<WkGreenfluxCdr>(APIRoute.WkGreenfluxCdr, id)
        .then((res: WkGreenfluxCdr | null) => (res ? setCdr(res) : returnToList()))
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else if (!id) returnToList();
    else if (id && cdr?.id && id !== cdr.id.toString()) setCdr(undefined);
  }, [id, cdr, navigate, returnToList, setHttpCodePage]);

  if (!cdr || cdr.isLoading) return <LoadingScreen />;
  return <CdrDetailUi cdr={cdr} />;
};
