import { LabelValueIf, TabStyleDataView } from '../shared/TabStyleDataView';
import { WkTask } from '../../interface/WkType';
import Utils from '../../helper/Utils';
import { SfAbsence } from '../../interface/SfType';
import React from 'react';

// Configuration de l'affichage des poppers des tâches
const taskViewConf: LabelValueIf[] = [
  { label: 'Type', value: () => 'Tâche' },
  { label: 'Station', value: (t: WkTask) => t.stationLabel },
  { label: 'Intervention', value: (t: WkTask) => t.interventionName },
  { label: 'Description', value: (t: WkTask) => t.interventionDescription },
  { label: 'Nom de la tâche', value: (t: WkTask) => t.name },
  { label: 'Du', value: (t: WkTask) => Utils.displayDate(t.startDate, true) },
  { label: 'Au', value: (t: WkTask) => Utils.displayDate(t.endDate, true) },
];

// Composant de popper pour les tâches
const TaskPopper = (item: any) => {
  if (!item?.value) return <></>;
  return (
    <div style={{ maxWidth: '400px' }}>
      <TabStyleDataView conf={taskViewConf} mapFrom={item.value} />
    </div>
  );
};

// Composant de popper pour les groupes de tâches, regroupé par intervention
const TaskGroupPopper = (group: any) => {
  if (!group) return <></>;
  return (
    <>
      <h2>Intervention {group.id}</h2>
      <p>{group.title}</p>
    </>
  );
};

// Configuration de l'affichage des poppers d'absences
const absenceViewConf: LabelValueIf[] = [
  { label: 'Utilisateur', value: (x: SfAbsence) => x.user },
  { label: 'Type', value: (x: SfAbsence) => x.type },
  { label: 'Du', value: (x: SfAbsence) => Utils.displayDate(x.startDate, true) },
  { label: 'Au', value: (x: SfAbsence) => Utils.displayDate(x.endDate, true) },
  { label: 'Validé par', value: (x: SfAbsence) => x.grantedByName, ifIs: (x: SfAbsence) => !!x.grantedBy },
  { label: 'Commentaire', value: (x: SfAbsence) => x.comment, ifIs: (x: SfAbsence) => !!x.comment },
  { label: 'Motif de refus', value: (x: SfAbsence) => x.refusedComment, ifIs: (x: SfAbsence) => x.status === 3 },
];

// Composant de popper pour les absences
const AbsencePopper = (item: any) => {
  if (!item?.value) return <></>;
  return (
    <div style={{ maxWidth: '400px' }}>
      <TabStyleDataView conf={absenceViewConf} mapFrom={item.value} />
    </div>
  );
};

// Composant de popper pour les absences et les tâches
const AbsenceAndTaskPopper = (item: any) => {
  if (!item?.value) return <></>;
  return (
    <div style={{ maxWidth: '400px' }}>
      <TabStyleDataView conf={item.type === 'task' ? taskViewConf : absenceViewConf} mapFrom={item.value} />
    </div>
  );
};

export const Popper = {
  TaskPopper,
  TaskGroupPopper,
  AbsencePopper,
  AbsenceAndTaskPopper,
};
