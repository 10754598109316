import React, { FC } from 'react';
import APIRoute from '../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import { NavigationButton } from 'component/shared/Buttons';
import { formatCurrency } from 'helper/Utils';

/**
 * Permet d'afficher les notes de virement générées automatiquement
 * lors de l'importation des CDR, sous forme de liste
 */
const GreenfluxNoteDeVirementList: FC = () => (
  <CmsFrontendTable
    title="Liste des notes de virement IRVE"
    route={APIRoute.WkGreenfluxNoteDeVirement}
    columns={NoteDeVirementListConfig}
    autoRefresh
    showFooter
    setFiltersInUrl
  />
);

/**
 * Liste des données affichées par note de virement récupérée en base
 */
const NoteDeVirementListConfig: CmsColumnDef<any>[] = [
  { header: 'ID invoiceSupplier', id: 'id', hide: true },
  { header: 'Référence de la note de virement', id: 'ref', Filter: CmsTableFilter.Select },
  { header: 'Émetteur de la note de virement', id: 'companySrc', Filter: CmsTableFilter.Select },
  {
    header: 'Total TTC Note de virement',
    id: 'totalCostInclVatInvoiceSupplier',
    cell: CmsTableCell.Price,
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Total TTC en réseau privé',
    id: 'totalCostPrivateNetwork',
    cell: (info) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NavigationButton
          title={`${formatCurrency(info.row.original?.totalCostInclVatPrivateNetwork)}`}
          to={`/castres/greenflux/NoteDeVirement/${info.row.original?.id}/PrivateNetwork/show`}
          color={info.row.original?.isValidated ? 'success' : 'primary'}
        />
        <div style={{ marginLeft: '10px' }}>{formatCurrency(+info.getValue())} HT</div>
      </div>
    ),
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Total TTC via Charge Assist',
    id: 'totalCostChargeAssist',
    cell: (info) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NavigationButton
          title={`${formatCurrency(info.row.original?.totalCostInclVatChargeAssist)}`}
          to={`/castres/greenflux/NoteDeVirement/${info.row.original?.id}/ChargeAssist/show`}
          color={info.row.original?.isValidated ? 'success' : 'primary'}
        />
        <div style={{ marginLeft: '10px' }}>{formatCurrency(+info.getValue())} HT</div>
      </div>
    ),
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Total TTC en Roaming',
    id: 'totalCostRoaming',
    cell: (info) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NavigationButton
          title={`${formatCurrency(info.row.original?.totalCostInclVatRoaming)}`}
          to={`/castres/greenflux/NoteDeVirement/${info.row.original?.id}/Roaming/show`}
          color={info.row.original?.isValidated ? 'success' : 'primary'}
        />
        <div style={{ marginLeft: '10px' }}>{formatCurrency(+info.getValue())} HT</div>
      </div>
    ),
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Total TTC via TPE',
    id: 'totalCostTpe',
    cell: (info) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <NavigationButton
          title={`${formatCurrency(info.row.original?.totalCostInclVatTpe)}`}
          to={`/castres/greenflux/NoteDeVirement/${info.row.original?.id}/Tpe/show`}
          color={info.row.original?.isValidated ? 'success' : 'primary'}
        />
        <div style={{ marginLeft: '10px' }}>{formatCurrency(+info.getValue())} HT</div>
      </div>
    ),
    Filter: CmsTableFilter.Number,
  },
  {
    header: 'Statut de la note de virement',
    id: 'status',
    cell: (info) => (
      <CmsTableCell.Colored
        color={info.row.original.isValidated ? '00cc00' : '0086cc'}
        content={info.row.original.status}
      />
    ),
  },
];

export default GreenfluxNoteDeVirementList;
