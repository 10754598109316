import React from 'react';
import Chip from '@mui/material/Chip';
import { DateCell } from '../../component/table/helper/customCells';
import { EqActionIcon } from '../../component/equipement/EquipementActionIcon';
import { ClStation } from '../../interface/ClType';
import { ThousandSpacing } from '../../helper/Utils';
import { TextEllipse } from '../../component/shared/Ui';
import { CmsColumnDef } from '../../component/table/CmsTable';
import { BlQuotation, BlQuotationRequest } from '../../interface/BlType';
import { EqEquipment } from '../../interface/EqType';
import CmsTableCell from '../../component/table/helper/CmsTableCell';

/**
 * Filtre les équipements AD
 * @param station station contenant les équipements
 */
export const filterAD = (station: ClStation): any => {
  if (!station.eqEquipment) return [];
  return station.eqEquipment.filter((equipement: any) => {
    if (equipement.typeLabel.includes('AD')) return false;
    return !((equipement.typeLabel.includes('RV2') || equipement.typeLabel.includes('TPI')) && equipement.parentId);
  });
};

// Configuration de tableau des devis
export const localQuotationColumn: CmsColumnDef<BlQuotation>[] = [
  {
    header: 'Créé le / par',
    id: 'createdAtBy',
    cell: (x) => (
      <>
        <DateCell value={x.getValue()?.createdAt} />
        <br />
        {x.getValue()?.createdByName}
      </>
    ),
  },
  {
    header: 'Référence / Titre',
    id: 'refTitle',
    cell: (x) => (
      <>
        {x.getValue()?.ref}
        <TextEllipse text={x.getValue()?.title} />
      </>
    ),
  },
  {
    header: 'Client / Contact',
    id: 'clientLabelContactName',
    cell: (x) => (
      <>
        <TextEllipse text={x.getValue()?.clientLabel} />
        <TextEllipse text={x.getValue()?.contactName} />
      </>
    ),
  },
  {
    header: 'Prix HT',
    id: 'totalHTAndCredit',
    cell: (x) => ThousandSpacing(x.getValue()?.toString()) + ' €',
  },
  {
    header: 'Statut',
    id: 'statusLabelColor',
    cell: (x) => (
      <div style={{ textAlign: 'center' }}>
        <Chip
          className="box-shadow-1"
          size="small"
          label={x.getValue()?.statusLabel}
          style={{ backgroundColor: x.getValue()?.statusColor ? '#' + x.getValue()?.statusColor : '#000000' }}
        />
      </div>
    ),
  },
  { header: '', id: 'clientLabel', hide: true },
  { header: '', id: 'contactName', hide: true },
  { header: '', id: 'createdByName', hide: true },
  { header: '', id: 'ref', hide: true },
  { header: '', id: 'title', hide: true },
  { header: '', id: 'statusLabel', hide: true },
];

// Configuration de tableau des demandes de devis
export const localQuotationRequestColumn: CmsColumnDef<BlQuotationRequest>[] = [
  {
    header: 'Créé le',
    id: 'createdAtBy',
    cell: (x) => (
      <>
        <DateCell value={x.getValue()?.createdAt} />
        <br />
        {x.getValue()?.createdByName}
      </>
    ),
  },
  {
    header: 'Label',
    id: 'label',
    cell: (x) => <TextEllipse text={x.getValue()} />,
  },
  {
    header: 'Client/Contact',
    id: 'clientLabelContactName',
    cell: (x) => (
      <>
        <TextEllipse text={x.getValue()?.clientLabel} />
        <TextEllipse text={x.getValue()?.contactName} />
      </>
    ),
  },
  {
    header: 'A faire',
    id: 'todoAtName',
    cell: (x) => (
      <>
        <DateCell value={x.getValue()?.todoAt} />
        <br />
        {x.getValue()?.todoByName}
      </>
    ),
  },
  { header: '', id: 'clientLabel', hide: true },
  { header: '', id: 'contactName', hide: true },
  { header: '', id: 'createdByName', hide: true },
  { header: '', id: 'statusLabel', hide: true },
];

// Configuration de tableau des équipements
export const localEquipmentColumn: CmsColumnDef<EqEquipment>[] = [
  { header: 'Référence', id: 'ref' },
  { header: 'Type', id: 'typeLabel' },
  { header: 'Désignation', id: 'label' },
  {
    header: () => <TextEllipse text={'Dernier Contrôle Réglementaire'} />,
    id: 'lastControlVisit',
    cell: CmsTableCell.Date,
  },
  { header: 'Contrat', id: 'contractLabel', cell: (x) => EqActionIcon(x.getValue()) },
];

/**
 * Génère la configuration du graphique pour les données afficher dans barChart
 * @param breakdowns données (Taux de pannes critiques) à afficher
 */
export const getChartConfForData = (breakdowns: Array<any>): any => {
  const breakdownsLabel = [];
  const breakdownsYear = [];
  const breakdownsTrimestriel = [];

  for (const breakdown of breakdowns) {
    breakdownsLabel.push(breakdown.typeLabel);
    breakdownsYear.push(breakdown.nbPanne?.year);
    breakdownsTrimestriel.push(breakdown.nbPanne?.trimestriel);
  }

  const xAxis = [{ scaleType: 'band', data: breakdownsLabel }];
  const series = [
    { label: 'Sur les 3 derniers mois', data: breakdownsTrimestriel, id: 'month', color: '#ffa706' },
    { label: 'Sur un an glissant', data: breakdownsYear, id: 'year', color: '#006dff' },
  ];
  return { xAxis, series };
};
