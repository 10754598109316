import React, { FC, useEffect, useState } from 'react';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { SkToolModel } from '../../interface/SkType';
import { CmsForm } from '../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { UI } from '../../component/shared';

export const ToolCreateEdit: FC<any> = ({ id }) => {
  const [toolModels, setToolModels] = useState<SkToolModel[]>([]);

  useEffect(() => {
    CRUD.getList<SkToolModel>(APIRoute.SkToolModel).then((result) => {
      const validModelList = result.filter((el) => !!el.skToolType);
      validModelList.map((model) => (model.label = model.skToolType?.name + ' - ' + model.name));
      setToolModels(validModelList);
    });
  }, []);

  return (
    <CmsForm
      id={id}
      paperStyle={{ width: '600px' }}
      currentUrl="/castres/stock/tool/"
      goToOnValidForm={(id, toShow) => `/castres/stock/tool/${id + (toShow ? '/show' : '/edit')}`}
      route={APIRoute.SkTool}
      onGetEditData={(data) => {
        return {
          serviceId: data?.service?.id ?? null,
          toolModelId: data?.toolModel?.id ?? null,
          toolMarkId: data?.toolMark?.id ?? null,
          ...data,
        };
      }}
      title="un outil"
      renderForm={(form) => {
        const watchModel = form.watch('toolModelId');
        const currentModel = watchModel ? toolModels.find((el) => el.id === parseInt(watchModel)) : null;
        const isAutoIncrement = currentModel ? currentModel.skToolType?.autoIncrement || false : false;
        return (
          <>
            {(isAutoIncrement && !id && (
              <UI.FormField>
                <UI.Label label="Référence" name="ref" />
                <div style={{ fontStyle: 'italic' }}>Référence auto-générée</div>
              </UI.FormField>
            )) || <CmsFormInput.Text id="ref" label="Référence" required readOnlyIf={(x) => !!x.id} />}
            <CmsFormInput.Select
              id="toolModelId"
              label="Modèle"
              options={toolModels}
              readOnlyIf={(x) => !!x.id}
              required
            />
            <CmsFormInput.Select id="toolMarkId" label="Marque" options={APIRoute.SkToolMark + '/Simplified'} />
            <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService + '/Simplified'} required />
            <CmsFormInput.Text id="serial" label="Numéro de série" max={50} />
            <CmsFormInput.Number id="dailyCost" label="Coût journalier" />
          </>
        );
      }}
    />
  );
};
