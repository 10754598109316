import React, { FC, useEffect, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsPaper } from '../../../component/shared/Ui';
import { FirstLevelProps, MultiLevelForm } from '../../../component/form/MultiLevelForm';
import { MultiLevelAutoComplete, MultiLevelInput, MultiLevelReadOnly } from '../../../component/form/MultiLevelInput';
import { CmsForm, CmsFormPaper } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { Buttons } from '../../../component/shared';
import CRUD from '../../../service/CRUD.service';
import { BlPriceScheduleGroup } from '../../../interface/BlType';
import notificationService from '../../../service/NotificationService';
import { ClCompany } from '../../../interface/ClType';
import { CmsMenuButton } from '../../../component/shared/Menu';

export const PriceScheduleAllPack: FC<{ id?: any }> = ({ id }) => {
  const [formState, setFormState] = useState<any>({});
  const [companyList, setCompanyList] = useState<ClCompany[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();

  useEffect(() => {
    CRUD.getList<ClCompany>(APIRoute.ClCompany + '/Simplified').then(setCompanyList);
    CRUD.getById<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup, id).then(setFormState);
  }, [id]);

  const onSubmitAll = () => {
    CRUD.put<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup + '/All', formState).then(() => {
      notificationService.success('Les modifications ont bien été enregistrées');
      window.location.reload();
    });
  };

  const handleCompanySelect = (companyId: number) => {
    setSelectedCompany(companyId);
    setFormState(fillData(formState, companyId));
  };

  return (
    <CmsForm
      id={id}
      currentUrl="/castres/billing/priceschedule/"
      route={APIRoute.BlPriceScheduleGroup}
      renderForm={(form) => {
        let companyOptions: any[] = companyList.filter((x) => form.watch().companyIdList?.includes(x.id));
        companyOptions = [{ id: undefined, label: 'Général' }, ...companyOptions];
        if (selectedCompany && !companyOptions.find((x) => x.id === selectedCompany)) setSelectedCompany(undefined);
        return (
          <>
            <CmsFormPaper title="Bordereau">
              <CmsFormInput.Text id="ref" label="Référence" required max={10} />
              <CmsFormInput.Text id="label" label="Nom" required max={255} />
              <CmsFormInput.Select
                id="priceScheduleGroupTypeId"
                label="Type"
                options={APIRoute.BlPriceScheduleGroupType}
                required
              />
              <CmsFormInput.Select id="companyIdList" label="Liste des enseignes" options={companyList} multiple />
              <CmsFormInput.Switch id="active" label="Actif" />
            </CmsFormPaper>
            {!!id && (
              <CmsPaper
                title="Chapitres de bordereau"
                actions={[
                  <CmsMenuButton title="Personnaliser" preventClose={false}>
                    {companyOptions.map((company, i) => (
                      <span key={i} onClick={() => handleCompanySelect(company.id)}>
                        {company.label}
                      </span>
                    ))}
                  </CmsMenuButton>,
                  <Buttons.Valid onClick={onSubmitAll}>Sauvegarder toutes les modifications</Buttons.Valid>,
                ]}
              >
                <MultiLevelForm
                  id={id}
                  onGetData={(x) => fillData(x, selectedCompany)}
                  getRoute={APIRoute.BlPriceScheduleGroup}
                  data={formState}
                  config={config}
                  onChange={setFormState}
                />
              </CmsPaper>
            )}
          </>
        );
      }}
    />
  );
};

const fillData = (result: any, companyId?: number): any => {
  const costlier = 'rgba(0, 0, 255, 0.2)';
  const cheaper = 'rgba(255, 0, 0, 0.2)';
  if (!result?.blPriceScheduleChapter || !Array.isArray(result.blPriceScheduleChapter)) return result;
  result.filled = true;
  for (let chapter of result.blPriceScheduleChapter.filter((x: any) => Array.isArray(x.blPriceSchedule))) {
    chapter.rowColor = undefined;
    for (let line of chapter.blPriceSchedule.filter((x: any) => Array.isArray(x.blPriceScheduleLineCompany))) {
      line.rowColor = undefined;
      line.currentCompanyId = companyId;
      const lastHistory = line.blPriceScheduleLineCompany.find((x: any) => x.companyId === companyId);
      line.price = lastHistory ? lastHistory.price : line.originalPrice;
      line.blPriceScheduleLineCompany.map((x: any) => ({ ...x, hideRow: x.companyId === companyId }));
      let byCompany = line.blPriceScheduleLineCompany?.filter((x: any) => x.companyId === companyId);
      if (byCompany.length > 0) line.rowColor = byCompany[0].price > line.originalPrice ? costlier : cheaper;
    }
    const haveCheaper = chapter.blPriceSchedule.some((x: any) => x.rowColor === cheaper);
    const haveCostlier = chapter.blPriceSchedule.some((x: any) => x.rowColor === costlier);
    if (haveCheaper && haveCostlier) chapter.rowColor = 'rgba(255, 0, 255, 0.2)';
    else if (haveCheaper) chapter.rowColor = cheaper;
    else if (haveCostlier) chapter.rowColor = costlier;
  }
  return result;
};

const config: FirstLevelProps = {
  subListTitle: 'Liste des chapitres de bordereaux',
  attrToList: 'blPriceScheduleChapter',
  subItem: {
    subListTitle: 'Liste des lignes de bordereaux',
    postRoute: APIRoute.BlPriceScheduleChapter,
    attrToList: 'blPriceScheduleChapter',
    rowStyle: (row) => (row.rowColor ? { backgroundColor: row.rowColor } : {}),
    parentIdName: 'groupId',
    columnList: [
      { id: 'ref', label: 'Référence', FormComponent: MultiLevelInput },
      { id: 'label', label: 'Nom', FormComponent: MultiLevelInput },
    ],
    subItem: {
      subListTitle: 'Historique des prix',
      attrToList: 'blPriceSchedule',
      rowStyle: (row) => (row.rowColor ? { backgroundColor: row.rowColor } : {}),
      postRoute: APIRoute.BlPriceSchedule,
      parentIdName: 'chapterId',
      columnList: [
        // {
        //   id: 'chapterId',
        //   label: 'Chapitre',
        //   FormComponent: MultiLevelAutoComplete,
        //   disableIf: (row) => !!row.currentCompanyId,
        //   optionRoute: APIRoute.BlPriceScheduleChapter + '/Simplified',
        // },
        {
          id: 'ref',
          label: 'Référence',
          FormComponent: MultiLevelInput,
          disableIf: (row) => !!row.currentCompanyId,
          width: 100,
        },
        {
          id: 'label',
          label: 'Nom',
          FormComponent: MultiLevelInput,
          disableIf: (row) => !!row.currentCompanyId,
          inputOption: { multilineToggle: true },
        },
        { id: 'price', label: 'Prix', FormComponent: MultiLevelInput, inputOption: { type: 'number' }, width: 100 },
        {
          id: 'unitId',
          label: 'Unité',
          FormComponent: MultiLevelAutoComplete,
          disableIf: (row) => !!row.currentCompanyId,
          optionRoute: APIRoute.WkUnit + '/Simplified',
          width: 200,
        },
        {
          id: 'typeId',
          label: 'Type',
          FormComponent: MultiLevelAutoComplete,
          disableIf: (row) => !!row.currentCompanyId,
          optionRoute: APIRoute.BlPriceScheduleType,
          width: 150,
        },
        {
          id: 'campaignTypeIdList',
          label: 'Type de campagne',
          FormComponent: MultiLevelAutoComplete,
          disableIf: (row) => !!row.currentCompanyId,
          optionRoute: APIRoute.WkCampaignType + '/Simplified',
          inputOption: { multiple: true },
          width: 300,
        },
      ],
      subItem: {
        attrToList: 'blPriceScheduleLineCompany',
        parentIdName: 'lineId',
        columnList: [
          { id: 'price', label: 'Prix', FormComponent: MultiLevelReadOnly },
          { id: 'companyId', label: 'Id Company', FormComponent: MultiLevelReadOnly },
        ],
      },
    },
  },
};
