import React, { FC } from 'react';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { BASE_URL } from 'constant/API_URL';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import CmsTableFilterCalculator from '../../../component/table/helper/CmsTableFilterCalculator';
import { WkCampaignType } from 'interface/WkType';
import { Link } from 'react-router-dom';
import ROLE from '../../../constant/role.constant';

const WkCampaignTypeListConfig: CmsColumnDef<WkCampaignType>[] = [
  {
    header: 'Nom',
    id: 'name',
    Filter: CmsTableFilter.Text,
    cell: (info) => (
      <Link
        to={`/castres/work/campaigntype/${info.row.original?.id}/edit`}
        key={info.row.original?.id}
        style={{ color: 'inherit' }}
        target="_blank"
      >
        <div style={{ fontWeight: 'bold' }}>{info.getValue()}</div>
      </Link>
    ),
  },
  {
    header: 'Source',
    id: 'sourceId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkSource },
    hide: true,
  },
  { header: 'Source', id: 'sourceName' },

  {
    header: 'Contrat',
    id: 'agreementId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkAgreement },
    hide: true,
  },
  { header: 'Contrat', id: 'agreementLabel' },
  { header: 'Code Total', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Nom ENI', id: 'eniPattern', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'Nb mois entre intervention', id: 'periodInMonth', Filter: CmsTableFilter.Number },
  { header: 'Code passage', id: 'frequencyNumber', Filter: CmsTableFilter.Select },
  { header: 'Extension PDF', id: 'pdfExt', Filter: CmsTableFilter.Text },
  { header: 'Envoi PDF', id: 'pdfFlux', Filter: CmsTableFilter.Bool },
  { header: 'Envoi CRI', id: 'cri', Filter: CmsTableFilter.Bool },
  { header: 'Type de rapport', id: 'reportTypeLabel', hide: 'hiddenByDefault' },
  {
    header: 'Type de rapport',
    id: 'reportTypeId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkReportType },
    hide: true,
  },
  { header: 'Famille', id: 'familyLabel' },
  {
    header: 'Famille',
    id: 'familyId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkFamily },
    hide: true,
  },
  { header: "Type d'équipement", id: 'equipmentTypeLabel' },
  {
    header: "Type d'équipement",
    id: 'equipmentTypeId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.EqEquipmentType },
    hide: true,
  },
  {
    header: 'Condition sélection équipements',
    id: 'equipmentAttributeLabel',
    cell: (info) =>
      info.getValue() !== null ? (
        <div style={{ fontSize: '.9em' }}>
          {info.row.original?.equipmentAttributeLabel} = {info.row.original?.val}
        </div>
      ) : null,
  },
  {
    header: 'Attribut Équipement',
    id: 'equipmentAttributeId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.EqEquipmentAttribute },
    hide: true,
  },
  {
    header: 'Réseaux',
    id: 'networksIds',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClNetwork, multiple: true },
    filterFn: CmsTableFilterCalculator.containsSome,
    hide: true,
  },
  {
    header: 'Réseaux',
    id: 'networks',
    hide: 'hiddenByDefault',
    size: 500,
    cell(info) {
      const networks = info.row.original?.networks;
      const ids = info.row.original?.networksIds;
      return (
        <div>
          {networks?.map((network, index) => (
            <React.Fragment key={ids[index]}>
              <Link to={`/castres/client/network/${ids[index]}/edit`}>{network}</Link>
              {index !== networks.length - 1 && ', '}
            </React.Fragment>
          ))}
        </div>
      );
    },
  },
  {
    header: 'Pétroliers',
    id: 'companiesIds',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.ClCompany, multiple: true },
    filterFn: CmsTableFilterCalculator.containsSome,
  },
  {
    header: 'Pétroliers',
    id: 'companies',
    hide: 'hiddenByDefault',
    size: 300,
    cell(info) {
      const companies = info.row.original?.companies;
      const ids = info.row.original?.companiesIds;
      return companies?.map((company, index) => (
        <React.Fragment key={ids[index]}>
          <Link to={`/castres/client/company/${ids[index]}/edit`}>{company}</Link>
          {index !== companies.length - 1 && ', '}
        </React.Fragment>
      ));
    },
  },
];

/**
 * Liste des types de campagnes
 */
export const WkCampaignTypeList: FC = () => {
  return (
    <CmsFrontendTable
      columns={WkCampaignTypeListConfig}
      title="Liste des types de campagne"
      route={APIRoute.WkCampaignType}
      actions={[
        <AddNavigationButton
          key="nav"
          title="Ajouter un type de campagne"
          href={`${BASE_URL}castres/work/campaigntype/create`}
          access={[ROLE.ADMIN_CONFIGURATION_CAMPAIGNTYPE_CREATE]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'un type de campagne
 * @param id id du type de campagne à éditer
 * @param setHttpCodePage fonction pour modifier le code http de la page
 */
export const WkCampaignTypeCreateEdit: FC = ({ id }: any) => (
  <CmsForm
    id={id}
    currentUrl="/castres/work/campaigntype/"
    route={APIRoute.WkCampaignType}
    title="un type de campagne"
    thin
  >
    <CmsFormInput.Text id="name" label="Nom" required max={255} />
    <CmsFormInput.Select id="sourceId" label="Source" options={APIRoute.WkSource + '/Simplified'} />
    <CmsFormInput.Select id="agreementId" label="Contrat" options={APIRoute.WkAgreement + '/Simplified'} />
    <CmsFormInput.Text id="code" label="Code Total" max={50} />
    <CmsFormInput.Text id="eniPattern" label="Nom ENI" max={512} />
    <CmsFormInput.Number id="periodInMonth" label="Nombre de mois entre intervention" required />
    <CmsFormInput.Text id="frequencyNumber" label="Code passage" max={2} />
    <CmsFormInput.Select id="reportTypeId" label="Type de rapport" options={APIRoute.WkReportType + '/Simplified'} />
    <CmsFormInput.Switch id="pdfFlux" label="Envoi PDF" />
    <CmsFormInput.Text id="pdfExt" label="Extension PDF" max={3} />
    <CmsFormInput.Select
      id="familyId"
      label="Famille d'équipement"
      options={APIRoute.WkFamily + '/Simplified'}
      required
    />
    <CmsFormInput.Select
      id="equipmentTypeId"
      label="Type d'équipement"
      options={APIRoute.EqEquipmentType + '/Simplified'}
      required
    />
    <CmsFormInput.Select
      id="equipmentAttributeId"
      label="Condition de sélection des équipements : Attribut"
      options={APIRoute.EqEquipmentAttribute + '/Simplified'}
    />
    <CmsFormInput.Text id="val" label="Condition de sélection des équipements : Valeur" max={255} />
    <CmsFormInput.Select id="networksIds" label="Réseaux" options={APIRoute.ClNetwork + '/Simplified'} multiple />
    <CmsFormInput.Select id="companiesIds" label="Pétroliers" options={APIRoute.ClCompany + '/Simplified'} multiple />
    <CmsFormInput.Switch id="cri" label="Envoi des CRI (Total)" />
  </CmsForm>
);
