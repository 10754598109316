import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { BlStatus } from '../../../interface/BlType';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import ROLE from '../../../constant/role.constant';

export const BlStatusConfigList: CmsColumnDef<BlStatus>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Description', id: 'description', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Parent', id: 'parentLabel', Filter: CmsTableFilter.Text },
  { header: 'Bloqué', id: 'locked', Filter: CmsTableFilter.Bool },
  { header: 'Position', id: 'position' },
];

export const BlStatusList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_BILLING_STATUS_CREATE])
    ? [<AddNavigationButton title="Ajouter un statut" to="/castres/billing/status/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des statuts des devis / factures"
      route={APIRoute.BlStatus}
      columns={BlStatusConfigList}
      navigateTo={(id: number) => `/castres/billing/status/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

export const BlStatusCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlStatus, title: 'statuts des devis / factures' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/status/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="description" label="Description" required multiline />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
      <CmsFormInput.Select id="parentId" label="Parent" options={APIRoute.BlStatus} />
      <CmsFormInput.Switch id="locked" label="Bloqué" />
    </CmsForm>
  );
};
