import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { WkUnit } from '../../../interface/WkType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import ROLE from '../../../constant/role.constant';

const wkUnitConfigList: CmsColumnDef<WkUnit>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Description', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  { header: 'Parent', id: 'parentLabel', Filter: CmsTableFilter.Text },
  { header: 'Position', id: 'position', Filter: CmsTableFilter.Number },
  { header: 'Couleur', id: 'color', cell: CmsTableCell.Color },
  { header: 'Bloqué', id: 'locked', Filter: CmsTableFilter.Bool },
];

export const BlContractStatusList: FC = () => {
  return (
    <CmsFrontendTable
      title="Statut de contrat"
      route={APIRoute.BlContractStatus}
      columns={wkUnitConfigList}
      navigateTo={(id: number) => `/castres/billing/contractstatus/${id}/edit`}
      setFiltersInUrl
      actions={[
        <AddNavigationButton
          title="Ajouter un statut de contrat"
          to="/castres/billing/contractstatus/create"
          access={[ROLE.ADMIN_CONFIGURATION_BILLING_CONTRACTSTATUS_CREATE]}
        />,
      ]}
    />
  );
};

export const BlContractStatusCreateEdit: FC = ({ id }: any) => {
  const props = { route: APIRoute.BlContractStatus, title: 'statut de contrat' };
  return (
    <CmsForm id={id} {...props} currentUrl="/castres/billing/contractstatus/" thin>
      <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
      <CmsFormInput.Text id="description" label="Description" multiline required />
      <CmsFormInput.Text id="code" label="Code" required min={2} max={20} />
      <CmsFormInput.Text id="position" label="Position" required />
      <CmsFormInput.Select id="parentId" label="Parent" options={APIRoute.BlContractStatus} />
      <CmsFormInput.Color id="color" label="color" withoutSharp />
      <CmsFormInput.Text id="popoverTitle" label="Titre au survol" />
      <CmsFormInput.Text id="popoverContent" label="Contenu au survol" multiline />
      <CmsFormInput.Text id="popoverAction" label="Action au survol" />
      <CmsFormInput.Switch id="locked" label="Bloqué" />
    </CmsForm>
  );
};
